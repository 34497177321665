import React, { useEffect, useState } from "react"
import { Chart } from "react-google-charts";
import { IModelStatisticsDailyItem } from "../appStatistics";

type Props = {
    createdModels: IModelStatisticsDailyItem[];
    createdVersions: IModelStatisticsDailyItem[];
    chartContainerStyle?: React.CSSProperties;
}

type ChartHeader = [{ type: "date" }, "New uploaded models", "New model design options"];
type ChartData = [serie: Date, newModels: number, newVersions: number];
type ChartSource = [ChartHeader, ...ChartData[]]

export const DailyModelsStatistics = ({ createdModels, createdVersions, chartContainerStyle }: Props) => {
    const chartHeader: ChartHeader = [{ type: "date" }, "New uploaded models", "New model design options"];
    const [chartSource, setChartSource] = useState<ChartSource>([chartHeader]);

    useEffect(() => {
        const data: ChartSource = [chartHeader];

        for (let i = 0; i < Math.max(createdModels.length, createdVersions.length); i++) {
            const models = createdModels[i];
            const versions = createdVersions[i];

            const date = models?.date || versions.date;

            data.push([
                new Date(date),
                models?.createdCount || 0,
                versions?.createdCount || 0
            ]);
        }

        setChartSource(data);
    }, [createdModels, createdVersions])

    return <div style={{...chartContainerStyle}}>
        <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={chartSource}
            options={{
                title: "Models",
                legend: { position: "top", maxLines: 3 }
            }} />
    </div>
}