import { LoginActionType } from '../actions/loginActions'

export type State = {
    login: string;
    password: string;
    loginResult: {
        isSuccess: boolean;
        message: string;
    }
}

export const initialState: State = {
    login: "",
    password: "",
    loginResult: {
        isSuccess: true,
        message: ""
    }
}

export const canSignIn = function (state: State) {
    return state.login !== "" && state.password !== "";
}

export const getUserName = function (state: State) {
    return state.login;
}

export const getPassword = function (state: State) {
    return state.password;
}

export const getLoginResults = function (state: State) {
    return state.loginResult;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state: State = initialState, action: LoginActionType): State {
    switch (action.type) {
        case "EDIT_LOGIN_FORM_MESSAGE":
            return { ...state, login: action.login, loginResult: { isSuccess: true, message: "" } }

        case "EDIT_PASSWORD_FORM_MESSAGE":
            return { ...state, password: action.password, loginResult: { isSuccess: true, message: "" } }

        case "SHOW_LOGIN_FAILED":
            return { ...state, loginResult: { isSuccess: false, message: action.message } };

        default:
            return state;
    }
}