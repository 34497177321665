import React, { useState } from "react";
import { Plus } from "react-feather";
import { TextH4 } from "../assets/text/index";
import { styles } from "../assets/addModelStyles";
import { buttonStyles } from "../assets/buttonStyles";
import { colors } from "../config/colors";
import { useButtonState } from "./useButtonState";

type Props = {
    onClick: () => void;
    colored?: boolean;
    hovered?: boolean;
}

export const AddModel = ({ onClick, colored}: Props) => {
    const addModelButtonState = useButtonState();

    return (
        <div style={{ position: "relative", flex: 1 }}>
            <div
                style={{
                    ...styles.card,
                    ...(colored && addModelButtonState.isHovered ? buttonStyles.hoveredButton : {}),
                    ...(colored && addModelButtonState.isSelected ? buttonStyles.selectedButton : {}),
                }}
                onClick={onClick}
                onMouseEnter={addModelButtonState.handleMouseEnter}
                onMouseLeave={() => { addModelButtonState.handleMouseLeave && addModelButtonState.handleMouseLeave(); addModelButtonState.handleMouseUp() }}
                onMouseDown={addModelButtonState.handleMouseDown}
                onMouseUp={addModelButtonState.handleMouseUp}
            >
                <Plus style={{
                    ...styles.iconS,
                    stroke: addModelButtonState.isSelected ? colors.textBlack : colored && addModelButtonState.isHovered ? colors.hovered : colors.subheading,
                }} />

            </div>
            <TextH4 style={{ ...styles.text, ...(colored && addModelButtonState.isHovered ? buttonStyles.hoveredText : {}) }}>New model</TextH4>
        </div>
    );
};