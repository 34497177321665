import React from "react"

const folderTopSelector: React.CSSProperties = {
    display: "flex",
    height: 50,
    flexDirection: "row",
    alignSelf: "flex-start",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 7,
}

const logo: React.CSSProperties = {
    width: "100%",
    height: 42,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 8,
    marginRight: 5
};

export const styles = { folderTopSelector, logo };