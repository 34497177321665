import React from "react"

const hoveredText: React.CSSProperties = { 
    color: "#304E49"
}

const hoveredButton: React.CSSProperties = {
    boxShadow: "0 0 0 2px #304E49",
};

const tinyHoveredButton: React.CSSProperties = {
    boxShadow: "0 0 0 0.5px #304E49",
};

const selectedButton: React.CSSProperties = {
    backgroundColor: "#b8d5d6d4",
    color: "black",
    boxShadow: "none"
}

const selectedText: React.CSSProperties = { 
    color: "black"
}

export const buttonStyles = {
    hoveredText,
    hoveredButton,
    tinyHoveredButton,
    selectedButton,
    selectedText
  };