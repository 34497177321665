import { Tooltip } from "@mui/material";
import React from "react";
import { buttonStyles } from "../assets/buttonStyles";
import { colors } from "../config";

type Props = {
  title?: string;
  prompt?: string
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  colored?: boolean;
  hovered?: boolean;
  handleMouseEnter?: () => void;
  handleMouseLeave?: () => void;
  selected?: boolean;
  handleMouseDown?: () => void;
  handleMouseUp?: () => void;
}

export const AppButton = ({ title, prompt, style, onClick, children, colored, hovered, handleMouseEnter, handleMouseLeave, selected, handleMouseDown, handleMouseUp }: Props) => {

  return (
    <Tooltip title={prompt}>
      <button
        style={{ 
          ...styles.button, 
          ...style, 
          ...(colored && hovered) && (buttonStyles.hoveredButton),
          ...(colored && selected ? buttonStyles.selectedButton : {}) 
        }}
        
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => { handleMouseLeave && handleMouseLeave(); handleMouseUp && handleMouseUp() }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        <>
          {title && title}
          {children && children}
        </>
      </button>
    </Tooltip>
  );
};


const styles = {
  button: {
    backgroundColor: colors.folderTopButton,
    color: colors.tag,
    fontFamily: "Arial",
    fontSize: "15px",
    lineHeight: "20px",
    borderRadius: "6px",
    border: "none",
    padding: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
};
