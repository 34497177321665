import React, { useEffect, useRef, useState } from 'react';
import { Search } from 'react-feather';
import { styles } from '../assets/searchBarStyles';

interface SearchBarProps {
    searchQuery: string;
    setSearchQuery: (query: string) => void;
    onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    isAdminPanel?: boolean;
}

const SearchBar = ({ searchQuery, setSearchQuery, onKeyUp, isAdminPanel }: SearchBarProps) => {
    const [isSearching, setIsSearching] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    
    const handleSearchClick = () => {
        setIsSearching(true);
    };

    const handleSearchBlur = () => {
        setIsSearching(false);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if ((event.ctrlKey || event.metaKey) && event.code === "KeyF") {
                handleSearchClick();
                event.preventDefault();
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const dynamicSearchInputContainerStyle = {
        ...(isAdminPanel ? styles.adminPanel : styles.searchInputContainer),
        border: `1.5px solid ${isSearching ? (isAdminPanel ? '#dee2e6' : 'black') : (isAdminPanel ? 'white' : '#c4c4c4')}`,
    };
      
    return (
        <div style={dynamicSearchInputContainerStyle}>
            <input
                ref={inputRef}
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={handleInputChange}
                onClick={handleSearchClick}
                onBlur={handleSearchBlur}
                onKeyUp={onKeyUp}
                style={styles.inputField}
                autoComplete="off"
            />
            <div style={isAdminPanel ? styles.adminPanelSearchIcon : styles.searchIcon}>
                <Search size={18} />
            </div>
        </div>
    );
};

export default SearchBar;
