import React from "react";
import { colors } from "../config/index";

type Styles = {
    container: React.CSSProperties;
    logo: React.CSSProperties;
    login: React.CSSProperties;
    image: React.CSSProperties;
    title: React.CSSProperties;
    input: React.CSSProperties;
    button: React.CSSProperties;
}

export const styles: Styles = {
    container: {
        display: "flex",
        flex: 1,
        height: "100vh",
        maxHeight: "100vh",
        overflow: "hidden",
        // backgroundColor: "pink",
    },
    logo: {
        backgroundColor: colors.formBg,
        borderRadius: "12px",
    },
    login: {
        display: "flex",
        flex: "1",
        alignItems: "center",
        flexDirection: "column",
        alignSelf: "center",
        marginRight: 25,
        marginLeft: 25,
    },
    image: {
        overflow: "hidden",
        width: 124,
        height: 124,
        background: colors.formBg,
        borderRadius: 100,
        marginBottom: 33,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    title: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "500px medium",
        fontSize: "32px",
        lineHeight: "40px",
        margin: "0 0 33px  0",
        color: colors.textBlack,
    },

    input: {
        display: "flex",
        background: colors.white,
        border: "1px solid #DCDCDC",
        boxSizing: "border-box",
        borderRadius: "4px",
        width: "320px",
        height: "40px",
        outline: "none",
        padding: "10px 12px",
        marginTop: 4,
        marginBottom: 4,
    },
    button: {
        marginTop: 20,
        padding: "10px 20px",
    },
};
