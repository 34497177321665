import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setModelName } from "../actions/sourceRevitModelsActions";
import { ProcessingPane } from './processingPane';
import { ProcessingFailure } from './processingFailure';
import styles from '../assets/optionStyles';
import repo from "../Repository";
import { Model3D } from '../reducers/modelsReducer';

type Props = {
    model: Model3D;

    onEditingDone: () => void;
}

const ModelNameEditor = ({ model, onEditingDone }: Props) => {
    const dispatch = useDispatch();
    const [newName, setNewName] = useState(model.name || "");
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewName(event.target.value);
    };

    const handleBlur = async () => {
        if (newName !== model.name) {
            setIsProcessing(true);
            
            const response = await repo.renameModel(model.id, newName);

            setIsProcessing(false);

            if (response.isSuccess) {
                dispatch(setModelName(model.id, newName));
                onEditingDone();
            } else {
                setErrorMessage(response.message);
                setNewName(model.name || "");
            }
        } else
            onEditingDone();
    };

    const closeProcessingErrorDialog = () => {
        setErrorMessage("");
        onEditingDone();
    }

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === "Enter" || e.code === "NumpadEnter")
            handleBlur();

        if (e.code === "Escape")
            onEditingDone();
    }

    return (
        <div>
            <ProcessingPane isVisible={isProcessing} title={"Updating the model name"} message="" />
            <div style={{marginTop: 4}}>
                <input
                    value={newName}
                    onChange={handleNameChange}
                    onBlur={handleBlur}
                    onKeyUp={handleKeyUp}
                    autoFocus
                    style={{ ...styles.container, ...styles.input }}
                />
                {errorMessage && <ProcessingFailure
                    style={{ marginTop: "168px" }} 
                    closeProcessingErrorDialog={closeProcessingErrorDialog}
                    isVisible={true}
                    message={errorMessage}
                    title="Error" />}
            </div>
        </div>
    );
};

export default ModelNameEditor;