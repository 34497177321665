import { SourceRevitModelActionType } from '../actions/sourceRevitModelsActions';
import { IEngineeringProductionModel, IModel3D, IModel3DVersion, ModelTranslationStatus, ModelWorkflowStatus } from '../components/model3d';

export type Model3D = IModel3D & { selectedVersionIndex: number };

export type State = {
    models: Model3D[];
    selectedModel: Model3D | null;
    selectedModelVersion: IModel3DVersion | null;
    highLodViewer: boolean;
    selectedModelDownloadUrl: string;
    engineeringProductionModels: IEngineeringProductionModel[];
    selectedEngineerindProductionModel: IEngineeringProductionModel | null;
    modelsLoading: boolean;
    engineeringModelsLoading: boolean,
}

export const initialState: State = {
    models: [],
    selectedModel: null,
    selectedModelVersion: null,
    highLodViewer: false,
    selectedModelDownloadUrl: "",
    engineeringProductionModels: [],
    selectedEngineerindProductionModel: null,
    modelsLoading: false,
    engineeringModelsLoading: false,
}

export const getModels = function (state: State) {
    return state.models;
}

export const getSelectedModel = function (state: State) {
    return state.selectedModel;
}

export const getSelectedModelVersion = function (state: State) {
    return state.selectedModelVersion;
}

export const isHighLodModelViewMode = function (state: State) {
    return state.highLodViewer;
}

export const getSelectedModelDownloadUrl = function (state: State) {
    return state.selectedModelDownloadUrl;
}

export const getEngineeringProductionModels = function (state: State) {
    return state.engineeringProductionModels;
}

export const getSelectedEngineeringProductionModel = function (state: State) {
    return state.selectedEngineerindProductionModel;
}

export const getIsModelLoading = function (state: State) {
    return state.modelsLoading;
}

export const getIsEngineeringModelLoading = function (state: State) {
    return state.engineeringModelsLoading;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state: State = initialState, action: SourceRevitModelActionType): State {
    switch (action.type) {
        case "SET_SOURCE_MODELS": {
            const models: Model3D[] = action.models.map(x => {
                const selectedVersionIndex = Math.max(x.versions.findIndex(v => v.isMainOption), 0);

                return { ...x, selectedVersionIndex }
            });

            const selectedModel = state.selectedModel && models.find(x => x.id === state.selectedModel?.id) ? state.selectedModel : null;

            if (selectedModel?.versions[0].status === ModelTranslationStatus.Created)
                return { ...state, models: models, selectedModel: null, selectedModelVersion: null };

            const selectedModelVersion = selectedModel && selectedModel.versions[selectedModel.selectedVersionIndex] || null;

            return { ...state, models: models, selectedModel, selectedModelVersion, highLodViewer: false, selectedModelDownloadUrl: "" };
        }

        case "SET_SELECTED_MODEL": {
            let selectedModel = state.models.find(x => x.id === action.modelId) || null;

            if (selectedModel?.versions[0].status === ModelTranslationStatus.Created)
                return { ...state };

            const versionIndex = action.versionIndex !== undefined
                ? action.versionIndex
                : selectedModel?.selectedVersionIndex || 0;

            if (selectedModel)
                selectedModel = { ...selectedModel, selectedVersionIndex: versionIndex };

            const selectedModelVersion = selectedModel && selectedModel.versions[versionIndex] || null;

            return { ...state, selectedModel: selectedModel ? { ...selectedModel } : null, selectedModelVersion, highLodViewer: false, selectedModelDownloadUrl: "" };
        }

        case "REMOVE_CURRENT_MODEL": {
            return removeCurrentModel(state);
        }

        case "REMOVE_CURRENT_MODEL_VERSION": {
            if (!state.selectedModel)
                return state;

            if (state.selectedModel.versions.length === 1)
                return removeCurrentModel(state);

            const selectedModelId = state.selectedModel.id;
            const selectedVersionIndex = state.selectedModel.selectedVersionIndex;

            const updateModel = (model: Model3D) => {
                if (model.id !== selectedModelId)
                    return model;

                const versions = [...model.versions];

                versions.splice(selectedVersionIndex, 1);

                const newVersionIndex = model.selectedVersionIndex < versions.length ? model.selectedVersionIndex : model.selectedVersionIndex - 1;

                return { ...model, versions: versions, selectedVersionIndex: newVersionIndex };
            }

            const models = state.models.map(x => updateModel(x));

            const selectedModel = updateModel(state.selectedModel);

            const selectedModelVersion = selectedModel.versions[selectedModel.selectedVersionIndex];

            return { ...state, models, selectedModel, selectedModelVersion, highLodViewer: false, selectedModelDownloadUrl: "" };
        }

        case "SET_PREVIOUS_MODEL_VERSION_SELECTED": {
            if (!state.selectedModel || state.selectedModel.selectedVersionIndex === state.selectedModel.versions.length - 1)
                return state;

            const updateModel = (model: Model3D) => {
                if (model.id !== state.selectedModel?.id)
                    return model;

                return { ...model, selectedVersionIndex: model.selectedVersionIndex + 1 }
            }

            const selectedModel = updateModel(state.selectedModel);

            const selectedModelVersion = selectedModel.versions[selectedModel.selectedVersionIndex];

            const models = state.models.map(x => updateModel(x));

            return { ...state, models, selectedModel, selectedModelVersion, highLodViewer: false, selectedModelDownloadUrl: "" };
        }

        case "SET_NEXT_MODEL_VERSION_SELECTED": {
            if (!state.selectedModel || state.selectedModel.selectedVersionIndex === 0)
                return state;

            const updateModel = (model: Model3D) => {
                if (model.id !== state.selectedModel?.id)
                    return model;

                return { ...model, selectedVersionIndex: model.selectedVersionIndex - 1 }
            }

            const models = state.models.map(x => updateModel(x));

            const selectedModel = updateModel(state.selectedModel);

            const selectedModelVersion = selectedModel.versions[selectedModel.selectedVersionIndex];

            return { ...state, models, selectedModel, selectedModelVersion, highLodViewer: false, selectedModelDownloadUrl: "" };
        }

        case "SET_CURRENT_MODEL_SELECTED_VERSION_INDEX": {
            if (!state.selectedModel)
                return state;

            const updateModel = (model: Model3D): Model3D => {
                if (model.id !== state.selectedModel?.id)
                    return model;

                return { ...model, selectedVersionIndex: action.versionIndex };
            }

            const models = state.models.map(x => updateModel(x));

            const selectedModel = updateModel(state.selectedModel);

            const selectedModelVersion = selectedModel.versions[selectedModel.selectedVersionIndex];

            return { ...state, models, selectedModel, selectedModelVersion, highLodViewer: false, selectedModelDownloadUrl: "" };
        }

        case "SET_CURRENT_MODEL_SELECTED_VERSION_PIN": {
            if (!state.selectedModel)
                return state;

            const updateModel = (model: Model3D): Model3D => {
                if (model.id !== state.selectedModel?.id)
                    return model;

                const versions = model
                    .versions
                    .map((x, i) => ({ ...x, isMainOption: i === model.selectedVersionIndex && action.value }));

                return { ...model, versions };
            }

            const models = state.models.map(x => updateModel(x));

            const selectedModel = updateModel(state.selectedModel);

            const selectedModelVersion = selectedModel.versions[selectedModel.selectedVersionIndex];

            return { ...state, models, selectedModel, selectedModelVersion, selectedModelDownloadUrl: "" };
        }

        case "SET_MODEL_VERSION_SUBMITTED": {
            if (!state.selectedModelVersion)
                return state;
            const updateModel = (model: Model3D): Model3D => {
                if (model.id !== state.selectedModel?.id)
                    return model;

                const versions = model.versions.map((x, i) => ({ ...x, workflowStatus: i === model.selectedVersionIndex ? ModelWorkflowStatus.Submitted : x.workflowStatus }));

                return { ...model, versions };
            }
            const models = state.models.map(x => updateModel(x));
            const selectedModel = updateModel(state.selectedModel!);
            const selectedModelVersion = { ...state.selectedModelVersion, workflowStatus: ModelWorkflowStatus.Submitted };

            return { ...state, models, selectedModel: selectedModel, selectedModelVersion: selectedModelVersion, selectedModelDownloadUrl: "" }
        }

        case "SET_MODEL_VERSION_BID": {
            if (!state.selectedModelVersion)
                return state;
            const updateModel = (model: Model3D): Model3D => {
                if (model.id !== state.selectedModel?.id)
                    return model;

                const versions = model.versions.map((x, i) => ({ ...x, workflowStatus: i === model.selectedVersionIndex ? ModelWorkflowStatus.Bid : x.workflowStatus }));

                return { ...model, versions };
            }

            const models = state.models.map(x => updateModel(x));
            const selectedModel = updateModel(state.selectedModel!);
            const selectedModelVersion = { ...state.selectedModelVersion, workflowStatus: ModelWorkflowStatus.Bid };

            return { ...state, models, selectedModel, selectedModelVersion, selectedModelDownloadUrl: "" }
        }

        case "SET_MODEL_VERSION_APPROVED": {
            if (!state.selectedModelVersion)
                return state;
            const updateModel = (model: Model3D): Model3D => {
                if (model.id !== state.selectedModel?.id)
                    return model;

                const versions = model.versions.map((x, i) => ({ ...x, workflowStatus: i === model.selectedVersionIndex ? ModelWorkflowStatus.Approved : x.workflowStatus }));

                return { ...model, versions };
            }
            const models = state.models.map(x => updateModel(x));
            const selectedModel = updateModel(state.selectedModel!);
            const selectedModelVersion = { ...state.selectedModelVersion, workflowStatus: ModelWorkflowStatus.Approved };

            return { ...state, models, selectedModel: selectedModel, selectedModelVersion: selectedModelVersion, selectedModelDownloadUrl: "" }
        }

        case "SET_MODEL_FORGE_PERCENTAGE": {
            const models: Model3D[] = state
                .models
                .map(x => {
                    if (x.versions[0].id !== action.modelId)
                        return x;

                    const model: IModel3DVersion = { ...x.versions[0], forgePercentage: action.percentage, forgeStatus: action.forgeStatus };

                    return { ...x, versions: [model] };
                });

            return { ...state, models };
        }

        case "SET_MODEL_COMPLETED": {
            const models: Model3D[] = state
                .models
                .map(x => {
                    if (x.versions[0].id !== action.modelId)
                        return x;

                    const model: IModel3DVersion = { ...x.versions[0], status: ModelTranslationStatus.Ready };

                    return { ...x, versions: [model] };
                });

            return { ...state, models };
        }

        case "SET_MODEL_FAILED": {
            const models = state
                .models
                .map(x => {
                    if (x.versions[0].id !== action.modelId)
                        return x;

                    const model: IModel3DVersion = { ...x.versions[0], status: ModelTranslationStatus.Failed, forgeStatus: action.forgeStatus };

                    return { ...x, versions: [model] };
                });

            return { ...state, models };
        }

        case "TOGGLE_HIGH_LOD_MODE": {
            return { ...state, highLodViewer: !!action.mode }
        }

        case "SET_SELECTED_MODEL_DOWNLOAD_URL": {
            return { ...state, selectedModelDownloadUrl: action.value }
        }

        case "SET_ENGINEERING_PRODUCTION_MODELS": {
            return { ...state, engineeringProductionModels: action.models, selectedEngineerindProductionModel: null }
        }

        case "SET_SELECTED_ENGINEERING_PRODUCTION_MODEL": {
            return { ...state, selectedEngineerindProductionModel: state.engineeringProductionModels.find(x => x.id === action.modelId) || null }
        }

        case "SET_MODEL_VERSION_NAME": {
            if (!state.selectedModel) return state;

            const updatedVersions = state.selectedModel.versions.map(version =>
                version.id === action.versionId ? { ...version, optionName: action.newName } : version
            );

            const updatedSelectedModel = { ...state.selectedModel, versions: updatedVersions };

            const updatedSelectedModelVersion = updatedVersions.find(v => v.id === action.versionId) || null;

            return {
                ...state,
                selectedModel: updatedSelectedModel,
                selectedModelVersion: updatedSelectedModelVersion
            };
        }

        case "SET_MODELS_LOADING": {
            return { ...state, modelsLoading: action.isLoading };
        }

        case "SET_ENGINEERING_MODELS_LOADING": {
            return { ...state,  engineeringModelsLoading: action.isLoading };
        }

        case "SET_MODEL_NAME": {
            const updatedModels = state.models.map((model) => {
                if (model.id === action.modelId) {
                    return { ...model, name: action.newName };
                }
                return model;
            });
        
            const updatedSelectedModel = state.selectedModel && state.selectedModel.id === action.modelId
                ? { ...state.selectedModel, name: action.newName }
                : state.selectedModel;
        
            return {
                ...state,
                models: updatedModels,
                selectedModel: updatedSelectedModel,
            };
        }

        default:
            return state;
    }
}

const removeCurrentModel = (state: State): State => {
    if (!state.selectedModel)
        return state;

    const models = state.models.filter(x => x.id !== state.selectedModel?.id);

    const selectedModel = models.find(x => x) || null;

    if (selectedModel?.versions[0].status === ModelTranslationStatus.Created)
        return { ...state, models: models, selectedModel: null, selectedModelVersion: null };

    const selectedModelVersion = selectedModel && selectedModel.versions[0] || null;

    return { ...state, models, selectedModel, selectedModelVersion, highLodViewer: false, selectedModelDownloadUrl: "" }
}