import React, { useEffect, useState } from "react"
import { Chart } from "react-google-charts";
import { ISessionsWeeklyStatisticsItem, IWeeklyActiveUsersStatisticsItem } from "../appStatistics";

type Props = {
    weeklySessions: ISessionsWeeklyStatisticsItem[];
    activeUsersWeekly: IWeeklyActiveUsersStatisticsItem[];
    chartContainerStyle?: React.CSSProperties;
}

type ChartHeader = ["Interval", "Sessions count", "Active users"]
type ChartData = [serie: string, sessionsCount: number, usersCount: number];
type ChartSource = [ChartHeader, ...ChartData[]]

export const WeeklySessionsStatistics = ({ weeklySessions, activeUsersWeekly, chartContainerStyle }: Props) => {
    const chartHeader: ChartHeader = ["Interval", "Sessions count", "Active users"];
    const [chartSource, setChartSource] = useState<ChartSource>([chartHeader]);

    useEffect(() => {
        const data: ChartSource = [chartHeader];

        for (let i = 0; i < weeklySessions.length; i++) {
            const sessions = weeklySessions[i];
            const activeUsers = activeUsersWeekly[i];

            data.push([
                `${new Date(sessions.start).toLocaleDateString()} - ${new Date(sessions.end).toLocaleDateString()}`,
                sessions.sessionsCount,
                activeUsers.usersCount
            ]);
        }

        setChartSource(data);
    }, [weeklySessions, activeUsersWeekly]);

    return <div style={{...chartContainerStyle}}>
        <Chart
            chartType="SteppedAreaChart"
            width="100%"
            height="400px"
            data={chartSource}
            options={{
                title: "Sessions",
                legend: { position: "top", maxLines: 3 }
            }} />
    </div>
}