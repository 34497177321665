import React, { useState } from 'react';
import { connect } from 'react-redux';
import { styles } from "../assets/studioPaneStyles";
import {
    createNewModelVersion,
    pushCurrentModelToEngineeringService,
    submitCurrentModelToApprove,
    submitCurrentModelToReview,
    submitModelToBid,
    createNewModelFromBidModel
} from "../actions/sourceRevitModelsActions";
import { showProcessingError, showProcessingPane } from "../actions/processingActions";
import { setAnalyticsVisibility } from "../actions/applicationUIActions";
import { getCurrentProject, getSelectedModelVersion, isAnalyticsTabVisible, isBidManager, State } from "../reducers/mainReducer";
import { ChevronRight } from "react-feather";
import { card, colors } from "../config/colors";
import { AddCopy } from "../assets/icons/addCopy";
import { AppButton } from "./appButton";
import { TextBody2, TextH2 } from "../assets/text/index";
import { ConfirmDialog } from "./confirmDialog";
import { IProject, SystemType } from './project';
import { IModel3DVersion, ModelTranslationStatus, ModelWorkflowStatus } from './model3d';
import { dWallColor, retrofitColor, shieldicButtonStyle, snowmanColor } from '../assets/panelSystemTypeStyles';
import { ApplicationType } from '../applicationType';
import Input from "@weave-design/input";
import Label from "@weave-design/label";
import { ModelSubmitToBidControls } from './modelSubmitToBidControls';
import { CreateNewModelFromBidControls } from './createNewModelFromBidControls';
import { useButtonState } from './useButtonState';

interface IComponentProps {
    project: IProject | null;
    selectedModelVersion: IModel3DVersion | null;
    isAnalyticsTabVisible: boolean;
    isBidManager: boolean;
    applicationType: ApplicationType

    createNewModelVersion: (applicationType: ApplicationType, modelId: string) => void;
    setAnalyticsVisibility: (isVisible: boolean) => void;
    submitCurrentModelToReview: () => void;
    submitModelToBid: () => void;
    submitCurrentModelToApprove: (value: string) => void;
    pushCurrentModelToEngineeringService: () => void;
    showProcessingPane: (visible: boolean, title?: string) => void;
    showProcessingError: (title: string, message: string) => void;
    createNewModelFromBidModel: (sourceModelId: string, targetProjectId: string) => void;
}

export const ModelToolbarControls = (props: IComponentProps) => {
    const addCopyButtonState = useButtonState();
    const submitButtonState = useButtonState();
    const analyticsButtonState = useButtonState();

    const [confirmSubmitToReviewDialogVisible, setConfirmSubmitToReviewDialogVisible] = useState(false);
    const [confirmSubmitToApproveDialogVisible, setConfirmSubmitToApproveDialogVisible] = useState(false);
    const [confirmPushToEngineeringVisible, setConfirmPushToEngineeringVisible] = useState(false);
    const [comment, setComment] = useState("");

    const _commentChanged = function (data: any) {
        const inputValue = data.target.value;
        setComment(inputValue);
    }

    const getSystemShieldic = () => {
        switch (props.project?.systemType) {
            case SystemType.Snowman:
                return {
                    color: snowmanColor,
                    title: "S"
                };

            case SystemType.Retrofit:
                return {
                    color: retrofitColor,
                    title: "R"
                };

            default:
                return {
                    color: dWallColor,
                    title: "D"
                };
        }
    }

    return (<div style={styles.modelFolderTop}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", alignSelf: "flex-end", paddingTop: "5px" }}>
            <div style={styles.topTextContainer}>
                {props.selectedModelVersion && <>
                    {props.applicationType !== "bid" &&
                        <AppButton
                            style={{ padding: 12, backgroundColor: card.versionProject, marginLeft: 8, marginRight: 8 }}
                            onClick={() => props.createNewModelVersion(props.applicationType, props.selectedModelVersion!.id)}
                            colored={true}
                            hovered={addCopyButtonState.isHovered}
                            handleMouseEnter={addCopyButtonState.handleMouseEnter}
                            handleMouseLeave={addCopyButtonState.handleMouseLeave}
                            selected={addCopyButtonState.isSelected}
                            handleMouseDown={addCopyButtonState.handleMouseDown}
                            handleMouseUp={addCopyButtonState.handleMouseUp}
                        >
                            <AddCopy colored={true} color={addCopyButtonState.isSelected ? colors.textBlack : addCopyButtonState.isHovered ? colors.hovered : "#9A9B9B"} />
                        </AppButton>}

                    {props.applicationType === "bid" && <CreateNewModelFromBidControls
                        selectedModelVersion={props.selectedModelVersion}
                        showProcessingPane={props.showProcessingPane}
                        showProcessingError={props.showProcessingError}
                        createNewModelFromBidModel={props.createNewModelFromBidModel} />}

                    <AppButton
                        style={{ ...shieldicButtonStyle, backgroundColor: getSystemShieldic().color }}>
                        {getSystemShieldic().title}
                    </AppButton>
                </>}
                {props.project && <div style={{ flexDirection: "column" }}>
                    <TextBody2 style={{ color: colors.subheading, lineHeight: "17px" }}>Project</TextBody2>
                    <TextH2 style={{ fontSize: 17 }}>{props.project.name}</TextH2>
                </div>}
                {props.selectedModelVersion && <>
                    <ChevronRight style={{ marginLeft: 8, marginRight: 8 }} color={colors.subheading} size="20" />

                    <div style={{ flexDirection: "column" }}>
                        <TextBody2 style={{ color: colors.subheading, lineHeight: "17px" }}>Model</TextBody2>
                        <TextH2 style={{ fontSize: 17 }}>{props.selectedModelVersion.fileName}</TextH2>
                    </div>

                    <ChevronRight style={{ marginLeft: 8, marginRight: 8 }} color={colors.subheading} size="20" />

                    <div style={{ flexDirection: "column" }}>
                        <TextBody2 style={{ color: colors.subheading, lineHeight: "17px" }}>Option</TextBody2>
                        <TextH2 style={{ fontSize: 17 }}>{
                            props.selectedModelVersion.optionName
                                ? `${props.selectedModelVersion.versionNumber} - ${props.selectedModelVersion.optionName}`
                                : props.selectedModelVersion.versionNumber
                        }</TextH2>
                    </div>
                </>}
            </div>
            {props.selectedModelVersion && props.selectedModelVersion.status === ModelTranslationStatus.Ready &&
                <div style={{ display: "flex" }}>
                    {props.selectedModelVersion.workflowStatus === ModelWorkflowStatus.Draft &&
                        <>
                            <AppButton
                                style={{
                                    backgroundColor: colors.folderTopButton,
                                    padding: "10px 17px",
                                    marginRight: 10,
                                    color: submitButtonState.isSelected ? colors.textBlack : (submitButtonState.isHovered ? colors.hovered : colors.tag)
                                }}
                                onClick={() => setConfirmSubmitToReviewDialogVisible(true)}
                                colored={true}
                                hovered={submitButtonState.isHovered}
                                handleMouseEnter={submitButtonState.handleMouseEnter}
                                handleMouseLeave={submitButtonState.handleMouseLeave}
                                selected={submitButtonState.isSelected}
                                handleMouseDown={submitButtonState.handleMouseDown}
                                handleMouseUp={submitButtonState.handleMouseUp}
                            >
                                Submit
                            </AppButton>

                            {props.isBidManager && props.applicationType === "ar" && <ModelSubmitToBidControls
                                selectedModelVersion={props.selectedModelVersion}
                                submitModelToBid={props.submitModelToBid} />}
                        </>}
                    {props.selectedModelVersion.workflowStatus !== ModelWorkflowStatus.Approved && props.applicationType === "en" &&
                        <AppButton
                            style={{
                                backgroundColor: colors.folderTopButton,
                                padding: "10px 17px",
                                marginRight: 10,
                            }}
                            onClick={() => setConfirmSubmitToApproveDialogVisible(true)}>Approve</AppButton>}

                    {props.selectedModelVersion.workflowStatus === ModelWorkflowStatus.Approved && props.applicationType === "en" &&
                        <AppButton
                            style={{
                                backgroundColor: colors.folderTopButton,
                                padding: "10px 17px",
                                marginRight: 10,
                            }}
                            onClick={() => setConfirmPushToEngineeringVisible(true)}>Create engineering model</AppButton>}
                    <AppButton
                        style={{
                            backgroundColor: colors.folderTopButton,
                            padding: "10px 17px",
                            color: analyticsButtonState.isSelected ? colors.textBlack : (analyticsButtonState.isHovered ? colors.hovered : colors.tag)
                        }}
                        onClick={() => { props.setAnalyticsVisibility(!props.isAnalyticsTabVisible); analyticsButtonState.setSelected(!analyticsButtonState.isSelected); }}
                        colored={true}
                        hovered={analyticsButtonState.isHovered}
                        handleMouseEnter={analyticsButtonState.handleMouseEnter}
                        handleMouseLeave={analyticsButtonState.handleMouseLeave}
                        selected={analyticsButtonState.isSelected}
                    >
                        Analytics
                    </AppButton>
                </div>}
        </div>
        <ConfirmDialog
            isOpened={confirmSubmitToReviewDialogVisible}
            title="Submit model"
            confirmButtonTitle="Submit"
            onCancel={() => setConfirmSubmitToReviewDialogVisible(false)}
            onConfirm={() => {
                setConfirmSubmitToReviewDialogVisible(false);
                props.submitCurrentModelToReview();
            }}
            windowStyles={{ width: "500px", height: "220px" }}
            promptContainerStyles={{ height: "50px" }}
        >Are you sure you want to submit model <b>{props.selectedModelVersion?.fileName}</b> for review?</ConfirmDialog>
        <ConfirmDialog
            isOpened={confirmSubmitToApproveDialogVisible}
            title="Approve model"
            confirmButtonTitle="Approve"
            onCancel={() => setConfirmSubmitToApproveDialogVisible(false)}
            onConfirm={() => {
                setConfirmSubmitToApproveDialogVisible(false);
                props.submitCurrentModelToApprove(comment);
            }}
            windowStyles={{ width: "500px", height: "330px" }}
            promptContainerStyles={{ height: "160px" }}
        >
            Are you sure you want to approve model <b>{props.selectedModelVersion?.fileName}</b>?
            <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
                <div style={{ marginTop: "auto", marginBottom: "auto", width: "25%" }}>
                    <Label style={{ fontSize: "18px", fontWeight: "600" }}>Comment</Label>
                </div>
                <div style={{ display: "flex", width: "100%", marginTop: "20px" }}>
                    <Input tagName="textarea"
                        value={comment}
                        onChange={(data: any) => _commentChanged(data)}
                        variant="box"
                        stylesheet={commentBoxStyleSheet} />
                </div>
            </div>
        </ConfirmDialog>

        <ConfirmDialog
            isOpened={confirmPushToEngineeringVisible}
            title="Push to engineering"
            confirmButtonTitle="Push"
            onCancel={() => setConfirmPushToEngineeringVisible(false)}
            onConfirm={() => {
                setConfirmPushToEngineeringVisible(false);
                props.pushCurrentModelToEngineeringService();
            }}>
            Are you sure you want to push model <b>{props.selectedModelVersion?.fileName}</b> to the engineering service?
        </ConfirmDialog>
    </div>)
}

const commentBoxStyleSheet = (styleSheet: any) => {
    if (styleSheet.input)
        return { ...styleSheet, input: { ...styleSheet.input, minWidth: "450px", maxWidth: "450px", minHeight: 56, maxHeight: 56, resize: "none" } }

    return styleSheet;
}


export default connect(function (store: State) {
    return {
        project: getCurrentProject(store),
        selectedModelVersion: getSelectedModelVersion(store),
        isAnalyticsTabVisible: isAnalyticsTabVisible(store),
        isBidManager: isBidManager(store)
    };
}, {
    createNewModelVersion, submitCurrentModelToReview, submitModelToBid, submitCurrentModelToApprove,
    createNewModelFromBidModel, pushCurrentModelToEngineeringService, setAnalyticsVisibility,
    showProcessingError, showProcessingPane
})(ModelToolbarControls);