import { useCallback } from "react"
import Label from '@weave-design/label';
import Dropdown from "@weave-design/dropdown";
import { ModelPrepareWallsOffset } from "./model3d";
import "./inputs.css";
import IconButton from '@weave-design/icon-button';
import { Info16 } from "@weave-design/icons";
import Flyout, { anchorPoints } from "@weave-design/flyout";
import { formatWallsOffset } from './modelUtils';

type Props = {
    value: ModelPrepareWallsOffset;
    setValue: (newValue: ModelPrepareWallsOffset) => void;
    style: (baseStyles: any) => any
}

export const ModelPrepareWallsOffsetSelector = ({ value, setValue, style }: Props) => {
    const onChange = useCallback((newValue: Option) => {
        if (newValue)
            setValue(newValue.value);
    }, [value, setValue]);

    const dropdownTooltipContent = (
        <span style={{ fontSize: "12px" }}>
            Select the thickness of the primary cladding layer for your building's facade. This setting determines the depth of the cladding material that, when exported back to your Revit model, will align with the external face of the Revit wall.
        </span>
    );

    const options: Option[] = Object.keys(ModelPrepareWallsOffset)
        .filter((key) => isNaN(+key))
        .map(key => {
            const enumKey = key as keyof typeof ModelPrepareWallsOffset;
            const enumValue = ModelPrepareWallsOffset[enumKey];
            return {
                value: enumValue,
                dropDownOption: formatWallsOffset(enumValue)
            };
        });

    const formatOption = (option: Option) => option.dropDownOption;

    const dropDownStyles = (styles: any) => {
        return {
            ...styles,
            menu: {
                ...styles.menu,
                maxHeight: "180px"
            }
        };
    };

    return <div className="stretch">
        <Label variant="top" style={{ display: "block", marginBottom: 1, marginTop: 3 }}>Primary cladding depth:</Label>
        <div style={{ display: "flex", flexDirection: "row" }}>
            <Dropdown
                variant="box"
                options={options}
                formatOption={formatOption}
                value={options.find(x => x.value === value)}
                onChange={onChange}
                stylesheet={dropDownStyles}
            />
            <Flyout stylesheet={style} content={dropdownTooltipContent} openOnHover={true} anchorPoint={anchorPoints.TOP_RIGHT}>
                <IconButton
                    icon={<Info16 />}
                    title="" />
            </Flyout>
        </div>
    </div>
}

type Option = { value: ModelPrepareWallsOffset, dropDownOption: string }