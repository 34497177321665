import { PanelEditor } from './forge/panelEditor';
import { CornerEditor } from "./forge/cornerEditor";
import { WallWindow } from './forge/wallWindow';
import repo from '../Repository';

export class ModelSaveCycle {
    private readonly newUnsavedPanels = new Set<PanelEditor>();
    private readonly newUnsavedCorners = new Set<CornerEditor>();
    private readonly newUnsavedWindows = new Set<WallWindow>();
    private readonly updatedUnsavedPanels = new Set<PanelEditor>();
    private readonly updatedUnsavedCorners = new Set<CornerEditor>();
    private readonly updatedUnsavedWindows = new Set<WallWindow>();
    private readonly removedPanels = new Set<PanelEditor>();
    private readonly removedCorners = new Set<CornerEditor>();
    private readonly removedWindows = new Set<string>();
    private requestedStop: boolean = false;

    constructor(private readonly modelId: string) {

        this.flushNewPanels = this.flushNewPanels.bind(this);
        this.flushUpdatedPanels = this.flushUpdatedPanels.bind(this);
        this.flushRemovedPanels = this.flushRemovedPanels.bind(this);

        this.flushNewCorners = this.flushNewCorners.bind(this);
        this.flushUpdatedCorners = this.flushUpdatedCorners.bind(this);
        this.flushRemovedCorners = this.flushRemovedCorners.bind(this);

        this.flushNewWindows = this.flushNewWindows.bind(this);
        this.flushUpdatedWindows = this.flushUpdatedWindows.bind(this);
        this.flushRemovedWindows = this.flushRemovedWindows.bind(this);

        this.save = this.save.bind(this);

        this.save();
    }

    addPanel(panel: PanelEditor) {
        this.newUnsavedPanels.add(panel);
    }

    addCorner(corner: CornerEditor) {
        this.newUnsavedCorners.add(corner);
    }

    addWindow(wallWindow: WallWindow) {
        this.newUnsavedWindows.add(wallWindow);
    }

    updatePanel(panel: PanelEditor) {
        if (!this.newUnsavedPanels.has(panel))
            this.updatedUnsavedPanels.add(panel);
    }

    updateCorner(corner: CornerEditor) {
        if (!this.newUnsavedCorners.has(corner))
            this.updatedUnsavedCorners.add(corner);
    }

    updateWallWindow(wallWindow: WallWindow) {
        if (!this.updatedUnsavedWindows.has(wallWindow))
            this.updatedUnsavedWindows.add(wallWindow);
    }

    removePanel(panel: PanelEditor) {
        if (this.newUnsavedPanels.has(panel))
            this.newUnsavedPanels.delete(panel);
        else
            this.removedPanels.add(panel);
    }

    removeCorner(corner: CornerEditor) {
        if (this.newUnsavedCorners.has(corner))
            this.newUnsavedCorners.delete(corner);
        else
            this.removedCorners.add(corner);
    }

    removeWindow(windowId: string) {
        this.removedWindows.add(windowId);
    }

    requestStop() {
        this.requestedStop = true;
    }

    getQueuedTasksCount() {
        return this.newUnsavedPanels.size + this.newUnsavedCorners.size + this.newUnsavedWindows.size
            + this.updatedUnsavedPanels.size + this.updatedUnsavedCorners.size + this.updatedUnsavedWindows.size
            + this.removedPanels.size + this.removedCorners.size + this.removedWindows.size;
    }

    private async save() {
        const newUnsavedPanels = Array.from(this.newUnsavedPanels.values());
        const newUnsavedCorners = Array.from(this.newUnsavedCorners.values());
        const newUnsavedWindows = Array.from(this.newUnsavedWindows.values());

        const updatedUnsavedPanels = Array.from(this.updatedUnsavedPanels.values());
        const updatedUnsavedCorners = Array.from(this.updatedUnsavedCorners.values());
        const updatedUnsavedWindows = Array.from(this.updatedUnsavedWindows.values());

        const removedPanels = Array.from(this.removedPanels.values());
        const removedCorners = Array.from(this.removedCorners.values());
        const removedWindows = Array.from(this.removedWindows.values());

        await Promise.all([
            this.flush(newUnsavedPanels, this.newUnsavedPanels, this.flushNewPanels),
            this.flush(newUnsavedCorners, this.newUnsavedCorners, this.flushNewCorners),
            this.flush(newUnsavedWindows, this.newUnsavedWindows, this.flushNewWindows)
        ]);

        await Promise.all([
            this.flush(updatedUnsavedPanels, this.updatedUnsavedPanels, this.flushUpdatedPanels),
            this.flush(updatedUnsavedCorners, this.updatedUnsavedCorners, this.flushUpdatedCorners),
            this.flush(updatedUnsavedWindows, this.updatedUnsavedWindows, this.flushUpdatedWindows)
        ]);

        await Promise.all([
            this.flush(removedPanels, this.removedPanels, this.flushRemovedPanels),
            this.flush(removedCorners, this.removedCorners, this.flushRemovedCorners),
            this.flush(removedWindows, this.removedWindows, this.flushRemovedWindows)
        ]);

        if (this.shouldStop())
            return;

        setTimeout(async () => this.save(), 1000);
    }

    private async flushNewPanels(items: PanelEditor[]) {
        const panels = items.map(x => x.serialize());

        try {
            await repo.createNewPanels(this.modelId, panels);

            return true;
        } catch (e) {
            console.error("Failed to flush new panels", e);

            return false;
        }
    }

    private async flushNewCorners(items: CornerEditor[]) {
        const corners = items.map(x => x.serialize());

        try {
            await repo.createNewCornerPanels(this.modelId, corners);

            return true;
        } catch (e) {
            console.error("Failed to flush new corner panels", e);

            return false;
        }
    }

    private async flushNewWindows(items: WallWindow[]) {
        const wallWindows = items.map(x => x.serialize());

        try {
            await repo.createNewWindows(this.modelId, wallWindows);

            return true;
        } catch (e) {
            console.error("Failed to flush new wall windows", e);

            return false;
        }
    }

    private async flushUpdatedPanels(items: PanelEditor[]) {
        const panels = items.map(x => x.serialize());

        try {
            await repo.updatePanels(this.modelId, panels);

            return true;
        } catch (e) {
            console.error("Failed to flush updated panels", e);

            return false;
        }
    }

    private async flushUpdatedCorners(items: CornerEditor[]) {
        const corners = items.map(x => x.serialize());

        try {
            await repo.updateCornerPanels(this.modelId, corners);

            return true;
        } catch (e) {
            console.error("Failed to flush updated corner panels", e);

            return false;
        }
    }

    private async flushUpdatedWindows(items: WallWindow[]) {
        const wallWindows = items.map(x => x.serialize());

        try {
            await repo.updateWallWindows(this.modelId, wallWindows);

            return true;
        } catch (e) {
            console.error("Failed to flush updated wall windows", e);

            return false;
        }
    }

    private async flushRemovedPanels(items: PanelEditor[]) {
        const ids = items.map(x => x.panel.id);

        try {
            await repo.removePanels(this.modelId, ids);

            return true;
        } catch (e) {
            console.error("Failed to flush removed panels", e);

            return false;
        }
    }

    private async flushRemovedCorners(items: CornerEditor[]) {
        const ids = items.map(x => x.corner.id);

        try {
            await repo.removeCornerPanels(this.modelId, ids);

            return true;
        } catch (e) {
            console.error("Failed to flush removed corner panels", e);

            return false;
        }
    }

    private async flushRemovedWindows(ids: string[]) {
        try {
            await repo.removeWallWindows(this.modelId, ids);

            return true;
        } catch (e) {
            console.error("Failed to flush removed wall windows", e);

            return false;
        }
    }

    private async flush<T>(sourceItems: T[], itemsToFlush: Set<T>, action: (items: T[]) => Promise<boolean>) {
        if (sourceItems.length === 0)
            return;

        for (const item of sourceItems)
            itemsToFlush.delete(item);

        const result = await action(sourceItems);

        if (result)
            return;

        for (const item of sourceItems)
            itemsToFlush.add(item);
    }

    private shouldStop() {
        return this.requestedStop && this.getQueuedTasksCount() === 0;
    }
}