import React, { SyntheticEvent } from "react"
import { TextH3 } from "../../assets/text";
import { IInsulatedGlassUnit } from "../forge/insulatedGlassUnit";

type Props = {
    insulatedGlassUnit: IInsulatedGlassUnit | null;
    editable: boolean;
    onEditRequested?: () => void;
}

export const IguTitle = ({ insulatedGlassUnit, editable, onEditRequested }: Props) => {
    const onEdit = (e: SyntheticEvent) => {
        if (typeof onEditRequested === "function")
            onEditRequested();

        e.preventDefault();
    }

    return <>
        {insulatedGlassUnit && <TextH3>{insulatedGlassUnit.name} [{insulatedGlassUnit.category}]</TextH3>}
        {editable && <a onClick={e => onEdit(e)}
            style={{
                color: "blue",
                cursor: "pointer",
                fontSize: 17,
                userSelect: "none"
            }}>change...</a>}
    </>
}