import React from "react"
import { TextBody2, TextH3 } from "../assets/text";
import { colors } from "../config/colors";

type Props = {
    title: string;
    text?: string;
    children?: React.ReactNode;
}

export const ModelInfoProperty = ({ title, text, children }: Props) => {
    return <>
        <TextBody2 style={{ color: colors.subheading, margin: "10px 0 0 0 " }}>{title}</TextBody2>
        {text && <TextH3 style={{ margin: "2px 0 0 0 ", fontSize: "15px" }}>{text}</TextH3>}
        {children}
    </>
}