import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Studio from "./components/studio";
import LoginForm from "./components/loginForm";
import UploadForm from "./components/uploadForm";
import ProcessingPane from "./components/processingPane";
import ProcessingFailure from "./components/processingFailure";
import { detectToken } from "./actions/profileActions";
import { fetchInsulatedGlassUnits } from "./actions/insulatedGlassUnitsActions";
import { areProjectsInitialized, getActiveTab, getInsulatedGlassUnits, isLoggedIn, State } from "./reducers/mainReducer";
import { Copyright } from './components/copyright';
import { ForgeInitializer } from "./components/forgeInitializer";
import { PluginsDownload } from './components/pluginsDownload';
import { LoginState } from './actions/common';
import { ApplicationTabType, validateVersion } from './actions/applicationUIActions';
import { styles } from './assets/studioStyles';
import { AppLoadingSpinner } from './components/appLoadingSpinner';
import { AppMaintenance } from './components/maintenance/appMaintenance';
import { ApplicationType } from './applicationType';
import './App.css'

interface IComponentProps {
  isLoggedIn: LoginState;
  glassUnitsLoaded: boolean;
  projectsInitialized: boolean;
  applicationType: ApplicationType;
  activeTab: ApplicationTabType;

  detectToken: (applicationType: ApplicationType) => void;
  fetchInsulatedGlassUnits: () => void;
  validateVersion: () => void;
}

export const App = (props: IComponentProps) => {
  useEffect(() => {
    props.detectToken(props.applicationType);
    props.validateVersion();
  }, []);

  // TODO: move fetching insulation units logic to Forge initializer
  useEffect(() => {
    if (props.isLoggedIn !== "logged")
      return;

    setTimeout(() => props.fetchInsulatedGlassUnits());
  }, [props.isLoggedIn])

  return (
    <>
      {props.isLoggedIn === "not-logged" && <LoginForm />}
      {props.isLoggedIn === "logged" && props.glassUnitsLoaded && <>
        <Studio applicationType={props.applicationType} />
        {props.applicationType === "ar" && <UploadForm />}
        <ProcessingPane />
        <ProcessingFailure />
        <PluginsDownload leftOffset={(props.activeTab === "project" ? 180 : 20)} />
        <ForgeInitializer />
      </>}
      {(props.isLoggedIn === "unknown" || props.isLoggedIn === "logged" && !props.glassUnitsLoaded && !props.projectsInitialized) && <div style={styles.container}>
        <AppLoadingSpinner />
      </div>}
      <Copyright />
      <AppMaintenance />
    </>
  );
}

export default connect(function (store: State) {
  return {
    isLoggedIn: isLoggedIn(store),
    glassUnitsLoaded: getInsulatedGlassUnits(store).length > 0,
    projectsInitialized: areProjectsInitialized(store),
    activeTab: getActiveTab(store)
  }
}, { detectToken, fetchInsulatedGlassUnits, validateVersion })(App);