type ShowProcessingPaneType = {
    type: "PROCESSING_PANE_SHOW";
    visible: boolean;
    title?: string;
}

type ReportProcessingProgressType = {
    type: "PROCESSING_PANE_PROGRESS";
    message: string;
}

type ShowProcessingErrorType = {
    type: "SET_PROCESSING_FAILURE";
    title: string;
    message: string;
}

type CloseProcessingErrorDialogType = {
    type: "CLOSE_PROCESSING_FAILURE"
}

export type ProcessingActionType = ShowProcessingPaneType | ReportProcessingProgressType | ShowProcessingErrorType | CloseProcessingErrorDialogType;

export const showProcessingPane = (visible: boolean, title?: string): ShowProcessingPaneType => {
    return {
        type: "PROCESSING_PANE_SHOW",
        visible,
        title
    }
}

export const reportProcessingProgress = (message: string): ReportProcessingProgressType => {
    return {
        type: "PROCESSING_PANE_PROGRESS",
        message
    }
}

export const showProcessingError = (title: string, message: string): ShowProcessingErrorType => {
    return {
        type: "SET_PROCESSING_FAILURE",
        title,
        message
    }
}

export const closeProcessingErrorDialog = (): CloseProcessingErrorDialogType => {
    return {
        type: "CLOSE_PROCESSING_FAILURE"
    }
}