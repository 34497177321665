import React from "react"
import { IInsulatedGlassUnitValues, ValuesValidationResult } from "../forge/insulatedGlassUnit";
import "./igu.css"

type Props = {
    title: string;
    values: IInsulatedGlassUnitValues | null;
    validationResults: ValuesValidationResult;
}

export const IguItemOperation = ({ title, values, validationResults }: Props) => {
    if (validationResults === "not-applicable" || values === null)
        return (null);

    return <li className={`igu-${validationResults}`}><div className="igu-operation-container">
        <div className="igu-cell igu-operation-title-cell">{title}</div>
        <div className="igu-cell igu-operation-value-cell">{values.uValue}</div>
        <div className="igu-cell igu-operation-value-cell">{values.shgc}</div>
        <div className="igu-cell igu-operation-value-cell">{values.vt}</div>
        <div className="igu-cell igu-operation-value-cell">{values.cr}</div>
        <div className="igu-cell igu-operation-value-cell">{values.stc}</div>
        <div className="igu-cell igu-operation-value-cell">{values.oitc}</div>
    </div></li>
}