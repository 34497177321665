import { InsulaterGlassUnitActions } from "../actions/insulatedGlassUnitsActions";
import { IInsulatedGlassUnit } from "../components/forge/insulatedGlassUnit"

export type State = {
    insulatedGlassUnits: IInsulatedGlassUnit[];
}

const initialState: State = {
    insulatedGlassUnits: []
}

export const getInsulatedGlassUnits = function (state: State) {
    return state.insulatedGlassUnits;
}

export default function (state: State = initialState, action: InsulaterGlassUnitActions): State {
    if (action.type === "SET_INSULATION_GLASS_UNITS")
        return { ...state, insulatedGlassUnits: action.units };

    return state;
}