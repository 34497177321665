import React from "react";

export const Engine = ({ style }: { style?: React.CSSProperties }) => (
    <svg width={9} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <path
            d="M9 7.25c0 .19-.105.355-.265.44l-3.95 2.22c-.08.06-.18.09-.285.09a.472.472 0 0 1-.285-.09L.265 7.69A.496.496 0 0 1 0 7.25v-4.5c0-.19.105-.355.265-.44L4.215.09c.08-.06.18-.09.285-.09.105 0 .205.03.285.09l3.95 2.22c.16.085.265.25.265.44v4.5ZM4.5 1.075 1.52 2.75 4.5 4.425 7.48 2.75 4.5 1.075ZM1 6.955l3 1.69V5.29L1 3.605v3.35Zm7 0v-3.35L5 5.29v3.355l3-1.69Z"
            fill="#C4C4C4"
        />
    </svg>
);
