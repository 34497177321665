import React from "react";

type Props = {
  colored?: boolean,
  color?: string
}

export const AddCopy = ({ colored, color }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill={colored ? color : "#9A9B9B"}
        d="M3.2 0v1.6h11.2v11.2H16V1.6A1.6 1.6 0 0014.4 0H3.2zM1.6 3.2A1.6 1.6 0 000 4.8v9.6A1.6 1.6 0 001.6 16h9.6a1.6 1.6 0 001.6-1.6V4.8a1.6 1.6 0 00-1.6-1.6H1.6zm0 1.6h9.6v9.6H1.6V4.8zm4 1.6v2.4H3.2v1.6h2.4v2.4h1.6v-2.4h2.4V8.8H7.2V6.4H5.6z"
      ></path>
    </svg>
  );
};
