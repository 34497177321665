import React from 'react';
import { connect } from 'react-redux';
import { AdminUsersApp } from './AdminUsersApp';
import { State, isApplicationAdmin, isStatsAdmin } from './reducers/mainReducer';
import { AdminPageType } from './AdminPageType';

interface AdminUsersAppProps {
    adminPageType: AdminPageType;
    isApplicationAdmin: boolean;
    isStatsAdmin: boolean;
}

const mapStateToProps = (state: State): AdminUsersAppProps => ({
    isApplicationAdmin: isApplicationAdmin(state),
    isStatsAdmin: isStatsAdmin(state),
    adminPageType: 'Dashboard',
});

const ConnectedAdminUsersApp = connect(mapStateToProps)(
    (props: AdminUsersAppProps) => <AdminUsersApp {...props} />
);

export default ConnectedAdminUsersApp;
