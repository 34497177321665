import React from "react"
import { styles } from "../assets/analyticsStyles";
import { TextH3 } from "../assets/text";

type Props = {
    title: string;
}

export const AnalyticsTitleCard = ({ title }: Props) => {
    return (<div style={{ ...styles.intermediateTitleCard }}>
        <TextH3>{title}</TextH3>
    </div>)
}