import React, { useEffect, useState } from "react"
import { MaintenanceMessages } from "./maintenanceWorker";
import { OnGoingMaintenance } from "./onGoingMaintenance";
import { ScheduldedMaintenance } from "./scheduldedMaintenance";

type ScheduledMaintenance = {
    startTime: Date;
}

export const AppMaintenance = () => {
    const [scheduledMaintenance, setScheduledMaintenance] = useState<ScheduledMaintenance>();
    const [onMaintenance, setOnMaintenance] = useState<boolean>(false);

    useEffect(() => {
        const worker = new Worker(new URL("./maintenanceWorker.ts", import.meta.url));

        const onMessage = (e: MessageEvent<MaintenanceMessages>) => {
            switch (e.data.type) {
                case "maintenance-scheduled":
                    setScheduledMaintenance({ startTime: e.data.date });
                    break;

                case "maintenance-started":
                    setScheduledMaintenance(undefined);
                    setOnMaintenance(true);
                    break;

                case "maintenance-completed":
                    window.location.reload();
                    break;

                default:
                    break;
            }
        }

        worker.onmessage = onMessage;

        return () => {
            worker.terminate();
        }
    }, []);

    return <>
        {onMaintenance && <OnGoingMaintenance />}
        {scheduledMaintenance && <ScheduldedMaintenance startTime={scheduledMaintenance.startTime} />}
    </>
}