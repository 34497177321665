import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom";
import Modal from "@weave-design/modal"
import Button from '@weave-design/button';
import { IInsulatedGlassUnit, InsulatedGlassUnitValuesPreferences, WindowProfileType } from "./forge/insulatedGlassUnit"
import { ProjectWindowsIGUPreferences } from "./igu/projectWindowsIGUPreferences";
import { WindowIguListSelection } from "./igu/windowIguListSelection";
import "./igu/igu.css"

type Props = {
    insulatedGlassUnits: IInsulatedGlassUnit[];
    selectedInsulationGlassUnitId: string | undefined;
    insulatedGlassUnitValuesPreferences: InsulatedGlassUnitValuesPreferences | null;

    confirm: (selectedInsulationGlassUnitId: string | null, insulatedGlassUnitValuesPreferences: InsulatedGlassUnitValuesPreferences | null) => void;
    cancel: () => void;
    onPreferencesChanged?: (preferences: InsulatedGlassUnitValuesPreferences) => void;
}

export const ProjectWindowsIGUPreferencesDialog = (props: Props) => {
    const [preferences, setPreferences] = useState<InsulatedGlassUnitValuesPreferences>(props.insulatedGlassUnitValuesPreferences
        ? { ...props.insulatedGlassUnitValuesPreferences }
        : { profileType: WindowProfileType.Aw });

    const [selectedInsulationGlassUnitId, setSelectedInsulationGlassUnitId] = useState(props.selectedInsulationGlassUnitId);

    useEffect(() => setPreferences(props.insulatedGlassUnitValuesPreferences
        ? { ...props.insulatedGlassUnitValuesPreferences }
        : { profileType: WindowProfileType.Aw }), [props.insulatedGlassUnitValuesPreferences]);

    useEffect(() => setSelectedInsulationGlassUnitId(props.selectedInsulationGlassUnitId), [props.selectedInsulationGlassUnitId])

    const updatePreferences = (preferences: InsulatedGlassUnitValuesPreferences) => {
        setPreferences(preferences);

        if (typeof props.onPreferencesChanged === "function")
            props.onPreferencesChanged(preferences);
    }

    return ReactDOM.createPortal(<Modal
        open={true}
        title="Windows IGU preferences"
        onCloseClick={() => props.cancel()}
        stylesheet={modalStyles}>
        <div className="igu-dialog">
            <div style={{ height: "100%", display: "flex", flexDirection: "row" }}>
                <div style={{ width: "140px", marginRight: "20px" }}>
                    <ProjectWindowsIGUPreferences preferences={preferences} onChanged={newPreferences => updatePreferences(newPreferences)} />
                </div>

                <div style={{ width: "100%" }}>
                    <WindowIguListSelection
                        insulatedGlassUnits={props.insulatedGlassUnits}
                        insulatedGlassUnitValuesPreferences={preferences}
                        selectedInsulationGlassUnitId={selectedInsulationGlassUnitId}
                        onChange={newId => setSelectedInsulationGlassUnitId(newId)} />
                </div>
            </div>
            <div style={{ alignSelf: "flex-end", flexFlow: "row" }}>
                <Button
                    style={{ width: "100px" }}
                    type="secondary"
                    size="standard"
                    title="Cancel"
                    width="grow"
                    onClick={() => props.cancel()} />

                <Button
                    style={{ width: "100px", marginLeft: "14px" }}
                    type="primary"
                    size="standard"
                    title="Confirm"
                    disabled={!selectedInsulationGlassUnitId}
                    width="grow"
                    onClick={() => props.confirm(selectedInsulationGlassUnitId || null, preferences)} />
            </div>
        </div>
    </Modal>, document.getElementById("root")!);
}

const modalStyles = (styles: any) => ({
    ...styles,
    modal: {
        ...styles.modal,
        window: {
            ...styles.modal.window,
            width: "700px",
            height: "700px",
            top: 35
        }
    }
});