import React from "react";

const container: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    margin: "0 0 22px 0",
    padding: "0 0 0 0"
}

const subtitleContainer: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
}

const iconContainer: React.CSSProperties = {
    marginRight: 4
}

export const styles = {
    container,
    subtitleContainer,
    iconContainer
};
