import React, { useEffect, useState } from "react"
import { MapPin, RefreshCcw } from "react-feather";
import { styles } from "../assets/analyticsStyles";
import { TextBody2, TextH2 } from "../assets/text";
import { colors } from "../config/colors";
import { AnalyticsCard } from "./analyticsCard";
import { AnalyticsCardGraph } from "./analyticsCardGraph";
import { ContentNotReady } from "./contentNotReady";
import { DisplayCurrencies, DisplayUnits, IMaterial, IModelAnalytics } from "./modelAnalytics";
import { ProjectEditorField } from "./projectEditorField";
import { roundToDecimalSigns } from "@dextall/shared";
import { AppButton } from "./appButton";
import { AnalyticsTitleCard } from "./analyticsTitleCard";
import { Material } from "../assets/icons/material";
import { PricesLoadingState } from "../actions/priceActions";
import { roundToMillions } from "./shared";
import { IMaterialPrice } from "./price";
import { Warning } from "../assets/icons/warning";
import ToggleOptions from "./toggleOptions";
import ralColors from "./forge/ralColorsFactory";
import "../../src/components/inputs.css";


export interface IAnalyticsProps {
    projectAddress: string | undefined | null;
    analytics: IModelAnalytics | undefined | null;
    materialPrices: IMaterial | undefined | null;
    price: IMaterialPrice;
    pricesLoadingState: PricesLoadingState;
    isBetaUser: boolean;
    isInternalUser: boolean;
    isBidManager: boolean;
    isRefreshNeeded: boolean;
    onRefresh: () => void;
    onShowPriceBreakdown: () => void;
}

const areaLabels = {
    [DisplayUnits.Metric]: "m²",
    [DisplayUnits.Imperial]: "ft²",
};

const lengthLabels = {
    [DisplayUnits.Metric]: "m",
    [DisplayUnits.Imperial]: "ft",
};

export const Analytics = (props: IAnalyticsProps) => {

    const [selectedUnit, setSelectedUnit] = useState(DisplayUnits.Imperial);
    const [selectedCurrency, setSelectedCurrency] = useState(DisplayCurrencies.Usd);
    const currentCurrency = localStorage.getItem("Dextall.Currency");
    const currentUnits = localStorage.getItem("Dextall.Units");

    useEffect(() => {
        if (currentUnits) {
            const units = currentUnits === "Metric" ? DisplayUnits.Metric : DisplayUnits.Imperial;
            setSelectedUnit(units);
        }
    }, [currentUnits]);

    useEffect(() => {
        if (currentCurrency) {
            const currency = currentCurrency === "EUR" ? DisplayCurrencies.Eur : DisplayCurrencies.Usd;
            setSelectedCurrency(currency);
        }
    }, [currentCurrency]);


    const handleUnitChange = (value: string) => {
        setSelectedUnit(value === "Metric" ? DisplayUnits.Metric : DisplayUnits.Imperial);
        localStorage.setItem("Dextall.Units", value)
    };

    const handleCurrencyChange = (value: string) => {
        setSelectedCurrency(value === "EUR" ? DisplayCurrencies.Eur : DisplayCurrencies.Usd);
        localStorage.setItem("Dextall.Currency", value);
    }

    const convertValue = (value: number, isArea: boolean): string => {
        const fromUnit = props.analytics?.displayUnits;
        if (fromUnit === DisplayUnits.Imperial && selectedUnit === DisplayUnits.Metric)
            return isArea ? (value / 10.764).toFixed(1) : (value / 3.28084).toFixed(1);
        else if (fromUnit === DisplayUnits.Metric && selectedUnit === DisplayUnits.Imperial)
            return isArea ? (value * 10.764).toFixed(1) : (value * 3.28084).toFixed(1);
        else
            return value.toFixed(1);
    };

    const getLabelFromUnit = (unit: DisplayUnits) => {
        return unit === DisplayUnits.Metric ? "Metric" : "Imperial";
    };

    const getLabelFromCurrencies = (currency: DisplayCurrencies) => {
        return currency === DisplayCurrencies.Usd ? "USD" : "EUR"
    }

    const [expanded, setExpanded] = useState(false);

    const refreshNeeded = props.isRefreshNeeded;

    const totalFacadeArea = props.analytics
        ? props.analytics.totalPanelsArea + props.analytics.totalWindowsArea
        : 0;

    const totalWindowsArea = props.analytics?.totalWindowsArea || 0;

    return (<div style={{ ...styles.container, ...(refreshNeeded ? styles.refreshNeededContainer : {}) }}>

        <p style={{ display: refreshNeeded ? 'block' : 'none', ...styles.notification }}>Please refresh to see the analytics</p>

        {props.analytics &&
            <div style={{ position: "absolute", right: "6px", top: "8px" }}>
                <AppButton onClick={() => props.onRefresh()}>
                    <RefreshCcw />
                </AppButton>
            </div>
        }

        <div style={{ ...(refreshNeeded ? styles.refreshNeeded : {}) }}>
            {props.projectAddress && <ProjectEditorField value={props.projectAddress} setValue={() => { }}
                title="Address" isEditMode={false} multiline={false} style={{ ...(refreshNeeded ? styles.refreshNeeded : {}) }}>
                <MapPin size={12} stroke={colors.subheading} strokeWidth={3} />
            </ProjectEditorField>}

            {!props.analytics && <ContentNotReady />}
            {props.analytics && <>
                <div style={styles.toggleButtons}>
                    <ToggleOptions
                        initialValue={getLabelFromUnit(selectedUnit)}
                        values={["Imperial", "Metric"]}
                        onChange={handleUnitChange} />
                    <ToggleOptions
                        initialValue={getLabelFromCurrencies(selectedCurrency)}
                        values={["USD", "EUR"]}
                        onChange={handleCurrencyChange} />
                </div>


                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                    <AnalyticsCard style={styles.topCard}>
                        <TextBody2 style={{ color: colors.subheading }}>Total facade area</TextBody2>
                        <TextH2 style={{ whiteSpace: "nowrap", overflowX: "hidden" }}>
                            {`${convertValue(totalFacadeArea, true)} ${areaLabels[selectedUnit]}`}
                        </TextH2>
                    </AnalyticsCard>
                    <AnalyticsCard style={styles.emptyCard} />

                    <AnalyticsCard style={styles.topCard}>
                        <TextBody2 style={{ color: colors.subheading }}>Total opaque area</TextBody2>
                        <TextH2 style={{ whiteSpace: "nowrap", overflowX: "hidden" }}>
                            {`${convertValue(props.analytics.totalPanelsArea, true)} ${areaLabels[selectedUnit]}`}
                        </TextH2>
                    </AnalyticsCard>
                    
                    <AnalyticsCard style={styles.topCard}>
                        <TextBody2 style={{ color: colors.subheading }}>Total window area</TextBody2>
                        <TextH2 style={{ whiteSpace: "nowrap", overflowX: "hidden" }}>
                            {`${convertValue(totalWindowsArea, true)} ${areaLabels[selectedUnit]}`}
                        </TextH2>
                    </AnalyticsCard>

                    <AnalyticsCard style={styles.topCard}>
                        <TextBody2 style={{ color: colors.subheading }}>Atypical panels area</TextBody2>
                        <TextH2 style={{ whiteSpace: "nowrap", overflowX: "hidden" }}>
                            {`${convertValue(props.analytics.atypicalPanelsArea, true)} ${areaLabels[selectedUnit]}`}
                        </TextH2>
                    </AnalyticsCard>
                    <AnalyticsCard style={styles.topCard}>
                        <TextBody2 style={{ color: colors.subheading }}>Atypical corners area</TextBody2>
                        <TextH2 style={{ whiteSpace: "nowrap", overflowX: "hidden" }}>
                            {`${convertValue(props.analytics.atypicalCornerPanelsArea, true)} ${areaLabels[selectedUnit]}`}
                        </TextH2>
                    </AnalyticsCard>

                    <AnalyticsCard style={styles.topCard}>
                        <TextBody2 style={{ color: colors.subheading }}>Panel types count</TextBody2>
                        <TextH2>{props.analytics.panelTypesCount}</TextH2>
                    </AnalyticsCard>
                    <AnalyticsCard style={styles.topCard}>
                        <TextBody2 style={{ color: colors.subheading }}>Corner types count</TextBody2>
                        <TextH2>{props.analytics.cornerTypesCount}</TextH2>
                    </AnalyticsCard>

                    <AnalyticsCard style={styles.topCard}>
                        <TextBody2 style={{ color: colors.subheading }}>Atypical panels count</TextBody2>
                        <TextH2>
                            {props.analytics.atypicalPanelsCount}
                        </TextH2>
                    </AnalyticsCard>
                    <AnalyticsCard style={styles.topCard}>
                        <TextBody2 style={{ color: colors.subheading }}>Atypical corners count</TextBody2>
                        <TextH2>
                            {props.analytics.atypicalCornerPanelsCount}
                        </TextH2>
                    </AnalyticsCard>

                    <AnalyticsCard style={styles.topCard}>
                        <TextBody2 style={{ color: colors.subheading }}>Panels (Total)</TextBody2>
                        <TextH2>{props.analytics.panelsCount}</TextH2>
                    </AnalyticsCard>
                    <AnalyticsCard style={styles.topCard}>
                        <TextBody2 style={{ color: colors.subheading }}>Corners (Total)</TextBody2>
                        <TextH2>{props.analytics.cornersCount}</TextH2>
                    </AnalyticsCard>

                    <AnalyticsCard style={styles.topCard}>
                        <TextBody2 style={{ color: colors.subheading }}>Window/Wall ratio</TextBody2>
                        <TextH2>{totalFacadeArea > 0 ? roundToDecimalSigns(totalWindowsArea / totalFacadeArea, 3) : "-"}</TextH2>
                    </AnalyticsCard>

                    {!props.isBetaUser && <AnalyticsCard style={{ ...styles.topCard, ...{ backgroundColor: colors.greyBackground, border: "none" } }}>
                        <TextBody2 style={{ color: colors.subheading }}>Price</TextBody2>
                        <TextH2 style={{ color: colors.subheading }}>Upcoming</TextH2>
                    </AnalyticsCard>}

                    {props.isBetaUser && <AnalyticsCard style={{ ...styles.topCard }}>
                        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}><TextBody2 style={{ color: colors.subheading }}>Price </TextBody2> {props.price.hasInvalidPanels &&
                            <div title="The price of the panel is not included in the price calculation." ><Warning size={16} color="#ffb657" /></div>
                        }</div>
                        <div title={props.pricesLoadingState === "ready" ? `${props.price.sum.toFixed(2)} €` : undefined} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <TextH2 style={props.pricesLoadingState === "not-supported" ? { fontSize: 14 } : undefined}>
                                {props.pricesLoadingState === "ready" && `${roundToMillions(props.price.sum, selectedCurrency, props.price.currencyCoef)}`}
                                {props.pricesLoadingState === "failed" ? "Failed" : ""}
                                {props.pricesLoadingState === "loading" ? "Loading..." : ""}
                                {props.pricesLoadingState === "not-supported" ? "DWALL is not supported" : ""}
                            </TextH2>
                            {props.pricesLoadingState === "ready" && props.isBidManager && <AppButton onClick={() => props.onShowPriceBreakdown()} prompt="Show price breakdown">
                                <Material />
                            </AppButton>}
                        </div>
                    </AnalyticsCard>}

                    <AnalyticsCard style={styles.bottomCard}>
                        <div style={{ display: "flex", flexDirection: "column", width: 140 }}>
                            <TextBody2>Colors</TextBody2>
                            <div style={{ flex: 1, marginRight: 20, marginTop: 10 }}>
                                {props.analytics.colors.map(x => {
                                    return <AnalyticsCardGraph key={x.color} style={{ width: `${(x.totalArea / props.analytics!.colors[0].totalArea) * 100}%`, backgroundColor: ralColors.getColor(x.color)! }} />
                                })}
                            </div>
                        </div>
                        <div style={{ flex: 1, marginRight: 20 }}>
                            <TextBody2 style={{ marginBottom: 10 }}>Color</TextBody2>
                            {props.analytics.colors.map(x => {
                                return <TextBody2 key={x.color} style={{ marginBottom: 4 }}>{x.color}</TextBody2>;
                            })}
                        </div>
                        <div style={{ flex: 1 }}>
                            <TextBody2 style={{ marginBottom: 10 }}>Amount</TextBody2>
                            {props.analytics.colors.map((x, i) => {
                                return <TextBody2 key={`analytics_color_${i}`} style={{ marginBottom: 4 }}>{`${convertValue(x.totalArea, true)} ${areaLabels[selectedUnit]}`}</TextBody2>;
                            })}
                        </div>
                    </AnalyticsCard>
                </div>

                {props.isInternalUser && <div>
                    <div onClick={() => setExpanded(!expanded)} style={{ marginBottom: expanded ? '' : '50px' }}>
                        <button style={styles.showMore}>{`Show ${expanded ? 'less' : 'more'}`}</button>
                    </div>
                    <div style={{ display: expanded ? "flex" : "none", flexDirection: "row", flexWrap: "wrap", paddingBottom: "10px" }}>
                        <AnalyticsTitleCard title="Opaque panels"></AnalyticsTitleCard>
                        <AnalyticsCard style={styles.topCard}>
                            <TextBody2 style={{ color: colors.subheading }}>Footage</TextBody2>
                            <TextH2>
                                {`${convertValue(props.analytics.opaquePanelsFootage, false)} ${lengthLabels[selectedUnit]}`}
                            </TextH2>
                        </AnalyticsCard>
                        <AnalyticsCard style={styles.topCard}>
                            <TextBody2 style={{ color: colors.subheading }}>Area</TextBody2>
                            <TextH2>
                                {`${convertValue(props.analytics.opaquePanelsArea, true)} ${areaLabels[selectedUnit]}`}
                            </TextH2>
                        </AnalyticsCard>
                        <AnalyticsCard style={styles.topCard}>
                            <TextBody2 style={{ color: colors.subheading }}>Avg. width</TextBody2>
                            <TextH2>
                                {props.analytics.averageOpaquePanelWidth !== null && <>{`${convertValue(props.analytics.averageOpaquePanelWidth, false)} ${lengthLabels[selectedUnit]}`}</>}
                                {props.analytics.averageOpaquePanelWidth === null && <>-</>}
                            </TextH2>
                        </AnalyticsCard>
                        <AnalyticsCard style={styles.topCard}>
                            <TextBody2 style={{ color: colors.subheading }}>Avg. height</TextBody2>
                            <TextH2>
                                {props.analytics.averageOpaquePanelHeight !== null && <>{`${convertValue(props.analytics.averageOpaquePanelHeight, false)} ${lengthLabels[selectedUnit]}`}</>}
                                {props.analytics.averageOpaquePanelHeight === null && <>-</>}
                            </TextH2>
                        </AnalyticsCard>

                        <AnalyticsTitleCard title="Window panels"></AnalyticsTitleCard>
                        <AnalyticsCard style={styles.topCard}>
                            <TextBody2 style={{ color: colors.subheading }}>Footage</TextBody2>
                            <TextH2>
                                {`${convertValue(props.analytics.windowPanelsFootage, false)} ${lengthLabels[selectedUnit]}`}
                            </TextH2>
                        </AnalyticsCard>
                        <AnalyticsCard style={styles.topCard}>
                            <TextBody2 style={{ color: colors.subheading }}>Area</TextBody2>
                            <TextH2>
                                {`${convertValue(props.analytics.windowPanelsArea, true)} ${areaLabels[selectedUnit]}`}
                            </TextH2>
                        </AnalyticsCard>
                        <AnalyticsCard style={styles.topCard}>
                            <TextBody2 style={{ color: colors.subheading }}>Avg. width</TextBody2>
                            <TextH2>
                                {props.analytics.averageWindowPanelWidth !== null && <>{`${convertValue(props.analytics.averageWindowPanelWidth, false)} ${lengthLabels[selectedUnit]}`}</>}
                                {props.analytics.averageWindowPanelWidth === null && <>-</>}
                            </TextH2>
                        </AnalyticsCard>
                        <AnalyticsCard style={styles.topCard}>
                            <TextBody2 style={{ color: colors.subheading }}>Avg. height</TextBody2>
                            <TextH2>
                                {props.analytics.averageWindowPanelHeight !== null && <>{`${convertValue(props.analytics.averageWindowPanelHeight, false)} ${lengthLabels[selectedUnit]}`}</>}
                                {props.analytics.averageWindowPanelHeight === null && <>-</>}
                            </TextH2>
                        </AnalyticsCard>

                        <AnalyticsTitleCard title="Corner panels"></AnalyticsTitleCard>
                        <AnalyticsCard style={styles.topCard}>
                            <TextBody2 style={{ color: colors.subheading }}>Footage</TextBody2>
                            <TextH2>
                                {`${convertValue(props.analytics.cornerPanelsFootage, false)} ${lengthLabels[selectedUnit]}`}
                            </TextH2>
                        </AnalyticsCard>
                        <AnalyticsCard style={styles.topCard}>
                            <TextBody2 style={{ color: colors.subheading }}>Area</TextBody2>
                            <TextH2>
                                {`${convertValue(props.analytics.cornerPanelsArea, true)} ${areaLabels[selectedUnit]}`}
                            </TextH2>
                        </AnalyticsCard>
                        <AnalyticsCard style={styles.topCard}>
                            <TextBody2 style={{ color: colors.subheading }}>Avg. width</TextBody2>
                            <TextH2>
                                {props.analytics.averageCornerPanelWidth !== null && <>{`${convertValue(props.analytics.averageCornerPanelWidth, false)} ${lengthLabels[selectedUnit]}`}</>}
                                {props.analytics.averageCornerPanelWidth === null && <>-</>}
                            </TextH2>
                        </AnalyticsCard>
                        <AnalyticsCard style={styles.topCard}>
                            <TextBody2 style={{ color: colors.subheading }}>Avg. height</TextBody2>
                            <TextH2>
                                {props.analytics.averageCornerPanelHeight !== null && <>{`${convertValue(props.analytics.averageCornerPanelHeight, false)} ${lengthLabels[selectedUnit]}`}</>}
                                {props.analytics.averageCornerPanelHeight === null && <>-</>}
                            </TextH2>
                        </AnalyticsCard>
                    </div>
                </div>}
            </>}
        </div>
    </div>)
}


