import React from "react";

type Props = {
    style?: React.CSSProperties;
}

export const Material = ({ style }: Props) => (
    <svg height={14} width={14} fill="#F4F4F4" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" style={style}>
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" transform="translate(3 3)">
            <path d="m14.4978951 12.4978973-.0105089-9.99999996c-.0011648-1.10374784-.8962548-1.99789734-2-1.99789734h-9.99999995c-1.0543629 0-1.91816623.81587779-1.99451537 1.85073766l-.00548463.151365.0105133 10.00000004c.0011604 1.1037478.89625045 1.9978973 1.99999889 1.9978973h9.99999776c1.0543618 0 1.9181652-.8158778 1.9945143-1.8507377z" />
            <path d="m4.5.5v13.817" />
            <path d="m7-2v14" transform="matrix(0 1 -1 0 12.5 -2.5)" />
        </g>
    </svg>
);
