import React from "react"
import { ExternalLink16 } from "@weave-design/icons";
import { colors } from "../../config/colors";

type Props = {
    title: string;
    url: string;
}

export const TagH4ExternalLink = ({ title, url }: Props) => {
    return <a href={url} style={styles} target="_blank">{title} <ExternalLink16 /></a>
}

const styles: React.CSSProperties = {
    fontSize: 15,
    fontFamily: "Roboto",
    color: colors.black,
    fontStyle: "normal",
    fontWeight: 500,
    textDecoration: "none",
    margin: "0 0 0 0",
    padding: "0 0 0 0"
};