import React, { useState } from "react"
import Label from "@weave-design/label";
import Typography from "@weave-design/typography";
import { IModel3DVersion } from "./model3d"
import { AddCopy } from "../assets/icons";
import { card } from "../config";
import { AppButton } from "./appButton";
import { IProject } from "./project";
import { ConfirmDialog } from "./confirmDialog";
import { ProjectSelector } from "./projectSelector";
import { AlertTriangle } from "react-feather";
import repo from "../Repository";

type Props = {
    selectedModelVersion: IModel3DVersion;

    showProcessingPane: (visible: boolean, title?: string) => void;
    showProcessingError: (title: string, message: string) => void;
    createNewModelFromBidModel: (sourceModelId: string, targetProjectId: string) => void;
}

export const CreateNewModelFromBidControls = ({ selectedModelVersion, showProcessingPane, showProcessingError, createNewModelFromBidModel }: Props) => {
    const [projects, setProjects] = useState<IProject[]>([]);
    const [selectedProjectIndex, setSelectedProjectIndex] = useState(-1);
    const [isProjectSelectionDialogVisible, setIsProjectSelectionDialogVisible] = useState(false);

    const showDialog = async () => {
        showProcessingPane(true, "Loading projects...");

        const response = await repo.loadProjects();

        showProcessingPane(false);

        if (!response.isSuccess) {
            showProcessingError("Error", response.message);
            return;
        }

        if (response.items.length === 0) {
            showProcessingError("No projects", "You must have a project we can copy source BID model to!");

            return;
        }

        setProjects(response.items);
        setSelectedProjectIndex(0);
        setIsProjectSelectionDialogVisible(true);
    }

    const createNewModelBasedOnBidModel = () => {
        setIsProjectSelectionDialogVisible(false);

        const sourceModelId = selectedModelVersion.id;
        const targetProjectId = projects[selectedProjectIndex].id;

        createNewModelFromBidModel(sourceModelId, targetProjectId);
    }

    return <>
        <AppButton style={{ padding: 12, backgroundColor: card.versionProject, marginLeft: 8, marginRight: 8 }}
            onClick={showDialog}>
            <AddCopy />
        </AppButton>
        {isProjectSelectionDialogVisible && <ConfirmDialog
            isOpened={isProjectSelectionDialogVisible}
            title="Create model version"
            confirmButtonTitle="Create"
            onCancel={() => setIsProjectSelectionDialogVisible(false)}
            onConfirm={() => createNewModelBasedOnBidModel()}
            windowStyles={{ width: "500px", height: "370px" }}
            promptContainerStyles={{ height: "200px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div><Label>Select the project to place a copy of this model</Label></div>
                <ProjectSelector projects={projects} selectedIndex={selectedProjectIndex} setSelectedProject={setSelectedProjectIndex} />
                {selectedModelVersion.systemType as unknown !== projects[selectedProjectIndex]?.systemType && <div style={{ display: "flex", flexDirection: "row", marginTop: 40 }}>
                    <div>
                        <AlertTriangle size={50} stroke="#FFAE00" />
                    </div>
                    <div style={{ marginLeft: 7 }}>
                        <Typography>Target project system type differs from the model system type. You'd probably need to check and adjust the design manually!</Typography>
                    </div>
                </div>}
            </div>
        </ConfirmDialog>}
    </>
}