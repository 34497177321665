import React from "react"
import { styles } from "../assets/inputTextStyles";

export interface IInputTextProps {
    value: string;
    setValue: (x: string) => void;
    placeholder?: string; 
    style?: React.CSSProperties;
    type?: string;
}

export const InputText = (props: IInputTextProps) => {
    return (
        <input
            value={props.value}
            onChange={(v) => props.setValue(v.target.value)}
            placeholder={props.placeholder}
            type={props.type !== undefined ? props.type : "text"}
            style={{ ...styles.input, ...props.style }} />
    );
}