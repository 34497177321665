import React from "react";
import { colors } from "../../config/colors";

type Props = {
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const TextH2 = ({ children, style }: Props) => {
  return <p style={{ ...styles.text, ...style }}>{children}</p>;
};

const styles = {
  text: {
    fontSize: "24px",
    fontFamily: "Roboto",
    color: colors.textBlack,
    fontStyle: "normal",
    fontWeight: 500,
  }
};
