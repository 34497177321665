import React, { useEffect, useState } from "react"
import { Chart } from "react-google-charts";
import { IModelStatisticsWeeklyItem } from "../appStatistics";

type Props = {
    createdModels: IModelStatisticsWeeklyItem[];
    createdVersions: IModelStatisticsWeeklyItem[];
    chartContainerStyle?: React.CSSProperties;
}

type ChartHeader = ["Interval", "New uploaded models", "New model design options"];
type ChartData = [serie: string, newModels: number, newVersions: number];
type ChartSource = [ChartHeader, ...ChartData[]]

export const WeeklyModelsStatistics = ({ createdModels, createdVersions, chartContainerStyle }: Props) => {
    const chartHeader: ChartHeader = ["Interval", "New uploaded models", "New model design options"];
    const [chartSource, setChartSource] = useState<ChartSource>([chartHeader]);

    useEffect(() => {
        const data: ChartSource = [chartHeader];

        for (let i = 0; i < Math.max(createdModels.length, createdVersions.length); i++) {
            const models = createdModels[i];
            const versions = createdVersions[i];

            data.push([
                `${new Date(models.start).toLocaleDateString()} - ${new Date(models.end).toLocaleDateString()}`,
                models?.createdCount || 0,
                versions?.createdCount || 0
            ]);
        }

        setChartSource(data);
    }, [createdModels, createdVersions])

    return <div style={{ ...chartContainerStyle }}>
        <Chart
            chartType="SteppedAreaChart"
            width="100%"
            height="400px"
            data={chartSource}
            options={{
                title: "Models",
                legend: { position: "top", maxLines: 3 },
                isStacked: true
            }} />
    </div>
}