import ralColorsFactory from "./forge/ralColorsFactory";

class RalColorSelectorUsedColorsCache {
    private readonly usedColorsCodes = new Set<string>();

    getLastUsedColorCodes(): string[] {
        return ralColorsFactory.getColorCodes().filter(x => this.usedColorsCodes.has(x));
    }

    use(colors: string | string[]) {
        const items = Array.isArray(colors) ? colors : [colors];

        for (const item of items)
            this.usedColorsCodes.add(item);
    }

    dispose() {
        this.usedColorsCodes.clear();
    }
}

export default new RalColorSelectorUsedColorsCache();