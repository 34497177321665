import { Button, IconButton, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Send from '@mui/icons-material/Send';
import Attach from '@mui/icons-material/AttachFile';
import { ITeamsSendMessage } from "../forge/teams";
import Linkify from 'react-linkify';
import { AppButton } from "../appButton";
import { useButtonState } from "../useButtonState";
import { colors } from "../../config";


interface IComponentProps {
    isVisible: boolean;
    profileName: string;

    messages: ITeamsSendMessage[] | undefined;
    onSendMessage: () => void;
    onTypeMessage: (message: string) => void;
    onFileSelect: (file: File, fileName: string) => void;
}

export const ChatWindow = (props: IComponentProps) => {
    const [input, setInput] = useState("");
    const [file, setFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState("");
    const fileInputElement = useRef<HTMLInputElement>(null);
    const [uploadedFile, setUploadedFile] = useState(false);
    const sendButtonState = useButtonState();
    const attachButtonState = useButtonState();
    const handleSubmit = () => {
        props.onSendMessage();
        setInput("");
        setUploadedFile(false);
    };

    useEffect(() => {
        const keyDownHandler = (event: KeyboardEvent) => {
            if (event.key === "Enter" && event.ctrlKey) {
                event.preventDefault();
                handleSubmit();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    })

    const messagesEndRef = useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [props.messages]);


    return (<div style={{ zIndex: 2 }}>
        {props.isVisible && <div style={{ width: 300, height: "auto", backgroundColor: "#EDEDED", border: "1px solid", borderColor: "#929292", borderRadius: "10px 10px 5px 5px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", gap: 5 }}>
            <div style={{ width: 300, height: 50, backgroundColor: "#E4E3E3", borderBottom: "2px solid", borderColor: "#929292", borderTopLeftRadius: 10, borderTopRightRadius: 10, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <p style={{ fontWeight: 700 }}>Dextall Chat</p>
            </div>
            <div style={{ height: 335, width: 300 }}>
                <div style={{ display: "flex", flexDirection: "column", gap: 10, width: '100%', maxWidth: 300, backgroundColor: 'background.paper', position: 'relative', }}>
                    {
                        props.messages!.length > 0 && <div style={{
                            overflowY: 'auto', overflowX: 'hidden', width: 300,
                            height: "335px", display: "flex", flexDirection: "column"
                        }}>
                            {
                                props.messages!.map(item => {
                                    const message = item.body.content.includes("<p>") ? item.body.content.replace("<p>", "").replace("</p>", "") : item.body.content;
                                    const name = item.from.user.displayName == "IT Support" ? props.profileName : item.from.user.displayName;
                                    const align = item.from.user.displayName == "IT Support" ? "flex-start" : "flex-end";
                                    return <div key={item.id} style={{ border: "1px solid #ccc", borderRadius: 5, margin: 10, padding: 5, maxWidth: "220px", width: "150px", display: "flex", flexDirection: "column", alignSelf: align }}>
                                        <p style={{ fontSize: 12 }}>{name}</p>
                                        <p style={{ fontSize: 16, margin: "10px 0", wordWrap: "break-word" }}><Linkify>{message}</Linkify></p>
                                        <p style={{ fontSize: 12 }}>{new Date(item.createdDateTime).toLocaleTimeString()}</p>
                                    </div>
                                })
                            }
                            <div ref={messagesEndRef} />
                        </div>
                    }
                </div>
            </div>
            <div style={{ height: 45, marginBottom: 35, width: 280, display: "flex", justifyContent: "center" }}>
                <TextField
                    value={input}
                    onChange={(e) => {
                        props.onTypeMessage(e.target.value)
                        setInput(e.target.value);
                    }}
                    multiline
                    rows={2}
                    sx={{
                        height: 45,
                        width: 300,
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: colors.hovered,
                            },
                        },
                    }} />
            </div>
            {uploadedFile && <p style={{ fontSize: 12, display: "flex", alignSelf: "start", width: 280, paddingLeft: 10 }}>You choose file:<i>{fileName}</i></p>}
            <div style={{ height: 35, marginBottom: 10, width: 250, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <AppButton
                    style={{ width: 35, height: 35, borderRadius: 20, backgroundColor: "#D9D9D9" }}
                    onClick={() => { fileInputElement.current?.click(); }}
                    colored={true}
                    hovered={attachButtonState.isHovered}
                    handleMouseEnter={attachButtonState.handleMouseEnter}
                    handleMouseLeave={attachButtonState.handleMouseLeave}
                    selected={attachButtonState.isSelected}
                    handleMouseDown={attachButtonState.handleMouseDown}
                    handleMouseUp={attachButtonState.handleMouseUp}
                    prompt="Attach"
                >
                    <Attach style={{ color: attachButtonState.isSelected ? colors.textBlack : attachButtonState.isHovered ? colors.hovered : "#929292" }} />
                </AppButton>
                <AppButton
                    style={{ width: 100, height: 35, backgroundColor: "#D9D9D9", borderRadius: 20, color: "#929292" }}
                    onClick={() => handleSubmit()}
                    colored={true}
                    hovered={sendButtonState.isHovered}
                    handleMouseEnter={sendButtonState.handleMouseEnter}
                    handleMouseLeave={sendButtonState.handleMouseLeave}
                    selected={sendButtonState.isSelected}
                    handleMouseDown={sendButtonState.handleMouseDown}
                    handleMouseUp={sendButtonState.handleMouseUp}
                    prompt="Send"
                >
                    <Send sx={{ color: sendButtonState.isSelected ? colors.textBlack : sendButtonState.isHovered ? colors.hovered : "#929292" }} />
                    <span style={{ marginLeft: 5, color: sendButtonState.isSelected ? colors.textBlack : sendButtonState.isHovered ? colors.hovered : "#929292" }} >SEND</span>
                </AppButton>
            </div>
            <input id="modelFileInput"
                ref={fileInputElement}
                type="file"
                accept=".*"
                onChange={(e) => {
                    if (!e.target.files || e.target.files.length === 0)
                        return;

                    const fileToUpload = e.target.files[0];

                    const uploadFileName = fileToUpload.name.toLowerCase();

                    setFile(fileToUpload);
                    setFileName(uploadFileName);
                    setUploadedFile(true);
                    props.onFileSelect(fileToUpload, uploadFileName);
                }}
            />
        </div>}
    </div>)
}