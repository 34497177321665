import React, { useEffect, useRef, useState } from "react";
import { card, colors } from "../config/index";
import { TextH4 } from "../assets/text/index";
import { AppButton } from "./appButton";
import { ModalWindow } from "./modalWindow"
import { styles } from "../assets/modelInfoStyles";
import { getModelVersionWorkflowStatusInfo } from "./modelCard";
import { IModel3DVersion, ModelTranslationStatus, ModelWorkflowStatus } from "./model3d";
import { OptionSwitcher } from "./optionSwitcher";
import { Model3D } from "../reducers/modelsReducer";
import { Pin } from "../assets/icons/pin";
import { OptionSwitcherCard } from "./optionSwitcherCard";
import { ModelInfoProperty } from "./modelInfoProperty";
import { ApplicationType } from "../applicationType";
import { ApplicationTabType } from "../actions/applicationUIActions";
import OptionNameEditor from "./optionNameEditor";
import { Edit, Trash } from "react-feather";
import { useButtonState } from "./useButtonState";

type Props = {
    model: Model3D | null;
    item: IModel3DVersion | null;
    style: React.CSSProperties;
    visible: boolean;
    applicationType: ApplicationType;
    activeTab: ApplicationTabType;
    modelsLoading: boolean;
    engineeringModelsLoading: boolean;

    closeModelInfo: () => void;
    requestModelDeletion: () => void;
    requestModelVersionDeletion: () => void;
    requestModelVersionSwitch: (versionIndex: number) => void;
    requestPinSelectedVersion: () => void;
    setSelectedWindow: (newTab: ApplicationTabType) => void;
}

export const ModelInfo = ({ model, item, style, visible, applicationType, activeTab, closeModelInfo,
    requestModelDeletion, requestModelVersionDeletion,
    requestModelVersionSwitch, requestPinSelectedVersion, setSelectedWindow, modelsLoading, engineeringModelsLoading }: Props) => {
    const [optionSwitcherVisible, setOptionSwitcherVisible] = useState(visible);
    const optionSwitcherRef = useRef<HTMLDivElement>(null);
    const [isEditingName, setIsEditingName] = useState(false);
    const [isEditHovered, setEditHovered] = useState(false);
    const [isTextHovered, setTextHovered] = useState(false);
    const trashButtonState = useButtonState();

    const handleEditingDone = () => {
        setIsEditingName(false);
    };

    useEffect(() => {
        setOptionSwitcherVisible(visible);
    }, [visible]);

    useEffect(() => {
        if (activeTab !== "project")
            closeModelInfo();
    }, [activeTab]);

    if (!(model && item && item.status !== ModelTranslationStatus.Created && visible))
        return null;

    const { workflowStatus, workflowTagStyle } = getModelVersionWorkflowStatusInfo(item);

    const parentVersionIndex = item.parentVersionNumber !== null
        ? model.versions.findIndex(x => x.versionNumber === item.parentVersionNumber)
        : -1;

    const parentVersion = model.versions[parentVersionIndex];

    const canRemoveDesignOption = model.versions.length > 1
        && (applicationType === "ar" && item.workflowStatus === ModelWorkflowStatus.Draft || applicationType === "en" && item.workflowStatus === ModelWorkflowStatus.EngineerDraft);

    const canRemoveModel = applicationType === "ar" && !model.versions.find(x => x.workflowStatus !== ModelWorkflowStatus.Draft);

    return (
        <div style={{ ...style }}>
            {(!modelsLoading && !engineeringModelsLoading) &&
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div ref={optionSwitcherRef}>
                        {optionSwitcherVisible && (
                            <OptionSwitcher
                                model={model}
                                selectVersion={requestModelVersionSwitch}
                                canRemoveModel={canRemoveModel}
                                requestModelDeletion={requestModelDeletion}
                            />
                        )}
                    </div>

                    <div>
                        <ModalWindow style={{ maxHeight: 615, marginLeft: 15 }}>
                            <div style={styles.container}>
                                <div
                                    style={styles.header}>
                                    <div style={{ display: "flex", justifyContent: "space-between"}}>
                                        <span style={{ display: "flex" }}>
                                            <AppButton style={styles.version}># {item.versionNumber}</AppButton>
                                            <AppButton style={{ ...styles.version, ...workflowTagStyle, height: "30px", marginRight: "5px" }}>{workflowStatus}</AppButton>
                                        </span>

                                        <span
                                            onMouseEnter={() => setTextHovered(true)}
                                            onMouseLeave={() => setTextHovered(false)}
                                            style={{ display: 'flex', alignItems: 'center', width: "130px" }}
                                        >
                                            <span style={{...styles.ellipsisStyle}}>
                                                {isEditingName ? (
                                                    <OptionNameEditor
                                                        selectedVersion={item}
                                                        onEditingDone={handleEditingDone}
                                                    />
                                                ) : (
                                                    <span style={{ fontSize: "14px", fontWeight: "500", marginRight: 6, width:"100%" }}>{item.optionName || "Model Option"}</span>
                                                )}
                                            </span>
                                            <div style={{ width: 20, flexShrink: 0, marginRight: 2 }}> 
                                            {isTextHovered && !isEditingName && (
                                                <span style={{ cursor: 'pointer' }}
                                                    onMouseEnter={() => setEditHovered(true)}
                                                    onMouseLeave={() => setEditHovered(false)}
                                                    onClick={() => setIsEditingName(true)}
                                                >
                                                    <Edit size={11} stroke={isEditHovered ? colors.hovered : colors.subheading} />
                                                </span>
                                            )}
                                            </div>
                                        </span>

                                        <span>
                                            {applicationType === "ar" && <AppButton
                                                style={item.isMainOption ? { backgroundColor: card.pin, color: colors.white } : { backgroundColor: colors.folderTopButton, color: "#7C7C7C" }}
                                                onClick={() => requestPinSelectedVersion()}>
                                                {item.isMainOption ? "un-main" : "main"}
                                                <Pin style={item.isMainOption ? { marginLeft: 6 } : { marginLeft: 6, fill: "#cccc" }} />
                                            </AppButton>}
                                        </span>
                                    </div>
                                </div>

                                <ModelInfoProperty title="Created at" text={new Date(item.createdAt).toLocaleDateString()} />

                                {(parentVersionIndex >= 0) && <ModelInfoProperty title="Based on version">
                                    <div style={{ width: 160 }}>
                                        <OptionSwitcherCard
                                            selectedVersion={parentVersion}
                                            isInModelInfo={true}
                                            onSelect={() => requestModelVersionSwitch(parentVersionIndex)}
                                            isSelected={false} />
                                    </div>
                                </ModelInfoProperty>}

                                <ModelInfoProperty title="Reviewed by" text="-" />

                                <ModelInfoProperty title="Last Review" text={item.approvedAt !== null ? new Date(item.approvedAt).toLocaleDateString() : "Never"} />

                                <ModelInfoProperty title="Comments">
                                    <div className="scrollBar" style={styles.comments}>
                                        <TextH4 style={{ fontFamily: "Arial", margin: "2px 0 0 0 " }}>{item.comment ? item.comment : "-"}</TextH4>
                                    </div>
                                </ModelInfoProperty>

                                <div style={{ display: "flex", position: "relative" }}>
                                    <AppButton
                                        title="Open Model"
                                        style={styles.openModel}
                                        onClick={() => setSelectedWindow("model")} />

                                    {canRemoveDesignOption &&
                                        <AppButton
                                            style={{ backgroundColor: card.versionProject, ...styles.deleteOption }}
                                            onClick={() => requestModelVersionDeletion()}
                                            colored={true}
                                            hovered={trashButtonState.isHovered}
                                            handleMouseEnter={trashButtonState.handleMouseEnter}
                                            handleMouseLeave={trashButtonState.handleMouseLeave}
                                            selected={trashButtonState.isSelected}
                                            handleMouseDown={trashButtonState.handleMouseDown}
                                            handleMouseUp={trashButtonState.handleMouseUp}
                                            prompt="Delete Option"
                                        >
                                            <Trash size={20} style={{ stroke: trashButtonState.isSelected ? colors.textBlack : trashButtonState.isHovered ? colors.hovered : colors.subheading }} />
                                        </AppButton>
                                    }
                                </div>
                            </div>
                        </ModalWindow>
                    </div>
                </div>
            }

        </div>
    )
}