import React from 'react';
import { connect } from 'react-redux';
import ProjectToolbarControls from "./projectToolbarControls";
import ModelToolbarControls from "./modelToolbarControls";
import EngineeringProductionModelToolbarControls from './engineeringProductionModelToolbarControls';
import { StudioToolbarHeader } from "./studioToolbarHeader";
import { ApplicationTabType, setActiveTab } from "../actions/applicationUIActions";
import { Project } from '../reducers/projectsReducer';
import { getActiveTab, getCurrentProject, State } from "../reducers/mainReducer";
import { styles } from "../assets/studioPaneStyles";
import { ApplicationType } from '../applicationType';

interface IComponentProps {
    project: Project | null;
    activeTab: ApplicationTabType;
    applicationType: ApplicationType;

    setActiveTab: (tab: ApplicationTabType) => void;
}

export const StudioToolbar = (props: IComponentProps) => {
    const toggleTab = (newTab: ApplicationTabType) => {
        if (!props.project || props.project.isDirty)
            return;

        props.setActiveTab(newTab);
    }

    return (<div style={styles.folderTopContainer}>
        <StudioToolbarHeader selectedWindow={props.activeTab} setSelectedWindow={toggleTab.bind(this)} isEngineer={props.applicationType === "en"} />
        <svg style={{ alignSelf: "flex-end" }} width="40" height="40" fill="none" viewBox="0 0 40 40">
            <path fill="#fff" d="M0 40C40 40 40 0 40 0v40H0z"></path>
        </svg>
        {props.project &&
            <>
                {props.activeTab === "project" && <ProjectToolbarControls applicationType={props.applicationType} />}
                {props.activeTab === "model" && <ModelToolbarControls applicationType={props.applicationType} />}
                {props.activeTab === "engineering-model" && <EngineeringProductionModelToolbarControls />}
            </>}
    </div>);
}

export default connect(function (store: State) {
    return {
        project: getCurrentProject(store),
        activeTab: getActiveTab(store)
    };
}, { setActiveTab })(StudioToolbar);