import React from "react";
import { colors } from "../../config/colors";

type Props = {
  title?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  verticallyLimited?: boolean;
}

export const TextH4 = ({ children, style, title, verticallyLimited }: Props) => {
  const className = verticallyLimited
    ? "unselectable two-lines-textbox"
    : "unselectable";

  return (
    <p className={className} style={{ ...styles.text, ...style }} title={title || ""}>
      {children}
    </p>
  );
};

const styles = {
  text: {
    fontSize: "15px",
    fontFamily: "Roboto",
    lineHeight: "20px",
    color: colors.textBlack,
    fontStyle: "normal",
    fontWeight: 500,
    margin: "0 0 0 0",
    padding: "0 0 0 0",
  },
};
