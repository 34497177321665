import { AppTag } from "./appTag";
import { IModel3DVersion } from "./model3d"
import { getModelVersionWorkflowStatusInfo } from "./modelCard";
import { styles } from "../assets/optionStyles";
import { setActiveTab } from "../actions/applicationUIActions";
import { useDispatch } from "react-redux";

type Props = {
    selectedVersion: IModel3DVersion;
    isSelected: boolean;
    isInModelInfo?: boolean;

    onSelect: () => void;
}

export const OptionSwitcherCard = ({ selectedVersion, isSelected, onSelect, isInModelInfo }: Props) => {
    const dispatch = useDispatch();
    const { workflowStatus, workflowTagStyle } = getModelVersionWorkflowStatusInfo(selectedVersion);

    const maxWidthForText = 108;

    const handleClick = () => {
        onSelect();
        if (isSelected) {
            dispatch(setActiveTab("model"));
        }
    };

    const cardStyle = {
        ...styles.container,
        ...(isSelected ? styles.selectedVersion : {}),
        cursor: 'pointer',
    };

    return (
        <div
            style={cardStyle}
            onClick={handleClick}
            className="optionSwitcherCard"
            title={selectedVersion.optionName || undefined}
        >
            <div style={isInModelInfo ? { ...styles.textContainerStyle, ...styles.content } : styles.content}>
                <span style={{ marginLeft: "5px", maxWidth: `${maxWidthForText}px`, paddingRight: "10px" }}>{selectedVersion.versionNumber}</span>
                <AppTag style={{ ...workflowTagStyle, cursor: "pointer", marginRight: "10px" }}>{workflowStatus}</AppTag>
                <span style={{ ...styles.ellipsisStyle, maxWidth: `${maxWidthForText}px` }}>{selectedVersion.optionName || ""}</span>
            </div>
        </div>
    );
}
