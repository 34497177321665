import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { TextH2 } from '../assets/text/index';
import { colors } from "../config/colors";

interface IComponentProperties {
    listLength: number;
    selectedIndex: number;
    setSelectedIndex: (index: number) => void;
}

export const ListItemByIndexSelector = ({ listLength, selectedIndex, setSelectedIndex }: IComponentProperties) => {
    const selectPrevious = () => {
        if (selectedIndex > 0)
            setSelectedIndex(selectedIndex - 1);
    }

    const selectNext = () => {
        if (selectedIndex < listLength - 1)
            setSelectedIndex(selectedIndex + 1);
    }

    if (listLength === 0)
        return (null);

    const canMoveLeft = selectedIndex > 0;
    const canMoveRight = selectedIndex < listLength - 1;

    const leftColor = canMoveLeft ? colors.black : colors.subheading;
    const rightColor = canMoveRight ? colors.black : colors.subheading;

    return (<>
        <ChevronLeft style={{ marginLeft: 8, marginRight: 8, cursor: canMoveLeft ? "pointer" : "" }} color={leftColor} size="20" onClick={() => selectPrevious()} />
        <TextH2 style={{ fontSize: 17 }}>#{selectedIndex + 1}</TextH2>
        <ChevronRight style={{ marginLeft: 8, marginRight: 8, cursor: canMoveRight ? "pointer" : "" }} color={rightColor} size="20" onClick={() => selectNext()} />
    </>)
}