import React from "react"

export const IguItemOperationHeader = () => {
    return <li className="igu-operation-header">
        <div className="igu-operation-container">
            <div className="igu-cell igu-operation-title-cell">Type</div>
            <div className="igu-cell igu-operation-value-cell">U-value</div>
            <div className="igu-cell igu-operation-value-cell">SHGC</div>
            <div className="igu-cell igu-operation-value-cell">VT</div>
            <div className="igu-cell igu-operation-value-cell">CR</div>
            <div className="igu-cell igu-operation-value-cell">STC</div>
            <div className="igu-cell igu-operation-value-cell">OITC</div>
        </div>
    </li>
}