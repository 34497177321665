import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Modal from '@weave-design/modal';
import Label from '@weave-design/label';
import Input from '@weave-design/input';
import Button from '@weave-design/button';
import Typography from "@weave-design/typography";
import IconButton from '@weave-design/icon-button';
import Spacer from '@weave-design/spacer';
import { Folder24 } from '@weave-design/icons';
import { showUploadModelSource, uploadNewModel, showUploadFileError } from "../actions/uploadModelActions";
import { isModelUploadDialogVisible, getNewModelCreationError, State } from "../reducers/mainReducer";
import { ModelPrepareOptions, ModelPrepareWallsOffset } from './model3d';
import { ModelPrepareWallsOffsetSelector } from './modelPrepareWallsOffsetSelector';
import { Info16 } from "@weave-design/icons";
import Flyout, { anchorPoints } from "@weave-design/flyout";
import merge from "lodash.merge";
import "./models.css"


interface IComponentState {
    isVisible: boolean;
    errorMessage: string | null;

    showUploadModelSource: (visible: boolean) => void;
    uploadNewModel: (file: File, modelPrepareOptions: ModelPrepareOptions) => void;
    showUploadFileError: (message: string | null) => void;
}

export const UploadForm = (props: IComponentState) => {
    const [file, setFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState("");
    const [modelPrepareOptions, setModelPrepareOptions] = useState<ModelPrepareOptions>({ wallsOffset: ModelPrepareWallsOffset.None, externalWallTypeNameStartsWith: "" })
    const fileInputElement = useRef<HTMLInputElement>(null);

    const inputTooltipContent = (
        <span style={{ fontSize: "12px", minWidth: 330 }}>
            Type the name of the Revit wall family used for the building's facade. This ensures that Dextall Studio accurately imports and represents the external structure of your building as designed in Revit. <strong>Note:</strong> The walls need to form a closed shell in all cases.
        </span>
    );

    useEffect(() => {
        if (!props.isVisible) {
            setFile(null);
            setFileName("");
        }
    }, [props.isVisible]);

    const modalStyles = useRef((styles: any) =>
        merge(styles, {
            modal: {
                window: {
                    width: "400px",
                    height: "340px",
                    top: "calc(50% - 180px)",
                },
                bodyContent: {
                    overflow: "hidden",
                    paddingRight: 8
                }
            }
        }));

    const tooltipStyles = (baseStyles: any) => {
        return {
            ...baseStyles,
            flyoutContainer: {
                ...baseStyles.flyoutContainer,
                minWidth: 330
            }
        };
    };

    if (!props.isVisible)
        return (null);

    const close = () => {
        setFile(null);
        setFileName("");
        props.showUploadModelSource(false);
    }

    return (<>
        <Modal
            open={props.isVisible}
            title="Upload the input model"
            onCloseClick={() => close()}
            stylesheet={modalStyles.current}>
            <div id="upload-file-form">
                <ModelPrepareWallsOffsetSelector
                    value={modelPrepareOptions.wallsOffset}
                    setValue={(wallsOffset) => setModelPrepareOptions({ ...modelPrepareOptions, wallsOffset })}
                    style={tooltipStyles}
                />
                <div className="stretch">
                    <Label variant="top" style={{ display: "block", marginBottom: 1, marginTop: 3 }}>Revit external walls type:</Label>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Input
                            variant="box"
                            value={modelPrepareOptions.externalWallTypeNameStartsWith}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setModelPrepareOptions({ ...modelPrepareOptions, externalWallTypeNameStartsWith: e.target.value })}
                        />
                        <Flyout stylesheet={tooltipStyles} content={inputTooltipContent} openOnHover={true} anchorPoint={anchorPoints.TOP_RIGHT}>
                            <IconButton
                                icon={<Info16 />}
                                title="" />
                        </Flyout>
                    </div>
                </div>
                <div className="fileBrowseContainer">
                    <div className="stretch">
                        <Label
                            variant="top"
                            disabled={false} >
                            RVT input file:
                        </Label>
                        <Input
                            variant="box"
                            value={fileName}
                            disabled={true}
                        />
                    </div>
                    <div className="browseButton">
                        <label htmlFor="modelFileInput">
                            <IconButton
                                icon={<Folder24 />}
                                title="Choose rvt file"
                                onClick={() => {
                                    props.showUploadFileError(null);
                                    fileInputElement.current?.click();
                                }}
                            />
                        </label>
                        <input id="modelFileInput"
                            ref={fileInputElement}
                            type="file"
                            accept=".rvt"
                            onChange={(e) => {
                                if (!e.target.files || e.target.files.length === 0)
                                    return;

                                const fileToUpload = e.target.files[0];

                                const uploadFileName = fileToUpload.name.toLowerCase();

                                if (!uploadFileName.endsWith(".rvt"))
                                    return;

                                setFile(fileToUpload);
                                setFileName(uploadFileName);
                            }}
                        />
                    </div>
                </div>

                {props.errorMessage !== null && <Typography children={props.errorMessage} className="creation-failed-message" />}
                {props.errorMessage === null && <Spacer spacing="l" />}

                <div className="buttonsContainer">
                    <Button
                        size="standard"
                        title="Create"
                        type="primary"
                        onClick={() => {
                            if (!file)
                                return;

                            props.uploadNewModel(file, modelPrepareOptions);
                        }}
                        disabled={file === null} />
                    <div style={{ width: '14px' }} />
                    <Button
                        id="cancel_button"
                        size="standard"
                        title="Cancel"
                        type="secondary"
                        onClick={() => close()} />
                </div>
            </div>
        </Modal>
    </>)
}

export default connect(function (store: State) {
    return {
        isVisible: isModelUploadDialogVisible(store),
        errorMessage: getNewModelCreationError(store)
    }
}, { showUploadModelSource, uploadNewModel, showUploadFileError })(UploadForm)