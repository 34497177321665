import React, { useState } from "react"
import { colors } from "../config"
import { AppButton } from "./appButton"
import { IModel3DVersion } from "./model3d"
import { ConfirmDialog } from "./confirmDialog"
import { useButtonState } from "./useButtonState"

type Props = {
    selectedModelVersion: IModel3DVersion;
    submitModelToBid: () => void;
}

export const ModelSubmitToBidControls = ({ selectedModelVersion, submitModelToBid }: Props) => {
    const [confirmSubmitToBidDialogVisible, setConfirmSubmitToBidDialogVisible] = useState(false);
    const BIDButtonState = useButtonState();

    return <>
        <AppButton
            style={{
                backgroundColor: colors.folderTopButton,
                padding: "10px 17px",
                marginRight: 10,
                color: BIDButtonState.isSelected ? colors.textBlack : (BIDButtonState.isHovered ? colors.hovered : colors.tag)
            }}
            onClick={() => setConfirmSubmitToBidDialogVisible(true)}
            colored={true}
            hovered={BIDButtonState.isHovered}
            handleMouseEnter={BIDButtonState.handleMouseEnter}
            handleMouseLeave={BIDButtonState.handleMouseLeave}
            selected={BIDButtonState.isSelected}
            handleMouseDown={BIDButtonState.handleMouseDown}
            handleMouseUp={BIDButtonState.handleMouseUp}
        >
            BID
        </AppButton>

        <ConfirmDialog
            isOpened={confirmSubmitToBidDialogVisible}
            title="Submit model"
            confirmButtonTitle="Submit"
            onCancel={() => setConfirmSubmitToBidDialogVisible(false)}
            onConfirm={() => {
                setConfirmSubmitToBidDialogVisible(false);
                submitModelToBid();
            }}
            windowStyles={{ width: "500px", height: "220px" }}
            promptContainerStyles={{ height: "50px" }}>Are you sure you want to submit model <b>{selectedModelVersion.fileName}</b> to BID?</ConfirmDialog>
    </>
}