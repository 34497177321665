import React from "react"
import { colors } from "../config/colors";
import { TextBody2 } from "../assets/text";
import { styles } from "../assets/projectEditorFieldStyle";

type Header = {
    title: string;
    children?: React.ReactNode;
}

type Props = {
    header: Header;
    style?: React.CSSProperties;
    isEditMode?: boolean;
    preview: React.ReactNode;
    edit?: React.ReactNode;
}

export const ProjectProperty = ({ header, style, isEditMode, preview, edit }: Props) => {
    return (<div style={{ ...styles.container, ...style }}>
        <div style={styles.subtitleContainer}>
            <div style={styles.iconContainer}>{header.children}</div>
            <TextBody2 style={{ color: colors.subheading }}>{header.title}</TextBody2>
        </div>
        {isEditMode && edit}
        {!isEditMode && preview}
    </div>)
}