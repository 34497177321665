import React, { useEffect, useState } from "react"
import { Chart } from "react-google-charts";
import { ISessionsWeeklyStatisticsItem } from "../appStatistics";

type Props = {
    weeklySessions: ISessionsWeeklyStatisticsItem[];
    chartContainerStyle?: React.CSSProperties;
}

type ChartHeader = ["Interval", "Max session duration", { role: 'tooltip' }, "Average session duration", { role: 'tooltip' }]
type ChartData = [serie: string, maxSessionDurationSec: number, maxSessionDuration: string, avgSessionDurationSec: number, avgSessionDuration: string];
type ChartSource = [ChartHeader, ...ChartData[]]

export const WeeklySessionDurationsStatistics = ({ weeklySessions, chartContainerStyle }: Props) => {
    const chartHeader: ChartHeader = ["Interval", "Max session duration", { role: 'tooltip' }, "Average session duration", { role: 'tooltip' }];
    const [chartSource, setChartSource] = useState<ChartSource>([chartHeader]);

    useEffect(() => {
        const data: ChartSource = [chartHeader];

        const timeRegex = /([\d]{2})\:([\d]{2})\:([\d]{2})/;
        const getDurationInSeconds = (time: string) => {
            const match = time.match(timeRegex);

            if (!match)
                return 0;

            return Number(match[1]) + Number(match[2]) / 60 + Number(match[3]) / 3600;
        }

        for (const sessions of weeklySessions) {
            const title = `${new Date(sessions.start).toLocaleDateString()} - ${new Date(sessions.end).toLocaleDateString()}`;

            data.push([
                title,
                getDurationInSeconds(sessions.maxSessionLength),
                `${title}\n${sessions.maxSessionLength}`,
                getDurationInSeconds(sessions.averageSessionLength),
                `${title}\n${sessions.averageSessionLength}`
            ])
        }

        setChartSource(data);
    }, [weeklySessions]);

    return <div style={{ ...chartContainerStyle }}>
        <Chart
            chartType="SteppedAreaChart"
            width="100%"
            height="400px"
            data={chartSource}
            options={{
                title: "Session duration",
                legend: { position: "top", maxLines: 3 }
            }} />
    </div>
}