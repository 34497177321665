import { useEffect } from 'react';
import { connect } from 'react-redux';
import StudioNavigationBar from './studioNavigationBar';
import StudioToolbar from "./studioToolbar";
import StudioProjectPane from "./studioProjectPane";
import StudioModelPane from "./studioModelPane";
import StudioEngineeringModelPane from './studioEngineeringModelPane';
import { fetchProjects } from "../actions/projectsActions";
import { styles } from "../assets/studioStyles";
import { styles as paneStyles } from "../assets/studioPaneStyles";
import { ApplicationTabType } from "../actions/applicationUIActions";
import { getActiveTab, State } from "../reducers/mainReducer";
import { Chat } from './chat/chat';
import { ApplicationType } from '../applicationType';

interface IComponentProps {
    activeTab: ApplicationTabType;
    applicationType: ApplicationType;

    fetchProjects: (applicationType: ApplicationType) => void;
}

export const Studio = (props: IComponentProps) => {

    useEffect(() => {
        props.fetchProjects(props.applicationType);
    }, [])

    return (<div style={styles.container}>
        <StudioNavigationBar applicationType={props.applicationType} />
        <div style={paneStyles.folderContainer}>
            <StudioToolbar applicationType={props.applicationType} />
            {props.activeTab === "project" && <StudioProjectPane applicationType={props.applicationType} />}
            {props.activeTab === "model" && <StudioModelPane />}
            {props.activeTab === "engineering-model" && <StudioEngineeringModelPane />}
        </div>
        <Chat/>
    </div>)
}

export default connect(function (store: State) {
    return {
        activeTab: getActiveTab(store),
    };
}, {
    fetchProjects,
})(Studio);