import { ForgeAlignmentExtensionLoadOptions, ForgeAlignmentExtensionName } from "./forge/extensions/forgeAlignmentExtension";
import { ForgeAnalyticsRefreshExtensionLoadOptions, ForgeAnalyticsRefreshExtensionName } from "./forge/extensions/forgeAnalyticsRefreshExtension";
import { ForgeApplyDesignFromAnotherModelExtensionLoadOptions, ForgeApplyDesignFromAnotherModelExtensionName } from "./forge/extensions/forgeApplyDesignFromAnotherModelExtension";
import { ForgeApplyTypeToSimilarPanelsExtensionLoadOptions, ForgeApplyTypeToSimilarPanelsExtensionName } from "./forge/extensions/forgeApplyTypeToSimilarPanelsExtension";
import { ForgeAutomaticalPanelsLayoutAssistantExtensionLoadOptions, ForgeAutomaticalPanelsLayoutAssistantExtensionName } from "./forge/extensions/forgeAutomaticalPanelsLayoutAssistantExtension";
import { ForgeAutomaticalPanelsLayoutExtensionLoadOptions, ForgeAutomaticalPanelsLayoutExtensionName } from "./forge/extensions/forgeAutomaticalPanelsLayoutExtension";
import { ForgeBuildingWideChangeExtensionLoadOptions, ForgeBuildingWideChangeExtensionName } from "./forge/extensions/forgeBuildingWideChangeExtension";
import { ForgeContextMenusExtensionLoadOptions, ForgeContextMenusExtensionName } from "./forge/extensions/forgeContextMenusExtension";
import { ForgeCornerEditorExtensionLoadOptions, ForgeCornerEditorExtensionName } from "./forge/extensions/forgeCornerEditorExtension";
import { ForgeHighLodPanelModelsExtensionLoadOptions, ForgeHighLodPanelModelsExtensionName } from "./forge/extensions/forgeHighLodPanelModelsExtension";
import { ForgeInteractiveBomExtensionLoadOptions, ForgeInteractiveBomExtensionName } from "./forge/extensions/forgeInteractiveBomExtension";
import { ForgeLinkedModelsExtensionLoadOptions, ForgeLinkedModelsExtensionName } from "./forge/extensions/forgeLinkedModelsExtension";
import { ForgeModelWarningsControllerExtensionLoadOptions, ForgeModelWarningsControllerExtensionName } from "./forge/extensions/forgeModelWarningsControllerExtension";
import { ForgePanelCatalogsExtensionLoadOptions, ForgePanelCatalogsExtensionName } from "./forge/extensions/forgePanelCatalogsExtension";
import { ForgePanelEditorExtensionLoadOptions, ForgePanelEditorExtensionName } from "./forge/extensions/forgePanelEditorExtension";
import { ForgePanelPreviewExtensionLoadOptions, ForgePanelPreviewExtensionName } from "./forge/extensions/forgePanelPreviewExtension";
import { ForgePanelsGridEditor2DExtensionLoadOptions, ForgePanelsGridEditor2DExtensionName } from "./forge/extensions/forgePanelsGridEditor2DExtension";
import { ForgePanelsGridEditorExtensionLoadOptions, ForgePanelsGridEditorExtensionName } from "./forge/extensions/forgePanelsGridEditorExtension";
import { ForgePanelsLayoutAssistant2DExtensionLoadOptions, ForgePanelsLayoutAssistant2DExtensionName } from "./forge/extensions/forgePanelsLayoutAssistant2DExtension";
import { ForgePanelsPropertiesExtensionLoadOptions, ForgePanelsPropertiesExtensionName } from "./forge/extensions/forgePanelsPropertiesExtension";
import { ForgeRevitFacadeModelExtensionLoadOptions, ForgeRevitFacadeModelExtensionName } from "./forge/extensions/forgeRevitFacadeModelExtension";
import { ForgeSearchElementsByIdExtensionLoadOptions, ForgeSearchElementsByIdExtensionName } from "./forge/extensions/forgeSearchElementsByIdExtension";
import { ForgeTypesEditorExtensionLoadOptions, ForgeTypesEditorExtensionName } from "./forge/extensions/forgeTypesEditorExtension";
import { ForgeUndoRedoExtensionLoadOptions, ForgeUndoRedoExtensionName } from "./forge/extensions/forgeUndoRedoExtension";
import { ForgeViewerModeSwitcherExtensionLoadOptions, ForgeViewerModeSwitcherExtensionName } from "./forge/extensions/forgeViewerModeSwitcherExtension";
import { ForgeWallWindows2dEditorExtensionLoadOptions, ForgeWallWindows2dEditorExtensionName } from "./forge/extensions/forgeWallWindows2dEditorExtension";
import { ForgeWallsEditorExtensionLoadOptions, ForgeWallsEditorExtensionName } from "./forge/extensions/forgeWallsEditorExtension";
import { ForgeWallsEditorUndoRedoExtensionLoadOptions, ForgeWallsEditorUndoRedoExtensionName } from "./forge/extensions/forgeWallsEditorUndoRedoExtension";
import { ForgeWindowsEditorExtensionLoadOptions, ForgeWindowsEditorExtensionName } from "./forge/extensions/forgeWindowsEditorExtension";
import { ViewerState } from "./forgeViewer";
import { IModel3DVersion } from "./model3d";
import { ForgeAtypicalSolutionsListExtensionLoadOptions, ForgeAtypicalSolutionsListExtensionName } from "./forge/extensions/forgeAtypicalSolutionsListExtension";

type ViewerExtension<TName extends string, TLoadOptions extends {}> = { id: TName, loadOptions: TLoadOptions };

export type LoadingExtension =
    | ViewerExtension<ForgeAlignmentExtensionName, ForgeAlignmentExtensionLoadOptions>
    | ViewerExtension<ForgeAnalyticsRefreshExtensionName, ForgeAnalyticsRefreshExtensionLoadOptions>
    | ViewerExtension<ForgeApplyDesignFromAnotherModelExtensionName, ForgeApplyDesignFromAnotherModelExtensionLoadOptions>
    | ViewerExtension<ForgeApplyTypeToSimilarPanelsExtensionName, ForgeApplyTypeToSimilarPanelsExtensionLoadOptions>
    | ViewerExtension<ForgeAutomaticalPanelsLayoutAssistantExtensionName, ForgeAutomaticalPanelsLayoutAssistantExtensionLoadOptions>
    | ViewerExtension<ForgeAutomaticalPanelsLayoutExtensionName, ForgeAutomaticalPanelsLayoutExtensionLoadOptions>
    | ViewerExtension<ForgeBuildingWideChangeExtensionName, ForgeBuildingWideChangeExtensionLoadOptions>
    | ViewerExtension<ForgeContextMenusExtensionName, ForgeContextMenusExtensionLoadOptions>
    | ViewerExtension<ForgeCornerEditorExtensionName, ForgeCornerEditorExtensionLoadOptions>
    | ViewerExtension<ForgeHighLodPanelModelsExtensionName, ForgeHighLodPanelModelsExtensionLoadOptions>
    | ViewerExtension<ForgeInteractiveBomExtensionName, ForgeInteractiveBomExtensionLoadOptions>
    | ViewerExtension<ForgeLinkedModelsExtensionName, ForgeLinkedModelsExtensionLoadOptions>
    | ViewerExtension<ForgeModelWarningsControllerExtensionName, ForgeModelWarningsControllerExtensionLoadOptions>
    | ViewerExtension<ForgePanelCatalogsExtensionName, ForgePanelCatalogsExtensionLoadOptions>
    | ViewerExtension<ForgePanelEditorExtensionName, ForgePanelEditorExtensionLoadOptions>
    | ViewerExtension<ForgePanelPreviewExtensionName, ForgePanelPreviewExtensionLoadOptions>
    | ViewerExtension<ForgePanelsGridEditor2DExtensionName, ForgePanelsGridEditor2DExtensionLoadOptions>
    | ViewerExtension<ForgePanelsGridEditorExtensionName, ForgePanelsGridEditorExtensionLoadOptions>
    | ViewerExtension<ForgePanelsLayoutAssistant2DExtensionName, ForgePanelsLayoutAssistant2DExtensionLoadOptions>
    | ViewerExtension<ForgePanelsPropertiesExtensionName, ForgePanelsPropertiesExtensionLoadOptions>
    | ViewerExtension<ForgeRevitFacadeModelExtensionName, ForgeRevitFacadeModelExtensionLoadOptions>
    | ViewerExtension<ForgeSearchElementsByIdExtensionName, ForgeSearchElementsByIdExtensionLoadOptions>
    | ViewerExtension<ForgeTypesEditorExtensionName, ForgeTypesEditorExtensionLoadOptions>
    | ViewerExtension<ForgeUndoRedoExtensionName, ForgeUndoRedoExtensionLoadOptions>
    | ViewerExtension<ForgeViewerModeSwitcherExtensionName, ForgeViewerModeSwitcherExtensionLoadOptions>
    | ViewerExtension<ForgeWallsEditorExtensionName, ForgeWallsEditorExtensionLoadOptions>
    | ViewerExtension<ForgeWallsEditorUndoRedoExtensionName, ForgeWallsEditorUndoRedoExtensionLoadOptions>
    | ViewerExtension<ForgeWallWindows2dEditorExtensionName, ForgeWallWindows2dEditorExtensionLoadOptions>
    | ViewerExtension<ForgeWindowsEditorExtensionName, ForgeWindowsEditorExtensionLoadOptions>
    | ViewerExtension<ForgeAtypicalSolutionsListExtensionName, ForgeAtypicalSolutionsListExtensionLoadOptions>
type Props = {
    selectedModel: IModel3DVersion | null;
}

export const loadViewerExtensions = async ({ selectedModel }: Props, viewer: Autodesk.Viewing.GuiViewer3D,
    extensions: LoadingExtension[], urn: string, state: ViewerState) => {
    for (const extension of extensions) {
        await loadViewerExtension(viewer, extension);

        if (selectedModel?.urn !== urn || state.shutdownSignaled)
            return;
    }
}

export const loadViewerExtension = (viewer: Autodesk.Viewing.Viewer3D, extension: LoadingExtension) => viewer.loadExtension(extension.id, extension.loadOptions);