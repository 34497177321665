import { ApplicationUIActionType, ApplicationTabType } from '../actions/applicationUIActions'

export type State = {
    isModalInfoVisible: boolean;
    isAnalyticsTabVisible: boolean;
    activeTab: ApplicationTabType;
}

export const initialState: State = {
    isModalInfoVisible: false,
    isAnalyticsTabVisible: false,
    activeTab: "project"
}

export const isModalInfoVisible = function (state: State) {
    return state.isModalInfoVisible;
}

export const isAnalyticsTabVisible = function (state: State) {
    return state.isAnalyticsTabVisible;
}

export const getActiveTab = function (state: State) {
    return state.activeTab;
}

export default function (state: State = initialState, action: ApplicationUIActionType): State {
    switch (action.type) {
        case "SET_ACTIVE_TAB": {
            return { ...state, activeTab: action.tab, isAnalyticsTabVisible: false }
        }

        case "SET_MODEL_INFO_VISIBILITY": {
            return { ...state, isModalInfoVisible: action.isVisible }
        }

        case "SET_ANALYTICS_VISIBILITY": {
            return { ...state, isAnalyticsTabVisible: action.isVisible }
        }

        default:
            return state;
    }
}