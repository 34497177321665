import React from "react";
import { colors } from "../../config/colors";

type Props = {
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const TextButton = ({ children, style }: Props) => {
  return (
    <p className="unselectable" style={{ ...styles.text, ...style }}>
      {children}
    </p>
  );
};

type Styles = {
  text: React.CSSProperties;
}

const styles: Styles = {
  text: {
    fontSize: "15px",
    fontFamily: "Roboto",
    lineHeight: "20px",
    color: colors.textBlack,
    fontStyle: "normal",
    fontWeight: 400,
  },
};
