import { ProcessingActionType } from '../actions/processingActions'

export type State = {
    processingPaneVisible: boolean;
    processingPaneTitle: string;
    processingCurrentMessageTitle: string | null;
    processingCurrentMessage: string | null;
    processingErrorMessage: string | null;
}

export const initialState: State = {
    processingPaneVisible: false,
    processingPaneTitle: "Processing...",
    processingCurrentMessageTitle: null,
    processingCurrentMessage: null,
    processingErrorMessage: null
}

export const isProcessingPaneVisible = function (state: State) {
    return state.processingPaneVisible;
}

export const getProcessingMessage = function (state: State) {
    return state.processingCurrentMessage;
}

export const getProcessingTitle = function (state: State) {
    return state.processingPaneTitle;
}

export const isProcessingFailed = function (state: State) {
    return state.processingErrorMessage !== null;
}

export const getProcessingFailure = function (state: State) {
    return state.processingErrorMessage;
}

export const getProcessingFailureTitle = function (state: State) {
    return state.processingCurrentMessageTitle;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state: State = initialState, action: ProcessingActionType): State {
    switch (action.type) {
        case "PROCESSING_PANE_SHOW": {
            return { ...state, processingPaneVisible: action.visible, processingPaneTitle: action.title || "", processingCurrentMessage: null };
        }

        case "PROCESSING_PANE_PROGRESS": {
            return { ...state, processingCurrentMessage: action.message };
        }

        case "SET_PROCESSING_FAILURE": {
            return { ...state, processingCurrentMessageTitle: action.title, processingErrorMessage: action.message };
        }

        case "CLOSE_PROCESSING_FAILURE": {
            return { ...state, processingErrorMessage: null }
        }

        default:
            return state;
    }
}