import React, { useEffect, useState } from "react"
import { Chart } from "react-google-charts";
import { ISessionsDailyStatisticsItem } from "../appStatistics";

type Props = {
    dailySessions: ISessionsDailyStatisticsItem[];
    chartContainerStyle?: React.CSSProperties;
}

type ChartHeader = [{ type: "date" }, "Max session duration", { role: 'tooltip', }, "Average session duration", { role: 'tooltip' }]
type ChartData = [serie: Date, maxSessionDurationSec: number, maxSessionDuration: string, avgSessionDurationSec: number, avgSessionDuration: string];
type ChartSource = [ChartHeader, ...ChartData[]];

export const DailySessionDurationsStatistics = ({ dailySessions, chartContainerStyle }: Props) => {
    const chartHeader: ChartHeader = [{ type: "date" }, "Max session duration", { role: 'tooltip' }, "Average session duration", { role: 'tooltip' }];
    const [chartSource, setChartSource] = useState<ChartSource>([chartHeader]);

    useEffect(() => {
        const data: ChartSource = [chartHeader];

        const timeRegex = /([\d]{2})\:([\d]{2})\:([\d]{2})/;
        const getDurationInSeconds = (time: string) => {
            const match = time.match(timeRegex);

            if (!match)
                return 0;

            return Number(match[1]) + Number(match[2]) / 60 + Number(match[3]) / 3600;
        }

        for (const sessions of dailySessions) {
            const date = new Date(sessions.date);
            data.push([
                date,
                getDurationInSeconds(sessions.maxSessionLength),
                `${date.toDateString()}\nMax session duration: ${sessions.maxSessionLength}`,
                getDurationInSeconds(sessions.averageSessionLength),
                `${date.toDateString()}\nAvg session duration: ${sessions.averageSessionLength}`
            ]);
        }

        setChartSource(data);
    }, [dailySessions]);

    return <div style={{ ...chartContainerStyle }}>
        <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={chartSource}
            options={{
                title: "Session duration",
                legend: { position: "top", maxLines: 3 }
            }} />
    </div>
}