import React from "react"
import "./maintenance.css"
import MaintenanceLogo from "./assets/maintenance.png"

export const OnGoingMaintenance = () => {
    return <div className="maintenance-backdrop-container">
        <div>
            <img src={MaintenanceLogo} alt="Maintenance" className="maintenance-image" />
        </div>
        <div className="maintenance-title">Dextall Studio is in the maintenance mode</div>
    </div>
}