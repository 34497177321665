import { IInsulatedGlassUnit, InsulatedGlassUnitValuesPreferences } from "./forge/insulatedGlassUnit";

export enum SystemType {
    DWall = 0,

    Retrofit = 1,

    Snowman = 2
}

export interface IProject {
    id: string;
    name: string;
    number: string;
    postalCode: string;
    address: string;
    author: string;
    margin: number;
    taxRate: number;
    duties: number;
    buildingName: string;
    clientName: string;
    createdAt: Date | string;
    issueDate: Date | string | null;
    organizationDescription: string;
    organizationName: string;
    location: IProjectLocation | null;
    description: string;
    systemType: SystemType;
    userName: string;
    insulatedGlassUnitValuesPreferences: InsulatedGlassUnitValuesPreferences | null;
    selectedInsulatedGlassUnit: IInsulatedGlassUnit | null;
}

export interface IProjectLocation {
    lat: number;
    lng: number;
}

export type BasicResponse = { isSuccess: true, message: null } | { isSuccess: false, message: string }

export type BasicItemResponse<T> = { isSuccess: true, message: null, item: T } | { isSuccess: false, message: string, item: null }

export type BasicItemsResponse<T> = { isSuccess: true, message: null, items: T[] } | { isSuccess: false, message: string, items: null }

export interface IForgeToken {
    access_token: string;
    expires_in: number;
}

export interface ICreateUpdateProjectCommand {
    id: string;
    name: string;
    postalCode: string;
    address: string;
    number: string;
    organizationName: string;
    organizationDescription: string;
    buildingName: string;
    author: string;
    margin: number;
    taxRate: number;
    duties: number;
    clientName: string;
    issueDate: string | null;
    location: IProjectLocation | null;
    insulatedGlassUnitValuesPreferences: InsulatedGlassUnitValuesPreferences | null;
    selectedInsulatedGlassUnitId: string | null;
    description: string;
    systemType: number;
}

export interface IApplicationMetadata {
    version: string;
}