import { IconButton } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { card } from "../../config"
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { ChatWindow } from "./chatWindow";
import { AxiosError } from "axios";
import { BasicItemResponse, BasicItemsResponse } from "../project";
import { ITeamsChannel, ITeamsSendMessage } from "../forge/teams";
import repo from "../../Repository";

export const Chat = () => {

    const [chatVisible, setChatVisible] = useState(false);
    const [chatMessage, setChatMessage] = useState("");
    const [userName, setUserName] = useState("")
    const channelIdRef = useRef("");
    const [channelId, setChannelId] = useState("");
    const messageIdRef = useRef("");
    const [messageId, setMessageId] = useState("");
    const [messages, setMessages] = useState<ITeamsSendMessage[]>([]);
    const [firstMessage, setFirstMessage] = useState<ITeamsSendMessage[]>([]);
    const uploadLink = useRef("");
    const localMessagesIds = useRef(new Set<string>());

    useEffect(() => {
        repo.loadProfile().then(profile => setUserName(profile.name));
        const handle = window.setInterval(async () => {
            if (channelIdRef.current != "" && messageIdRef.current != "") {
                await findMessages(channelIdRef.current, messageIdRef.current);
            }
        }, 2500);

        return () => {
            window.clearInterval(handle);
        }
    }, [])


    const setChannelsId = async (channelId: string) => {
        setChannelId(channelId);
        channelIdRef.current = channelId;
    };

    const setMessagesId = async (messageId: string) => {
        setMessageId(messageId);
        messageIdRef.current = messageId;
    };
    const messageReply = async (channel: ITeamsChannel, messageId: string) => {
        let messageResponse: BasicItemResponse<ITeamsSendMessage>;
        try {
            const message = uploadLink.current != "" ? chatMessage + " " + uploadLink.current : chatMessage;
            messageResponse = await repo.sendMessageAnswer(channel.id, messageId, message);
        } catch (ex: AxiosError | any) {
            return;
        }

        if (messageResponse.isSuccess) {
            const localMessages = [...messages];
            localMessages.push(messageResponse.item)
            setMessages(localMessages);
        }
    }

    const message = async (channel: ITeamsChannel) => {
        let messageResponse: BasicItemResponse<ITeamsSendMessage>;
        try {
            const message = uploadLink.current != "" ? chatMessage + " " + uploadLink.current : chatMessage;
            messageResponse = await repo.sendMessage(channel.id, message);
        } catch (ex: AxiosError | any) {
            return;
        }

        if (messageResponse.isSuccess) {
            if (!localMessagesIds.current.has(messageResponse.item.id)) {
                localMessagesIds.current.add(messageResponse.item.id);
                setFirstMessage(new Array(messageResponse.item));
            }
            await setMessagesId(messageResponse.item.id);
        }
    }


    const findMessagesResponses = async (channelId: string, messageId: string) => {
        let findMessagesResponse: BasicItemsResponse<ITeamsSendMessage>;
        try {
            findMessagesResponse = await repo.findMessagesReplies(channelId, messageId);
        } catch (ex: AxiosError | any) {
            return;
        }

        if (findMessagesResponse.isSuccess) {
            let sorted = findMessagesResponse.items.reverse();
            setMessages(sorted);
        }
    }

    const findMessages = async (channelId: string, messageId: string) => {
        let findMessagesResponse: BasicItemResponse<ITeamsSendMessage>;
        try {
            findMessagesResponse = await repo.findMessages(channelId, messageId);
            await findMessagesResponses(channelId, messageId);
        } catch (ex: AxiosError | any) {
            return;
        }

        if (findMessagesResponse.isSuccess) {
            setFirstMessage(new Array(findMessagesResponse.item));
        }
    }
    const createChannel = async (email: string) => {
        let channelResponse: BasicItemResponse<ITeamsChannel>;
        try {
            channelResponse = await repo.createChannel(email);
        } catch (ex: AxiosError | any) {
            if (ex.channelResponse.data?.message)
                channelResponse = ex.response.data;
            else
                channelResponse = { isSuccess: false, message: "Something went wrong. Please try again", item: null }
        }

        if (channelResponse.isSuccess) {
            await setChannelsId(channelResponse.item.id);
            await message(channelResponse.item);
        }
    }

    const uploadFile = async (file: File, fileName: string) => {

        let signedUrlResponse: BasicItemResponse<string>;

        try {
            signedUrlResponse = await repo.createChatBlobSignedUrl(userName, fileName);
        } catch (ex: AxiosError | any) {
            if (ex.signedUrlResponse.data?.message)
                signedUrlResponse = ex.response.data;
            else
                signedUrlResponse = { isSuccess: false, message: "Something went wrong. Please try again", item: null }
        }

        if (signedUrlResponse.isSuccess) {
            try {
                await repo.uploadChatFileToBucket(signedUrlResponse.item, file);
                const googleUrl = `https://dextall-chat-messages.storage.googleapis.com/${userName}/${encodeURIComponent(fileName)}`;
                uploadLink.current = googleUrl;
            } catch (ex: AxiosError | any) {
                console.error(ex);
            }
        }

    }

    return (<div style={{ position: "absolute", right: 25, bottom: 25, display: "flex", flexDirection: "column", gap: 5, alignItems: "center", zIndex: 2 }}>

        <ChatWindow isVisible={chatVisible} onSendMessage={async () => {
            const profile = await repo.loadProfile();
            setUserName(profile.name);
            let channels: BasicItemsResponse<ITeamsChannel>;

            try {
                channels = await repo.findChannels();

                if (!channels.isSuccess)
                    return;

                const channel = channels.items.find(c => c.displayName == profile.name);
                if (channel != null) {
                    await setChannelsId(channel.id);
                    if (messageIdRef.current.length == 0) {
                        await message(channel);
                    } else {
                        await messageReply(channel, messageId);
                    }
                } else {
                    await createChannel(profile.name);
                }
            } catch (ex: AxiosError | any) {

            }

        }} onTypeMessage={(e) => setChatMessage(e)} profileName={userName} messages={firstMessage.concat(...messages)} onFileSelect={(file, fileName) => uploadFile(file, fileName)} />
        <div style={{display: "flex", flexDirection: "column", gap: "8px", alignItems: "center", alignSelf: "end"}}>
            <IconButton aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setChatVisible(!chatVisible)
                        }}
                        sx={{ width: 48, height: 48, borderRadius: 24, backgroundColor: card.chat }}>
                <QuestionAnswerIcon sx={{ color: 'white' }} />
            </IconButton>
            <p>Chat</p>
        </div>
    </div>)
}