import React from "react";
import { colors } from "../../config/colors";

type Props = {
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const TextBody2 = ({ children, style }: Props) => {
  return <p style={{ ...styles.text, ...style }}>{children}</p>;
};

type Styles = {
  text: React.CSSProperties;
}

const styles: Styles = {
  text: {
    fontSize: "13px",
    fontFamily: "Roboto",
    lineHeight: "20px",
    color: colors.subheading,
    fontStyle: "normal",
    fontWeight: 400,
  },
};
