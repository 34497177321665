import React, { useEffect, useState } from "react"
import { viewerCss, viewerJs } from "./shared"
import { makeFix } from "./forge/fixes/fixes";
import repo from '../Repository';

type Props = {
    viewerInitialized?: () => void;
    children?: React.ReactNode;
}

export const ForgeInitializer = ({ viewerInitialized, children }: Props) => {
    const [initialized, setInitialized] = useState(false);

    const onViewerInitialized = () => {
        makeFix();

        if (viewerInitialized)
            viewerInitialized();

        setInitialized(true);
    }

    const initializeViewer = () => {
        const options: Autodesk.Viewing.InitializerOptions = {
            getAccessToken: (callback) => {
                let attempt = 0;

                const queryToken = () => {
                    repo.getForgeViewerToken().then(x => {
                        if (typeof callback !== "function")
                            return;

                        if (x.isSuccess) {
                            callback(x.item.access_token, x.item.expires_in);
                            return;
                        }

                        ++attempt;

                        setTimeout(queryToken, 1000 * Math.pow(2, attempt));
                    });
                }

                queryToken();
            },
            env: 'AutodeskProduction2',
            api: 'streamingV2'
        }

        Autodesk.Viewing.Initializer(options, onViewerInitialized);
    }

    const shutdownViewer = () => Autodesk.Viewing.shutdown();

    useEffect(() => {
        const scriptId = "forge-viewer-script";

        if (document.getElementById(scriptId)) {
            initializeViewer();

            return () => shutdownViewer();
        }

        const script = document.createElement("script");

        script.id = scriptId;
        script.onload = () => initializeViewer();
        script.src = viewerJs;

        document.head.appendChild(script);

        const css = document.createElement("link");

        css.rel = "stylesheet";
        css.type = "text/css";
        css.href = viewerCss;

        document.head.appendChild(css);

        return () => shutdownViewer();
    }, []);

    return (<>
        {initialized && children}
    </>)
}