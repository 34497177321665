import React from "react";
import { colors } from "../config/index";
import { TagH4 } from "../assets/text/index";
import { styles } from "../assets/studioToolbarHeaderStyles";
import { ApplicationTabType } from "../actions/applicationUIActions";
import DextallLogo from "../assets/icons/dextall-logo.png"

type Props = {
  selectedWindow: ApplicationTabType;
  isEngineer: boolean;
  setSelectedWindow: (newTab: ApplicationTabType) => void;
}

export const StudioToolbarHeader = ({ selectedWindow, setSelectedWindow }: Props) => {

  return (
    <div style={styles.folderTopSelector}>
      {selectedWindow != "project" && 
        <div style={styles.logo}>
          <img style={{ width: 24 }} src={DextallLogo} alt="" />
        </div>
      }
      <TagH4
        style={
          selectedWindow === "project"
            ? { color: colors.textBlack, height: 80, marginLeft: 15, marginRight: 15 }
            : { height: 80, marginLeft: 15, marginRight: 15, cursor: "pointer" }
        }
        onClick={() => setSelectedWindow("project")}>
        Project
      </TagH4>
    </div>
  );
};