import React from "react"
import { colors } from "../config/colors";

const container: React.CSSProperties = {
    border: "0.2px solid rgba(198, 198, 198, 0.5)",
    borderRadius: 12,
    backgroundColor: colors.white,
    boxShadow: "0px 0px 28px rgba(0, 0, 0, 0.05)",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 16,
    paddingRight: 16,
}

export const styles = { container }