import React from "react"
import { colors } from "../config/colors";

const container: React.CSSProperties = {
    position: "relative",
    display: "flex",
    flex: 1,
    height: "100vh",
    backgroundColor: colors.greyBackground,
}

const logo: React.CSSProperties = {
    width: "100%",
    height: 42,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

const list: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 130,
    paddingLeft: 18,
    paddingBottom: 70,
};

const signOutContainer: React.CSSProperties = {
    position: "absolute",
    bottom: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: 140,
};

const fileUploadContainer: React.CSSProperties = {
    width: "100%",
    height: "100vh",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 99,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
}

export const styles = {
    container,
    logo,
    list,
    signOutContainer,
    fileUploadContainer
};
