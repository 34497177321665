import React, { useRef, useState } from "react"
import { connect } from "react-redux";
import { getMaterialPrices, isMaterialTableVisible, State } from "../reducers/mainReducer";
import { DisplayCurrencies, DisplayUnits, highPrices, IMaterial, roundToEuro } from "./modelAnalytics";
import merge from "lodash.merge";
import Modal from "@weave-design/modal";
import { roundToDecimalSigns } from "@dextall/shared";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IMaterialPrice } from "./price";
import './materialTable.css'

export interface Props {
    isVisible: boolean;
    materialPrices: IMaterial | null | undefined;

    price: IMaterialPrice | null | undefined;
    taxRate: number;

    displayUnits: DisplayUnits | undefined;
    onCancel: () => void;
}

export const PriceBreakdownDialog = (props: Props) => {

    const [expanded, setExpanded] = useState<string | false>("panel1");
    const handleChange =
        (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const currency = localStorage.getItem("Dextall.Currency") === "EUR" ? DisplayCurrencies.Eur : DisplayCurrencies.Usd;
    const prices = props.materialPrices?.prices || [];
    const modalStyles = useRef((styles: any) =>
        merge(styles, {
            modal: {
                window: { // by design
                    width: "1340px",
                    height: "800px",
                    top: "calc(50% - 400px)"
                },
                bodyContent: {
                    overflow: "hidden" // no scrollbar
                }
            }
        }));

    const unit = (x: string) => {
        switch (x) {
            case "m3":
                return <span>m<sup>3</sup></span>;
            case "m2":
                return <span>m<sup>2</sup></span>;
            default:
                return <span>{x}</span>;
        }
    }

    const areaUnits = props.displayUnits === DisplayUnits.Imperial ? "ft²" : "m²"

    return (
        <Modal
            open={props.isVisible}
            title="Prices breakdown"
            close={!props.isVisible}
            onCloseClick={() => props.onCancel()} stylesheet={modalStyles.current}>
            <div className="table-container">
                <Accordion expanded={expanded === 'panel1'} sx={{ width: 1262 }} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{ backgroundColor: "#dedede", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                        component='div'>
                        <Typography sx={{ width: "50%", flexShrink: 0, fontWeight: 600 }} component='div'>
                            Materials
                        </Typography>
                        <Typography sx={{ color: 'text.secondary', width: "50%", textAlign: 'end', paddingRight: '10px' }} component='div'>{roundToEuro(highPrices(prices), currency, props.price?.currencyCoef!)}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0, maxHeight: 350, minHeight: 0, display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
                        <span className="flex-table header" role="rowgroup">
                            <div className="flex-row flex-row-head" role="columnheader"><b>Category</b></div>
                            <div className="flex-row flex-row-head" role="columnheader"><b>Group</b></div>
                            <div className="flex-row flex-row-head" role="columnheader"><b>Item Name</b></div>
                            <div className="flex-row flex-row-head" role="columnheader"><b>Quantity</b></div>
                            <div className="flex-row flex-row-head" role="columnheader"><b>Units</b></div>
                            <div className="flex-row flex-row-head" role="columnheader"><b>Price</b></div>
                        </span>
                        <span className="date-table">
                            {
                                prices?.map(price => {
                                    return <div className="flex-table row" role="rowgroup" key={price.id}>
                                        <div className="flex-row first" role="cell"> {price.category}
                                        </div>
                                        <div className="flex-row" role="cell">{price.group}</div>
                                        <div className="flex-row" role="cell" style={{ textAlign: "left" }}>
                                            <p className="price-item-name-column-value">{price.itemName}</p>
                                        </div>
                                        <div className="flex-row" role="cell" style={{ textAlign: "right" }}>
                                            <p className="price-item-column-value">{roundToDecimalSigns(price.quantity, 2)}</p>
                                        </div>
                                        <div className="flex-row" role="cell" style={{ textAlign: "right" }}>
                                            <p className="price-item-column-value">{unit(price.units)}</p>
                                        </div>
                                        <div className="flex-row" role="cell" style={{ textAlign: "right" }}>
                                            <p className="price-item-column-value">{price.highPrice != null ? roundToEuro(price.highPrice, currency, props.price?.currencyCoef!) : ""}</p>
                                        </div>
                                    </div>
                                })
                            }
                        </span>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} sx={{ width: 1262 }} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                        sx={{ backgroundColor: "#dedede", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                    >
                        <Typography sx={{ width: "50%", flexShrink: 0, fontWeight: 600 }} component='div'>Production Prices</Typography>
                        <Typography sx={{ color: 'text.secondary', width: "50%", textAlign: 'end', paddingRight: '10px' }} component='div'>
                            {roundToEuro(props.price?.productionPrice!, currency, props.price?.currencyCoef!)}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component='div'>
                            <span className="list">
                                <div className="list_item">Total opaque area: {roundToDecimalSigns(props.price?.totalArea!, 2)} {areaUnits}</div>
                                <div className="list_item">Production prices: {roundToEuro(props.price?.productionPrice!, currency, props.price?.currencyCoef!)}</div>
                            </span>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} sx={{ width: 1262 }} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                        sx={{ backgroundColor: "#dedede", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                    >
                        <Typography sx={{ width: "50%", flexShrink: 0, fontWeight: 600 }} component='div'>Administrative Prices</Typography>
                        <Typography sx={{ color: 'text.secondary', width: "50%", textAlign: 'end', paddingRight: '10px' }} component='div'>
                            {roundToEuro(props.price?.administrativePrice!, currency, props.price?.currencyCoef!)}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component='div'>
                            <span className="list">
                                <div className="list_item">Duties: {roundToEuro(props.price?.dutiesPrice!, currency, props.price?.currencyCoef!)}</div>
                                <div className="list_item">Margins: {roundToEuro(props.price?.marginPrice!, currency, props.price?.currencyCoef!)}</div>
                                <div className="list_item">Administrative Prices: {roundToEuro(props.price?.administrativePrice!, currency, props.price?.currencyCoef!)}</div>
                            </span>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} sx={{ width: 1262 }} onChange={handleChange('panel4')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                        sx={{ backgroundColor: "#dedede", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                    >
                        <Typography sx={{ width: "50%", flexShrink: 0, fontWeight: 600 }} component='div'>Taxes</Typography>
                        <Typography sx={{ color: 'text.secondary', width: "50%", textAlign: 'end', paddingRight: '10px' }} component='div'>
                            {roundToEuro(props.price?.taxes!, currency, props.price?.currencyCoef!)}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component='div'>
                            <span className="list">
                                <div className="list_item">Tax Rate: {props.taxRate}%</div>
                                <div className="list_item">Taxes: {roundToEuro(props.price?.taxes!, currency, props.price?.currencyCoef!)}</div>
                            </span>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel5'} sx={{ width: 1262 }} onChange={handleChange('panel5')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                        sx={{ backgroundColor: "#dedede", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                        component='div'>
                        <Typography sx={{ width: "50%", flexShrink: 0, fontWeight: 600 }} component='div'>Packing Price</Typography>
                        <Typography sx={{ color: 'text.secondary', width: "50%", textAlign: 'end', paddingRight: '10px' }} component='div'>
                            {roundToEuro(props.price?.packingPrice!, currency, props.price?.currencyCoef!)}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component='div'>
                            <span className="table__info">
                                <div className="table__item">
                                    <p className="table__item_header">Packaging</p>
                                    <p className="table__item_element"><span className="table__item_title">Total Boxes:</span> <span className="table__item_value">{props.price?.totalBoxes!} pc</span></p>
                                    <p className="table__item_element"><span className="table__item_title">Total Wood:</span> <span className="table__item_value">{props.price?.totalWood!} m<sup>3</sup></span></p>
                                    <p className="table__item_element"><span className="table__item_title">Total Screws:</span> <span className="table__item_value">{props.price?.totalScrews!} pc</span></p>
                                    <p className="table__item_element"><span className="table__item_title">Total Stretch tape:</span> <span className="table__item_value">{props.price?.totalStretch} m</span></p>

                                </div>
                                <div className="table__item">
                                    <p className="table__item_header"></p>
                                    <p className="table__item_element"><span className="table__item_value"></span></p>
                                    <p className="table__item_element"><span className="table__item_value">{roundToEuro(props.price?.woodPrice!, currency, props.price?.currencyCoef!)}</span></p>
                                    <p className="table__item_element"><span className="table__item_value">{roundToEuro(props.price?.screwPrice!, currency, props.price?.currencyCoef!)}</span></p>
                                    <p className="table__item_element"><span className="table__item_value">{roundToEuro(props.price?.tapePrice!, currency, props.price?.currencyCoef!)}</span></p>
                                </div>
                            </span>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel6'} sx={{ width: 1262 }} onChange={handleChange('panel6')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                        sx={{ backgroundColor: "#dedede", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                        component='div'>
                        <Typography sx={{ width: "50%", flexShrink: 0, fontWeight: 600 }} component='div'>Transportation Price</Typography>
                        <Typography sx={{ color: 'text.secondary', width: "50%", textAlign: 'end', paddingRight: '10px' }} component='div'>
                            {roundToEuro(props.price?.transportationPrice!, currency, props.price?.currencyCoef!)}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component='div'>
                            <span className="table__info">
                                <div className="table__item">
                                    <p className="table__item_header">Panel Type 1 Box Dimensions</p>
                                    <p className="table__item_element"><span className="table__item_title">Box Width:</span> <span className="table__item_value">{props.materialPrices?.panelPacking.boxWidth!.toFixed(2)} m</span></p>
                                    <p className="table__item_element"><span className="table__item_title">Box Length:</span> <span className="table__item_value">{props.materialPrices?.panelPacking.boxLength!.toFixed(2)} m</span></p>
                                    <p className="table__item_element"><span className="table__item_title">Box Height:</span> <span className="table__item_value">{props.materialPrices?.panelPacking.boxHeight!.toFixed(2)} m</span></p>
                                    <p className="table__item_element"><span className="table__item_title">Surface Area:</span> <span className="table__item_value">{props.materialPrices?.panelPacking.surfaceArea!.toFixed(2)} m<sup>2</sup></span></p>
                                    <p className="table__item_element"><span className="table__item_title">Total Containers:</span> <span className="table__item_value">{props.materialPrices?.panelPacking.totalContainers!} pc</span></p>
                                </div>
                                <div className="table__item">
                                    <p className="table__item_header">Panel Type 3 Box Dimensions</p>
                                    <p className="table__item_element"><span className="table__item_title">Corner Box Width:</span> <span className="table__item_value">{props.materialPrices?.cornerPacking.boxWidth!.toFixed(2)} m</span></p>
                                    <p className="table__item_element"><span className="table__item_title">Corner Box Length:</span> <span className="table__item_value">{props.materialPrices?.cornerPacking.boxLength!.toFixed(2)} m</span></p>
                                    <p className="table__item_element"><span className="table__item_title">Corner Box Height:</span> <span className="table__item_value">{props.materialPrices?.cornerPacking.boxHeight!.toFixed(2)} m</span></p>
                                    <p className="table__item_element"><span className="table__item_title">Corner Surface Area:</span> <span className="table__item_value">{props.materialPrices?.cornerPacking.surfaceArea!.toFixed(2)} m<sup>2</sup></span></p>
                                    <p className="table__item_element"><span className="table__item_title">Total Containers:</span> <span className="table__item_value">{props.materialPrices?.cornerPacking.totalContainers!} pc</span></p>
                                </div>
                            </span>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="flex-table footer" role="rowgroup">
                <div className="flex-row flex-row-head" role="columnheader" style={{ textAlign: "right" }}>
                    <p className="price-item-column-value">Sum: <b>{roundToEuro(props.price?.sum!, currency, props.price?.currencyCoef!)}</b></p>
                </div>
            </div>

        </Modal>
    )
}

export default connect(function (store: State) {
    return {
        isVisible: isMaterialTableVisible(store),
        materialPrices: getMaterialPrices(store)
    }
}, {})(PriceBreakdownDialog)