import { UploadModelActionType } from '../actions/uploadModelActions'

export type State = {
    showUploadDialog: boolean;
    newModelCreationError: string | null;
}

export const initialState: State = {
    showUploadDialog: false,
    newModelCreationError: null
}

export const isModelUploadDialogVisible = function (state: State) {
    return state.showUploadDialog;
}

export const getNewModelCreationError = function (state: State) {
    return state.newModelCreationError;
}

export default function (state: State = initialState, action: UploadModelActionType) {
    switch (action.type) {
        case "SHOW_UPLOAD_MODEL_SOURCE": {
            return { ...state, showUploadDialog: action.visible, newModelCreationError: null }
        }

        case "SHOW_UPLOAD_NEW_FILE_ERROR": {
            return { ...state, newModelCreationError: action.message }
        }

        default:
            return state;
    }
}