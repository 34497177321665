import React, { useEffect, useRef } from "react"
import { Download } from "react-feather";
import { connect } from 'react-redux';
import { card, colors } from "../config";
import { prepareSelectedModelDownloadUrl, setSelectedModelDownloadUrl } from "../actions/sourceRevitModelsActions";
import { getSelectedModelDownloadUrl, State } from "../reducers/mainReducer";
import { AppButton } from "./appButton";
import { useButtonState } from "./useButtonState";

type Props = {
    modelDownloadUrl: string;

    prepareSelectedModelDownloadUrl: () => void;
    setSelectedModelDownloadUrl: (value: string) => void;
}

export const ModelDownload = ({ modelDownloadUrl, prepareSelectedModelDownloadUrl, setSelectedModelDownloadUrl }: Props) => {
    const downloadLinkRef = useRef<HTMLAnchorElement>(null);
    const downloadButtonState = useButtonState();

    useEffect(() => {
        if (modelDownloadUrl === "")
            return;

        const downloadLink = downloadLinkRef.current!;

        downloadLink.href = modelDownloadUrl;

        downloadLink.click();

        setSelectedModelDownloadUrl("");
    }, [modelDownloadUrl]);

    return <>
        <AppButton
            style={{
                backgroundColor: card.versionProject,
                padding: "8px 0",
                marginTop: 15,
                width: 37
            }}
            colored={true}
            hovered={downloadButtonState.isHovered}
            handleMouseEnter={downloadButtonState.handleMouseEnter}
            handleMouseLeave={downloadButtonState.handleMouseLeave}
            selected={downloadButtonState.isSelected}
            handleMouseDown={downloadButtonState.handleMouseDown}
            handleMouseUp={downloadButtonState.handleMouseUp}
            prompt="Download Model"
            onClick={() => prepareSelectedModelDownloadUrl()}>
            <Download size={18} style={{ stroke: downloadButtonState.isSelected ? colors.textBlack : downloadButtonState.isHovered ? colors.hovered : colors.subheading }}/>
        </AppButton>
        <a ref={downloadLinkRef} style={{ display: "none" }} />
    </>
}

export default connect(function (store: State) {
    return {
        modelDownloadUrl: getSelectedModelDownloadUrl(store)
    };
}, { prepareSelectedModelDownloadUrl, setSelectedModelDownloadUrl })(ModelDownload);