import { useState } from "react"
import { Info } from "react-feather";
import { styles } from "../assets/optionSwitcherStyles";
import { TextH3 } from "../assets/text";
import { colors } from "../config/colors";
import { Model3D } from "../reducers/modelsReducer"
import { AppButton } from "./appButton";
import { ModalWindow } from "./modalWindow";
import { ModelInfoDialog } from "./modelInfoDialog";
import { formatWallsOffset } from "./modelUtils";
import { OptionSwitcherCard } from "./optionSwitcherCard";
import { useButtonState } from "./useButtonState";

type Props = {
    model: Model3D;
    canRemoveModel: boolean;

    selectVersion: (newVersionIndex: number) => void;
    requestModelDeletion: () => void;
}

export const OptionSwitcher = ({ model, canRemoveModel, selectVersion, requestModelDeletion }: Props) => {
    const infoButtonState = useButtonState();
    const [showModelInfoDialog, setShowModelInfoDialog] = useState(false);
    const [isInModelNameEdit, setIsInModelNameEdit] = useState(false);
    const [isEditHovered, setEditHovered] = useState(false);
    const trashButtonState = useButtonState();
    const wallsOffsetString = formatWallsOffset(model.versions[0].wallsOffset);

    const handleEditingDone = () => {
        setIsInModelNameEdit(false);
    };

    const versions = model
        .versions
        .map((x, i) => <OptionSwitcherCard
            selectedVersion={x}
            isSelected={model.selectedVersionIndex === i}
            onSelect={() => {
                selectVersion(i);
            }}
            key={`option-switcher-${x.id}`} />)

    return (<ModalWindow style={styles.container}>
        <div>
            <div style={styles.modelTitle}>
                <TextH3 style={{ ...styles.ellipsisStyle, ...styles.modelName }}>{model.name}</TextH3>
                <AppButton
                    style={{ backgroundColor: colors.folderTopButton, ...styles.modelInfoButton }}
                    onClick={() => setShowModelInfoDialog(true)}
                    colored={true}
                    hovered={infoButtonState.isHovered}
                    handleMouseEnter={infoButtonState.handleMouseEnter}
                    handleMouseLeave={infoButtonState.handleMouseLeave}
                    selected={infoButtonState.isSelected}
                    handleMouseDown={infoButtonState.handleMouseDown}
                    handleMouseUp={infoButtonState.handleMouseUp}
                    prompt="Model Information"
                >
                    <Info size={16} style={{ stroke: infoButtonState.isSelected ? colors.textBlack : infoButtonState.isHovered ? colors.hovered : colors.subheading }} />
                </AppButton>
            </div>
        </div>
        <TextH3 style={styles.optionsTitle}>Options</TextH3>
        <div style={styles.versions}>
            {versions}
        </div>
        <ModelInfoDialog
            model={model}
            isOpen={showModelInfoDialog}
            onClose={() => setShowModelInfoDialog(false)}
            isInModelNameEdit={isInModelNameEdit}
            setIsInModelNameEdit={setIsInModelNameEdit}
            wallsOffsetString={wallsOffsetString}
            requestModelDeletion={requestModelDeletion}
            canRemoveModel={canRemoveModel}
            handleEditingDone={handleEditingDone}
            isEditHovered={isEditHovered}
            setEditHovered={setEditHovered}
            trashButtonState={trashButtonState}
        />
    </ModalWindow>)
}