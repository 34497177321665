import React from "react";
import { colors, icon } from "../config/colors";

type Styles = {
    card: React.CSSProperties;
    cardS: React.CSSProperties;
    icon: React.CSSProperties;
    iconS: React.CSSProperties;
    text: React.CSSProperties;
    textS: React.CSSProperties;
    button: React.CSSProperties;
    pinButton: React.CSSProperties;
    submit: React.CSSProperties;
}

export const styles: Styles = {
    card: {
        position: "relative",
        display: "flex",
        backgroundColor: "#DDDDDD",
        width: 110,
        height: 110,
        borderRadius: "6px",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 0 0 0",
        zIndex: 3,   
        marginLeft: "2px",
        marginTop: "2px"
    },
    cardS: {
        backgroundColor: "#e7e7e7",
    },
    icon: {
        color: icon.defualt,
    },
    iconS: {
        color: "#B4B4B4",
    },
    text: {
        color: "#959595",
        paddingTop: "6px",
        marginBottom: 6,
        maxWidth: "110px",
        overflowX: "hidden",
        textOverflow: "ellipsis"
    },
    textS: {
        color: colors.textBlack,
    },
    button: {
        position: "absolute",
        backgroundColor: colors.formBg,
        top: 10,
        right: 20,
        borderRadius: 3,
        zIndex: 10,
        height: 24,
    },
    pinButton: {
        position: "absolute",
        backgroundColor: "#888888",
        top: 10,
        right: 50,
        borderRadius: 3,
        zIndex: 10,
        height: 24,
    },
    submit: {
        fontSize: 13,
        backgroundColor: "#fff",
        border: "1px solid #DDDDDD",
        borderRadius: 11,
        padding: "2px 10px",
        height: 22,
    },
};