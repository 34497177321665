import { LoginState } from '../actions/common';
import { ProfileActionType } from '../actions/profileActions';
import { IUserProfile } from '../components/userProfile';

export type State = {
    isLoggedIn: LoginState;
    profile: IUserProfile;
}

export const initialState: State = {
    isLoggedIn: "unknown",
    profile: {
        name: "Anonymous",
        isEngineer: false,
        isBidManager: false,
        isDextallEmployee: false,
        canExposeFacadeModelToPublic: false,
        areUnreleasedFeaturesAvailable: false,
        applicationAdmin: false,
        statsAdmin: false
    }
};

export const isLoggedIn = function (state: State) {
    return state.isLoggedIn;
}

export const isApplicationAdmin = function (state: State) {
    return state.profile.applicationAdmin;
}

export const isStatsAdmin = function (state: State) {
    return state.profile.statsAdmin;
}

export const getUserName = function (state: State) {
    return state.profile.name;
}

export const isEngineer = function (state: State) {
    return state.profile.isEngineer;
}

export const isBidManager = function (state: State) {
    return state.profile.isBidManager;
}

export const isDextallEmployee = function (state: State) {
    return state.profile.isDextallEmployee;
}

export const canExposeFacadeModelToPublic = function (state: State) {
    return state.profile.canExposeFacadeModelToPublic;
}

export const areUnreleasedFeaturesAvailable = function (state: State) {
    return state.profile.areUnreleasedFeaturesAvailable;
}

export default function (state: State = initialState, action: ProfileActionType): State {
    switch (action.type) {
        case "PROFILE_LOADED": {
            return { isLoggedIn: action.isLoggedIn, profile: action.profile };
        }
        default:
            return state;
    }
}