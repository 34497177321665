import React, { useEffect, useState } from "react";
import ToggleOptionsStyles from "../assets/toggleOptionsStyles";

interface ToggleOptionsProps {
  initialValue: string;
  values: [string, string];
  onChange: (value: string) => void;
}

const ToggleOptions = ({ initialValue, values, onChange }: ToggleOptionsProps) => {
  const [selectedOption, setSelectedOption] = useState(initialValue);

  useEffect(() => {
    setSelectedOption(initialValue);
  }, [initialValue]);

  const [left, right] = values;

  const handleToggle = () => {
    const newOption = selectedOption === left ? right : left;
    setSelectedOption(newOption);
    onChange(newOption);
  };

  return (
    <div style={ToggleOptionsStyles.toggleOptions}>
      <button
        style={{
          ...ToggleOptionsStyles.option,
          ...(selectedOption === left ? ToggleOptionsStyles.activeOption : {}),
        }}
        onClick={handleToggle}
      >
        {values[0]}
      </button>
      <button
        style={{
          ...ToggleOptionsStyles.option,
          ...(selectedOption === right ? ToggleOptionsStyles.activeOption : {}),
        }}
        onClick={handleToggle}
      >
        {right}
      </button>
    </div>
  );
};

export default ToggleOptions;
