import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { styles } from '../../assets/adminPanelStyles';
import { roleKeys, roleDisplayNames } from '../userRolesConfig';
import { IExtendedUser } from '../appStatistics';

interface FilterPanelProps {
    roleFilters: { [key in keyof IExtendedUser]?: boolean };
    setRoleFilters: React.Dispatch<React.SetStateAction<{ [key in keyof IExtendedUser]?: boolean }>>;
}

const FilterPanel = ({ roleFilters, setRoleFilters }: FilterPanelProps) => {
    return (
        <div style={styles.roleFilter}>
            <FormControlLabel
                control={
                    <Checkbox
                        size="small"
                        checked={Object.keys(roleFilters).length === roleKeys.length && Object.values(roleFilters).every(value => value)}
                        indeterminate={Object.keys(roleFilters).some(key => roleFilters[key as keyof IExtendedUser])}
                        onChange={() => {
                            setRoleFilters({});
                        }}
                        color='success'
                    />
                }
                label={<span style={{ fontSize: '14px' }}>Reset</span>}
                style={{ margin: 0, color: '#000' }}
            />

            {roleKeys.map(role => (
                <FormControlLabel
                    key={role}
                    control={<Checkbox
                        size="small"
                        checked={!!roleFilters[role]}
                        onChange={(e) => setRoleFilters({ ...roleFilters, [role]: e.target.checked })}
                        color='success'
                    />}
                    label={
                        <span style={{ fontSize: '14px' }}>{roleDisplayNames[role] || role}</span>
                    }
                    style={{ margin: 0, color: '#000', fontSize: '14px' }}
                />
            ))}
        </div>
    );
};

export default FilterPanel;
