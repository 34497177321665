import React, { useEffect, useState } from 'react';
import { IActiveUsersStatisticsItem } from "../appStatistics";
import SearchBar from '../searchBar';
import styles from '../../assets/adminPanelStyles';
import { UsersStatsTable } from './usersStatsTable';
import repo from '../../Repository';
import { AppLoadingSpinner } from '../appLoadingSpinner';


export const StatsWindow = () => {
    const [activeUsers, setActiveUsers] = useState<IActiveUsersStatisticsItem[]>([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const filteredUsers = activeUsers.filter(user => {
        const matchesSearch = user.name.toLowerCase().includes(searchTerm.toLowerCase());
        return matchesSearch;
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const activeUsersResponse = await repo.findActiveUsersStatistics();
                if (activeUsersResponse.isSuccess) {
                    setActiveUsers(activeUsersResponse.items);
                } else {
                    console.error("Failed to fetch the users");
                }
            } catch (error) {
                console.error("Failed to fetch the users");
            }
            setDataLoaded(true);
        };

        fetchData();
    }, []);

    if (!dataLoaded) {
        return <AppLoadingSpinner />;
    }

    return (
        <div>
            <div style={styles.navbar}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SearchBar searchQuery={searchTerm} setSearchQuery={setSearchTerm} isAdminPanel={true} />
                </div>
            </div>

            <UsersStatsTable usersContentCreationStatistics={filteredUsers.filter(user => !user.isInternal)} internalUsersStatistics={false} />
            <div style={{marginBottom: 45}}> </div>
            <UsersStatsTable usersContentCreationStatistics={filteredUsers.filter(user => user.isInternal)} internalUsersStatistics={true} />
        </div>
    );
};

