import React, { useEffect, useState } from "react"
import { Chart } from "react-google-charts";
import { IUserCreationStatisticsDailyItem } from "../appStatistics";

type Props = {
    usersStatistics: IUserCreationStatisticsDailyItem[];
    chartContainerStyle?: React.CSSProperties;
    cumulative: boolean;
}

type ChartHeader = [{ type: "date" }, "Created external users", "Created Dextall users"];
type ChartData = [serie: Date, newExternalUsers: number, newInternalUsers: number];
type ChartSource = [ChartHeader, ...ChartData[]]

export const DailyUsersCreationStatistics = ({ usersStatistics, chartContainerStyle, cumulative }: Props) => {
    const chartHeader: ChartHeader = [{ type: "date" }, "Created external users", "Created Dextall users"];
    const [chartSource, setChartSource] = useState<ChartSource>([chartHeader]);

    useEffect(() => {
        const data: ChartSource = [chartHeader];

        let cumulativeExternalCount = 0;
        let cumulativeInternalCount = 0;

        for (const stat of usersStatistics) {
            data.push([
                new Date(stat.date),
                stat.externalCount + cumulativeExternalCount,
                stat.internalCount + cumulativeInternalCount
            ]);

            cumulativeExternalCount += cumulative ? stat.externalCount : 0;
            cumulativeInternalCount += cumulative ? stat.internalCount : 0;
        }

        setChartSource(data);
    }, [usersStatistics, cumulative]);

    return <div style={{ ...chartContainerStyle }}>
        <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={chartSource}
            options={{
                title: cumulative ? "Users count" : "New users",
                legend: { position: "top", maxLines: 3 }
            }} />
    </div>
}