import { AutodeskSvfLoader } from "./svfLoader";
import { loadData } from "./viewingWithoutCredentialsLoadOperation";

export const createLoaderWithoutCredentials = (): Autodesk.Viewing.FileLoaderConstructor<Autodesk.Viewing.FileLoader> => {
    const SvfLoader = Autodesk.Viewing.FileLoaderManager.getFileLoader<AutodeskSvfLoader>("svf")!;

    return class extends SvfLoader {
        constructor(parent: Autodesk.Viewing.Viewer3D | Autodesk.Viewing.Private.Viewer3DImpl) {
            super(parent);
        }

        protected loadSvfCB(path: string, options: Autodesk.Viewing.FileLoaderOptions, onDone: (message: string | null, model?: Autodesk.Viewing.Model | null) => void, onWorkerStart: () => void) {
            loadData(() => super.loadSvfCB(path, options, onDone, onWorkerStart));
        }

        protected loadGeometryPack(packId: any, path: any) {
            loadData(() => super.loadGeometryPack(packId, path));
        }

        protected loadPropertyDb() {
            loadData(() => super.loadPropertyDb());
        }
    }
}