import React from "react"
import Banner from "@weave-design/banner";
import "./maintenance.css"

type Props = {
    startTime: Date;
}

export const ScheduldedMaintenance = ({ startTime }: Props) => {
    return <div className="scheduled-maintenance-banner-containter">
        <Banner type="warning">ATTENTION! The maintenance is scheduled for {formatTimePart(startTime.getHours())}:{formatTimePart(startTime.getMinutes())}</Banner>
    </div>
}

const formatTimePart = (x: number) => x.toString().padStart(2, "0");