const ToggleOptionsStyles = {
    toggleOptions: {
        display: "flex",
        backgroundColor: "#f0f0f0",
        border: "1px solid #ccc",
        borderRadius: "15px",
        overflow: "hidden",
    },
    option: {
        fontSize: "12px",
        flex: 1,
        padding: "5px 10px",
        backgroundColor: "#fff",
        border: "none",
        cursor: "pointer",
        minWidth: "70px"
    },
    activeOption: {
        backgroundColor: "#656565",
        color: "#fff",
    },
};

export default ToggleOptionsStyles;
