import React from "react"
import { colors } from "../config/colors";

const input: React.CSSProperties = {
    background: colors.white,
    border: "1px solid #DCDCDC",
    borderRadius: "4px",
    width: "320px",
    height: "40px",
    boxSizing: "border-box",
    outline: "none",
    fontFamily: "Arial",
    fontSize: "15px",
    margin: "0 0 0 0",
    padding: "0 0 0 12px",
}

const areaInput: React.CSSProperties = {
    ...input,
    minWidth: "320px",
    maxWidth: "320px",
    minHeight: "150px",
    padding: "12px"
}

export const styles = {
    input: input,
    multiLineInput: areaInput
};