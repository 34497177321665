import { useEffect, useRef, useState } from 'react';
import { IPanelSourceType } from './forge/panelSource';
import { styles } from "../assets/studioStyles";
import { styles as paneStyles } from "../assets/studioPaneStyles";
import { ListItemByIndexSelector } from './listItemByIndexSelector';
import Button from '@weave-design/button';
import Modal from "@weave-design/modal";
import { tokenStorageName } from './shared';
import { ContentNotReady } from './contentNotReady';
import { ProcessingIndicator } from './processingIndicator';
import { ForgeInitializer } from './forgeInitializer';
import { AppLoadingSpinner } from './appLoadingSpinner';
import { createLoaderWithoutCredentials } from './forge/loaders/svfLoaderWithoutCredentials';
import repo from '../Repository';

export const PanelTypesCatalog = () => {
    const [dataLoaded, setDataLoaded] = useState(false);
    const [forgeInitialized, setForgeInitialized] = useState(false);
    const [panelTypes, setPanelTypes] = useState<IPanelSourceType[]>([]);
    const [selectedPanelType, setSelectedPanelType] = useState(-1);
    const viewer = useRef<Autodesk.Viewing.GuiViewer3D>();
    const [statistics, setStatistics] = useState<{ panelsCount: number, cornersCount: number }>({ panelsCount: 0, cornersCount: 0 });
    const [isProcessingIndicatorVisible, setProcessingIndicatorVisible] = useState(false);
    const [panelDefinitionDialogData, setPanelDefinitionDialogData] = useState<{ dialogVisible: boolean, content: string }>({ dialogVisible: false, content: "" });

    const viewerDiv = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const fetchData = async () => {
            const savedToken = localStorage[tokenStorageName];

            if (savedToken)
                repo.setAccessToken(savedToken);

            const isTokenValid = await repo.validateAccessToken();

            if (!isTokenValid) {
                repo.forgetAccessToken();

                window.location.href = window.location.origin;
                return;
            }

            const panels = await repo.findAllPanelTypes();

            const corners = await repo.findAllCornerTypes();

            const allTypes = panels.concat(corners);

            setPanelTypes(allTypes);
            setStatistics({ panelsCount: panels.length, cornersCount: corners.length });

            if (allTypes.length > 0) {
                const preselectedId = window.location.hash;

                const preselectedPanelIndex = allTypes.findIndex(x => `#${x.id}` === preselectedId);

                setSelectedPanelType(Math.max(preselectedPanelIndex, 0));
            }

            setDataLoaded(true);
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (!(dataLoaded && forgeInitialized && selectedPanelType >= 0 && viewerDiv.current))
            return;

        if (viewer.current)
            viewer.current.finish();

        const container = viewerDiv.current;

        const config = {
            disabledExtensions: {
                bimwalk: true
            },
            theme: "light-theme"
        };

        const forgeViewer = new Autodesk.Viewing.GuiViewer3D(container!, config);

        viewer.current = forgeViewer;

        forgeViewer.start();

        const url = panelTypes[selectedPanelType].modelUrl;

        fetch(url)
            .then(response => response.json())
            .then(bubble => new Autodesk.Viewing.Document(bubble, url, "$file$"))
            .then(doc => handleModelLoaded(forgeViewer, doc));

    }, [dataLoaded, forgeInitialized, selectedPanelType]);

    const handleModelLoaded = async (forgeViewer: Autodesk.Viewing.GuiViewer3D, viewerDocument: Autodesk.Viewing.Document) => {
        const geometry = viewerDocument.getRoot().getDefaultGeometry();
        await forgeViewer.loadDocumentNode(viewerDocument, geometry, {
            fileLoader: createLoaderWithoutCredentials()
        });
        await forgeViewer.waitForLoadDone();
        forgeViewer.setLightPreset(7);
        forgeViewer.navigation.toOrthographic();
        forgeViewer.autocam.setCurrentViewAsHome(true);
    }

    const showPanelDefinition = async () => {
        const isPanel = selectedPanelType < statistics.panelsCount;

        const id = panelTypes[selectedPanelType].id;

        setProcessingIndicatorVisible(true);

        if (isPanel) {
            const panelDefinition = await repo.findPanelInventorDefinition(id);

            setPanelDefinitionDialogData({
                dialogVisible: true,
                content: JSON.stringify(panelDefinition, null, 2)
            });
        } else {
            const cornerDefinition = await repo.findCornerInventorDefinition(id);

            setPanelDefinitionDialogData({
                dialogVisible: true,
                content: JSON.stringify(cornerDefinition, null, 2)
            })
        }

        setProcessingIndicatorVisible(false);
    }

    return (<div style={styles.container}>
        <div style={paneStyles.folderContainer}>
            {(dataLoaded && forgeInitialized) && <>
                <div style={paneStyles.folderTopContainer}>
                    <svg style={{ alignSelf: "flex-end", paddingLeft: "20px" }} width="40" height="40" fill="none" viewBox="0 0 40 40">
                        <path fill="#fff" d="M0 40C40 40 40 0 40 0v40H0z"></path>
                    </svg>
                    <div style={paneStyles.modelFolderTop}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", alignSelf: "flex-end", paddingTop: "5px" }}>
                            <div style={paneStyles.topTextContainer}>
                                <ListItemByIndexSelector listLength={panelTypes.length} selectedIndex={selectedPanelType} setSelectedIndex={setSelectedPanelType} />
                                {panelTypes.length > 0 && <Button type="primary" title="Show input" width="grow" onClick={() => showPanelDefinition()} />}
                            </div>
                        </div>
                    </div>
                </div>

                {selectedPanelType === -1 && <div style={paneStyles.folder}><ContentNotReady /></div>}
                {selectedPanelType !== -1 && <div style={{ ...paneStyles.modelView, backgroundColor: "white" }} ref={viewerDiv}></div>}
            </>}

            {!(dataLoaded && forgeInitialized) && <AppLoadingSpinner />}

            {isProcessingIndicatorVisible && <div className='fullheight' style={{ position: "absolute", width: "100%", backgroundColor: "white", zIndex: 5000 }}><ProcessingIndicator message='Loading...' /></div>}
        </div>

        {panelDefinitionDialogData.dialogVisible && <Modal
            open={true}
            title="Inventor definition"
            onCloseClick={() => setPanelDefinitionDialogData({ dialogVisible: false, content: "" })}
            stylesheet={modalStyles}>
            <pre>{panelDefinitionDialogData.content}</pre>
        </Modal>}
        <ForgeInitializer viewerInitialized={() => setForgeInitialized(true)} />
    </div>)
}

const modalStyles = (styles: any) => ({
    ...styles,
    modal: {
        ...styles.modal,
        window: {
            ...styles.modal.window,
            width: "700px",
            height: "700px"
        }
    }
});