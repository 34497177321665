import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { loadCurrentModelAnalytics, setRefreshNeeded } from "../actions/modelsAnalyticsActions";
import {
    getSelectedModelVersion,
    isHighLodModelViewMode,
    isAnalyticsTabVisible,
    getModelAnalytics,
    getCurrentProject,
    State,
    getMaterialPrices,
    areUnreleasedFeaturesAvailable,
    getPricesLoadingState,
    isDextallEmployee,
    getRefreshNeeded,
    isBidManager
} from "../reducers/mainReducer";
import { ContentNotReady } from "./contentNotReady";
import { styles } from "../assets/studioPaneStyles";
import ModelEditorViewer from "./modelEditorViewer";
import ForgeHighLodView from "./forgeHighLodView";
import { Analytics } from "./analytics";
import { IModel3DVersion, ModelTranslationStatus } from './model3d';
import { fullPrice, IMaterial, IModelAnalytics } from './modelAnalytics';
import { IProject } from './project';
import { loadCurrentModelPrices, PricesLoadingState, showPriceBreakdownDialog } from "../actions/priceActions";
import PriceBreakdownDialog from "./priceBreakdownDialog";

interface IComponentProps {
    model: IModel3DVersion | null;
    isHighLodMode: boolean;
    isAnalyticsTabVisible: boolean;
    analytics: IModelAnalytics | null;
    project: IProject | null;
    materialPrices: IMaterial | null | undefined;
    pricesLoadingState: PricesLoadingState;
    isBetaUser: boolean;
    isInternalUser: boolean;
    isBidManager: boolean;
    loadCurrentModelAnalytics: () => void;
    loadCurrentModelPrices: () => void;
    showPriceBreakdownDialog: (visible: boolean) => void;
}

export const StudioModelPane = (props: IComponentProps) => {
    const dispatch = useDispatch();
    const isRefreshNeeded = useSelector(getRefreshNeeded); 

    const price = fullPrice(props.materialPrices, props.project?.margin, props.project?.duties, props.project?.taxRate)

    useEffect(() => {
        props.loadCurrentModelAnalytics();

        if (props.isBetaUser)
            props.loadCurrentModelPrices();
    }, [props.model]);

    if (!props.model || props.model.status !== ModelTranslationStatus.Ready)
        return (<div style={styles.folder}><ContentNotReady /></div>);

    return (<div style={styles.folder}>
        <div style={{ ...styles.modelView, }}>
            {(props.isHighLodMode ? <ForgeHighLodView /> : <ModelEditorViewer />)}
        </div>
        <PriceBreakdownDialog onCancel={() => props.showPriceBreakdownDialog(false)} displayUnits={props.analytics?.displayUnits} taxRate={props.project?.taxRate!} price={price} />
        {props.isAnalyticsTabVisible &&
            <Analytics
                analytics={props.analytics}
                projectAddress={props.project?.address}
                materialPrices={props.materialPrices!}
                pricesLoadingState={props.pricesLoadingState}
                isBetaUser={props.isBetaUser}
                isInternalUser={props.isInternalUser}
                isBidManager={props.isBidManager}
                isRefreshNeeded={isRefreshNeeded}
                onRefresh={() => { dispatch(setRefreshNeeded(false)); props.loadCurrentModelAnalytics(); props.loadCurrentModelPrices() }}
                onShowPriceBreakdown={() => { props.showPriceBreakdownDialog(true) }}
                price={price} />
        }
    </div>)
}

export default connect(function (store: State) {
    return {
        model: getSelectedModelVersion(store),
        isHighLodMode: isHighLodModelViewMode(store),
        isAnalyticsTabVisible: isAnalyticsTabVisible(store),
        analytics: getModelAnalytics(store),
        materialPrices: getMaterialPrices(store),
        pricesLoadingState: getPricesLoadingState(store),
        project: getCurrentProject(store),
        isBetaUser: areUnreleasedFeaturesAvailable(store),
        isBidManager: isBidManager(store),
        isInternalUser: isDextallEmployee(store)
    };
}, { loadCurrentModelAnalytics, loadCurrentModelPrices, showPriceBreakdownDialog })(StudioModelPane);