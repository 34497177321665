import { getLocalApplicationMetadata } from "../appMetadata";
import repo from "../Repository";

export type ApplicationTabType = "project" | "model" | "engineering-model"

type SetActiveTabType = {
    type: "SET_ACTIVE_TAB";
    tab: ApplicationTabType;
}

type SetFullScreenModeType = {
    type: "SET_FULL_SCREEN";
    isInFullScreen: boolean;
}

type SetModelInfoVisibilityType = {
    type: "SET_MODEL_INFO_VISIBILITY";
    isVisible: boolean;
}

type SetAnalyticsVisibilityType = {
    type: "SET_ANALYTICS_VISIBILITY";
    isVisible: boolean;
}

export type ApplicationUIActionType = SetActiveTabType | SetFullScreenModeType | SetModelInfoVisibilityType | SetAnalyticsVisibilityType;

export const setActiveTab = (tab: ApplicationTabType): SetActiveTabType => {
    return {
        type: "SET_ACTIVE_TAB",
        tab
    }
}

export const setFullScreenMode = (isInFullScreen: boolean): SetFullScreenModeType => {
    return {
        type: "SET_FULL_SCREEN",
        isInFullScreen
    }
}

export const setModelInfoVisibility = (isVisible: boolean): SetModelInfoVisibilityType => {
    return {
        type: "SET_MODEL_INFO_VISIBILITY",
        isVisible
    }
}

export const setAnalyticsVisibility = (isVisible: boolean): SetAnalyticsVisibilityType => {
    return {
        type: "SET_ANALYTICS_VISIBILITY",
        isVisible
    }
}

export const validateVersion = () => async () => {
    const localMetadata = getLocalApplicationMetadata();

    if (localMetadata.environment === "development")
        return;

    const applicationMetadata = await repo.loadApplicationMetadata();

    if (applicationMetadata.version === localMetadata.version)
        return;

    if (caches) {
        const keys = await caches.keys();

        for (const key of keys)
            caches.delete(key);
    }

    window.location.search = `?r=${Date.now().toString()}`;
}