import React from "react"
import { InsulatedGlassUnitValuesPreferences } from "../forge/insulatedGlassUnit";
import { IguProperty } from "./iguProperty";
import { IguWindowProfileSwitcher } from "./iguWindowProfileSwitcher";

type Props = {
    preferences: InsulatedGlassUnitValuesPreferences;
    onChanged: (newPreferences: InsulatedGlassUnitValuesPreferences) => void;
}

export const ProjectWindowsIGUPreferences = ({ preferences, onChanged }: Props) => {
    return <>
        <IguWindowProfileSwitcher preferences={preferences} onChanged={onChanged} />
        <IguProperty title="U-value" value={preferences.uValue} onChange={uValue => onChanged({ ...preferences, uValue })} />
        <IguProperty title="SHGC" value={preferences.shgc} onChange={shgc => onChanged({ ...preferences, shgc })} />
        <IguProperty title="VT" value={preferences.vt} onChange={vt => onChanged({ ...preferences, vt })} />
        <IguProperty title="CR" value={preferences.cr} onChange={cr => onChanged({ ...preferences, cr })} />
        <IguProperty title="STC" value={preferences.stc} onChange={stc => onChanged({ ...preferences, stc })} />
        <IguProperty title="OITC" value={preferences.oitc} onChange={oitc => onChanged({ ...preferences, oitc })} />
    </>
}