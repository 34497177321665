import repo from "../Repository";
import { getSelectedModelVersion } from "../reducers/modelsReducer";
import { ActionDispatch, ActionGetState } from "./common";
import { IModelAnalytics } from "../components/modelAnalytics";
import { AxiosError } from "axios";
import { BasicItemResponse } from "../components/project";

type SetAnalyticsType = {
    type: "SET_CURRENT_MODEL_ANALYTICS";
    analytics: IModelAnalytics | null
}

type SetRefreshNeeded = {
    type: "SET_IS_REFRESH_NEEDED";
    isRefreshNeeded: boolean;
}

export type ModelAnalyticsActionType = SetAnalyticsType | SetRefreshNeeded;

export const loadCurrentModelAnalytics = () => async (dispatch: ActionDispatch, getState: ActionGetState) => {
    const model = getSelectedModelVersion(getState().models);

    if (!model || model.status !== 2) {
        dispatch(setAnalytics(null));
        return;
    }

    let analyticsResponse: BasicItemResponse<IModelAnalytics>;

    try {
        analyticsResponse = await repo.getModelAnalytics(model.id);
    } catch (e: AxiosError | any) {
        if (e instanceof AxiosError && e.response?.data?.message)
            analyticsResponse = e.response.data;
        else
            analyticsResponse = { isSuccess: false, message: "Server error", item: null }
    }

    if (!analyticsResponse.isSuccess)
        return;

    dispatch(setAnalytics(analyticsResponse.item));
}

export const requireAnalyticsRefresh = () => async (dispatch: ActionDispatch, getState: ActionGetState) => {
    const state = getState();

    if (state.modelAnalytics.isRefreshNeeded)
        return;

    dispatch(setRefreshNeeded(true));
}

export const setAnalytics = (analytics: IModelAnalytics | null): SetAnalyticsType => {
    return {
        type: "SET_CURRENT_MODEL_ANALYTICS",
        analytics
    }
}

export const setRefreshNeeded = (isRefreshNeeded: boolean): SetRefreshNeeded => ({
    type: "SET_IS_REFRESH_NEEDED",
    isRefreshNeeded
});