import React from "react";
import { colors, icon } from "../config/colors";

type Styles = {
    container: React.CSSProperties;
    card: React.CSSProperties;
    cardS: React.CSSProperties;
    cardH: React.CSSProperties;
    icon: React.CSSProperties;
    iconS: React.CSSProperties;
    iconH: React.CSSProperties;
    iconNewProjectS: React.CSSProperties;
    text: React.CSSProperties;
    textS: React.CSSProperties;
}

export const styles: Styles = {
    container: {
        marginBottom: 20,
    },
    card: {
        display: "flex",
        backgroundColor: "#E3E3E3",
        width: 110,
        height: "90px",
        borderRadius: "6px",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        cursor: "pointer",
        marginLeft: "2px",
        marginTop: "2px"
    },
    cardS: {
        display: "flex",
        backgroundColor: "#C7C7C7",
        width: 110,
        height: "90px",
        borderRadius: "6px",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        cursor: "pointer",
        marginLeft: "2px"
    },
    cardH: {
        display: "flex",
        width: 110,
        height: "90px",
        borderRadius: "6px",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginLeft: "2px"
    },

    icon: {
        color: icon.defualt,
    },
    iconS: {
        color: "#656565",
    },
    iconH: {
        stroke: "#304E49"
    },
    iconNewProjectS: {
        stroke: colors.textBlack
    },
    text: {
        color: "#959595",
        paddingTop: "6px",
        marginLeft: "2px"
    },
    textS: {
        color: colors.textBlack,
        paddingTop: "6px",
        marginLeft: "2px"
    },
};
