import { isLessThan, isMoreThan } from "@dextall/shared";

export enum WindowProfileType {
    Cw = 0,

    Aw = 1
}

export interface IInsulatedGlassUnit {
    id: string;
    name: string;
    category: string;
    profileType: WindowProfileType;
    thickness: number;
    price: number; // 40"x40", dollars
    fixed: IInsulatedGlassUnitValues;
    casement: IInsulatedGlassUnitValues;
    dualAction: IInsulatedGlassUnitValues;
    hopper: IInsulatedGlassUnitValues;
    awning: IInsulatedGlassUnitValues | null;
}

export interface IInsulatedGlassUnitValues {
    uValue: number;
    shgc: number;
    vt: number;
    cr: number;
    stc: number;
    oitc: number;
}

export type InsulatedGlassUnitValuesPreferences = Partial<IInsulatedGlassUnitValues> & {
    profileType: WindowProfileType;
}

export type ValidationResult = "not-valid" | "partially-valid" | "valid";

export type ValuesValidationResult = Exclude<ValidationResult, "partially-valid"> | "not-applicable";

export type InsulatedGlassUnitValidationResult = {
    result: ValidationResult;
    fixed: ValuesValidationResult;
    casement: ValuesValidationResult;
    dualAction: ValuesValidationResult;
    hopper: ValuesValidationResult;
    awning: ValuesValidationResult;
}

export const isInsulatedGlassUnitValidFor = (insulatedGlassUnit: IInsulatedGlassUnit, preferences: InsulatedGlassUnitValuesPreferences): InsulatedGlassUnitValidationResult => {
    if (insulatedGlassUnit.profileType !== preferences.profileType)
        return {
            result: "not-valid",
            fixed: "not-applicable",
            casement: "not-applicable",
            dualAction: "not-applicable",
            hopper: "not-applicable",
            awning: "not-applicable"
        };

    const fixed: ValuesValidationResult = areInsulatedGlassUnitValuesValuesValid(insulatedGlassUnit.fixed, preferences) ? "valid" : "not-valid";
    const casement: ValuesValidationResult = areInsulatedGlassUnitValuesValuesValid(insulatedGlassUnit.casement, preferences) ? "valid" : "not-valid";
    const dualAction: ValuesValidationResult = areInsulatedGlassUnitValuesValuesValid(insulatedGlassUnit.dualAction, preferences) ? "valid" : "not-valid";
    const hopper: ValuesValidationResult = areInsulatedGlassUnitValuesValuesValid(insulatedGlassUnit.hopper, preferences) ? "valid" : "not-valid";
    const awning: ValuesValidationResult = insulatedGlassUnit.profileType === WindowProfileType.Aw
        ? (areInsulatedGlassUnitValuesValuesValid(insulatedGlassUnit.awning!, preferences) ? "valid" : "not-valid")
        : "not-applicable";

    const hasValid = fixed === "valid" || casement === "valid" || dualAction === "valid" || hopper === "valid" || awning === "valid";
    const allValid = fixed === "valid" && casement === "valid" && dualAction === "valid" && hopper === "valid" && awning !== "not-valid";

    return {
        result: allValid ? "valid" : (hasValid ? "partially-valid" : "not-valid"),
        fixed,
        casement,
        dualAction,
        hopper,
        awning
    }
}

const areInsulatedGlassUnitValuesValuesValid = (values: IInsulatedGlassUnitValues, preferences: Partial<IInsulatedGlassUnitValues>) => {
    const hasValue = (value: number | undefined | null): value is number => {
        return value !== undefined && value !== null;
    }

    if (hasValue(preferences.uValue) && isMoreThan(values.uValue, preferences.uValue))
        return false;

    if (hasValue(preferences.shgc) && isMoreThan(values.shgc, preferences.shgc))
        return false;

    if (hasValue(preferences.vt) && isLessThan(values.vt, preferences.vt))
        return false;

    if (hasValue(preferences.cr) && isLessThan(values.cr, preferences.cr))
        return false;

    if (hasValue(preferences.stc) && isLessThan(values.stc, preferences.stc))
        return false;

    if (hasValue(preferences.oitc) && isLessThan(values.oitc, preferences.oitc))
        return false;

    return true;
}