import React from 'react';
import { connect } from 'react-redux';
import ProjectsNavigationBar from "./projectsNavigationBar";
import { AppButton } from "./appButton";
import { TextButton } from "../assets/text";
import { LogOut } from "react-feather";
import { card, colors } from "../config/colors";
import { getActiveTab, State } from "../reducers/mainReducer";
import { styles } from "../assets/studioStyles";
import { logout } from "../actions/loginActions";
import { ApplicationTabType } from '../actions/applicationUIActions';
import { ApplicationType } from '../applicationType';
import DextallLogo from "../assets/icons/dextall-logo.png"

interface IComponentProps {
    activeTab: ApplicationTabType;
    applicationType: ApplicationType;
}

export const StudioNavigationBar = (props: IComponentProps) => {

    return (<div style={props.activeTab === "project" ? styles.list : undefined}>
        {props.activeTab === "project" &&
            <div style={styles.logo}>
                <img style={{ width: 24, paddingTop: 10 }} src={DextallLogo} alt="" />
            </div>
        }

        {props.activeTab === "project" && <ProjectsNavigationBar applicationType={props.applicationType} />}

        {props.activeTab == "project" &&
            <div style={styles.signOutContainer}>
                <AppButton style={{ padding: "10px", backgroundColor: card.default }} onClick={() => logout()}>
                    <LogOut size={20} stroke={colors.subheading} />
                </AppButton>
                <TextButton
                    style={{
                        marginLeft: 15,
                        alignSelf: "center",
                        color: colors.subheading,
                    }}>
                    Sign out
                </TextButton>
            </div>
        }
    </div>);
}


export default connect(function (store: State) {
    return {
        activeTab: getActiveTab(store)
    };
}, {})(StudioNavigationBar);