import repo from "../Repository";
import { IMaterial } from "../components/modelAnalytics";
import { ActionDispatch, ActionGetState } from "./common";
import { getSelectedModelVersion } from "../reducers/modelsReducer";
import { BasicItemResponse } from "../components/project";
import { AxiosError } from "axios";
import { DextallGeneratorInterop } from "@dextall/interop";

type SetPricesType = {
    type: "SET_CURRENT_MODEL_PRICES";
    materialPrices?: IMaterial | null;
    priceLoadingState: PricesLoadingState;
}

type ShowPricesBreakdownDialogType = {
    type: "SHOW_PRICES_BREAKDOWN_DIALOG";
    visible: boolean;
}

export type PricesActionType = SetPricesType | ShowPricesBreakdownDialogType;

export type PricesLoadingState = "loading" | "ready" | "failed" | "not-supported";

export const loadCurrentModelPrices = () => async (dispatch: ActionDispatch, getState: ActionGetState) => {
    dispatch(setPrices(null, "loading"));
    const model = getSelectedModelVersion(getState().models);

    if (!model)
        return;

    if (model.systemType !== DextallGeneratorInterop.Entities.Inputs.SystemType.Snowman) {
        dispatch(setPrices(null, "not-supported"));
        return;
    }

    let pricesResponse: BasicItemResponse<IMaterial>;

    try {
        pricesResponse = await repo.getBomPrices(model!!.id);
    } catch (e: AxiosError | any) {
        if (e instanceof AxiosError && e.response?.data?.message)
            pricesResponse = e.response.data;
        else
            pricesResponse = { isSuccess: false, message: "Server error", item: null }
    }

    dispatch(setPrices(pricesResponse.item, pricesResponse.isSuccess ? "ready" : "failed"))
}

export const setPrices = (materialPrices: IMaterial | null, priceLoadingState: PricesLoadingState): SetPricesType => {
    return {
        type: "SET_CURRENT_MODEL_PRICES",
        materialPrices,
        priceLoadingState
    }
}

export const showPriceBreakdownDialog = (visible: boolean): ShowPricesBreakdownDialogType => {
    return {
        type: "SHOW_PRICES_BREAKDOWN_DIALOG",
        visible
    }
}