import React from "react";
import { Folder } from "react-feather";
import { TextH4 } from "../assets/text/textH4";
import { styles } from "../assets/projectCardStyles";
import { buttonStyles } from "../assets/buttonStyles";
import { useButtonState } from "./useButtonState";

type Props = {
    item: { name: string };
    isSelected?: boolean;
    onClick: () => void;
    children?: React.ReactNode;
    colored?: boolean;
    hovered?: boolean;
    handleMouseEnter?: () => void;
    handleMouseLeave?: () => void;
    selected?: boolean;
    handleMouseDown?: () => void;
    handleMouseUp?: () => void;
}

export const ProjectCard = ({ item, isSelected = false, onClick, children, colored, hovered, handleMouseEnter, handleMouseLeave, selected, handleMouseDown, handleMouseUp }: Props) => {
    const buttonState = useButtonState();

    return (
        <div style={styles.container}>
            <div
                className="projectCard"
                style={{
                    ...(isSelected ? styles.cardS : styles.card),
                    ...(colored && (hovered || buttonState.isHovered) ? buttonStyles.hoveredButton : {}),
                    ...(colored && (selected || buttonState.isSelected) ? buttonStyles.selectedButton : {})

                }}
                onClick={() => onClick && onClick()}
                onMouseEnter={handleMouseEnter || buttonState.handleMouseEnter}
                onMouseLeave={() => {
                    const onMouseLeave = handleMouseLeave || buttonState.handleMouseLeave;
                    const onMouseUp = handleMouseUp || buttonState.handleMouseUp;

                    onMouseLeave();
                    onMouseUp();
                }}
                onMouseDown={handleMouseDown || buttonState.handleMouseDown}
                onMouseUp={handleMouseUp || buttonState.handleMouseUp}
            >
                {children ? children : <Folder style={{ ...(isSelected ? styles.iconS : styles.icon) }} />}
            </div>

            <TextH4 style={{
                ...isSelected ? styles.textS : styles.text,
                ...(colored && hovered ? buttonStyles.hoveredText : {}),
                ...(colored && selected ? buttonStyles.selectedText : {})
            }}>
                {item.name}
            </TextH4>
        </div>
    );
};
