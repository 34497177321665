import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setModelVersionName } from "../actions/sourceRevitModelsActions";
import { IModel3DVersion } from './model3d';
import { ProcessingPane } from './processingPane';
import { ProcessingFailure } from './processingFailure';
import styles from '../assets/optionStyles';
import repo from "../Repository";

type Props = {
    selectedVersion: IModel3DVersion;

    onEditingDone: () => void;
}

const OptionNameEditor = ({ selectedVersion, onEditingDone }: Props) => {
    const dispatch = useDispatch();
    const [newName, setNewName] = useState(selectedVersion.optionName || "");
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewName(event.target.value);
    };

    const handleBlur = async () => {
        if (newName !== selectedVersion.optionName) {
            setIsProcessing(true);

            const response = await repo.renameModelVersion(selectedVersion.id, newName);

            setIsProcessing(false);

            if (response.isSuccess) {
                dispatch(setModelVersionName(selectedVersion.id, newName));

                onEditingDone();
            } else {
                setErrorMessage(response.message);
                setNewName(selectedVersion.optionName || "")
            }
        } else
            onEditingDone();
    };

    const closeProcessingErrorDialog = () => {
        setErrorMessage("");
        onEditingDone();
    }

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === "Enter" || e.code === "NumpadEnter")
            handleBlur();

        if (e.code === "Escape")
            onEditingDone();
    }

    return (
        <div>
            <ProcessingPane isVisible={isProcessing} title={"Updating the option name"} message="" />
            <div style={{marginTop: 8}}>
                <input
                    value={newName}
                    onChange={handleNameChange}
                    onBlur={handleBlur}
                    onKeyUp={handleKeyUp}
                    autoFocus
                    style={{ ...styles.container, ...styles.input}}
                />
                {errorMessage && <ProcessingFailure
                    closeProcessingErrorDialog={closeProcessingErrorDialog}
                    isVisible={true}
                    message={errorMessage}
                    title="Error" />}
            </div>
        </div>
    );
};

export default OptionNameEditor;