import React, { useState } from 'react';
import { IExtendedUser } from "../appStatistics";
import styles from '../../assets/adminPanelStyles';
import DextallLogo from '../../assets/images/DextallLogo.png';
import { UsersWindow } from './usersWindow';
import { StatsWindow } from './statsWindow';
import { DashboardWindow } from './dashboardWindow';
import { AdminPageType } from '../../AdminPageType';
import { ContentNotReady } from '../contentNotReady';

type Props = {
    users: IExtendedUser[];
    adminPageType: AdminPageType;
    isApplicationAdmin: boolean;
    isStatsAdmin: boolean;
}

export const AdminPanel = ({ users, adminPageType, isApplicationAdmin, isStatsAdmin }: Props) => {

    const [activePage, setActivePage] = useState(adminPageType);
    
    const isNeitherAdmin = !isApplicationAdmin && !isStatsAdmin;

    if (isNeitherAdmin) {
        return (
            <ContentNotReady />
        );
    }

    return (
        <div style={{ position: 'relative' }}>

            <div style={styles.sidebar}>
                <img src={DextallLogo} alt="Dextall Logo" style={{ marginBottom: "60px", width: "200px" }} />
                {isStatsAdmin &&
                    <div style={activePage === 'Dashboard' ? { ...styles.menuItem, ...styles.activeMenuItem } : styles.menuItem}
                        onClick={() => setActivePage('Dashboard')}>
                        Dashboard
                    </div>
                }
                {isApplicationAdmin &&
                    <div style={activePage === 'Users' ? { ...styles.menuItem, ...styles.activeMenuItem } : styles.menuItem}
                        onClick={() => setActivePage('Users')}>
                        Users
                    </div>
                }
                {isStatsAdmin && 
                    <div style={activePage === 'UserStatistics' ? { ...styles.menuItem, ...styles.activeMenuItem } : styles.menuItem}
                        onClick={() => setActivePage('UserStatistics')}>
                        User Statistics
                    </div>
                }
            </div>

            <div style={styles.mainContent}>
                {activePage === 'Dashboard' && <DashboardWindow />}
                {activePage === 'Users' && <UsersWindow users={users} />}
                {activePage === 'UserStatistics' && <StatsWindow />}
            </div>

        </div>
    );
};