import { ProjectActionType } from '../actions/projectsActions';
import { IProject, SystemType } from '../components/project';
import { guid } from "@dextall/shared";

export type Project = IProject & { isTemporary?: boolean, isDirty?: boolean, isNew?: boolean }

export type State = {
    projects: Project[];
    currentProject: Project | null;
    editingProjectCopy: Project | null;
    projectsInitialized: boolean;
    showInfo: boolean,
    showSettings: boolean,
}

export const initialState: State = {
    projects: [],
    currentProject: null,
    editingProjectCopy: null,
    projectsInitialized: false,
    showInfo: false,
    showSettings: false,
}

export const getProjects = function (state: State) {
    return state.projects;
}

export const getCurrentProject = function (state: State) {
    return state.currentProject;
}

export const isCurrentProjectInEditMode = function (state: State) {
    return !!state.currentProject?.isDirty;
}

export const areProjectsInitialized = function (state: State) {
    return state.projectsInitialized;
}

export default function (state = initialState, action: ProjectActionType): State {
    switch (action.type) {
        case "SET_PROJECTS": {
            return { ...state, projects: action.projects, editingProjectCopy: null, projectsInitialized: true };
        }

        case "CURRENT_PROJECT": {
            return { ...state, currentProject: action.project, editingProjectCopy: null };
        }

        case "CREATE_NEW_PROJECT": {
            const newProject: Project = {
                id: guid(),
                name: "My new project",
                postalCode: "",
                address: "",
                author: "",
                buildingName: "",
                clientName: "",
                issueDate: "",
                organizationName: "",
                organizationDescription: "",
                isNew: true,
                isDirty: true,
                isTemporary: true,
                createdAt: "",
                description: "",
                location: null,
                number: "",
                systemType: SystemType.Snowman,
                userName: "",
                margin: 30,
                insulatedGlassUnitValuesPreferences: null,
                selectedInsulatedGlassUnit: action.insulatedGlassUnit,
                duties: 5,
                taxRate: 6.25
            }

            const projects = [...state.projects];

            projects.push(newProject);

            return { ...state, projects: projects, currentProject: newProject, editingProjectCopy: null };
        }

        case "EDIT_CURRENT_PROJECT": {
            if (!state.currentProject)
                return state;

            const projectCopy = action.mode ? { ...state.currentProject } : null;

            const currentProject = { ...state.currentProject, isDirty: action.mode };

            if (!action.mode)
                currentProject.isNew = false;

            const projects = [...state.projects].map(x => x.id === currentProject.id ? currentProject : x);

            return { ...state, projects, currentProject, editingProjectCopy: projectCopy };
        }

        case "CANCEL_EDITING_PROJECT": {
            if (!state.currentProject)
                return state;

            const currentProject = { ...state.currentProject };
            const editingCopy = state.editingProjectCopy;

            if (!(currentProject && currentProject.isDirty && (editingCopy || currentProject.isNew)))
                return state;

            if (currentProject.isNew) {
                const projects = state.projects.filter(x => x.id !== currentProject.id);

                const newCurrentProject = projects.find(x => x) || null;

                return { ...state, projects: projects, currentProject: newCurrentProject !== null ? { ...newCurrentProject } : null, editingProjectCopy: null };
            }

            if (!editingCopy)
                return state;

            currentProject.name = editingCopy.name;
            currentProject.address = editingCopy.address;
            currentProject.author = editingCopy.author;
            currentProject.buildingName = editingCopy.buildingName;
            currentProject.clientName = editingCopy.clientName;
            currentProject.issueDate = editingCopy.issueDate;
            currentProject.organizationName = editingCopy.organizationName;
            currentProject.organizationDescription = editingCopy.organizationDescription;
            currentProject.systemType = editingCopy.systemType;
            currentProject.insulatedGlassUnitValuesPreferences = editingCopy.insulatedGlassUnitValuesPreferences;
            currentProject.selectedInsulatedGlassUnit = editingCopy.selectedInsulatedGlassUnit;
            currentProject.isDirty = false;

            const projects = [...state.projects].map(x => x.id === currentProject.id ? currentProject : x);

            return { ...state, projects, currentProject, editingProjectCopy: null };
        }

        case "UPDATE_PROJECT": {
            const projects = state
                .projects
                .map(x => x.id === action.project.id ? action.project : x);

            const currentProject = state.currentProject?.id === action.project.id ? action.project : state.currentProject;

            return { ...state, projects, currentProject }
        }

        case "CREATE_NEW_PROJECT_SUCCESS": {
            const newProjects = state.projects.filter(p => !p.isTemporary);
            const updatedProjects = [...newProjects, { ...action.project, isTemporary: false }];
            return {
                ...state,
                projects: updatedProjects,
                currentProject: action.project,
            };
        }        

        case "CURRENT_PROJECT_REMOVED": {
            if (!state.currentProject)
                return state;

            const projects = [...state.projects].filter(x => x.id !== state.currentProject?.id);

            const newCurrentProject = projects.find(x => x) || null;

            return { ...state, projects: projects, currentProject: newCurrentProject }
        }

        case "SHOW_INFO_DIAGRAM": {
            return { ...state, showInfo: action.mode, showSettings: false };
        }
        
        case "SHOW_SETTINGS_DIAGRAM": {
            return { ...state, showInfo: false, showSettings: action.mode };
        }

        default:
            return state;
    }
}
