import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { detectToken, loadProfile } from './actions/profileActions';
import { isLoggedIn, State } from './reducers/mainReducer';
import { AppLoadingSpinner } from './components/appLoadingSpinner';
import { LoginState } from './actions/common';

interface ProfileLoaderProps {
  children: React.ReactNode;
  isLoggedIn: LoginState;
  detectToken: () => void;
  loadProfile: () => void;
}

const ProfileLoader = ({ children, isLoggedIn, detectToken, loadProfile }: ProfileLoaderProps) => {
  useEffect(() => {
    detectToken();
  }, [detectToken]);

  useEffect(() => {
    if (isLoggedIn === "logged") {
      loadProfile();
    }
  }, [isLoggedIn, loadProfile]);

  if (isLoggedIn === "unknown") {
    return <AppLoadingSpinner />;
  }

  return <>{children}</>;
};

const mapStateToProps = (state: State) => ({
  isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = {
  detectToken: () => detectToken("ar"),
  loadProfile: () => loadProfile("ar"),
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileLoader);
