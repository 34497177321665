import React from "react";

type Styles = {
    container: React.CSSProperties;
    selectedVersion: React.CSSProperties;
    input: React.CSSProperties;
    content: React.CSSProperties;
    editButton: React.CSSProperties;
    errorMessage: React.CSSProperties;
    ellipsisStyle: React.CSSProperties;
    textContainerStyle: React.CSSProperties;
}

export const styles: Styles = {
    container: {
        marginBottom: 6,
        justifyContent: "space-between",
        width: "100%",
        padding: "8px",
        border: "1px solid #fff",
        borderRadius: 8,
        cursor: "pointer",
        boxSizing: "border-box",
        display: "flex",
        flexFlow: "row"
    },
    selectedVersion: {
        border: "1px solid #DDDDDD",
        backgroundColor: "#F7F7F7",
        borderRadius: 8,
    },
    input: {
        border: "none",
        borderRadius: "unset",
        padding: 0,
        top: 25,
        fontSize: "14px",
        boxSizing: "border-box",
        width: "100%",
    },
    content: {
        fontSize: "13px",
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        justifyContent: "left",
    },
    editButton: {
        marginLeft: 8,
        marginRight: 5
    },
    errorMessage: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: '0.5rem'
    },
    ellipsisStyle: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    textContainerStyle: {
        maxWidth: '150px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    }

};

export default styles;
