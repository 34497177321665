import React from "react";
import { colors } from "../config/index";

type Props = {
  title?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  onClick?: () => void;
}

export const AppTag = ({ title, style, children, onClick }: Props) => {
  return (
    <button
      className="unselectable"
      style={{ ...styles.button, ...style }}
      onClick={onClick}
    >
      {title && title}
      {children && children}
    </button>
  );
};

type Styles = {
  button: React.CSSProperties;
}

const styles: Styles = {
  button: {
    fontFamily: "Arial",
    fontSize: "13px",
    fontWeight: 400,
    borderRadius: "4px",
    border: "none",
    padding: "4px 10px",
    backgroundColor: colors.appTag,
  },
};
