import React from 'react';
import { connect } from 'react-redux';
import { AppButton } from "./appButton";
import { TextBody2, TextH2 } from "../assets/text";
import { editLogin, editPassword, tryLogin } from '../actions/loginActions'
import { canSignIn, getUserName, getPassword, getLoginResults } from '../reducers/mainReducer';
import { styles } from "../assets/loginStyles";
import './loginForm.css';
import { State } from '../reducers/mainReducer';
import { useButtonState } from './useButtonState';
import { colors } from '../config/colors';
import LoginScreeen from "../assets/images/loginScreen.jpg"

interface IComponentProps {
    canSignIn: boolean;
    userName: string;
    password: string;
    loginResults: {
        isSuccess: boolean;
        message: string;
    };

    editLogin: (newLogin: string) => void;
    editPassword: (newPassword: string) => void;
    tryLogin: () => void;
}

export const LoginForm = (props: IComponentProps) => {
    const onImageLoaded = (img: HTMLImageElement) => {
        if (!img.src.endsWith(img.dataset.src!))
            img.src = img.dataset.src!;
    }

    const loginButtonState = useButtonState();

    return (
        <div style={styles.container}>
            <img src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 5'%3E%3C/svg%3E"
                data-src={LoginScreeen}
                alt=""
                onLoad={e => { setTimeout(() => onImageLoaded(e.target as HTMLImageElement), 1) }} />
            <div style={styles.login}>
                <div style={styles.image}>
                    <img style={{ width: 50, aspectRatio: "1" }} src={LoginScreeen} alt="" />
                </div>
                <TextH2>Welcome to Dextall Studio</TextH2>
                <TextBody2 style={{ marginBottom: 12 }}>Enter the details to log in</TextBody2>
                <div>
                    <input style={styles.input} placeholder="Email" type="text" value={props.userName} onChange={data => props.editLogin(data.target.value)} />
                    <input style={styles.input} placeholder="Password" type="password" value={props.password} onChange={data => props.editPassword(data.target.value)} />
                    <div className="invalid-login">{props.loginResults.isSuccess ? "" : props.loginResults.message}</div>
                </div>
                <AppButton
                    title="Log in"
                    onClick={() => props.tryLogin()}
                    style={{ ...styles.button, color: loginButtonState.isSelected ? colors.textBlack : (loginButtonState.isHovered ? colors.hovered : colors.tag) }}
                    colored={true}
                    hovered={loginButtonState.isHovered}
                    handleMouseEnter={loginButtonState.handleMouseEnter}
                    handleMouseLeave={loginButtonState.handleMouseLeave}
                    selected={loginButtonState.isSelected}
                    handleMouseDown={loginButtonState.handleMouseDown}
                    handleMouseUp={loginButtonState.handleMouseUp}
                />
            </div>
        </div>
    )
}

export default connect(function (store: State) {
    return {
        canSignIn: canSignIn(store),
        userName: getUserName(store),
        password: getPassword(store),
        loginResults: getLoginResults(store)
    }
}, { editLogin, editPassword, tryLogin })(LoginForm);