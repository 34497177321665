import { ModelAnalyticsActionType } from '../actions/modelsAnalyticsActions';
import { IModelAnalytics } from '../components/modelAnalytics';

export type State = {
    modelAnalytics: IModelAnalytics | null;
    isRefreshNeeded: boolean;
}

export const initialState: State = {
    modelAnalytics: null,
    isRefreshNeeded: false
}

export const getModelAnalytics = function (state: State) {
    return state.modelAnalytics;
}

export const getRefreshNeeded = function (state: State) {
    return state.isRefreshNeeded;
}

export default function (state: State = initialState, action: ModelAnalyticsActionType) {
    switch (action.type) {
        case "SET_CURRENT_MODEL_ANALYTICS": {
            return { ...state, modelAnalytics: action.analytics };
        }
        case "SET_IS_REFRESH_NEEDED": {
            if (state.isRefreshNeeded === action.isRefreshNeeded)
                return state;

            return { ...state, isRefreshNeeded: action.isRefreshNeeded };
        }
        default:
            return state;
    }
}