import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Checkbox, FormControlLabel } from "@mui/material";
import { IExtendedUser } from "../appStatistics";
import { roleKeys, roleDisplayNames } from '../userRolesConfig';

interface EditUserRolesModalProps {
    user: IExtendedUser;
    open: boolean;
    onClose: () => void;
    onSave: (user: IExtendedUser) => void;
}

export const EditUserRolesModal = ({ user, open, onClose, onSave }: EditUserRolesModalProps) => {
    const [userRoles, setUserRoles] = useState<IExtendedUser>(user);

    useEffect(() => {
        setUserRoles(user);
    }, [user]);

    const handleRoleChange = (role: keyof IExtendedUser) => {
        setUserRoles({ ...userRoles, [role]: !userRoles[role] });
    };

    const handleSave = () => {
        onSave(userRoles);
    };

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{
            style: { minWidth: '800px' }
        }}>
            <DialogTitle style={{ fontSize: '18px' }}>Edit user roles</DialogTitle>
            <DialogContent>
                {roleKeys.map((role) => (
                    <FormControlLabel
                        key={role}
                        control={
                            <Checkbox
                                size='small'
                                checked={userRoles[role] as boolean}
                                onChange={() => handleRoleChange(role as keyof IExtendedUser)}
                                name={role}
                                color='success'
                            />
                        }
                        label={
                            <span style={{ fontSize: '14px' }}>{roleDisplayNames[role] || role}</span>
                        }
                    />
                ))}
            </DialogContent>
            <DialogActions>
                <Button style={{ color: '#2E7D32', fontSize: '13px' }} onClick={onClose}>Cancel</Button>
                <Button style={{ color: '#2E7D32', fontSize: '13px' }} onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};
