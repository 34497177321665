import React from "react";

export const AnalyticsCardGraph = (props: { style: React.CSSProperties }) => {
    return (
        <div
            style={{
                ...{
                    display: "flex",
                    flex: 1,
                    height: 20,
                    marginBottom: 4,
                    justifyContent: "space-between",
                    width: "100%",
                    backgroundColor: "red",
                    borderRadius: 4,
                },
                ...props.style,
            }}
        ></div>
    );
}