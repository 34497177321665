import React from "react"
import { getLocalApplicationMetadata } from "../appMetadata";
import "../App.css"

export const Copyright = () => {
    const applicationMetadata = getLocalApplicationMetadata();

    const applicationVersion = applicationMetadata.environment === "development" ? "development" : applicationMetadata.version;

    return (<div className="copyright">Copyright 2021-2024 © Dextall, inc. Version: {applicationVersion}</div>)
}