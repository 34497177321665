import React from "react"
import "./pluginsDownload.css"

type Props = {
    leftOffset: number;
}

export const PluginsDownload = ({ leftOffset }: Props) => {
    return (<div className="plugins-download" style={{ left: leftOffset }}>
        Download our <a href="https://dextall-studio.storage.googleapis.com/revit-plugin/Dextall%20studio.msi" target="_blank" rel="noreferrer">plugin</a> for Autodesk Revit
    </div>)
}