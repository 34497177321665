import React from "react"
import Dropdown from "@weave-design/dropdown";
import { IProject } from "./project"

type Props = {
    projects: IProject[];
    selectedIndex: number;

    setSelectedProject: (newIndex: number) => void;
}

export const ProjectSelector = ({ projects, selectedIndex, setSelectedProject }: Props) => {
    const options = projects.map((x, i) => toDropDownOption(i, x));

    const selectedOption = selectedIndex >= 0 ? toDropDownOption(selectedIndex, projects[selectedIndex]) : "";

    return <Dropdown
        options={options}
        value={selectedOption}
        formatOption={formatOption}
        onChange={(value: string) => setSelectedProject(fromDropdownOption(value))}
        stylesheet={getDropdownStyles} />
}

const splitter = String.fromCharCode(7);

const toDropDownOption = (index: number, project: IProject): string => {
    return `${index}${splitter}${project.name}`;
}

const fromDropdownOption = (dropDownValue: string): number => {
    const [strIndex, _] = dropDownValue.split(splitter);

    return parseInt(strIndex);
}

const formatOption = (dropDownValue: string): string => {
    const [_, name] = dropDownValue.split(splitter);

    return name;
}

const getDropdownStyles = (styles: any) => {
    return {
        ...styles,
        menu: {
            ...styles.menu,
            maxHeight: "140px"
        }
    }
}