export const colors = {
  main: "#0C4FBB",
  selectedText: "#848484",
  white: "#FFFFFF",
  formBg: "#F1F1F1",
  subheading: "#C4C4C4",
  textBlack: "#333333",
  greyBackground: "#F1F1F1",
  tag: "#A2A2A2",
  appTag: "#EDEDED",
  fullscreen: "#B9CDF2",
  analyticsTitile: "#535353",
  folderTopButton: "#F8F8FA",
  concrete: "#C4C4C4",
  metal: "#A1A1A1",
  glass: "#EEEEEE",
  copper: "#C17A5B",
  plastic: "#FFF6A3",
  lightGray: "#DFDFDF",
  darkGray: "#6F6F6F",
  yellow: "#EFDB26",
  blue: "#3558D6",
  black: "#313131",
  admin: "#F25C1B",
  hovered: "#304E49"
};
export const card = {
  approved: "#4AFF2D4A",
  selected: "#DFDFDF",
  default: "#EEEEEE",
  chevron: "#656565",
  middleCard: "#E8E8E8",
  pending: "#FFF6A3",
  declined: "#FFCACA",
  engineer: "#cadcff",
  bid: "#87CEFA",
  versionProject: "#F8F8FA",
  pin: "#888888",
  chat: "#304E49",
};

export const icon = {
  selected: "#8A8C92",
  defualt: "#C4C4C4",
};
