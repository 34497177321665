import { ExternalIdsMapping, ViewerModelUtils } from "./viewer-utils/viewerModelUtils";
import { IWallFace } from "./wallFace";

export class WallFacesCollection {
    private readonly wallFacesById = new Map<string, IWallFace>();
    private readonly wallFacesByUniqueId = new Map<string, IWallFace[]>();
    private readonly wallFacesByDbId = new Map<number, IWallFace[]>();
    private readonly dbIdsByWallFaceId = new Map<string, number>();
    private hasRemovedWalls = false;

    private constructor(private readonly wallFaces: IWallFace[], externalIdsMapping: ExternalIdsMapping) {
        for (const wallFace of wallFaces) {
            this.wallFacesById.set(wallFace.id, wallFace);

            const facesByUniqueId = this.wallFacesByUniqueId.get(wallFace.wallUniqueId) || [];
            facesByUniqueId.push(wallFace);
            this.wallFacesByUniqueId.set(wallFace.wallUniqueId, facesByUniqueId);

            const dbId = externalIdsMapping[wallFace.wallUniqueId];

            if (dbId !== undefined) {
                const facesByDbId = this.wallFacesByDbId.get(dbId) || [];
                facesByDbId.push(wallFace);
                this.wallFacesByDbId.set(dbId, facesByDbId);
                this.dbIdsByWallFaceId.set(wallFace.id, dbId);
            }
        }
    }

    static async create(wallFaces: IWallFace[], model: Autodesk.Viewing.Model): Promise<WallFacesCollection> {
        const externalIdsMapping = await ViewerModelUtils.getExternalIdsMapping(model);

        return new WallFacesCollection(wallFaces, externalIdsMapping);
    }

    static createWithoutDbIds(wallFaces: IWallFace[]): WallFacesCollection {
        return new WallFacesCollection(wallFaces, {});
    }

    static createEmpty(): WallFacesCollection {
        return new WallFacesCollection([], {});
    }

    asArray(): IWallFace[] {
        // for performance reasons: in most cases we don't change the collection after creation
        // so no need to create new arrays each time we're calling this
        return !this.hasRemovedWalls
            ? this.wallFaces
            : Array.from(this.wallFacesById.values());
    }

    findById(id: string): IWallFace | undefined {
        return this.wallFacesById.get(id);
    }

    findByUniqueId(uniqueId: string): IWallFace[] {
        return this.wallFacesByUniqueId.get(uniqueId) || [];
    }

    findByDbId(dbId: number): IWallFace[] {
        return this.wallFacesByDbId.get(dbId) || [];
    }

    findWallFaceDbId(id: string): number | undefined {
        return this.dbIdsByWallFaceId.get(id);
    }

    findWallFaceDbIdByWallUniqueId(uniqueId: string): number[] {
        return this.findByUniqueId(uniqueId)
            .map(x => this.findWallFaceDbId(x.id))
            .filter((x): x is number => x !== undefined);
    }

    getAllDbIds(): number[] {
        return Array.from(this.wallFacesByDbId.keys());
    }

    remove(wallFace: IWallFace) {
        if (!this.wallFacesById.delete(wallFace.id))
            return;

        this.wallFacesByUniqueId.delete(wallFace.wallUniqueId);

        const dbId = this.findWallFaceDbId(wallFace.id);

        if (dbId !== undefined) {
            this.wallFacesByDbId.delete(dbId);
            this.dbIdsByWallFaceId.delete(wallFace.id);
        }

        this.hasRemovedWalls = true;
    }

    isEmpty(): boolean {
        return this.wallFacesById.size === 0;
    }

    dispose() {
        this.wallFacesById.clear();
        this.wallFacesByUniqueId.clear();
        this.wallFacesByDbId.clear();
        this.dbIdsByWallFaceId.clear();
    }
}