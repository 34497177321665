import React from "react";
import { colors } from "../config/index";

type Styles = {
    container: React.CSSProperties;
}

export const styles: Styles = {
    container: {
        backgroundColor: colors.white,
        borderRadius: "12px",
        border: "0.6px solid #F7F7F7",
        boxShadow: "0px 0px 31px rgba(0, 0, 0, 0.05)",
    },
};