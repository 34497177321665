import React from "react";
import { styles } from "../assets/modalWindowStyles";

type Props = {
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

export const ModalWindow = ({ children, style }: Props) => {
    return <div style={{ ...styles.container, ...style }}>{children}</div>;
};