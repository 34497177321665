import React from "react"
import { connect } from 'react-redux';
import { getCurrentProject, getSelectedEngineeringProductionModel, State } from "../reducers/mainReducer";
import { styles } from "../assets/studioPaneStyles";
import { IEngineeringProductionModel } from "./model3d";
import { Project } from "../reducers/projectsReducer";
import { snowmanColor, retrofitColor, dWallColor, shieldicButtonStyle } from "../assets/panelSystemTypeStyles";
import { SystemType } from "./project";
import { AppButton } from "./appButton";
import { TextBody2, TextH2 } from "../assets/text";
import { colors } from "../config";
import { ChevronRight } from "react-feather";

type Props = {
    selectedModel: IEngineeringProductionModel | null;
    project: Project | null;
}

export const EngineeringProductionModelToolbarControls = ({ selectedModel, project }: Props) => {
    const getSystemShieldic = () => {
        switch (project?.systemType) {
            case SystemType.Snowman:
                return {
                    color: snowmanColor,
                    title: "S"
                };

            case SystemType.Retrofit:
                return {
                    color: retrofitColor,
                    title: "R"
                };

            default:
                return {
                    color: dWallColor,
                    title: "D"
                };
        }
    }

    return <div style={styles.modelFolderTop}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", alignSelf: "flex-end", paddingTop: "5px" }}>
            <div style={styles.topTextContainer}>
                {selectedModel && project && <>
                    <AppButton
                        style={{ ...shieldicButtonStyle, backgroundColor: getSystemShieldic().color }}>
                        {getSystemShieldic().title}
                    </AppButton>

                    <div style={{ flexDirection: "column" }}>
                        <TextBody2 style={{ color: colors.subheading, lineHeight: "17px" }}>Project</TextBody2>
                        <TextH2 style={{ fontSize: 17 }}>{project.name}</TextH2>
                    </div>

                    <ChevronRight style={{ marginLeft: 8, marginRight: 8 }} color={colors.subheading} size="20" />

                    <div style={{ flexDirection: "column" }}>
                        <TextBody2 style={{ color: colors.subheading, lineHeight: "17px" }}>Model</TextBody2>
                        <TextH2 style={{ fontSize: 17 }}>{selectedModel.name}</TextH2>
                    </div>

                    <ChevronRight style={{ marginLeft: 8, marginRight: 8 }} color={colors.subheading} size="20" />

                    <div style={{ flexDirection: "column" }}>
                        <TextBody2 style={{ color: colors.subheading, lineHeight: "17px" }}>Option</TextBody2>
                        <TextH2 style={{ fontSize: 17 }}>{selectedModel.version}</TextH2>
                    </div>
                </>}
            </div>
        </div>
    </div>
}

export default connect(function (store: State) {
    return {
        selectedModel: getSelectedEngineeringProductionModel(store),
        project: getCurrentProject(store)
    };
}, {})(EngineeringProductionModelToolbarControls);