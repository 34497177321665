import React, { useState } from "react"
import { Code } from "react-feather";
import { colors } from "../../config";
import { ProjectProperty } from "../projectProperty";
import Input from "@weave-design/input";

type Props = {
    title: string;
    value: number | undefined;
    onChange: (newValue: number | undefined) => void;
}

export const IguProperty = ({ title, value, onChange }: Props) => {
    const [propertyValue, setPropertyValue] = useState(value?.toString() || "");

    const updatePropertyValue = (val: string) => {
        setPropertyValue(val);

        if (val.trim() === "") {
            onChange(undefined);

            return;
        }

        const numberValue = new Number(val);

        if (!isNaN(numberValue.valueOf()))
            onChange(numberValue.valueOf());
    }

    const onBlur = () => {
        if (propertyValue.trim() !== "" && !isNaN(new Number(propertyValue).valueOf()))
            return;

        setPropertyValue("");
        onChange(undefined);
    }

    return <ProjectProperty header={{ title, children: <Code size={12} stroke={colors.subheading} /> }}
        preview={<Input
            value={propertyValue}
            variant="box"
            stylesheet={inputStyleSheet}
            onChange={(data: any) => updatePropertyValue(data.target.value)}
            onBlur={() => onBlur()} />} />
}

const inputStyleSheet = (styles: any) => {
    if (!styles.input)
        return styles;

    return { ...styles, input: { ...styles.input, height: "34px", boxSizing: "border-box!important" } };
}