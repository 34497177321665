import React, { useEffect, useState } from "react"
import { IInsulatedGlassUnit, InsulatedGlassUnitValuesPreferences, isInsulatedGlassUnitValidFor } from "../forge/insulatedGlassUnit";
import { IguItem } from "./iguItem";

type Props = {
    insulatedGlassUnits: IInsulatedGlassUnit[];
    selectedInsulationGlassUnitId: string | undefined;
    insulatedGlassUnitValuesPreferences: InsulatedGlassUnitValuesPreferences;

    onChange: (newIguId: string | undefined) => void;
}

export const WindowIguListSelection = ({ insulatedGlassUnits, selectedInsulationGlassUnitId, insulatedGlassUnitValuesPreferences, onChange }: Props) => {
    const [validIgus, setValidIgus] = useState<IInsulatedGlassUnit[]>([])

    useEffect(() => {
        const validItems = insulatedGlassUnits.filter(x => isInsulatedGlassUnitValidFor(x, insulatedGlassUnitValuesPreferences).result !== "not-valid");

        setValidIgus(validItems);

        if (selectedInsulationGlassUnitId) {
            const stillValid = !!validItems.find(x => x.id === selectedInsulationGlassUnitId);

            if (!stillValid)
                onChange(undefined);
        }
    }, [insulatedGlassUnitValuesPreferences]);

    return <div style={{ width: "100%", height: 535, overflowY: "auto" }}>
        {validIgus.map(x => <IguItem
            key={x.id} insulatedGlassUnit={x}
            isSelected={x.id === selectedInsulationGlassUnitId}
            onSelect={id => onChange(id)}
            insulatedGlassUnitValuesPreferences={insulatedGlassUnitValuesPreferences} />)}
    </div>
}