import React from "react"
import { IInsulatedGlassUnit, InsulatedGlassUnitValidationResult } from "../forge/insulatedGlassUnit";
import { IguItemOperation } from "./iguItemOperation";
import { IguItemOperationHeader } from "./iguItemOperationHeader";

type Props = {
    insulatedGlassUnit: IInsulatedGlassUnit;
    validationResults: InsulatedGlassUnitValidationResult;
}

export const IguItemOperationsList = ({ insulatedGlassUnit, validationResults }: Props) => {
    return <div>
        <ul className="igu-item-operations">
            <IguItemOperationHeader />
            <IguItemOperation title="Fixed" values={insulatedGlassUnit.fixed} validationResults={validationResults.fixed} />
            <IguItemOperation title="Casement" values={insulatedGlassUnit.casement} validationResults={validationResults.casement} />
            <IguItemOperation title="Dual Action" values={insulatedGlassUnit.dualAction} validationResults={validationResults.dualAction} />
            <IguItemOperation title="Hopper" values={insulatedGlassUnit.hopper} validationResults={validationResults.hopper} />
            <IguItemOperation title="Awning" values={insulatedGlassUnit.awning} validationResults={validationResults.awning} />
        </ul>
    </div>
}