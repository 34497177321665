import React from 'react';
import { Copyright } from './components/copyright';
import { PanelTypesCatalog } from './components/panelTypesCatalog';
import './App.css'

export const AdminPanelTypesApp = () => {
    return (<>
        <PanelTypesCatalog />
        <Copyright />
    </>)
}