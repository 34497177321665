import React from 'react';
import { Edit, Trash2 } from "react-feather";
import { styles } from '../../assets/adminPanelStyles';
import { IExtendedUser } from '../appStatistics';
import { roleColors } from '../userRolesConfig';

interface UserTableProps {
  users: IExtendedUser[];
  onEditClick: (user: IExtendedUser) => void;
  onDeleteClick: (user: IExtendedUser) => void;
}

export const UserTable = ({ users, onEditClick, onDeleteClick }: UserTableProps) => {

  const generateBadges = (user: IExtendedUser) => {
    const badgeElements = [];

    if (user.applicationAdmin) {
      badgeElements.push(
        <span key="applicationAdmin" style={{ ...styles.badge, backgroundColor: roleColors.applicationAdmin }}>
          Application Admin
        </span>
      );
    }
    if (user.panelsCatalogAdmin) {
      badgeElements.push(
        <span key="panelsCatalogAdmin" style={{ ...styles.badge, backgroundColor: roleColors.panelsCatalogAdmin }}>
          Panels Catalog Admin
        </span>
      );
    }
    if (user.statsAdmin) {
      badgeElements.push(
        <span key="statsAdmin" style={{ ...styles.badge, backgroundColor: roleColors.statsAdmin }}>
          Stats Admin
        </span>
      );
    }
    if (user.materialsDatabaseAdmin) {
      badgeElements.push(
        <span key="customer" style={{ ...styles.badge, backgroundColor: roleColors.materialsDatabaseAdmin }}>
          Materials Database Admin
        </span>
      );
    }
    if (user.dextallEmployee) {
      badgeElements.push(
        <span key="dextallEmployee" style={{ ...styles.badge, backgroundColor: roleColors.dextallEmployee }}>
          Dextall Employee
        </span>
      );
    }
    if (user.engineer) {
      badgeElements.push(
        <span key="engineer" style={{ ...styles.badge, backgroundColor: roleColors.engineer }}>
          Engineer
        </span>
      );
    }
    if (user.bidManager) {
      badgeElements.push(
        <span key="bidManager" style={{ ...styles.badge, backgroundColor: roleColors.bidManager }}>
          BID
        </span>
      );
    }
    if (user.canExposeFacadeModelToPublic) {
      badgeElements.push(
        <span key="canExposeFacadeModelToPublic" style={{ ...styles.badge, backgroundColor: roleColors.canExposeFacadeModelToPublic }}>
          Can Expose Facade
        </span>
      );
    }
    if (user.betaTester) {
      badgeElements.push(
        <span key="betaTester" style={{ ...styles.badge, backgroundColor: roleColors.betaTester }}>
          Beta Tester
        </span>
      );
    }
    if (!user.dextallEmployee) {
      badgeElements.push(
        <span key="customer" style={{ ...styles.badge, backgroundColor: roleColors.customer }}>
          Customer
        </span>
      );
    }

    return badgeElements;
  };

  const createTableCell = (content: React.ReactNode, isLastRow: boolean) => {
    const cellStyle = isLastRow ? { ...styles.tableCell, borderBottom: 'none' } : styles.tableCell;
    return <td style={cellStyle}>{content}</td>;
  };

  return (
    <div style={styles.tableContainer}>
      <table style={styles.table}>
        <thead style={styles.tableHeader}>
          <tr>
            <th style={styles.tableHeaderCell}>User Name</th>
            <th style={styles.tableHeaderCell}>Company</th>
            <th style={styles.tableHeaderCell}>Type</th>
            <th style={styles.tableHeaderCell}>Created</th>
            <th style={styles.tableHeaderCell}>Last Seen</th>
            <th style={styles.tableHeaderCell}>User Roles</th>
            <th style={styles.tableHeaderCell}>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={styles.spacerCell} colSpan={7} />
          </tr>
          {users.map((user, index) => {
            const isLastRow = index === users.length - 1;
            const lastCellStyle = isLastRow
              ? { ...styles.tableCell, borderBottom: 'none', padding: 0, paddingRight: 6 }
              : { ...styles.tableCell, padding: 0, paddingRight: 6 };
            const rowStyle = user.locked
            ? { ...styles.tableRow, opacity: 0.3 }
            : styles.tableRow;
            const userCreatedAt = new Date(user.createdAt);
            const userLastSeenAt = new Date(user.lastSeenAt!);
            return (
              <tr
                key={user.id}
                style={{ ...styles.tableRow, ...rowStyle }}
              >
                {createTableCell(user.name, isLastRow)}
                {createTableCell(user.company, isLastRow)}
                {createTableCell(user.dextallEmployee ? "Internal" : "External", isLastRow)}
                {createTableCell(userCreatedAt.toLocaleDateString(), isLastRow)}
                {createTableCell(user.lastSeenAt ? userLastSeenAt.toLocaleDateString() : "Not seen", isLastRow)}
                {createTableCell(generateBadges(user), isLastRow)}
                <td style={lastCellStyle}>
                  <button 
                  style={user.locked ? { ...styles.iconButton, cursor: 'not-allowed' } : styles.iconButton} 
                  onClick={() => onEditClick(user)}
                  disabled={user.locked}
                  >
                    <Edit size={18} />
                  </button>
                  <button 
                  style={user.locked ? { ...styles.iconButton, backgroundColor: "red", cursor: 'not-allowed' } : {...styles.iconButton, backgroundColor: "red"}}
                  onClick={() => onDeleteClick(user)}
                  disabled={user.locked}
                  >
                    <Trash2 size={16} color="white" />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}



