import React from "react";
import { styles } from "../assets/analyticsCardStyles";

export interface IAnalyticsCardProps {
    style: React.CSSProperties;
    children?: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const AnalyticsCard = (props: IAnalyticsCardProps) => {
    return <div style={{ ...styles.container, ...props.style }}>{props.children}</div>;
}