import React from 'react';

type Styles = {
  menuItem: React.CSSProperties;
  activeMenuItem: React.CSSProperties;
  adminPanel: React.CSSProperties;
  button: React.CSSProperties;
  navbar: React.CSSProperties;
  sidebar: React.CSSProperties;
  contentHeader: React.CSSProperties;
  mainContent: React.CSSProperties;
  contentNotReady: React.CSSProperties;
  addButton: React.CSSProperties;
  tableContainer: React.CSSProperties;
  table: React.CSSProperties;
  tableHeader: React.CSSProperties;
  tableRow: React.CSSProperties;
  tableHeaderCell: React.CSSProperties;
  tableCell: React.CSSProperties;
  badge: React.CSSProperties;
  iconButton: React.CSSProperties;
  spacerCell: React.CSSProperties;
  roleFilter: React.CSSProperties;
  chartContainerStyle: React.CSSProperties;
};

export const styles: Styles = {
  menuItem: {
    padding: '6px 0',
    paddingLeft: '20px',
    cursor: 'pointer',
    fontWeight: '400',
    fontSize: '15px',
    borderBottom: '0.9px solid #dee2e6',
  },
  activeMenuItem: {
    paddingLeft: '35px',
    backgroundColor: '#55746F',
    fontWeight: '700',
  },
  adminPanel: {
    padding: '20px',
    backgroundColor: '#f7f7f7',
  },
  button: {
    padding: '10px 15px',
    backgroundColor: '#5cb85c',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  tableContainer: {
    borderRadius: '15px',
    overflow: 'hidden',
    borderCollapse: 'separate',
    borderSpacing: '0',
    width: '100%',
    marginBottom: '1rem',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0',
  },
  tableHeader: {
    backgroundColor: 'white',
    marginBottom: '30px',
  },
  tableRow: {
    backgroundColor: 'white',
    borderRadius: '15px'
  },
  tableHeaderCell: {
    padding: '13px',
    paddingLeft: '15px',
    textAlign: 'left',
  },
  tableCell: {
    padding: '15px',
    textAlign: 'left',
    borderBottom: '1px solid #dee2e6',
    verticalAlign: 'middle',
    maxWidth: 400
  },
  spacerCell: {
    height: '10px',
    background: 'F4F4F4',
    borderBottom: 'none',
  },
  navbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    backgroundColor: '#B1CBC0',
    color: 'white',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '62px',
    zIndex: 10,
    marginLeft: '200px'
  },
  sidebar: {
    backgroundColor: '#304E49',
    color: 'white',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '200px',
    height: '100%',
    zIndex: 20,
  },
  mainContent: {
    paddingTop: '60px',
    marginLeft: '200px',
    padding: '22px',
    marginTop: '63px',
    fontSize: '14px',
  },
  contentNotReady: {
    position: 'absolute',
    top: '50%',
    right: '45%',
    fontWeight: 500,
    fontSize: '20px',
  },
  contentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  addButton: {
    padding: '6px 15px',
    backgroundColor: '#304E49',
    color: 'white',
    border: 'none',
    borderRadius: '14px',
    cursor: 'pointer',
    marginLeft: '15px',
    fontSize: '13px'
  },
  badge: {
    display: 'inline-block',
    padding: '5px 10px',
    fontSize: '11px',
    fontWeight: 'bold',
    borderRadius: '15px',
    textAlign: 'center',
    margin: '2px',
    color: 'white',
  },
  iconButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '5px',
    borderRadius: '13px',
    padding: '5px'
  },
  roleFilter: {
    position: 'absolute',
    top: '100%',
    left: 0,
    backgroundColor: 'white',
    boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
    zIndex: 1000,
    padding: '10px',
    maxWidth: '300px',
    borderBottomRightRadius: "15px"
  },
  chartContainerStyle: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "20px",
    minHeight: "420px"
  }  
};

export default styles;
