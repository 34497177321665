import React from "react"
import { IEngineeringProductionModel } from "./model3d"
import { styles } from "../assets/modelCardStyles";
import { Building } from "../assets/icons/building";
import { TextH4 } from "../assets/text";
import { AppTag } from "./appTag";
import { ApplicationTabType } from "../actions/applicationUIActions";
import { useButtonState } from "./useButtonState";
import { buttonStyles } from "../assets/buttonStyles";

type Props = {
    item: IEngineeringProductionModel;
    isSelected: boolean;
    onSelect: () => void;
    setSelectedWindow: (newTab: ApplicationTabType) => void;
}

export const EngineeringProductionModelCard = ({ item, isSelected, onSelect, setSelectedWindow }: Props) => {
    const buttonState = useButtonState();

    return <div style={{ position: "relative", width: 120, marginBottom: 20, cursor: "pointer" }}
        onMouseEnter={buttonState.handleMouseEnter}
        onMouseLeave={buttonState.handleMouseLeave}>
        <div style={{ ...styles.card, ...(isSelected && styles.cardS), ...(buttonState.isHovered && buttonStyles.hoveredButton ) }} onClick={() => { onSelect(); }}>
            <span style={{ cursor: "pointer" }} onClick={() => { setSelectedWindow("engineering-model"); }}><Building color={isSelected ? "#656565" : "#C4C4C4"} /></span>
        </div>
        <TextH4 style={{ ...styles.text, ...styles.textS }} title={item.name}>{item.name}</TextH4>
        <div style={{ marginBottom: 30 }}>
            <AppTag style={{ marginRight: 6 }}>{`# ${item.version}`}</AppTag>
        </div>
    </div>
}