import React, { useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Building, Warning } from "../assets/icons/index";
import { card, colors } from "../config/colors";
import { TextH4 } from "../assets/text/index";
import { AppButton } from "./appButton";
import { AppTag } from "./appTag";
import { styles } from "../assets/modelCardStyles";
import { IModel3DVersion, ModelTranslationStatus, ModelWorkflowStatus } from "./model3d";
import { Model3D } from "../reducers/modelsReducer";
import { Pin } from "../assets/icons/pin";

export const getModelVersionWorkflowStatusInfo = (modelVersion: IModel3DVersion) => {
    switch (modelVersion.workflowStatus) {
        case ModelWorkflowStatus.Submitted:
            return {
                workflowStatus: "Submitted",
                workflowTagStyle: { backgroundColor: card.pending, fontSize: "12px", height: "23px" }
            };

        case ModelWorkflowStatus.Approved:
            return {
                workflowStatus: "Approved",
                workflowTagStyle: { backgroundColor: card.approved }
            };

        case ModelWorkflowStatus.Declined:
            return {
                workflowStatus: "Declined",
                workflowTagStyle: { backgroundColor: card.declined }
            };

        case ModelWorkflowStatus.EngineerDraft:
            return {
                workflowStatus: "Draft",
                workflowTagStyle: { backgroundColor: card.engineer }
            }

        case ModelWorkflowStatus.Bid:
            return {
                workflowStatus: "Bid",
                workflowTagStyle: { backgroundColor: card.bid }
            }

        default:
            return {
                workflowStatus: "Draft",
                workflowTagStyle: { backgroundColor: "#fff" }
            };
    }
}

type Props = {
    item: Model3D;
    isSelected: boolean;
    isEngineer: boolean;
    onSelect: () => void;
}

export const ModelCard = React.forwardRef<HTMLDivElement, Props>(({
    item,
    isSelected,
    isEngineer,
    onSelect, }, ref) => {
    const [isHovered, setIsHovered] = useState(false);

    const currentVersion = item.versions[item.selectedVersionIndex];
    const name = item.name;
    const itemStatus = currentVersion.status;
    const { workflowStatus, workflowTagStyle } = getModelVersionWorkflowStatusInfo(currentVersion);

    const isModelInProgress = currentVersion.status === ModelTranslationStatus.Created;
    const isModelNotReady = currentVersion.status !== ModelTranslationStatus.Ready;

    const versionsSize = item.versions.length - 1
    const isPrevVersionAvailable = item.selectedVersionIndex < versionsSize;
    const isSecondPrevVersionAvailable = item.selectedVersionIndex < versionsSize - 1;

    const hoverStyles = {
        boxShadow: "0 0 0 2px #304E49",
    };

    return (<div ref={ref} style={{ position: "relative", width: 120, marginBottom: 30, marginTop: isModelInProgress ? -20 : 0, cursor: currentVersion.status === 1 ? "" : "pointer" }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        {isSelected && currentVersion.status !== ModelTranslationStatus.Created && (
            <>
                {currentVersion.isMainOption && !isEngineer && (
                    <AppButton style={styles.pinButton}>
                        <Pin />
                    </AppButton>
                )}
            </>
        )}
        <div
            style={{ ...styles.card, ...(isSelected && styles.cardS), ...(isHovered && hoverStyles) }}
            onClick={(e) => {
                onSelect();
            }}
        >
            {itemStatus === ModelTranslationStatus.Created && <div style={{ width: 60, height: 60 }}>
                <CircularProgressbar
                    strokeWidth={12}
                    styles={buildStyles({ textColor: colors.fullscreen, pathColor: colors.fullscreen })}
                    value={currentVersion.forgePercentage}
                    text={`${currentVersion.forgePercentage}%`} />
            </div>}
            <span style={{ cursor: "pointer" }}>
                {itemStatus === ModelTranslationStatus.Ready &&
                    <Building color={isSelected ? "#304e49" : "#C4C4C4"} />
                }
            </span>
            {itemStatus === ModelTranslationStatus.Failed && <Warning size={34} color={isSelected ? "#656565" : "#C4C4C4"} />}
        </div>
        {isPrevVersionAvailable && (
            <div
                style={{
                    ...styles.card,
                    ...{
                        position: "absolute",
                        top: -10,
                        right: 0,
                        zIndex: 1,
                        backgroundColor: isSelected ? "#E2E2E2" : "#E3E3E3",
                        boxShadow: isHovered ? "0 0 0 2px #54716a" : "none"
                    },
                }}></div>
        )}
        {isSecondPrevVersionAvailable && (
            <div
                style={{
                    ...styles.card,
                    ...{
                        position: "absolute",
                        top: -20,
                        right: -10,
                        zIndex: 0,
                        backgroundColor: isSelected ? "#D7D7D7" : "#EBEBEB",
                        boxShadow: isHovered ? "0 0 0 2px #54716a" : "none"
                    },
                }}></div>
        )}
        {isModelNotReady && <>
            <TextH4 style={{ ...styles.text, ...styles.textS }} title={name}>{name}</TextH4>
            <TextH4
                style={{ color: colors.subheading, marginBottom: 30, maxWidth: "110px", overflowX: "hidden", textOverflow: "ellipsis" }}
                title={currentVersion.forgeStatus || "Pending..."}
                verticallyLimited={true}>{item.versions[0].forgeStatus || "Pending..."}</TextH4>
        </>}
        {!isModelNotReady && <>
            <TextH4 style={{ ...styles.text, ...(isSelected && styles.textS) }} title={name}>{name}</TextH4>
            <div style={{ marginBottom: 30 }}>
                <AppTag style={{ marginRight: 6 }}>{`# ${currentVersion.versionNumber}`}</AppTag>
                <AppTag style={workflowTagStyle}>{workflowStatus}</AppTag>
            </div>
        </>}
    </div>);
});