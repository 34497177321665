import React, { useEffect, useState } from 'react';
import { IDailyActiveUsersStatisticsItem, IModelStatisticsDailyItem, IModelStatisticsWeeklyItem, ISessionsDailyStatisticsItem, ISessionsWeeklyStatisticsItem, IUserCreationStatisticsDailyItem, IUserCreationStatisticsWeeklyItem, IWeeklyActiveUsersStatisticsItem } from "../appStatistics";
import styles from '../../assets/adminPanelStyles';
import repo from '../../Repository';
import { AppLoadingSpinner } from '../appLoadingSpinner';
import Toggle from "@weave-design/toggle";
import { DailyModelsStatistics } from '../statistics/dailyModelsStatistics';
import { DailySessionDurationsStatistics } from '../statistics/dailySessionDurationsStatistics';
import { DailySessionsStatistics } from '../statistics/dailySessionsStatistics';
import { DailyUsersCreationStatistics } from '../statistics/dailyUsersCreationStatistics';
import { WeeklyModelsStatistics } from '../statistics/weeklyModelsStatistics';
import { WeeklySessionDurationsStatistics } from '../statistics/weeklySessionDurationsStatistics';
import { WeeklySessionsStatistics } from '../statistics/weeklySessionsStatistics';
import { WeeklyUsersCreationStatistics } from '../statistics/weeklyUsersCreationStatistics';


export const DashboardWindow = () => {
    const [weeklySessions, setWeeklySessions] = useState<ISessionsWeeklyStatisticsItem[]>([]);
    const [dailySessions, setDailySessions] = useState<ISessionsDailyStatisticsItem[]>([]);
    const [modelsCreationWeekly, setModelsCreationWeekly] = useState<IModelStatisticsWeeklyItem[]>([]);
    const [modelsCreationDaily, setModelsCreationDaily] = useState<IModelStatisticsDailyItem[]>([]);
    const [usersCreationWeekly, setUsersCreationWeekly] = useState<IUserCreationStatisticsWeeklyItem[]>([]);
    const [usersCreationDaily, setUsersCreationDaily] = useState<IUserCreationStatisticsDailyItem[]>([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [dailyBasedView, setDailyBasedView] = useState(false);
    const [modelVersionsCreationWeekly, setModelVersionsCreationWeekly] = useState<IModelStatisticsWeeklyItem[]>([]);
    const [modelVersionsCreationDaily, setModelVersionsCreationDaily] = useState<IModelStatisticsDailyItem[]>([]);
    const [activeUsersWeekly, setActiveUsersWeekly] = useState<IWeeklyActiveUsersStatisticsItem[]>([]);
    const [activeUsersDaily, setActiveUsersDaily] = useState<IDailyActiveUsersStatisticsItem[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const [weeklySessionsResponse, dailySessionResponse, modelsCreationWeeklyResponse, modelsCreationDailyResponse, modelVersionsWeeklyResponse, modelVersionsDailyResponse, usersCreationWeeklyResponse, usersCreationDailyResponse, activeUsersWeeklyResponse, activeUsersDailyResponse] = await Promise.all([
                repo.findSessionsWeeklyStatistics(),
                repo.findSessionsDailyStatistics(),
                repo.findModelsCreationWeeklyStatistics(),
                repo.findModelsCreationDailyStatistics(),
                repo.findModelVersionsCreationWeeklyStatistics(),
                repo.findModelVersionsCreationDailyStatistics(),
                repo.findUsersCreationWeeklyStatistics(),
                repo.findUsersCreationDailyStatistics(),
                repo.findActiveUsersWeeklyStatistics(),
                repo.findActiveUsersDailyStatistics()
            ]);

            if (weeklySessionsResponse.isSuccess) setWeeklySessions(weeklySessionsResponse.items);
            if (dailySessionResponse.isSuccess) setDailySessions(dailySessionResponse.items);
            if (modelsCreationWeeklyResponse.isSuccess) setModelsCreationWeekly(modelsCreationWeeklyResponse.items);
            if (modelsCreationDailyResponse.isSuccess) setModelsCreationDaily(modelsCreationDailyResponse.items);
            if (modelVersionsWeeklyResponse.isSuccess) setModelVersionsCreationWeekly(modelVersionsWeeklyResponse.items);
            if (modelVersionsDailyResponse.isSuccess) setModelVersionsCreationDaily(modelVersionsDailyResponse.items);
            if (usersCreationWeeklyResponse.isSuccess) setUsersCreationWeekly(usersCreationWeeklyResponse.items);
            if (usersCreationDailyResponse.isSuccess) setUsersCreationDaily(usersCreationDailyResponse.items);
            if (activeUsersWeeklyResponse.isSuccess) setActiveUsersWeekly(activeUsersWeeklyResponse.items);
            if (activeUsersDailyResponse.isSuccess) setActiveUsersDaily(activeUsersDailyResponse.items);

            setDataLoaded(true);
        };

        fetchData();
    }, []);

    if (!dataLoaded) {
        return <AppLoadingSpinner />;
    }

    const toggleSharingStylesheet = (styles: any) => {
        return {
            ...styles,
            toggleWrapper: {
                ...styles.toggleWrapper,
                marginLeft: 5,
                marginRight: 5,
                cursor: "pointer",
                backgroundColor: dailyBasedView ? '#304E49' : 'white',
                borderColor: "#80808080",
                boxShadow: "white"
            },
            toggleInput: {
                ...styles.toggleInput,
                cursor: "pointer",
            }
        };
    };  

    return (
        <div>
            <div style={styles.navbar}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ display: "flex", marginLeft: 22 }}>
                        Weekly
                        <Toggle
                            on={dailyBasedView}
                            onChange={(x: boolean) => setDailyBasedView(x)}
                            stylesheet={toggleSharingStylesheet} 
                            />
                        Daily
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: "white", borderRadius: "15px" }}>
                {!dailyBasedView ? (
                    <>
                        <WeeklyUsersCreationStatistics usersStatistics={usersCreationWeekly} cumulative={false} chartContainerStyle={styles.chartContainerStyle} />
                        <WeeklyUsersCreationStatistics usersStatistics={usersCreationWeekly} cumulative={true} chartContainerStyle={styles.chartContainerStyle} />
                        <WeeklyModelsStatistics createdModels={modelsCreationWeekly} createdVersions={modelVersionsCreationWeekly} chartContainerStyle={styles.chartContainerStyle} />
                        <WeeklySessionsStatistics weeklySessions={weeklySessions} activeUsersWeekly={activeUsersWeekly} chartContainerStyle={styles.chartContainerStyle} />
                        <WeeklySessionDurationsStatistics weeklySessions={weeklySessions} chartContainerStyle={styles.chartContainerStyle} />
                    </>
                ) : (
                    <>
                        <DailyUsersCreationStatistics usersStatistics={usersCreationDaily} cumulative={false} chartContainerStyle={styles.chartContainerStyle} />
                        <DailyUsersCreationStatistics usersStatistics={usersCreationDaily} cumulative={true} chartContainerStyle={styles.chartContainerStyle} />
                        <DailyModelsStatistics createdModels={modelsCreationDaily} createdVersions={modelVersionsCreationDaily} chartContainerStyle={styles.chartContainerStyle} />
                        <DailySessionsStatistics dailySessions={dailySessions} activeUsersDaily={activeUsersDaily} chartContainerStyle={styles.chartContainerStyle} />
                        <DailySessionDurationsStatistics dailySessions={dailySessions} chartContainerStyle={styles.chartContainerStyle} />
                    </>
                )}
            </div>
        </div>
    );
};