import { IInsulatedGlassUnit } from "../components/forge/insulatedGlassUnit";
import { ActionDispatch } from "./common";
import { addError, addLog } from "./notificationActions";
import repo from "../Repository";

type SetGlassUnits = {
    type: "SET_INSULATION_GLASS_UNITS";
    units: IInsulatedGlassUnit[];
}

export type InsulaterGlassUnitActions = SetGlassUnits;

export const fetchInsulatedGlassUnits = () => async (dispatch: ActionDispatch) => {
    dispatch(addLog("Fetch glass units"));

    try {
        const insulatedGlassUnits = await repo.loadInsulatedGlassUnits();

        if (insulatedGlassUnits.isSuccess)
            dispatch(setGlassUnits(insulatedGlassUnits.items));
    } catch (error) {
        console.error("Failed to fetch glass units", error);
        dispatch(addError('Failed to fetch glass units. (' + error + ')'));
    }
}

export const setGlassUnits = (units: IInsulatedGlassUnit[]): SetGlassUnits => {
    return {
        type: "SET_INSULATION_GLASS_UNITS",
        units
    }
}