import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ProjectCard } from "./projectCard";
import { Plus } from "react-feather";
import { setCurrentProject, createNewProject, editCurrentProject, currentProjectInfo } from "../actions/projectsActions";
import { fetchModels, setModels } from "../actions/sourceRevitModelsActions";
import { getProjects, getCurrentProject, State } from "../reducers/mainReducer";
import { colors } from "../config/colors";
import { Project } from '../reducers/projectsReducer';
import { IProject } from './project';
import { IModel3D } from './model3d';
import { ApplicationType } from '../applicationType';
import { useButtonState } from './useButtonState';
import SearchBar from './searchBar';

interface IComponentProps {
    projects: Project[];
    project: Project | null;
    applicationType: ApplicationType;

    setCurrentProject: (project: IProject | null) => void;
    createNewProject: () => void;
    fetchModels: (applicationType: ApplicationType, projectId: string, openModelId?: string) => void;
    setModels: (models: IModel3D[]) => void;
    editCurrentProject: (mode: boolean) => void;
    currentProjectInfo: (mode: boolean) => void;
}

export const ProjectsNavigationBar = (props: IComponentProps) => {
    const [searchQuery, setSearchQuery] = useState("");

    const newProjectButtonState = useButtonState();

    const setCurrentProject = (project: Project) => {
        if (props.project && props.project.isDirty)
            return;

        props.setCurrentProject(project);

        if (project) {
            props.setModels([]);
            props.fetchModels(props.applicationType, project.id);
        }
    }

    const createNewProject = () => {
        if (props.project && props.project.isDirty)
            return;

        props.createNewProject();
        props.setModels([]);
        props.editCurrentProject(true);
        props.currentProjectInfo(true);
    }

    const activateFirstFoundProject = () => {
        const project = filterProjects(props.projects, searchQuery)[0];

        if (!project)
            return;

        setCurrentProject(project);
    }

    const filterProjects = (projects: Project[], query: string) => {
        return projects.filter((project) => project.name.toLowerCase().includes(query.toLowerCase()));
    };

    const projectCards = props.projects
        .filter(project => !project.isTemporary && project.name.toLowerCase().includes(searchQuery.toLowerCase()))
        .map(project => (
            <ProjectCard
                isSelected={project.id === props.project?.id}
                item={project}
                key={project.id}
                colored={true}
                onClick={() => setCurrentProject(project)} />
        ));

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === "Enter" || e.code === "NumpadEnter")
            activateFirstFoundProject();
    }

    return (
        <div id="container" style={{ height: "100%", width: "130px" }}>
            <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} onKeyUp={handleKeyUp} />
            <div
                className="scrollBar"
                style={{
                    overflowY: "scroll",
                    scrollbarWidth: "none",
                    width: "100%",
                    maxHeight: props.applicationType === "ar" ? "calc(100% - 235px)" : "calc(100vh - 180px)",
                    paddingTop: 2
                }}>
                {projectCards}
            </div>
            <div style={{ position: "absolute", bottom: 53 }}>
                {
                    props.applicationType === "ar" &&
                    <ProjectCard
                        item={{ name: "New Project" }}
                        onClick={() => createNewProject()}
                        colored={true}
                        hovered={newProjectButtonState.isHovered}
                        handleMouseEnter={newProjectButtonState.handleMouseEnter}
                        handleMouseLeave={newProjectButtonState.handleMouseLeave}
                        selected={newProjectButtonState.isSelected}
                        handleMouseDown={newProjectButtonState.handleMouseDown}
                        handleMouseUp={newProjectButtonState.handleMouseUp}
                    >
                        <Plus style={{ stroke: newProjectButtonState.isSelected ? colors.textBlack : newProjectButtonState.isHovered ? colors.hovered : colors.subheading }} />
                    </ProjectCard>
                }
            </div>
        </div>)
}

export default connect(function (store: State) {
    return {
        projects: getProjects(store),
        project: getCurrentProject(store),
    };
}, { setCurrentProject, createNewProject, fetchModels, setModels, editCurrentProject, currentProjectInfo })(ProjectsNavigationBar);