import { tokenStorageName } from '../components/shared';
import { IUserProfile } from '../components/userProfile';
import { ActionDispatch, LoginState } from './common';
import { addError, addLog } from './notificationActions';
import { ApplicationType } from '../applicationType';
import * as Sentry from "@sentry/react";
import repo from '../Repository';

type UpdateProfileType = {
    type: "PROFILE_LOADED";
    profile: IUserProfile;
    isLoggedIn: LoginState;
}

export type ProfileActionType = UpdateProfileType;

export const detectToken = (applicationType: ApplicationType) => async (dispatch: ActionDispatch) => {
    const savedToken = localStorage[tokenStorageName];

    if (savedToken) {
        repo.setAccessToken(savedToken);

        const isTokenValid = await repo.validateAccessToken();

        if (!isTokenValid)
            repo.forgetAccessToken();
    }
    else {
        repo.forgetAccessToken();
    }

    dispatch(loadProfile(applicationType));
}

export const storeToken = (token: string) => {
    localStorage[tokenStorageName] = token;
}

export const cleanToken = () => {
    delete localStorage[tokenStorageName];
}

export const loadProfile = (applicationType: ApplicationType) => async (dispatch: ActionDispatch) => {
    dispatch(addLog('Load profile invoked'));
    try {
        const profile = await repo.loadProfile();
        dispatch(addLog('Load profile received'));

        if (applicationType === "en" && !profile.isEngineer) {
            window.location.href = window.location.origin;
            return;
        }

        if (applicationType === "bid" && !profile.isBidManager) {
            window.location.href = window.location.origin;
            return;
        }

        const isLoggedIn = repo.hasAccessToken();
        dispatch(updateProfile(profile, isLoggedIn ? "logged" : "not-logged"));

        if (isLoggedIn)
            Sentry.setUser({ email: profile.name })
        else
            Sentry.setUser(null);

    } catch (error) {
        console.log("TODO process error")
        console.log(error);
        dispatch(addError('Failed to get profile. (' + error + ')'));
    }
};

export const updateProfile = (profile: IUserProfile, isLoggedIn: LoginState): UpdateProfileType => {
    return {
        type: "PROFILE_LOADED",
        profile,
        isLoggedIn
    };
};