import React, { useRef } from "react"
import { connect } from 'react-redux';
import { styles } from "../assets/studioPaneStyles"
import { getSelectedEngineeringProductionModel, State } from "../reducers/mainReducer";
import { ContentNotReady } from "./contentNotReady"
import { IEngineeringProductionModel } from "./model3d";
import repo from "../Repository";

type Props = {
    model: IEngineeringProductionModel | null;
}

export const StudioEngineeringModelPane = ({ model }: Props) => {
    const frameRef = useRef<HTMLIFrameElement>(null);
    const accessToken = repo.getAccessToken();

    const focusFrame = () => {
        if (!frameRef.current)
            return;

        frameRef.current.focus();

        frameRef.current.contentWindow?.focus();
    }

    return <div style={styles.folder}>
        {model && <iframe
            src={`https://engineering.dextall.com/${model.id}?r=${Date.now().toString()}#${accessToken}`}
            style={{ width: "100%", border: 0 }}
            ref={frameRef}
            onLoad={focusFrame}
            onBlur={focusFrame}></iframe>}
        {!model && <ContentNotReady />}
    </div>
}

export default connect(function (store: State) {
    return {
        model: getSelectedEngineeringProductionModel(store)
    };
}, {})(StudioEngineeringModelPane);