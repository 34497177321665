import { ModelPrepareWallsOffset } from './model3d'; 

export const formatWallsOffset = (wallsOffset: ModelPrepareWallsOffset) => {
    switch (wallsOffset) {
        case ModelPrepareWallsOffset.None:
            return "Ignored";
        case ModelPrepareWallsOffset.Inch1:
            return "1\"";
        case ModelPrepareWallsOffset.Inch2:
            return "2\"";
        case ModelPrepareWallsOffset.Inch3:
            return "3\"";
        case ModelPrepareWallsOffset.Inch4:
            return "4\"";
        case ModelPrepareWallsOffset.Inch5:
            return "5\"";
        case ModelPrepareWallsOffset.Inch6:
            return "6\"";
        case ModelPrepareWallsOffset.Acm:
            return "ACM";
        default:
            return "Unknown";
    }
};
