import { addLog } from './notificationActions';
import { showProcessingPane, reportProcessingProgress } from "./processingActions";
import { fetchModels } from "./sourceRevitModelsActions";
import { getCurrentProject } from "../reducers/mainReducer";
import { ActionDispatch, ActionGetState } from './common';
import { ModelPrepareOptions } from '../components/model3d';
import repo from '../Repository';

type ShowUploadModelSourceType = {
    type: "SHOW_UPLOAD_MODEL_SOURCE";
    visible: boolean;
}

type ShowUploadFileErrorType = {
    type: "SHOW_UPLOAD_NEW_FILE_ERROR";
    message: string | null;
}

export type UploadModelActionType = ShowUploadModelSourceType | ShowUploadFileErrorType;

export const showUploadModelSource = (visible: boolean): ShowUploadModelSourceType => {
    return {
        type: "SHOW_UPLOAD_MODEL_SOURCE",
        visible
    }
}

export const showUploadFileError = (message: string | null): ShowUploadFileErrorType => {
    return {
        type: "SHOW_UPLOAD_NEW_FILE_ERROR",
        message
    }
}

export const uploadNewModel = (file: File, modelPrepareOptions: ModelPrepareOptions) => async (dispatch: ActionDispatch, getState: ActionGetState) => {
    dispatch(addLog("Upload new model invoked"));

    dispatch(showProcessingPane(true, "Uploading..."));
    dispatch(reportProcessingProgress("Uploading the model"));

    const state = getState();

    const project = getCurrentProject(state);

    const uploadFileResponse = await repo.uploadFile({
        ...modelPrepareOptions,
        file,
        projectId: project!.id
    });

    dispatch(showProcessingPane(false));

    if (uploadFileResponse.isSuccess)
        dispatch(showUploadModelSource(false));
    else {
        dispatch(showUploadFileError(uploadFileResponse.message));

        return;
    }

    const modelId = uploadFileResponse.item;

    await repo.startModelDerivativeProcessing(modelId);

    dispatch(fetchModels("ar", project!.id));
}