import React from "react";

export const dWallColor = "#FFEBB8";
export const retrofitColor = "#FFA98E";
export const snowmanColor = "#ADD8E6";
export const textColor = "#2C2D2E";

export const shieldicButtonStyle: React.CSSProperties = {
    width: 24,
    height: 24,
    color: textColor,
    fontSize: 13,
    marginRight: 20,
    cursor: "default"
}