import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { pinSelectedModelVersion, removeCurrentModel, removeCurrentModelVersion, selectCurrentModelVersionIndex, selectModel, updateModelStatuses } from "../actions/sourceRevitModelsActions";
import { ApplicationTabType, setModelInfoVisibility } from "../actions/applicationUIActions";
import { showUploadModelSource } from "../actions/uploadModelActions";
import { getModels, getSelectedModel, getSelectedModelVersion, getCurrentProject, State, getActiveTab, isModelLoading, isModalInfoVisible, isEngineeringModelLoading } from "../reducers/mainReducer";
import { ModelCard } from "./modelCard";
import { styles } from "../assets/modelsNavigationBarStyles";
import { Model3D } from '../reducers/modelsReducer';
import { IModel3DVersion, ModelTranslationStatus } from './model3d';
import { Project } from '../reducers/projectsReducer';
import { setActiveTab } from "../actions/applicationUIActions";
import { AddModel } from './addModel';
import { ApplicationType } from '../applicationType';
import { ModelInfo } from './modelInfo';
import { ConfirmDialog } from './confirmDialog';
import { fetchProjects } from '../actions/projectsActions';

interface IComponentProps {
    models: Model3D[];
    selectedModel: Model3D | null;
    selectedModelVersion: IModel3DVersion | null;
    project: Project | null;
    isEngineer: boolean;
    modelsLoading: boolean;
    activeTab: ApplicationTabType;
    model: Model3D | null;
    modelVersion: IModel3DVersion | null;
    isModalInfoVisible: boolean;
    applicationType: ApplicationType;
    engineeringModelsLoading: boolean;

    selectModel: (modelId: string, versionIndex?: number) => void;
    setModelInfoVisibility: (isVisible: boolean) => void;
    showUploadModelSource: (visible: boolean) => void;
    updateModelStatuses: (unprocessedModels: string[]) => void;
    setActiveTab: (tab: ApplicationTabType) => void;
    removeCurrentModel: () => void;
    removeCurrentModelVersion: () => void;
    selectCurrentModelVersionIndex: (versionIndex: number) => void;
    pinSelectedModelVersion: () => void;
}

export const ModelsNavigationBar = (props: IComponentProps) => {
    const [confirmDeleteModelDialogVisible, setConfirmDeleteModelDialogVisible] = useState(false);
    const [confirmDeleteModelVersionDialogVisible, setConfirmDeleteModelVersionDialogVisible] = useState(false);
    const [modelInfoTop, setModelInfoTop] = useState(0);

    const modelsContainerRef = useRef<HTMLDivElement>(null);
    const selectedModelCardRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const interval = setInterval(() => {
            const unprocessedModels = props.models.map(x => x.versions[0]).filter(x => x.status === ModelTranslationStatus.Created).map(x => x.id);

            if (unprocessedModels.length === 0)
                return;

            props.updateModelStatuses(unprocessedModels);
        }, 5000);

        return () => clearInterval(interval);
    }, [props.models]);

    const toggleTab = (newTab: ApplicationTabType) => {
        props.setActiveTab(newTab);
    }

    const updateModelInfoPosition = () => {
        if (selectedModelCardRef.current && modelsContainerRef.current) {
            const cardTop = selectedModelCardRef.current.offsetTop;
            setModelInfoTop(cardTop);
        }
    };

    useEffect(() => {
        props.setModelInfoVisibility(true);

        const handleScroll = () => {
            updateModelInfoPosition();
        };

        const container = modelsContainerRef.current;
        container?.addEventListener('scroll', handleScroll);

        updateModelInfoPosition();

        return () => {
            container?.removeEventListener('scroll', handleScroll);
        };
    }, [props.selectedModel]);

    const modelCards = props.models.map(x => {
        let isModelSelected = x.id === props.selectedModel?.id;

        return <ModelCard
            ref={isModelSelected ? selectedModelCardRef : null}
            item={x}
            key={x.id}
            isSelected={isModelSelected}
            isEngineer={props.isEngineer}
            onSelect={() => {
                if (!isModelSelected) {
                    props.selectModel(x.id);
                } else {
                    props.setActiveTab("model");
                }
                updateModelInfoPosition();
            }}
        />
    });

    return (<div
        className="scrollBar"
        ref={modelsContainerRef}
        style={styles.scrollBar}>
        <div>
            {modelCards}
            {props.project && !props.project.isNew && !props.isEngineer && !props.modelsLoading &&
                <AddModel
                    onClick={() => props.showUploadModelSource(true)}
                    colored={true}
                />}
        </div>

        <ModelInfo item={props.modelVersion} model={props.model}
            style={{ ...styles.modelInfo, top: `${modelInfoTop}px` }}
            visible={props.isModalInfoVisible}
            applicationType={props.applicationType}
            closeModelInfo={() => props.setModelInfoVisibility(false)}
            requestModelDeletion={() => setConfirmDeleteModelDialogVisible(true)}
            requestModelVersionDeletion={() => setConfirmDeleteModelVersionDialogVisible(true)}
            requestModelVersionSwitch={versionIndex => props.selectCurrentModelVersionIndex(versionIndex)}
            requestPinSelectedVersion={() => props.pinSelectedModelVersion()}
            setSelectedWindow={toggleTab.bind(this)}
            activeTab={props.activeTab}
            modelsLoading={props.modelsLoading}
            engineeringModelsLoading={props.engineeringModelsLoading}
        />

        <ConfirmDialog
            isOpened={confirmDeleteModelDialogVisible}
            title="Remove model"
            confirmButtonTitle="Delete"
            deleteButtonClassName="red"
            onCancel={() => setConfirmDeleteModelDialogVisible(false)}
            onConfirm={() => {
                setConfirmDeleteModelDialogVisible(false);
                props.setModelInfoVisibility(false);
                props.removeCurrentModel();
            }}
            windowStyles={{ width: "500px", height: "280px", top: "250px" }}
            promptContainerStyles={{ height: "50px" }}
        >Are you sure you want to delete model <b>{props.modelVersion?.fileName}</b>?</ConfirmDialog>

        <ConfirmDialog
            isOpened={confirmDeleteModelVersionDialogVisible}
            title="Remove model version"
            confirmButtonTitle="Delete"
            deleteButtonClassName="red"
            onCancel={() => setConfirmDeleteModelVersionDialogVisible(false)}
            onConfirm={() => {
                setConfirmDeleteModelVersionDialogVisible(false);
                props.setModelInfoVisibility(false);
                props.removeCurrentModelVersion()
            }}
            windowStyles={{ width: "500px", height: "280px" }}
            promptContainerStyles={{ height: "50px" }}
        >Are you sure you want to delete model <b>{props.modelVersion?.fileName}</b> option #<b>{props.modelVersion?.versionNumber}</b>?</ConfirmDialog>
    </div>)
}

export default connect(function (store: State) {
    return {
        models: getModels(store),
        selectedModel: getSelectedModel(store),
        selectedModelVersion: getSelectedModelVersion(store),
        project: getCurrentProject(store),
        activeTab: getActiveTab(store),
        modelsLoading: isModelLoading(store),
        model: getSelectedModel(store),
        modelVersion: getSelectedModelVersion(store),
        isModalInfoVisible: isModalInfoVisible(store),
        engineeringModelsLoading: isEngineeringModelLoading(store)
    };
}, {
    selectModel, setModelInfoVisibility, showUploadModelSource, updateModelStatuses, setActiveTab,
    fetchProjects,
    removeCurrentModel,
    removeCurrentModelVersion,
    selectCurrentModelVersionIndex,
    pinSelectedModelVersion
})(ModelsNavigationBar);