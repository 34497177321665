import React from 'react';
import { styles } from '../../assets/adminPanelStyles';
import { IActiveUsersStatisticsItem } from '../appStatistics';

interface UserTableProps {
  usersContentCreationStatistics: IActiveUsersStatisticsItem[];
  internalUsersStatistics: boolean;
}

export const UsersStatsTable = ({ usersContentCreationStatistics, internalUsersStatistics }: UserTableProps) => {

  const createTableCell = (content: React.ReactNode, isLastRow: boolean) => {
    const cellStyle = isLastRow ? { ...styles.tableCell,  padding: '17px', borderBottom: 'none' } : {...styles.tableCell, padding: '17px'};
    return <td style={cellStyle}>{content}</td>;
  };

  return (
    <div style={styles.tableContainer}>
      <table style={styles.table}>
        <thead style={styles.tableHeader}>
          <tr>
            <th style={styles.tableHeaderCell}>{internalUsersStatistics ? "Internal" : "External"} User</th>
            <th style={styles.tableHeaderCell}>Created projects count</th>
            <th style={styles.tableHeaderCell}>Uploaded models count</th>
            <th style={styles.tableHeaderCell}>Created model design options count</th>
            <th style={styles.tableHeaderCell}>Submitted models count</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={styles.spacerCell} colSpan={7} />
          </tr>
          {usersContentCreationStatistics.map((user, index) => {
            const isLastRow = index === usersContentCreationStatistics.length - 1;
            const rowStyle = styles.tableRow;
            return (
              <tr
                key={user.id}
                style={{ ...styles.tableRow, ...rowStyle }}
              >
                {createTableCell(user.name, isLastRow)}
                {createTableCell(user.createdProjects, isLastRow)}
                {createTableCell(user.uploadedModels, isLastRow)}
                {createTableCell(user.createdVersions, isLastRow)}
                {createTableCell(user.submittedVersions, isLastRow)}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}



