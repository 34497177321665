import { relative } from "path"
import React from "react"

const container: React.CSSProperties = {
    overflow: "hidden scroll",
    scrollbarWidth: "none",
    paddingLeft: 20,
    width: 375,
    flexDirection: "column",
    justifyContent: "space-between",
    maxHeight: "calc(100vh - 102px)",
    position: "relative"
}

const topCard: React.CSSProperties = {
    minWidth: 110,
    maxWidth: 140,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    margin: 6,
    flex: 1
}

const emptyCard: React.CSSProperties = { ...topCard, border: "none", boxShadow: "none", opacity: 0 }

const bottomCard: React.CSSProperties = {
    display: "flex",
    flex: 1,
    margin: 7,
    flexDirection: "row"
}

const intermediateTitleCard: React.CSSProperties = {
    width: "100%",
    paddingLeft: 10
}

const refreshNeededContainer: React.CSSProperties = {
    backgroundColor: "#e8eaed", 
    position: "relative"
}

const refreshNeeded: React.CSSProperties = {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    display: "none"
}

const notification: React.CSSProperties = {
    position: "absolute",
    top: "50%", 
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 2,
    lineHeight: "24px",
    fontStyle: "normal",
    color: "#3c4043",
    fontFamily: "Roboto,Arial,sans-serif",
    fontSize: "20px"
}

const showMore: React.CSSProperties = {
    width: "100%",
    border: "none",
    backgroundColor: "inherit",
    fontSize: "14px",
    fontWeight: 550,
    marginTop: 10,
    marginBottom: 10,
    cursor: "pointer"
}

const toggleButtons: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    gap: "42px",
    marginTop: "12px",
    marginBottom: "12px",
    marginLeft: "6px" 
}

export const styles = { container, topCard, emptyCard, bottomCard, intermediateTitleCard, showMore, refreshNeededContainer, refreshNeeded, notification, toggleButtons }
