import { useEffect, useRef } from "react"
import { Category, ViewerModelCategoriesMap } from "./forge/viewer-utils/viewerModelCollector";
import { getLocalApplicationMetadata } from "../appMetadata";
import "./forgeView.css";

export type ViewerState = {
    get shutdownSignaled(): boolean;

    addShutdownCallback: (callback: () => void) => void;
}

interface IComponentProps {
    id: string;
    urn: string;
    defaultExtensions?: string[];
    hiddenCategories: Category[];
    disabledExtensions?: Autodesk.Viewing.ViewerConfigDisabledExtensions;
    analyticsVisible: boolean;
    cleanup: () => void;
    immediateCleanup: () => void;
    extendViewer: (viewer: Autodesk.Viewing.GuiViewer3D, urn: string, state: ViewerState) => Promise<void>;
}

export const ForgeViewer = (props: IComponentProps) => {
    const viewerDiv = useRef<HTMLDivElement>(null);
    const viewer = useRef<Autodesk.Viewing.GuiViewer3D>();
    const applicationState = useRef<ForgeApplicationState>(new ForgeApplicationState());

    const onModelLoaded = async (forgeViewer: Autodesk.Viewing.GuiViewer3D, urn: string, state: ForgeApplicationState, viewerDocument: Autodesk.Viewing.Document) => {
        if (!(viewerDocument.myPath === `urn:${urn}` && forgeViewer.impl)) {
            state.notifyInitialized();
            return;
        }

        try {
            const root = viewerDocument.getRoot();

            const threeDModels = root.get3DModelNodes();

            const model = threeDModels.find(x => x.data.name === "Forge") || threeDModels.find(x => x) || root.getDefaultGeometry();

            const createLoadOptions = (): Autodesk.Viewing.ModelLoadOptions | undefined => {
                if (props.hiddenCategories.length === 0)
                    return undefined;

                const viewerModelCategoriesMap = new ViewerModelCategoriesMap();

                const categories: Autodesk.Viewing.ModelPropertyFilterExpression[] = [];

                for (const category of props.hiddenCategories) {
                    const categoryId = viewerModelCategoriesMap.findCategoryId(category);

                    if (categoryId === undefined)
                        continue;

                    categories.push({ "?CategoryId": categoryId });
                }

                return {
                    filter: {
                        property_query: {
                            "$not": {
                                "$or": categories
                            }
                        }
                    }
                }
            }

            await forgeViewer.loadDocumentNode(viewerDocument, model, createLoadOptions());

            await forgeViewer.waitForLoadDone();

            if (urn !== props.urn) {
                state.notifyInitialized();

                return;
            }

            await props.extendViewer(forgeViewer, urn, state);

            state.notifyInitialized();
        } catch (e) {
            state.notifyInitialized();

            const applicationMetadata = getLocalApplicationMetadata();

            if (applicationMetadata.environment !== "production")
                console.error(e);
        }
    }

    useEffect(() => {
        if (viewerDiv.current === null)
            return;

        if (viewer.current) {
            props.cleanup();
            viewer.current.finish();
        }

        const config: Autodesk.Viewing.Viewer3DConfig = {
            extensions: props.defaultExtensions || [],
            disabledExtensions: {
                modelBrowser: true,
                propertiesPanel: true,
                ...props.disabledExtensions,
                explode: true,
                bimwalk: true
            },
            theme: "light-theme"
        }

        const forgeViewer = new Autodesk.Viewing.GuiViewer3D(viewerDiv.current, config);

        viewer.current = forgeViewer;

        const errorCode = forgeViewer.start();

        if (errorCode !== 0) {
            console.error(`Failed to initialize the viewer. Error code = ${errorCode}`);
            return;
        }

        const urnToLoad = `urn:${props.urn}`;

        let loadAttempt = 0;
        const maxAttempts = 4;

        const load = () => Autodesk.Viewing.Document.load(
            urnToLoad,
            onModelLoaded.bind(null, forgeViewer, props.urn, applicationState.current),
            (errCode, errMsg) => {
                ++loadAttempt;

                if (loadAttempt === maxAttempts) {
                    console.error(`[Forge viewer] Failed to load manifest [${errCode}] ${errMsg}. Urn: ${urnToLoad}. Last load attempt: ${loadAttempt}`);
                    return;
                }

                setTimeout(load, 1000 * Math.pow(2, loadAttempt));
            });

        load();
    }, [props.urn, props.id, props.defaultExtensions]);

    useEffect(() => {
        const viewerSavedDiv = viewerDiv.current!;
        const savedProps = props;
        const savedState = applicationState.current;

        return () => {
            savedState.notifyShutdown();

            savedProps.immediateCleanup();

            if (viewer.current) {
                viewerSavedDiv.style.display = "none";
                document.body.appendChild(viewerSavedDiv);

                const forgeViewer = viewer.current;

                const cleanup = () => {
                    forgeViewer.finish()
                    savedProps.cleanup();
                    document.body.removeChild(viewerSavedDiv);
                }

                if (savedState.initialized)
                    cleanup();
                else
                    savedState.addInitializedCallback(cleanup);
            }
        };
    }, [])

    useEffect(() => { viewer.current?.resize() }, [props.analyticsVisible]);

    return (<div className="viewer" id="ForgeViewer">
        <div ref={viewerDiv}></div>
    </div>)
}

class ForgeApplicationState {
    private _initialized = false;
    private _shutdownSignaled = false;
    private onInitialized?: () => void;
    private onShutdown?: () => void;

    get initialized(): boolean {
        return this._initialized;
    }

    get shutdownSignaled(): boolean {
        return this._shutdownSignaled;
    }

    notifyInitialized() {
        this._initialized = true;

        if (this.onInitialized)
            this.onInitialized();
    }

    notifyShutdown() {
        this._shutdownSignaled = true;

        if (this.onShutdown)
            this.onShutdown();
    }

    addInitializedCallback(callback: () => void) {
        this.onInitialized = callback;
    }

    addShutdownCallback(callback: () => void) {
        this.onShutdown = callback;
    }
}