import React, { useState } from "react";
import { Alert, AlertColor, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import { ProcessingPane } from "../processingPane";
import CloseIcon from '@mui/icons-material/Close';
import { AxiosError } from "axios";
import { IUserCredentials } from "../appStatistics";
import { BasicItemsResponse } from "../project";
import repo from "../../Repository";

export interface IModalProps {
    isVisible: boolean;
    onCancel: () => void;
    onResponse: () => void;
}

export const CreateUserModal = (props: IModalProps) => {
    const [userName, setUserName] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const [alert, setAlert] = useState("");
    const [severity, setSeverity] = useState<AlertColor>("success");

    const createUser = async (username: string) => {
        if (username.length > 0) {
            setError("");
            setLoading(true);
            props.onCancel()

            let response: BasicItemsResponse<IUserCredentials>;

            try {
                response = await repo.createUser(username);
            } catch (ex: AxiosError | any) {
                if (ex.response.data?.message)
                    response = ex.response.data;
                else
                    response = { isSuccess: false, message: "Something went wrong. Please try again", items: null }
            }

            if (response.isSuccess) {
                const user = response.items[0];
                setAlert(`User ${user.name} was created. User password is ${user.password}`);
                setSeverity("success");
            } else {
                setAlert(response.message);
                setSeverity("error");
            }

            setAlertOpen(true);
            setLoading(false);

            props.onResponse();
        } else {
            setError("Username or Email can not be empty!")
        }
    }

    const ResponseModal = () => {
        return (
            <Box sx={{ width: '100%'}}>
                <Collapse in={alertOpen}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setAlertOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        severity={severity}
                        sx={{ mb: 2 }}
                    >
                        {alert}
                    </Alert>
                </Collapse>
            </Box>
        )
    }

    const onClose = () => {
        props.onCancel();
        setError("");
    }

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"xs"}
                open={props.isVisible}
                onClose={() => props.onCancel()}>
                <DialogTitle style={{ fontSize: '18px' }}>Create new user</DialogTitle>
                <DialogContent>
                    <TextField helperText={error} error={error.length > 0} fullWidth placeholder="Enter user name" autoFocus required variant="standard" color="success" value={userName} onChange={(e) => { setUserName(e.target.value) }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose()} color='success' style={{fontSize: '13px'}}>Cancel</Button>
                    <Button onClick={() => {
                        createUser(userName);
                    }} color='success' style={{fontSize: '13px'}}>Create User</Button>
                </DialogActions>
            </Dialog>
            <ProcessingPane isVisible={loading} title={"Creating a new user"} message="" />
            <ResponseModal />
        </div>
    )
};