import React from "react";
import { colors } from "../config/index";

type Styles = {
    container: React.CSSProperties;
    header: React.CSSProperties;
    comments: React.CSSProperties;
    version: React.CSSProperties;
    ellipsisStyle: React.CSSProperties;
    deleteOption: React.CSSProperties;
    openModel: React.CSSProperties;
}

export const styles: Styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: colors.white,
        margin: "16px 12px 0 15px",
        minWidth: 180,
        maxHeight: 600,
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    comments: {
        overflow: "auto",
        maxHeight: 450,
        maxWidth: 470,
        marginBottom: 20,
        scrollbarWidth: "none",
    },
    version: {
        marginLeft: 5,
        fontSize: "13px",
        color: colors.black,
    },
    ellipsisStyle: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: "110px",
        marginLeft: 8,
        marginRight: 3,
        flex: 1,
    },
    deleteOption: {
        padding: 8,
        marginLeft: 10,
        height: 37,
        position: "absolute",
        right: 0,
        bottom: "15px"
    },
    openModel: {
        color: "#155724",
        backgroundColor: "#D4EDDA",
        padding: "2px 10px",
        marginBottom: 22,
        marginRight: 10,
        borderRadius: 17,
        fontSize: 12
    }
};