import React from "react";
import { card, colors, icon } from "../config/index";

type Styles = {
    card: React.CSSProperties;
    cardS: React.CSSProperties;
    icon: React.CSSProperties;
    iconS: React.CSSProperties;
    text: React.CSSProperties;
    textS: React.CSSProperties;
    button: React.CSSProperties;
}

export const styles: Styles = {
    card: {
        position: "relative",
        display: "flex",
        backgroundColor: card.default,
        width: 110,
        height: 110,
        borderRadius: "6px",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        zIndex: 3,
        cursor: "pointer",
        marginLeft: "2px"
    },
    cardS: {
        backgroundColor: card.selected,
    },
    icon: {
        color: icon.defualt,
    },
    iconS: {
        color: icon.selected,
    },
    text: {
        color: colors.subheading,
        paddingTop: "6px",
        marginBottom: 6,
    },
    textS: {
        color: colors.textBlack,
    },
    button: {
        position: "absolute",
        backgroundColor: colors.white,
        top: 10,
        right: 10,
        borderRadius: 3,
    },
};
