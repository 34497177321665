import React, { useState } from "react";
import { colors } from "../config/colors";
import { TextBody2, TextH3 } from "../assets/text";
import { Hint } from "react-autocomplete-hint";
import { styles } from "../assets/projectEditorFieldStyle";
import { styles as inputStyles } from "../assets/inputTextStyles";
import { IProjectLocation } from "./project";
import { IGeocodeResponse } from "./maps/geocode";

export type LocationValue = {
    postalCode: string;
    address: string;
    location: IProjectLocation | null;
}

type Props = {
    value: LocationValue;
    setValue: (address: string, geoLocation: LocationValue | null) => void;
    title: string;
    isEditMode: boolean;
    children?: React.ReactNode;
    style?: React.CSSProperties;
    queryAutocompleteItem: (address: string) => Promise<IGeocodeResponse | null>;
    getLabel: (value: LocationValue) => string;
}

export const ProjectEditorAutocompleteField = ({ value, setValue, title, isEditMode, children, style, queryAutocompleteItem, getLabel }: Props) => {
    const [autocompleteValues, setAutocompleteValues] = useState(value ? [value] : []);
    const [labels, setLabels] = useState(value && getLabel(value) ? [getLabel(value)] : []);

    const onChange = async (newValue: string) => {
        setValue(newValue, null);
        const newAutocompleteValue = await queryAutocompleteItem(newValue);

        if (!newAutocompleteValue)
            return;

        const newLabel = getLabel(newAutocompleteValue);

        if (labels.find(x => x === newLabel))
            return;

        const items = [...autocompleteValues];

        items.push(newAutocompleteValue);
        setAutocompleteValues(items);

        labels.push(newLabel);
        setLabels(labels);
    }

    const onFill = (newLabel: string) => {
        const newValue = autocompleteValues.find(x => getLabel(x) === newLabel) || null;

        setValue(newLabel, newValue);
    }

    return (<div style={{ ...styles.container, ...style }}>
        <div style={styles.subtitleContainer}>
            <div style={styles.iconContainer}>{children}</div>
            <TextBody2 style={{ color: colors.subheading }}>{title}</TextBody2>
        </div>
        {!isEditMode && <TextH3 style={{ margin: "0 0 0 0", minHeight: "24px" }}>{getLabel(value)}</TextH3>}
        {isEditMode && <Hint
            options={labels}
            allowTabFill={true}
            allowEnterFill={true}
            onFill={x => onFill(x as string)}>
            <input
                value={getLabel(value)}
                onChange={v => onChange(v.target.value)}
                style={inputStyles.input}
            />
        </Hint>}
    </div>)
}