class RalColorsFactory {
    private readonly colors: Map<string, string> = new Map();

    constructor() {
        this.colors.set("RAL 1000", '#BEBD7F');
        this.colors.set("RAL 1001", '#C2B078');
        this.colors.set("RAL 1002", '#C6A664');
        this.colors.set("RAL 1003", '#E5BE01');
        this.colors.set("RAL 1004", '#CDA434');
        this.colors.set("RAL 1005", '#A98307');
        this.colors.set("RAL 1006", '#E4A010');
        this.colors.set("RAL 1007", '#DC9D00');
        this.colors.set("RAL 1011", '#8A6642');
        this.colors.set("RAL 1012", '#C7B446');
        this.colors.set("RAL 1013", '#EAE6CA');
        this.colors.set("RAL 1014", '#E1CC4F');
        this.colors.set("RAL 1015", '#E6D690');
        this.colors.set("RAL 1016", '#EDFF21');
        this.colors.set("RAL 1017", '#F5D033');
        this.colors.set("RAL 1018", '#F8F32B');
        this.colors.set("RAL 1019", '#9E9764');
        this.colors.set("RAL 1020", '#999950');
        this.colors.set("RAL 1021", '#F3DA0B');
        this.colors.set("RAL 1023", '#FAD201');
        this.colors.set("RAL 1024", '#AEA04B');
        this.colors.set("RAL 1026", '#FFFF00');
        this.colors.set("RAL 1027", '#9D9101');
        this.colors.set("RAL 1028", '#F4A900');
        this.colors.set("RAL 1032", '#D6AE01');
        this.colors.set("RAL 1033", '#F3A505');
        this.colors.set("RAL 1034", '#EFA94A');
        this.colors.set("RAL 1035", '#6A5D4D');
        this.colors.set("RAL 1036", '#705335');
        this.colors.set("RAL 1037", '#F39F18');
        this.colors.set("RAL 2000", '#ED760E');
        this.colors.set("RAL 2001", '#C93C20');
        this.colors.set("RAL 2002", '#CB2821');
        this.colors.set("RAL 2003", '#FF7514');
        this.colors.set("RAL 2004", '#F44611');
        this.colors.set("RAL 2005", '#FF2301');
        this.colors.set("RAL 2007", '#FFA420');
        this.colors.set("RAL 2008", '#F75E25');
        this.colors.set("RAL 2009", '#F54021');
        this.colors.set("RAL 2010", '#D84B20');
        this.colors.set("RAL 2011", '#EC7C26');
        this.colors.set("RAL 2012", '#E55137');
        this.colors.set("RAL 2013", '#C35831');
        this.colors.set("RAL 3000", '#AF2B1E');
        this.colors.set("RAL 3001", '#A52019');
        this.colors.set("RAL 3002", '#A2231D');
        this.colors.set("RAL 3003", '#9B111E');
        this.colors.set("RAL 3004", '#75151E');
        this.colors.set("RAL 3005", '#5E2129');
        this.colors.set("RAL 3007", '#412227');
        this.colors.set("RAL 3009", '#642424');
        this.colors.set("RAL 3011", '#781F19');
        this.colors.set("RAL 3012", '#C1876B');
        this.colors.set("RAL 3013", '#A12312');
        this.colors.set("RAL 3014", '#D36E70');
        this.colors.set("RAL 3015", '#EA899A');
        this.colors.set("RAL 3016", '#B32821');
        this.colors.set("RAL 3017", '#E63244');
        this.colors.set("RAL 3018", '#D53032');
        this.colors.set("RAL 3020", '#CC0605');
        this.colors.set("RAL 3022", '#D95030');
        this.colors.set("RAL 3024", '#F80000');
        this.colors.set("RAL 3026", '#FE0000');
        this.colors.set("RAL 3027", '#C51D34');
        this.colors.set("RAL 3028", '#CB3234');
        this.colors.set("RAL 3031", '#B32428');
        this.colors.set("RAL 3032", '#721422');
        this.colors.set("RAL 3033", '#B44C43');
        this.colors.set("RAL 4001", '#6D3F5B');
        this.colors.set("RAL 4002", '#922B3E');
        this.colors.set("RAL 4003", '#DE4C8A');
        this.colors.set("RAL 4004", '#641C34');
        this.colors.set("RAL 4005", '#6C4675');
        this.colors.set("RAL 4006", '#A03472');
        this.colors.set("RAL 4007", '#4A192C');
        this.colors.set("RAL 4008", '#924E7D');
        this.colors.set("RAL 4009", '#A18594');
        this.colors.set("RAL 4010", '#CF3476');
        this.colors.set("RAL 4011", '#8673A1');
        this.colors.set("RAL 4012", '#6C6874');
        this.colors.set("RAL 5000", '#354D73');
        this.colors.set("RAL 5001", '#1F3438');
        this.colors.set("RAL 5002", '#20214F');
        this.colors.set("RAL 5003", '#1D1E33');
        this.colors.set("RAL 5004", '#18171C');
        this.colors.set("RAL 5005", '#1E2460');
        this.colors.set("RAL 5007", '#3E5F8A');
        this.colors.set("RAL 5008", '#26252D');
        this.colors.set("RAL 5009", '#025669');
        this.colors.set("RAL 5010", '#0E294B');
        this.colors.set("RAL 5011", '#231A24');
        this.colors.set("RAL 5012", '#3B83BD');
        this.colors.set("RAL 5013", '#1E213D');
        this.colors.set("RAL 5014", '#606E8C');
        this.colors.set("RAL 5015", '#2271B3');
        this.colors.set("RAL 5017", '#063971');
        this.colors.set("RAL 5018", '#3F888F');
        this.colors.set("RAL 5019", '#1B5583');
        this.colors.set("RAL 5020", '#1D334A');
        this.colors.set("RAL 5021", '#256D7B');
        this.colors.set("RAL 5022", '#252850');
        this.colors.set("RAL 5023", '#49678D');
        this.colors.set("RAL 5024", '#5D9B9B');
        this.colors.set("RAL 5025", '#2A6478');
        this.colors.set("RAL 5026", '#102C54');
        this.colors.set("RAL 6000", '#316650');
        this.colors.set("RAL 6001", '#287233');
        this.colors.set("RAL 6002", '#2D572C');
        this.colors.set("RAL 6003", '#424632');
        this.colors.set("RAL 6004", '#1F3A3D');
        this.colors.set("RAL 6005", '#2F4538');
        this.colors.set("RAL 6006", '#3E3B32');
        this.colors.set("RAL 6007", '#343B29');
        this.colors.set("RAL 6008", '#39352A');
        this.colors.set("RAL 6009", '#31372B');
        this.colors.set("RAL 6010", '#35682D');
        this.colors.set("RAL 6011", '#587246');
        this.colors.set("RAL 6012", '#343E40');
        this.colors.set("RAL 6013", '#6C7156');
        this.colors.set("RAL 6014", '#47402E');
        this.colors.set("RAL 6015", '#3B3C36');
        this.colors.set("RAL 6016", '#1E5945');
        this.colors.set("RAL 6017", '#4C9141');
        this.colors.set("RAL 6018", '#57A639');
        this.colors.set("RAL 6019", '#BDECB6');
        this.colors.set("RAL 6020", '#2E3A23');
        this.colors.set("RAL 6021", '#89AC76');
        this.colors.set("RAL 6022", '#25221B');
        this.colors.set("RAL 6024", '#308446');
        this.colors.set("RAL 6025", '#3D642D');
        this.colors.set("RAL 6026", '#015D52');
        this.colors.set("RAL 6027", '#84C3BE');
        this.colors.set("RAL 6028", '#2C5545');
        this.colors.set("RAL 6029", '#20603D');
        this.colors.set("RAL 6032", '#317F43');
        this.colors.set("RAL 6033", '#497E76');
        this.colors.set("RAL 6034", '#7FB5B5');
        this.colors.set("RAL 6035", '#1C542D');
        this.colors.set("RAL 6036", '#193737');
        this.colors.set("RAL 6037", '#008F39');
        this.colors.set("RAL 6038", '#00BB2D');
        this.colors.set("RAL 7000", '#78858B');
        this.colors.set("RAL 7001", '#8A9597');
        this.colors.set("RAL 7002", '#7E7B52');
        this.colors.set("RAL 7003", '#6C7059');
        this.colors.set("RAL 7004", '#969992');
        this.colors.set("RAL 7005", '#646B63');
        this.colors.set("RAL 7006", '#6D6552');
        this.colors.set("RAL 7008", '#6A5F31');
        this.colors.set("RAL 7009", '#4D5645');
        this.colors.set("RAL 7010", '#4C514A');
        this.colors.set("RAL 7011", '#434B4D');
        this.colors.set("RAL 7012", '#4E5754');
        this.colors.set("RAL 7013", '#464531');
        this.colors.set("RAL 7015", '#434750');
        this.colors.set("RAL 7016", '#293133');
        this.colors.set("RAL 7021", '#23282B');
        this.colors.set("RAL 7022", '#332F2C');
        this.colors.set("RAL 7023", '#686C5E');
        this.colors.set("RAL 7024", '#474A51');
        this.colors.set("RAL 7026", '#2F353B');
        this.colors.set("RAL 7030", '#8B8C7A');
        this.colors.set("RAL 7031", '#474B4E');
        this.colors.set("RAL 7032", '#B8B799');
        this.colors.set("RAL 7033", '#7D8471');
        this.colors.set("RAL 7034", '#8F8B66');
        this.colors.set("RAL 7035", '#CBD0CC');
        this.colors.set("RAL 7036", '#7F7679');
        this.colors.set("RAL 7037", '#7D7F7D');
        this.colors.set("RAL 7038", '#B5B8B1');
        this.colors.set("RAL 7039", '#6C6960');
        this.colors.set("RAL 7040", '#9DA1AA');
        this.colors.set("RAL 7042", '#8D948D');
        this.colors.set("RAL 7043", '#4E5452');
        this.colors.set("RAL 7044", '#CAC4B0');
        this.colors.set("RAL 7045", '#909090');
        this.colors.set("RAL 7046", '#82898F');
        this.colors.set("RAL 7047", '#D0D0D0');
        this.colors.set("RAL 7048", '#898176');
        this.colors.set("RAL 8000", '#826C34');
        this.colors.set("RAL 8001", '#955F20');
        this.colors.set("RAL 8002", '#6C3B2A');
        this.colors.set("RAL 8003", '#734222');
        this.colors.set("RAL 8004", '#8E402A');
        this.colors.set("RAL 8007", '#59351F');
        this.colors.set("RAL 8008", '#6F4F28');
        this.colors.set("RAL 8011", '#5B3A29');
        this.colors.set("RAL 8012", '#592321');
        this.colors.set("RAL 8014", '#382C1E');
        this.colors.set("RAL 8015", '#633A34');
        this.colors.set("RAL 8016", '#4C2F27');
        this.colors.set("RAL 8017", '#45322E');
        this.colors.set("RAL 8019", '#403A3A');
        this.colors.set("RAL 8022", '#212121');
        this.colors.set("RAL 8023", '#A65E2E');
        this.colors.set("RAL 8024", '#79553D');
        this.colors.set("RAL 8025", '#755C48');
        this.colors.set("RAL 8028", '#4E3B31');
        this.colors.set("RAL 8029", '#763C28');
        this.colors.set("RAL 9001", '#FDF4E3');
        this.colors.set("RAL 9002", '#E7EBDA');
        this.colors.set("RAL 9003", '#F4F4F4');
        this.colors.set("RAL 9004", '#282828');
        this.colors.set("RAL 9005", '#0A0A0A');
        this.colors.set("RAL 9006", '#A5A5A5');
        this.colors.set("RAL 9007", '#8F8F8F');
        this.colors.set("RAL 9010", '#FFFFFF');
        this.colors.set("RAL 9011", '#1C1C1C');
        this.colors.set("RAL 9016", '#F6F6F6');
        this.colors.set("RAL 9017", '#1E1E1E');
        this.colors.set("RAL 9018", '#CFD3CD');
        this.colors.set("RAL 9022", '#9C9C9C');
        this.colors.set("RAL 9023", '#828282');
    }

    getColorCodes(): string[] {
        return Array.from(this.colors.keys());
    }

    getColor(code: string) {
        return this.colors.get(code);
    }
}

export default new RalColorsFactory();