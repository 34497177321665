import React from "react"
import { textColor } from "../assets/panelSystemTypeStyles";
import { TextH3 } from "../assets/text";
import { AppButton } from "./appButton";

export type ProjectSystemTypeProps = {
    isEditable: boolean;
    selectable: boolean;
    checked: boolean;
    title: {
        full: string;
        short: string;
    };
    color: string;
    check: () => void;
}

export const ProjectSystemType = ({ isEditable, selectable, checked, title, color, check }: ProjectSystemTypeProps) => {
    return (<div style={{ display: "flex", alignItems: "center", marginBottom: 16, marginTop: 10 }}>
        {isEditable && <input
            type="radio"
            style={{ marginRight: 11 }}
            name="engine" onClick={() => check()}
            checked={checked}
            onChange={() => { }}
            disabled={!selectable} />}
        <AppButton style={{ marginRight: 5, width: 30, backgroundColor: color, color: textColor, cursor: "default" }}>{title.short}</AppButton>
        <TextH3>{title.full}</TextH3>
    </div>)
}