import React from "react"
import { styles } from "../assets/inputTextStyles";
import { IInputTextProps } from "./inputText";

export const InputTextArea = (props: IInputTextProps) => {
    return (
        <textarea
            value={props.value}
            onChange={(v) => props.setValue(v.target.value)}
            placeholder={props.placeholder}
            style={{ ...styles.multiLineInput, ...props.style }} />
    );
}