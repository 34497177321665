import React from "react"
import { connect } from 'react-redux';
import { ApplicationTabType, setModelInfoVisibility } from "../actions/applicationUIActions";
import { setSelectedEngineeringProductionModel } from "../actions/sourceRevitModelsActions";
import { TextH3 } from "../assets/text";
import { getActiveTab, getCurrentProject, getEngineeringProductionModels, getSelectedEngineeringProductionModel, State } from "../reducers/mainReducer";
import { Project } from "../reducers/projectsReducer";
import { EngineeringProductionModelCard } from "./engineeringProductionModelCard";
import { IEngineeringProductionModel } from "./model3d";
import { setActiveTab } from "../actions/applicationUIActions";

type Props = {
    models: IEngineeringProductionModel[];
    selectedModel: IEngineeringProductionModel | null;
    project: Project
    setSelectedEngineeringProductionModel: (modelId: string) => void;
    setActiveTab: (tab: ApplicationTabType) => void;
    setModelInfoVisibility: (isVisible: boolean) => void;
}

export const EngineeringProductionModelsNavigationBar = ({ models, selectedModel, project, setSelectedEngineeringProductionModel, setActiveTab, setModelInfoVisibility }: Props) => {
    const toggleTab = (newTab: ApplicationTabType) => {
        if (!project || project.isDirty)
            return;

        setActiveTab(newTab);
    }
    
    const modelCards = models
        .map(x => <EngineeringProductionModelCard
            key={`engineering-model${x.id}`}
            item={x} isSelected={x.id === selectedModel?.id}
            onSelect={() => setSelectedEngineeringProductionModel(x.id)} 
            setSelectedWindow={toggleTab.bind(this)}
            />)

    return (
        <div>
            {models.length != 0 && <TextH3 style={{ marginTop: 7, marginBottom: 10, fontSize: 15 }}>Engineering models</TextH3>}
            <div className="scrollBar"
                style={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                    scrollbarWidth: "none",
                    maxHeight: "90%",
                    width: "100%",
                    paddingTop: 2,
                }}>
                {modelCards}
            </div>
        </div>
    )
}

export default connect(function (store: State) {
    return {
        models: getEngineeringProductionModels(store),
        selectedModel: getSelectedEngineeringProductionModel(store),
        project: getCurrentProject(store)!,
        activeTab: getActiveTab(store)
    };
}, { setSelectedEngineeringProductionModel, setActiveTab, setModelInfoVisibility })(EngineeringProductionModelsNavigationBar);