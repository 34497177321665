import React, { useState } from "react";
import { colors, icon } from "../../config/colors";

type Props = {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  onClick?: () => void;
}

export const TagH4 = ({ children, style, onClick }: Props) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <button
      className="unselectable"
      style={
        isShown ? { ...styles.textH, ...style } : { ...styles.text, ...style }
      }
      onClick={onClick}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      {children}
    </button>
  );
};

type Styles = {
  text: React.CSSProperties;
  textH: React.CSSProperties;
}

const styles: Styles = {
  text: {
    fontSize: "17px",
    fontFamily: "Roboto",
    lineHeight: "20px",
    color: colors.tag,
    fontStyle: "normal",
    fontWeight: 500,
    margin: "0 0 0 0",
    padding: "0 0 0 0",
    border: "none",
    background: "none",
  },

  textH: {
    fontSize: "17px",
    fontFamily: "Roboto",
    lineHeight: "20px",
    color: icon.selected,
    fontStyle: "normal",
    fontWeight: 500,
    margin: "0 0 0 0",
    padding: "0 0 0 0",
    border: "none",
    background: "none",
  },
};
