import { roundToDecimalSigns } from "@dextall/shared";
import { IMaterialPrice } from "./price";

export enum DisplayUnits {
    Metric = 1,

    Imperial = 2
}

export enum DisplayCurrencies {
    Eur = 1,
    Usd = 2
}

export interface IModelAnalytics {
    totalWallsArea: number;
    totalWindowsArea: number;
    totalPanelsArea: number;
    panelTypesCount: number;
    cornerTypesCount: number;
    panelsCount: number;
    cornersCount: number;
    opaquePanelsFootage: number;
    windowPanelsFootage: number;
    cornerPanelsFootage: number;
    averageOpaquePanelWidth: number | null;
    averageOpaquePanelHeight: number | null;
    averageWindowPanelWidth: number | null;
    averageWindowPanelHeight: number | null;
    averageCornerPanelWidth: number | null;
    averageCornerPanelHeight: number | null;
    opaquePanelsArea: number;
    windowPanelsArea: number;
    cornerPanelsArea: number;
    atypicalPanelsCount: number;
    atypicalCornerPanelsCount: number;
    atypicalPanelsArea: number;
    atypicalCornerPanelsArea: number;
    colors: IModelAnalyticsColorSummary[];
    displayUnits: DisplayUnits;
}

export interface IModelAnalyticsColorSummary {
    color: string;
    totalArea: number;
}

export interface IModelBom {
    units: DisplayUnits;
    frameProfile: IModelBomEntity[];
    claddings: IModelBomEntity[];
    outerBoards: IModelBomEntity[];
    innerSheets: IModelBomEntity[];
}

export interface IMaterial {
    totalPanelsArea: number;
    totalRate: number;
    bidCosts: IBidCosts;
    prices: IPrice[];
    panelPacking: IPanelPacking;
    cornerPacking: IPanelPacking;
    woodPrice: number;
    screwPrice: number;
    tapePrice: number;
    currency: number;
}

export interface ITaxes {
    totalRate: number;
}

export interface ICurrencies {
    source: string;
    quotes: IQuote[]
}

export interface IQuote {
    eurusd: string;
}


export interface IPrice {
    id: string;
    category: string;
    group: string;
    units: string;
    quantity: number;
    itemName: string;
    lowPrice: number;
    highPrice: number;
}

export interface IPanelPacking {
    boxWidth: number;
    boxLength: number;
    boxHeight: number;
    surfaceArea: number;
    totalContainers: number;
    totalBoxesPerContainer: number;
    hasInvalidPanels: boolean;
}

export interface IBidCosts {
    productionPrice: number;
    transportationPrice: number;
    duties: number;
    administrativeCost: number;
}

export interface IModelBomEntity {
    partNumber: string;
    quantity: number;
    depth: number;
    height: number;
    width: number;
    length: number;
    thickness: number;
    flatpatternLength: number;
    flatpatternWidth: number;
    flatpatternArea: number;
    totalFlatPatternArea: number;
    materialGroup: string;
    material: string;
    color: string;
    gloss: string;
    elements: IModelBomEntityElement[];
}

export interface IModelBomEntityElement {
    id: string;
    partNumber: string;
}

export const highPrices = (prices: IPrice[]) => {
    return prices.reduce((acc, elem) => acc + elem.highPrice, 0);
}

export const roundToEuro = (price: number, currency: DisplayCurrencies, currencyCoef: number) => {
    const sign = currency === DisplayCurrencies.Eur ? "€" : "$";
    const priceValue = currency === DisplayCurrencies.Eur ? roundToDecimalSigns(price, 2) : roundToDecimalSigns(price * currencyCoef, 2);
    return `${priceValue} ${sign}`
}

export const fullPrice = (materialPrices: IMaterial | null | undefined, margin: number | undefined, duties: number | undefined, taxRate: number | undefined): IMaterialPrice => {

    const prices = materialPrices?.prices || [];
    const administrative = materialPrices?.bidCosts.administrativeCost!;
    const totalArea = materialPrices?.totalPanelsArea!;
    const prodPrices = totalArea * materialPrices?.bidCosts.productionPrice!;
    const dutiesPrice = ((prodPrices + highPrices(prices)) * duties! / 100);
    const marginPrice = ((prodPrices + highPrices(prices) + dutiesPrice) * margin! / 100);
    const administrativePrice = ((prodPrices + highPrices(prices) + dutiesPrice + marginPrice) * administrative / 100);
    const transportationPanelsPrice = materialPrices?.panelPacking.totalContainers! * materialPrices?.bidCosts.transportationPrice!;
    const transportationCornerPrice = materialPrices?.cornerPacking.totalContainers! * materialPrices?.bidCosts.transportationPrice!;
    const transportationPrice = transportationPanelsPrice + transportationCornerPrice;


    const totalPanelBoxes = materialPrices?.panelPacking.totalContainers! * materialPrices?.panelPacking.totalBoxesPerContainer!;
    const totalCornerBoxes = materialPrices?.cornerPacking.totalContainers! * materialPrices?.cornerPacking.totalBoxesPerContainer!;
    const totalBoxes = totalPanelBoxes + totalCornerBoxes;

    const stepCoverLength = 0.3;
    const stepCover = 0.9;
    const stepDiagonals = 1.0
    const stepVerticals = 0.6;
    const woodProfileHeight = 0.022;
    const woodProfileWidth = 0.1;
    const stretchTapeLayers = 3;

    const woodRequiredPerBox = (boxLength: number, boxWidth: number, boxHeight: number) => {
        const profile_1_1 = boxLength * woodProfileHeight * woodProfileWidth * 8;
        const profile_1_2 = boxWidth * woodProfileHeight * woodProfileWidth * 8;
        const profile_2 = boxWidth * woodProfileHeight * woodProfileWidth * Math.trunc(2 * (boxHeight / stepDiagonals)) + 2;
        const profile_3 = boxHeight * woodProfileWidth * woodProfileWidth * Math.trunc((boxLength * 2 + boxWidth * 2) / stepVerticals);
        const profile_4 = boxLength * woodProfileHeight * woodProfileWidth * Math.trunc(2 * (boxHeight / stepDiagonals)) + 2;
        const profile_5 = boxLength * woodProfileHeight * woodProfileWidth * Math.trunc(boxWidth * 2) / stepDiagonals;
        const profile_6 = boxWidth * woodProfileHeight * woodProfileWidth * Math.trunc(boxHeight * 2) / stepDiagonals;
        const profile_7_1 = boxWidth * woodProfileHeight * woodProfileWidth * Math.trunc(boxLength / stepCover);
        const profile_7_2 = boxWidth * woodProfileHeight * woodProfileWidth * Math.trunc(boxWidth / stepCoverLength);
        const addProfiles = 0.05 * 0.1 * boxLength * Math.trunc(3 * (boxLength / stepVerticals));
        return [profile_1_1, profile_1_2, profile_2, profile_3, profile_4, profile_5, profile_6, profile_7_1, profile_7_2, addProfiles].reduce((sum, current) => sum + current, 0);
    }

    const woodRequiredPerBoxPanels = woodRequiredPerBox(materialPrices?.panelPacking.boxLength!, materialPrices?.panelPacking.boxWidth!, materialPrices?.panelPacking.boxHeight!) * 1.3;
    const cornerBoxWoodVolume = woodRequiredPerBox(materialPrices?.cornerPacking.boxLength!, materialPrices?.cornerPacking.boxWidth!, materialPrices?.cornerPacking.boxHeight!) * 2.0;

    const panelsWood = Math.ceil(totalPanelBoxes * woodRequiredPerBoxPanels);
    const cornersWood = Math.ceil(totalCornerBoxes * cornerBoxWoodVolume);
    const totalWood = panelsWood + cornersWood;

    const panelsScrews = Math.ceil(Math.trunc(materialPrices?.panelPacking.surfaceArea! * 5) * totalPanelBoxes);
    const cornersScrews = Math.ceil(Math.trunc(materialPrices?.cornerPacking.surfaceArea! * 5) * totalCornerBoxes);
    const totalScrews = panelsScrews + cornersScrews;

    const panelsStretch = Math.ceil(materialPrices?.panelPacking.surfaceArea! * stretchTapeLayers * totalPanelBoxes);
    const cornersStretch = Math.ceil(materialPrices?.cornerPacking.surfaceArea! * stretchTapeLayers * totalCornerBoxes);
    const totalStretch = panelsStretch + cornersStretch;

    const woodPrice = materialPrices?.woodPrice!;
    const screwPrice = materialPrices?.screwPrice!;
    const tapePrice = materialPrices?.tapePrice!;

    const woodPriceValue = totalWood * woodPrice;
    const screwPriceValue = totalScrews * screwPrice;
    const tapePriceValue = totalStretch * tapePrice;

    const packingPrice = woodPriceValue! + screwPriceValue! + tapePriceValue!;

    const summ = prodPrices + highPrices(prices) + administrativePrice + transportationPrice + packingPrice;
    const taxes = (summ * taxRate!) / 100.0;
    const coef = materialPrices?.currency;

    const isInvalid = materialPrices?.panelPacking.hasInvalidPanels! || materialPrices?.cornerPacking.hasInvalidPanels

    return {
        administrativePrice: administrativePrice,
        totalArea: totalArea,
        productionPrice: prodPrices,
        dutiesPrice: dutiesPrice,
        marginPrice: marginPrice,
        transportationPrice: transportationPrice,
        totalBoxes: totalBoxes,
        totalWood: totalWood,
        totalScrews: totalScrews,
        totalStretch: totalStretch,
        woodPrice: woodPriceValue,
        screwPrice: screwPriceValue,
        tapePrice: tapePriceValue,
        packingPrice: packingPrice,
        taxes: taxes,
        sum: summ,
        hasInvalidPanels: isInvalid!,
        currencyCoef: coef!
    };
}

