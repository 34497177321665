import React from "react";

type Styles = {
    modelInfo: React.CSSProperties;
    scrollBar: React.CSSProperties;
}

export const styles: Styles = {
    modelInfo: {
        position: 'absolute',
        left: 140,
        zIndex: 900,
        paddingBottom: 15,
        width: '...'
    },
    scrollBar: {
        scrollbarWidth: "none",
        maxHeight: "93.5%",
        width: "100%",
        paddingTop: 18,
        display: "flex",
        flexDirection: "row",
        position: "relative"
    }
};