import React from "react";
import { ProjectProperty } from "./projectProperty";
import { ProjectPropertyPreview } from "./projectPropertyPreview";
import { InputText } from "./inputText";
import { InputTextArea } from "./inputTextArea"

interface IComponentProps {
    value: string | React.ReactNode;
    isEditMode?: boolean;
    setValue: (newValue: string) => void;
    title: string;
    multiline?: boolean;
    style?: React.CSSProperties;
    children?: React.ReactNode;
    edit?: React.ReactNode;
    type?: string
}


export const ProjectEditorField = (props: IComponentProps) => {
    return (<ProjectProperty
        style={props.style}
        header={{
            title: props.title,
            children: props.children
        }}
        isEditMode={props.isEditMode}
        preview={typeof props.value === "string"
            ? <ProjectPropertyPreview value={props.value} />
            : props.value}
        edit={props.edit || <>
            {!props.multiline && typeof props.value === "string" && <InputText type={props.type} value={props.value} setValue={props.setValue} />}
            {props.multiline && typeof props.value === "string" && <InputTextArea type={props.type} value={props.value} setValue={props.setValue} />}
        </>}
    />);
}