import React from "react";

export const Building = ({ color }: { color: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 34 34"
    >
      <path
        fill={color}
        d="M5.456 0a1.263 1.263 0 00-.526.107c-.167.073-.32.181-.447.319-.129.137-.23.301-.3.482a1.591 1.591 0 000 1.141c.07.18.171.345.3.482.128.138.28.246.447.32.167.072.346.108.526.106H19.05c.18.002.358-.034.526-.107.167-.073.319-.181.447-.319a1.49 1.49 0 00.3-.482 1.592 1.592 0 000-1.141 1.49 1.49 0 00-.3-.482 1.355 1.355 0 00-.447-.32A1.263 1.263 0 0019.049 0H5.456zM1.38 5.913a1.264 1.264 0 00-.526.107c-.168.073-.32.181-.448.319-.128.137-.23.301-.3.482a1.591 1.591 0 000 1.141c.07.18.172.345.3.482.128.138.28.246.448.32.167.072.345.108.526.106V34h8.155v-5.913h5.437V34h8.155V8.87c.18.002.36-.034.526-.107.167-.073.32-.181.448-.319.128-.137.23-.301.3-.482a1.592 1.592 0 000-1.141 1.492 1.492 0 00-.3-.482 1.356 1.356 0 00-.448-.32 1.264 1.264 0 00-.526-.106H1.38zm2.718 4.435h2.718v2.956H4.097v-2.956zm5.437 0h5.437v2.956H9.534v-2.956zm8.155 0h2.719v2.956h-2.719v-2.956zm8.156 0V34H34V10.348h-8.155zm2.718 4.435h2.719v2.956h-2.719v-2.956zM4.097 16.26h2.718v2.957H4.097V16.26zm5.437 0h5.437v2.957H9.534V16.26zm8.155 0h2.719v2.957h-2.719V16.26zm10.874 4.435h2.719v2.956h-2.719v-2.956zM4.097 22.174h2.718v2.957H4.097v-2.957zm5.437 0h5.437v2.957H9.534v-2.957zm8.155 0h2.719v2.957h-2.719v-2.957zm10.874 4.435h2.719v2.956h-2.719V26.61zM4.097 28.087h2.718v2.957H4.097v-2.957zm13.592 0h2.719v2.957h-2.719v-2.957z"
      ></path>
    </svg>
  );
};
