export const loadData = (operation: () => void) => {
    Autodesk.Viewing.endpoint.setUseCredentials(false);
    Autodesk.Viewing.setOffline(true);
    const headers = Autodesk.Viewing.endpoint.HTTP_REQUEST_HEADERS;

    Autodesk.Viewing.endpoint.HTTP_REQUEST_HEADERS = {};

    operation();

    Autodesk.Viewing.endpoint.HTTP_REQUEST_HEADERS = headers;
    Autodesk.Viewing.setOffline(false);
    Autodesk.Viewing.endpoint.setUseCredentials(true);
}