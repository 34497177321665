import { IExtendedUser } from "./appStatistics";

export const roleKeys: (keyof IExtendedUser)[] = [
    'applicationAdmin',
    'panelsCatalogAdmin',
    'statsAdmin',
    'materialsDatabaseAdmin',
    'dextallEmployee',
    'engineer',
    'bidManager',
    'canExposeFacadeModelToPublic',
    'betaTester'
];

export const roleDisplayNames: { [key in keyof IExtendedUser]?: string } = {
    applicationAdmin: 'Application Admin',
    panelsCatalogAdmin: 'Panels Catalog Admin',
    statsAdmin: 'Statistics Admin',
    materialsDatabaseAdmin: 'Materials Database Admin',
    dextallEmployee: 'Dextall Employee',
    engineer: 'Engineer',
    bidManager: 'BID Manager',
    canExposeFacadeModelToPublic: 'Can Expose Facade Model To Public',
    betaTester: 'Beta Tester'
};

export const roleColors: { [key: string]: string } = {
    applicationAdmin: '#38B6FF',
    panelsCatalogAdmin: '#004AAD',
    statsAdmin: '#5271FF',
    materialsDatabaseAdmin: '#4F0BAF',
    dextallEmployee: '#B1CBC0',
    engineer: '#304E49',
    bidManager: '#84948D',
    canExposeFacadeModelToPublic: '#B28DE8',
    betaTester: '#8550D1',
    customer: '#8FD6FF'
};
