import {IMaterial} from "../components/modelAnalytics";
import { PricesActionType, PricesLoadingState } from "../actions/priceActions";

export type State = {
    materialPrices: IMaterial | null;
    isVisible: boolean;
    state: PricesLoadingState;
}

export const initialState: State = {
    materialPrices: null,
    isVisible: false,
    state: "loading"
}

export const getPricesLoadingState = function (state: State) {
    return state.state;
}

export const getMaterialPrices = function (state: State) {
    return state.materialPrices;
}

export const isMaterialTablelVisible = function (state: State) {
    return state.isVisible;
}

export default function (state: State = initialState, action: PricesActionType) {
    switch (action.type) {
        case "SET_CURRENT_MODEL_PRICES": {
            return { ...state, materialPrices: action.materialPrices, state: action.priceLoadingState }
        }

        case "SHOW_PRICES_BREAKDOWN_DIALOG": {
            return { ...state, isVisible: action.visible }
        }

        default:
            return state;
    }
}