import React from "react"
import { IInsulatedGlassUnit, InsulatedGlassUnitValuesPreferences, isInsulatedGlassUnitValidFor } from "../forge/insulatedGlassUnit";
import { IguPrice } from "./iguPrice";
import { IguTitle } from "./iguTitle";
import { IguItemOperationsList } from "./iguItemOperationsList";
import "./igu.css"

type Props = {
    insulatedGlassUnit: IInsulatedGlassUnit;
    isSelected: boolean;
    insulatedGlassUnitValuesPreferences: InsulatedGlassUnitValuesPreferences;
    onSelect: (id: string) => void;
}

export const IguItem = ({ insulatedGlassUnit, insulatedGlassUnitValuesPreferences, isSelected, onSelect }: Props) => {
    const validationResults = isInsulatedGlassUnitValidFor(insulatedGlassUnit, insulatedGlassUnitValuesPreferences);

    const className = `igu-item${isSelected ? " selected-igu-item" : ""}`;

    return <div className={className} onClick={e => { onSelect(insulatedGlassUnit.id); e.preventDefault(); }}>
        <IguTitle insulatedGlassUnit={insulatedGlassUnit} editable={false} />
        <div style={{ display: "flex", flexDirection: "row" }}>
            <IguItemOperationsList insulatedGlassUnit={insulatedGlassUnit} validationResults={validationResults} />
            <IguPrice price={insulatedGlassUnit.price} />
        </div>
    </div>
}