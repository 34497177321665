type AddErrorType = {
    type: "ADD_ERROR";
    info: string | Error;
}

type AddLogType = {
    type: "ADD_LOG";
    info: string;
}

export const addError = (error: string | Error): AddErrorType => {
    return {
        type: "ADD_ERROR",
        info: error
    };
};

export const addLog = (info: string): AddLogType => {
    return {
        type: "ADD_LOG",
        info
    };
};