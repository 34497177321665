import React from "react";

type Props = {
    style?: React.CSSProperties;
}

export const Pin = ({ style }: Props) => (
  <svg width={14} height={14} xmlns="http://www.w3.org/2000/svg" fill="#F4F4F4" style={style}>
    <path d="M8.761 0 7.703 1.058l.82.82L3.883 5.6 2.775 4.49 1.716 5.55 4.56 8.393 0 12.952V14h1.048l4.559-4.56 2.844 2.844 1.059-1.058-1.045-1.045 3.67-4.69.807.806L14 5.239 8.761 0Z" />
  </svg>
);
