import axios, { AxiosError } from 'axios';
import { IActiveUsersStatisticsItem, IApplicationUser, IDailyActiveUsersStatisticsItem, IExtendedUser, IModelStatisticsDailyItem, IModelStatisticsWeeklyItem, ISessionsDailyStatisticsItem, ISessionsWeeklyStatisticsItem, IUserCreationStatisticsDailyItem, IUserCreationStatisticsWeeklyItem, IUserCredentials, IWeeklyActiveUsersStatisticsItem } from './components/appStatistics';
import { ICornerPanelSource, ICornerPanelTemplateSource } from './components/forge/cornerPanelSource';
import { IInsulatedGlassUnit } from './components/forge/insulatedGlassUnit';
import { IModelCorner } from './components/forge/modelCorner';
import { AssistPanelsLayoutOptimizationCommand, OptimizePanelsLayoutCommand, IPanelsLayoutOptimizationResults, PanelsLayoutAssistanceResponse } from './components/forge/optimizationDomainEntities';
import { IPanelCommentAttachment, IPanelSource, IPanelSourceType, IPanelTemplateSource } from './components/forge/panelSource';
import { CreateRevitFacadeModelSharedParametersProfileCommand, FacadeRevitModelStatus, IRevitFacadeModel, IRevitFamiliesGeneratingJob, RevitFacadeModelSharedParametersProfile } from './components/forge/revitFacadeModelDomainEntities';
import { SystemSettings } from './components/forge/systemSettings';
import { ITeamsChannel, ITeamsSendMessage } from './components/forge/teams';
import { IWallFace, IWallWindowSource, IWallWindowTemplateSource } from './components/forge/wallFace';
import { IGeocodeResponse } from './components/maps/geocode';
import { AssignLinkedModelCommand, CreateLinkedModelCommand, CreateSourceModelCommand, IEngineeringProductionModel, ILinkedModel, IModel3D, IModel3DVersion, IModelStorageObject, LinkedModelExtension, UploadFileRequest, UploadLinkedModelFileRequest, UploadModelFileRequest } from './components/model3d';
import { IMaterial, IModelAnalytics, IModelBom, ITaxes } from './components/modelAnalytics';
import { IProject, ICreateUpdateProjectCommand, BasicResponse, BasicItemResponse, BasicItemsResponse, IForgeToken, IApplicationMetadata } from './components/project';
import { IUserProfile } from './components/userProfile';

const AuthorizationHeader = 'Authorization';
const unknownFailureMessage = "Server error. Repeat operation later";

class Repository {
    private _accessToken: string | null = null;

    async validateAccessToken() {
        const response = await axios.post("/api/login/validate");

        return response.data.item;
    }

    async loadProfile(): Promise<IUserProfile> {
        const response = await axios.get("/api/login/profile");

        return response.data.item;
    }

    async login(userData: { name: string, password: string }): Promise<BasicItemResponse<string>> {
        const formData = new FormData();

        formData.append('name', userData.name);
        formData.append('password', userData.password);

        const result = await axios.post('/api/login', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return result.data;
    }

    async loadModels(projectId: string): Promise<BasicItemsResponse<IModel3D>> {
        try {
            const result = await axios.get<BasicItemsResponse<IModel3D>>(`/api/projects/${projectId}/models`);

            return result.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async loadEngineerModels(projectId: string): Promise<BasicItemsResponse<IModel3D>> {
        try {
            const result = await axios.get<BasicItemsResponse<IModel3D>>(`/api/projects/${projectId}/engineering-models`);

            return result.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async loadBidModels(projectId: string): Promise<BasicItemsResponse<IModel3D>> {
        try {
            const result = await axios.get<BasicItemsResponse<IModel3D>>(`/api/projects/${projectId}/bid-models`);

            return result.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async loadProductionEnginneringModels(projectId: string): Promise<BasicItemsResponse<IEngineeringProductionModel>> {
        const url = `https://engineering.dextall.com/api/projects/${projectId}/models`;

        try {
            const response = await axios.get<BasicItemsResponse<IEngineeringProductionModel>>(url);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async removeModel(versionIdentificator: string): Promise<BasicResponse> {
        const url = `/api/models/${versionIdentificator}`;

        const result = await axios({
            url: url,
            method: "DELETE"
        });

        return result.data;
    }

    async removeModelVersion(id: string): Promise<BasicResponse> {
        const url = `/api/models/${id}/version`;

        const result = await axios({
            url: url,
            method: "DELETE"
        });

        return result.data;
    }

    async pinModelVersion(modelId: string, versionId: string, pin: boolean): Promise<BasicResponse> {
        const response = await axios({
            url: "/api/models/main-option",
            method: "PATCH",
            data: {
                versionIdentificator: modelId,
                versionId,
                isMainOption: pin
            }
        });

        return response.data;
    }

    async renameModelVersion(versionId: string, name: string): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/models/${versionId}/rename`, { name });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async renameModel(modelId: string, name: string): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/models/${modelId}/rename-model`, { name });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async findModelsByIds(ids: string[]): Promise<IModel3DVersion[]> {
        const response = await axios({
            url: "/api/models/ids",
            method: "POST",
            data: { ids: ids }
        });

        return response.data.items;
    }

    async getModelAnalytics(modelId: string): Promise<BasicItemResponse<IModelAnalytics>> {
        const response = await axios.get(`/api/models/${modelId}/analytics`);

        return response.data;
    }

    async getModelDownloadableUrl(modelId: string): Promise<BasicItemResponse<string>> {
        const response = await axios.post<BasicItemResponse<string>>(`/api/models/${modelId}/downloadable`);

        return response.data;
    }

    async uploadChatFileToBucket(url: string, file: File): Promise<void> {
        await fetch(url, {
            method: "put",
            body: file,
            redirect: "follow"
        });
    }

    async createFileStorage(fileExtension: LinkedModelExtension): Promise<BasicItemResponse<IModelStorageObject>> {
        try {
            const response = await axios.post<BasicItemResponse<IModelStorageObject>>(`/api/forge/create-storage/${fileExtension}`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async uploadFileToBucket(model: UploadFileRequest, fileExtension: LinkedModelExtension): Promise<BasicItemResponse<IModelStorageObject>> {
        const createStorageResponse = await this.createFileStorage(fileExtension);

        if (!createStorageResponse.isSuccess)
            return createStorageResponse;

        const fileName = model.file.name;

        try {
            await axios({
                url: createStorageResponse.item.signedUrl,
                method: "put",
                data: model.file,
                headers: {
                    "Content-Disposition": "attachment; filename=\"" + encodeURIComponent(fileName) + "\""
                }
            });
        } catch (e) {
            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }

        return createStorageResponse;
    }

    async uploadFile(request: UploadModelFileRequest): Promise<BasicItemResponse<string>> {
        const fileName = request.file.name;

        const storage = await this.uploadFileToBucket(request, "rvt");

        if (!storage.isSuccess)
            return storage;

        const command: CreateSourceModelCommand = {
            fileName: fileName,
            objectKey: storage.item.objectKey,
            wallsOffset: request.wallsOffset,
            externalWallTypeNameStartsWith: request.externalWallTypeNameStartsWith
        }

        try {
            const response = await axios.post<BasicItemResponse<string>>(`/api/models/${request.projectId}`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async startModelDerivativeProcessing(modelId: string): Promise<any> {
        const url = `/api/models/${modelId}/translate`;

        const result = await axios({
            url: url,
            method: "post"
        });

        return result.data;
    }

    async findModelCorners(modelId: string): Promise<IModelCorner[]> {
        const url = `/api/models/${modelId}/model-corners`;

        const result = await axios.get(url);

        return result.data.items;
    }

    async findModelWallFaces(modelId: string): Promise<IWallFace[]> {
        const url = `/api/models/${modelId}/model-faces`;

        const result = await axios.get(url);

        return result.data.items;
    }

    async findModelWallWindows(modelId: string): Promise<IWallWindowSource[]> {
        const url = `/api/models/${modelId}/wall-windows`;

        const result = await axios.get(url);

        return result.data.items;
    }

    async getForgeViewerToken(): Promise<BasicItemResponse<IForgeToken>> {
        try {
            const response = await axios.get<BasicItemResponse<IForgeToken>>("/api/forge/token")

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findPanels(modelId: string): Promise<IPanelSource[]> {
        const url = `/api/models/${modelId}/wall-panels`;

        const result = await axios.get(url);

        return result.data.items;
    }

    async findPanelsByIds(ids: string[]): Promise<BasicItemsResponse<IPanelSource>> {
        try {
            const response = await axios.post<BasicItemsResponse<IPanelSource>>("/api/find-wall-panels-batch", ids);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async createNewPanels(modelId: string, panels: IPanelSource[]): Promise<void> {
        const command = {
            panels
        };

        await axios({
            url: `/api/models/${modelId}/wall-panels`,
            method: "POST",
            data: command
        });
    }

    async updatePanels(modelId: string, panels: IPanelSource[]): Promise<void> {
        const command = {
            panels
        };

        await axios({
            url: `/api/models/${modelId}/wall-panels`,
            method: "PATCH",
            data: command
        })
    }

    async removePanels(modelId: string, panelIds: string[]): Promise<void> {
        const command = {
            ids: panelIds
        }

        await axios({
            url: `/api/models/${modelId}/wall-panels`,
            method: "DELETE",
            data: command
        })
    }

    async findCornerPanels(modelId: string): Promise<ICornerPanelSource[]> {
        const url = `/api/models/${modelId}/wall-corner-panels`;

        const result = await axios.get(url);

        return result.data.items;
    }

    async findCornerPanelsByIds(ids: string[]): Promise<BasicItemsResponse<ICornerPanelSource>> {
        try {
            const response = await axios.post<BasicItemsResponse<ICornerPanelSource>>("/api/find-wall-corner-panels-batch", ids);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async createNewCornerPanels(modelId: string, corners: ICornerPanelSource[]): Promise<void> {
        const command = {
            corners
        };

        await axios({
            url: `/api/models/${modelId}/wall-corner-panels`,
            method: "POST",
            data: command
        });
    }

    async updateCornerPanels(modelId: string, corners: ICornerPanelSource[]): Promise<void> {
        const command = {
            corners
        };

        await axios({
            url: `/api/models/${modelId}/wall-corner-panels`,
            method: "PATCH",
            data: command
        });
    }

    async removeCornerPanels(modelId: string, cornerIds: string[]): Promise<void> {
        const command = {
            ids: cornerIds
        }

        await axios({
            url: `/api/models/${modelId}/wall-corner-panels`,
            method: "DELETE",
            data: command
        });
    }

    async createNewWindows(modelId: string, wallWindows: IWallWindowSource[]): Promise<void> {
        const command = {
            windows: wallWindows
        };

        await axios({
            url: `/api/models/${modelId}/wall-windows`,
            method: "POST",
            data: command
        })
    }

    async updateWallWindows(modelId: string, wallWindows: IWallWindowSource[]): Promise<void> {
        const command = {
            windows: wallWindows
        };

        await axios({
            url: `/api/models/${modelId}/wall-windows`,
            method: "PATCH",
            data: command
        })
    }

    async removeWallWindows(modelId: string, wallWindowsIds: string[]): Promise<void> {
        const command = {
            ids: wallWindowsIds
        }

        await axios({
            url: `/api/models/${modelId}/wall-windows`,
            method: "DELETE",
            data: command
        });
    }

    async createSinglePanelType(panelId: string): Promise<IPanelSource[]> {
        const response = await axios({
            url: `/api/panels/${panelId}/create-panel-type`,
            method: "POST"
        });

        return response.data.items;
    }

    async createSingleCornerPanelType(cornerId: string): Promise<ICornerPanelSource[]> {
        const response = await axios({
            url: `/api/corners/${cornerId}/create-panel-type`,
            method: "POST"
        });

        return response.data.items;
    }

    async createNewModelVersion(modelId: string): Promise<BasicItemResponse<string>> {
        const response = await axios({
            url: `/api/models/${modelId}/create-new-version`,
            method: "POST"
        });

        return response.data;
    }

    async createNewBidModelVersion(modelId: string, targetProjectId: string): Promise<BasicResponse> {
        try {
            const response = await axios<BasicResponse>({
                url: `/api/models/${modelId}/create-new-bid-version?targetProjectId=${targetProjectId}`,
                method: "POST"
            });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async createNewModelEngineerVersion(modelId: string): Promise<BasicItemResponse<string>> {
        const response = await axios({
            url: `/api/models/${modelId}/create-new-version-engineering`,
            method: "POST"
        });

        return response.data;
    }

    async submitModelToReview(modelId: string): Promise<BasicResponse> {
        try {
            const response = await axios<BasicResponse>({
                url: `/api/models/${modelId}/submit`,
                method: "POST"
            });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async submitModelToBid(modelId: string): Promise<BasicResponse> {
        try {
            const response = await axios<BasicResponse>({
                url: `/api/models/${modelId}/submit-to-bid`,
                method: "POST"
            });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async submitModelToApprove(modelId: string, comment: string): Promise<BasicResponse> {
        const command = {
            comment: comment
        };

        const response = await axios({
            url: `/api/models/${modelId}/approve`,
            method: "POST",
            data: command
        });

        return response.data;
    }

    async createChannel(email: string): Promise<BasicItemResponse<ITeamsChannel>> {
        const response = await axios({
            url: `/api/channels`,
            method: "POST",
            data: { email }
        });

        return response.data;

    }

    async findChannels(): Promise<BasicItemsResponse<ITeamsChannel>> {
        const response = await axios({
            url: `/api/channels`,
            method: "GET"
        });

        return response.data;
    }


    async createChatBlobSignedUrl(userName: string, fileName: string): Promise<BasicItemResponse<string>> {
        const command = {
            Username: userName,
            FileName: fileName
        }

        const response = await axios({
            url: "/api/chat/blob/createSignedUrl",
            method: "POST",
            data: command
        });

        return response.data;

    }

    async sendMessage(channelId: string, message: string): Promise<BasicItemResponse<ITeamsSendMessage>> {

        const response = await axios({
            url: `/api/channels/${channelId}/messages/`,
            method: "POST",
            data: { message }
        });

        return response.data;
    }

    async sendMessageAnswer(channelId: string, messageId: string, message: string): Promise<BasicItemResponse<ITeamsSendMessage>> {

        const response = await axios({
            url: `/api/channels/${channelId}/messages/${messageId}/replies`,
            method: "POST",
            data: { message }
        });

        return response.data;
    }

    async findMessages(channelId: string, messageId: string): Promise<BasicItemResponse<ITeamsSendMessage>> {
        const response = await axios({
            url: `/api/channels/${channelId}/messages/${messageId}`,
            method: "GET"
        });

        return response.data;
    }

    async findMessagesReplies(channelId: string, messageId: string): Promise<BasicItemsResponse<ITeamsSendMessage>> {
        const response = await axios({
            url: `/api/channels/${channelId}/messages/${messageId}/replies`,
            method: "GET"
        });

        return response.data;
    }

    async optimizePanelsLayout(command: OptimizePanelsLayoutCommand): Promise<IPanelsLayoutOptimizationResults> {
        const failureResponse: IPanelsLayoutOptimizationResults = { isSuccess: false, panelsLayout: [] };

        try {
            const response = await axios.post<BasicItemResponse<IPanelsLayoutOptimizationResults>>(
                "https://optimizer.dextall.com/optimize/panels-layout", command);

            return response.data.isSuccess
                ? response.data.item
                : failureResponse;
        } catch (e) {
            return failureResponse;
        }
    }

    async symmetricalPanelsLayout(command: OptimizePanelsLayoutCommand): Promise<IPanelsLayoutOptimizationResults> {
        const failureResponse: IPanelsLayoutOptimizationResults = { isSuccess: false, panelsLayout: [] };

        try {
            const response = await axios.post<BasicItemResponse<IPanelsLayoutOptimizationResults>>(
                "https://optimizer.dextall.com/optimize/symmetrical-layout", command);

            return response.data.isSuccess
                ? response.data.item
                : failureResponse;
        } catch (e) {
            return failureResponse;
        }
    }

    async symmetricalSplitLinesPanelsLayout(command: OptimizePanelsLayoutCommand): Promise<IPanelsLayoutOptimizationResults> {
        const failureResponse: IPanelsLayoutOptimizationResults = { isSuccess: false, panelsLayout: [] };

        try {
            const response = await axios.post<BasicItemResponse<IPanelsLayoutOptimizationResults>>(
                "https://optimizer.dextall.com/optimize/symmetrical-split-lines-layout", command);

            return response.data.isSuccess
                ? response.data.item
                : failureResponse;
        } catch (e) {
            return failureResponse;
        }
    }

    async symmetricalGridLinesPanelsLayout(command: OptimizePanelsLayoutCommand): Promise<IPanelsLayoutOptimizationResults> {
        const failureResponse: IPanelsLayoutOptimizationResults = { isSuccess: false, panelsLayout: [] };

        try {
            const response = await axios.post<BasicItemResponse<IPanelsLayoutOptimizationResults>>(
                "https://optimizer.dextall.com/optimize/symmetrical-grid-layout", command);

            return response.data.isSuccess
                ? response.data.item
                : failureResponse;
        } catch (e) {
            return failureResponse;
        }
    }

    async assistPanelsLayout(command: AssistPanelsLayoutOptimizationCommand): Promise<PanelsLayoutAssistanceResponse> {
        try {
            const response = await axios.post<PanelsLayoutAssistanceResponse>(
                "https://optimizer.dextall.com/optimize/assist", command);

            return response.data;
        } catch (e: any) {
            if (e.response?.data?.message)
                return e.data;

            return { isSuccess: false, message: "", items: null, canSkipInvalidZones: false }
        }
    }

    async createMissingModelPanelTypes(modelId: string): Promise<any[]> {
        const response = await axios({
            url: `/api/models/${modelId}/create-panel-types`,
            method: "POST"
        });

        return response.data.items;
    }

    async createMissingCornerPanelTypes(modelId: string): Promise<any[]> {
        const response = await axios({
            url: `/api/models/${modelId}/create-corner-types`,
            method: "POST"
        });

        return response.data.items;
    }

    async createMissingRevitFamilies(modelId: string, highResolutionFamilies: boolean): Promise<any> {
        const response = await axios({
            url: "/api/panel-types/create-model-revit-families",
            method: "POST",
            data: { modelId, highResolutionFamilies }
        });

        return response.data.item;
    }

    async checkModelRevitFamiliesStatus(modelId: string, highResolutionFamilies: boolean): Promise<BasicItemResponse<IRevitFamiliesGeneratingJob>> {
        try {
            const response = await axios.get<BasicItemResponse<IRevitFamiliesGeneratingJob>>(`/api/panel-types/model/${modelId}/status?highRes=${highResolutionFamilies}`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async createRevitFacadeModel(modelId: string, sharedParameterProfileId: string | null, highResolutionModel: boolean): Promise<IRevitFacadeModel> {
        const response = await axios({
            url: "/api/revit-facade-model/create",
            method: "POST",
            data: { modelId, highResolutionModel, sharedParameterProfileId, regenerateIfExists: true }
        });

        return response.data.item;
    }

    async getRevitFacadeModel(modelId: string, highResolutionModel: boolean): Promise<BasicItemResponse<IRevitFacadeModel>> {
        try {
            const resolution = highResolutionModel ? "high-res" : "low-res";

            const response = await axios.get<BasicItemResponse<IRevitFacadeModel>>(`/api/revit-facade-model/${modelId}/${resolution}/status`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async getRevitFacadeModelStatus(modelId: string, highResolutionModel: boolean): Promise<BasicItemResponse<FacadeRevitModelStatus>> {
        try {
            const resolution = highResolutionModel ? "high-res" : "low-res";

            const response = await axios.get<BasicItemResponse<FacadeRevitModelStatus>>(`/api/revit-facade-model/${modelId}/${resolution}/latest-model-status`);

            return response.data;
        } catch (e: any) {
            if (e.response?.data?.message)
                return e.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null }
        }
    }

    async exposeModelPublicly(facadeModelId: string): Promise<BasicResponse> {
        const response = await axios.post<BasicResponse>(`/api/revit-facade-model/${facadeModelId}/expose-publicly`);

        return response.data;
    }

    async loadProjects(): Promise<BasicItemsResponse<IProject>> {
        try {
            const response = await axios.get<BasicItemsResponse<IProject>>("/api/projects");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async loadEngineeringProjects(): Promise<BasicItemsResponse<IProject>> {
        try {
            const response = await axios.get<BasicItemsResponse<IProject>>("/api/projects/engineering");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async loadBidProjects(): Promise<BasicItemsResponse<IProject>> {
        try {
            const response = await axios.get<BasicItemsResponse<IProject>>("/api/projects/bid");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async createProject(project: IProject): Promise<BasicResponse> {
        const command: ICreateUpdateProjectCommand = {
            id: project.id,
            name: project.name,
            postalCode: project.postalCode,
            address: project.address,
            number: "",
            organizationName: project.organizationName,
            organizationDescription: "",
            buildingName: project.buildingName,
            author: project.author,
            margin: project.margin,
            taxRate: project.taxRate,
            duties: project.duties,
            clientName: project.clientName,
            issueDate: this.getIssueDate(project),
            location: project.location ? { ...project.location } : null,
            insulatedGlassUnitValuesPreferences: project.insulatedGlassUnitValuesPreferences,
            selectedInsulatedGlassUnitId: project.selectedInsulatedGlassUnit?.id || null,
            description: project.description,
            systemType: project.systemType
        };

        try {
            const response = await axios({
                method: "POST",
                url: "/api/projects",
                data: command
            });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updateProject(project: IProject): Promise<BasicResponse> {
        const command: ICreateUpdateProjectCommand = {
            id: project.id,
            name: project.name,
            postalCode: project.postalCode,
            address: project.address,
            number: "",
            organizationName: project.organizationName,
            organizationDescription: "",
            buildingName: project.buildingName,
            author: project.author,
            margin: project.margin,
            taxRate: project.taxRate,
            duties: project.duties,
            clientName: project.clientName,
            issueDate: this.getIssueDate(project),
            location: project.location ? { ...project.location } : null,
            insulatedGlassUnitValuesPreferences: project.insulatedGlassUnitValuesPreferences,
            selectedInsulatedGlassUnitId: project.selectedInsulatedGlassUnit?.id || null,
            description: project.description,
            systemType: project.systemType
        };

        try {
            const response = await axios({
                method: "PATCH",
                url: "/api/projects",
                data: command
            });
    
            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async removeProject(projectId: string): Promise<BasicResponse> {
        const response = await axios({
            method: "DELETE",
            url: `/api/projects/${projectId}`
        })

        return response.data;
    }

    async findWindowTemplates(): Promise<IWallWindowTemplateSource[]> {
        const response = await axios.get("/api/windows/templates");

        return response.data.items;
    }

    async findAddressByCoordinates(latitude: number, longitude: number): Promise<BasicItemResponse<IGeocodeResponse>> {
        try {
            const response = await axios.post<BasicItemResponse<IGeocodeResponse>>("https://geo.dextall.com/maps/geocode", { lat: latitude, lng: longitude });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            } else {
                return { isSuccess: false, message: "Failed to get BOM", item: null }
            }
        }
    }

    async autocompleteAddress(address: string): Promise<BasicItemResponse<IGeocodeResponse>> {
        if (address.length < 5)
            return {
                isSuccess: false,
                item: null,
                message: "address too short"
            }

        const response = await axios({
            url: "https://geo.dextall.com/maps/places",
            method: "POST",
            data: { address }
        });

        return response.data;
    }

    async findAllPanelTypes(): Promise<IPanelSourceType[]> {
        const response = await axios.get("/api/types/panels");

        return response.data.items;
    }

    async findAllCornerTypes(): Promise<IPanelSourceType[]> {
        const response = await axios.get("/api/types/corners");

        return response.data.items;
    }

    async findPanelInventorDefinition(id: string): Promise<any> {
        const response = await axios.get(`/api/panel-types-inventor-definition/${id}`);

        return response.data;
    }

    async findCornerInventorDefinition(id: string): Promise<any> {
        const response = await axios.get(`/api/corner-panel-types-inventor-definition/${id}`);

        return response.data;
    }

    async findPanelTemplates(modelId: string): Promise<IPanelTemplateSource[]> {
        const response = await axios.get(`/api/models/${modelId}/panels/templates`);

        return response.data.items;
    }

    async findCornerTemplates(): Promise<ICornerPanelTemplateSource[]> {
        const response = await axios.get("/api/corners/templates");

        return response.data.items;
    }

    async findModelBom(id: string): Promise<BasicItemResponse<IModelBom>> {
        try {
            const response = await axios.get<BasicItemResponse<IModelBom>>(`https://bom.dextall.com/api/model/${id}/bom`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            } else {
                return { isSuccess: false, message: "Failed to get BOM", item: null }
            }
        }
    }

    async findModelSystemSettings(id: string): Promise<SystemSettings> {
        const response = await axios.get<BasicItemResponse<SystemSettings>>(`/api/models/${id}/system-settings`);

        if (!response.data.isSuccess)
            throw new Error("Failed to get system settings!")

        return response.data.item;
    }

    async getExcelBom(id: string): Promise<ArrayBuffer> {
        const response = await axios.get<ArrayBuffer>(`https://bom.dextall.com/api/model/${id}/bom-excel`, {
            responseType: "arraybuffer"
        });

        return response.data;
    }

    async getBomPrices(panelTypeId: string): Promise<BasicItemResponse<IMaterial>> {
        const response = await axios.get<BasicItemResponse<IMaterial>>(`https://bom.dextall.com/api/model/${panelTypeId}/price`);

        return response.data
    }

    async getTaxesByAddress(postalCode: string): Promise<BasicItemResponse<ITaxes>> {
        const response = await axios.get<BasicItemResponse<ITaxes>>("/api/taxes");

        return response.data
    }

    async findPanelTypeBom(panelTypeId: string, metric: boolean): Promise<BasicItemResponse<IModelBom>> {
        const response = await axios.get<BasicItemResponse<IModelBom>>(
            `https://bom.dextall.com/api/panel-types/${panelTypeId}/bom?system=${metric ? "metric" : "imperial"}`);

        return response.data;
    }

    async getExcelPanelTypeBom(panelTypeId: string, metric: boolean): Promise<ArrayBuffer> {
        const response = await axios.get<ArrayBuffer>(
            `https://bom.dextall.com/api/panel-types/${panelTypeId}/bom-excel?system=${metric ? "metric" : "imperial"}`,
            { responseType: "arraybuffer" });

        return response.data;
    }

    async findCornerTypeBom(panelTypeId: string, metric: boolean): Promise<BasicItemResponse<IModelBom>> {
        const response = await axios.get<BasicItemResponse<IModelBom>>(
            `https://bom.dextall.com/api/panel-corner-types/${panelTypeId}/bom?system=${metric ? "metric" : "imperial"}`);

        return response.data;
    }

    async getExcelCornerTypeBom(panelTypeId: string, metric: boolean): Promise<ArrayBuffer> {
        const response = await axios.get<ArrayBuffer>(
            `https://bom.dextall.com/api/panel-corner-types/${panelTypeId}/bom?system=${metric ? "metric" : "imperial"}`,
            { responseType: "arraybuffer" });

        return response.data;
    }

    async confirmPanelTypeTemplateUsage(templateId: string): Promise<BasicResponse> {
        const response = await axios.patch<BasicResponse>(`/api/panels/templates/${templateId}`);

        return response.data;
    }

    async removePanelTypeTemplate(templateId: string): Promise<BasicResponse> {
        const response = await axios.delete<BasicResponse>(`/api/panels/templates/${templateId}`);

        return response.data;
    }

    async confirmCornerTypeTemplateUsage(templateId: string): Promise<BasicResponse> {
        const response = await axios.patch<BasicResponse>(`/api/corners/templates/${templateId}`);

        return response.data;
    }

    async removeCornerTypeTemplate(templateId: string): Promise<BasicResponse> {
        const response = await axios.delete<BasicResponse>(`/api/corners/templates/${templateId}`);

        return response.data;
    }

    async loadInsulatedGlassUnits(): Promise<BasicItemsResponse<IInsulatedGlassUnit>> {
        const response = await axios.get<BasicItemsResponse<IInsulatedGlassUnit>>("/api/insulated-glass-units");

        return response.data;
    }

    async findApplicationUsers(): Promise<BasicItemsResponse<IApplicationUser>> {
        try {
            const response = await axios.get<BasicItemsResponse<IApplicationUser>>("/api/users/admin/list")

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findExtendedUsers(): Promise<BasicItemsResponse<IExtendedUser>> {
        try {
            const response = await axios.get<BasicItemsResponse<IExtendedUser>>("/api/users/admin/extended-list")

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async createUser(username: string): Promise<BasicItemsResponse<IUserCredentials>> {
        let usernames = [];
        usernames.push(username)
        const command = {
            usernames
        };

        const response = await axios({
            url: `/api/users/admin/create`,
            method: "POST",
            data: command
        });

        return response.data;
    }

    async updateUsers(users: IExtendedUser[]): Promise<BasicResponse> {
        const command = {
            users
        };

        const response = await axios({
            url: "/api/users/admin/update",
            method: "PATCH",
            data: command
        })

        return response.data;
    }

    async deleteUser(id: string): Promise<BasicResponse> {
        try {
            const response = await axios.delete<BasicResponse>(`/api/users/admin/${id}`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async findSessionsWeeklyStatistics(): Promise<BasicItemsResponse<ISessionsWeeklyStatisticsItem>> {
        try {
            const response = await axios.get<BasicItemsResponse<ISessionsWeeklyStatisticsItem>>("https://stats.dextall.com/api/statistics/sessions/weekly");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findUserSessionsWeeklyStatistics(userId: string): Promise<BasicItemsResponse<ISessionsWeeklyStatisticsItem>> {
        try {
            const url = `https://stats.dextall.com/api/statistics/sessions/weekly/${userId}`;

            const response = await axios.get<BasicItemsResponse<ISessionsWeeklyStatisticsItem>>(url);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findSessionsDailyStatistics(): Promise<BasicItemsResponse<ISessionsDailyStatisticsItem>> {
        try {
            const response = await axios.get<BasicItemsResponse<ISessionsDailyStatisticsItem>>("https://stats.dextall.com/api/statistics/sessions/daily");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findUserSessionsDailyStatistics(userId: string): Promise<BasicItemsResponse<ISessionsDailyStatisticsItem>> {
        try {
            const url = `https://stats.dextall.com/api/statistics/sessions/daily/${userId}`;

            const response = await axios.get<BasicItemsResponse<ISessionsDailyStatisticsItem>>(url);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findActiveUsersWeeklyStatistics(): Promise<BasicItemsResponse<IWeeklyActiveUsersStatisticsItem>> {
        try {
            const response = await axios.get<BasicItemsResponse<IWeeklyActiveUsersStatisticsItem>>("https://stats.dextall.com/api/statistics/active-users/weekly");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findActiveUsersDailyStatistics(): Promise<BasicItemsResponse<IDailyActiveUsersStatisticsItem>> {
        try {
            const response = await axios.get<BasicItemsResponse<IDailyActiveUsersStatisticsItem>>("https://stats.dextall.com/api/statistics/active-users/daily");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelsCreationWeeklyStatistics(): Promise<BasicItemsResponse<IModelStatisticsWeeklyItem>> {
        try {
            const response = await axios.get<BasicItemsResponse<IModelStatisticsWeeklyItem>>("https://stats.dextall.com/api/statistics/model/new-uploads/weekly");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findUserModelsCreationWeeklyStatistics(userId: string): Promise<BasicItemsResponse<IModelStatisticsWeeklyItem>> {
        try {
            const url = `https://stats.dextall.com/api/statistics/model/new-uploads/weekly/${userId}`;

            const response = await axios.get<BasicItemsResponse<IModelStatisticsWeeklyItem>>(url);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelsCreationDailyStatistics(): Promise<BasicItemsResponse<IModelStatisticsDailyItem>> {
        try {
            const response = await axios.get<BasicItemsResponse<IModelStatisticsDailyItem>>("https://stats.dextall.com/api/statistics/model/new-uploads/daily")

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findUserModelsCreationDailyStatistics(userId: string): Promise<BasicItemsResponse<IModelStatisticsDailyItem>> {
        try {
            const url = `https://stats.dextall.com/api/statistics/model/new-uploads/daily/${userId}`;

            const response = await axios.get<BasicItemsResponse<IModelStatisticsDailyItem>>(url);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelVersionsCreationWeeklyStatistics(): Promise<BasicItemsResponse<IModelStatisticsWeeklyItem>> {
        try {
            const response = await axios.get<BasicItemsResponse<IModelStatisticsWeeklyItem>>("https://stats.dextall.com/api/statistics/model/new-versions/weekly");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findUserModelVersionsCreationWeeklyStatistics(userId: string): Promise<BasicItemsResponse<IModelStatisticsWeeklyItem>> {
        try {
            const url = `https://stats.dextall.com/api/statistics/model/new-versions/weekly/${userId}`;

            const response = await axios.get<BasicItemsResponse<IModelStatisticsWeeklyItem>>(url);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelVersionsCreationDailyStatistics(): Promise<BasicItemsResponse<IModelStatisticsDailyItem>> {
        try {
            const response = await axios.get<BasicItemsResponse<IModelStatisticsDailyItem>>("https://stats.dextall.com/api/statistics/model/new-versions/daily")

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findUserModelVersionsCreationDailyStatistics(userId: string): Promise<BasicItemsResponse<IModelStatisticsDailyItem>> {
        try {
            const url = `https://stats.dextall.com/api/statistics/model/new-versions/daily/${userId}`;

            const response = await axios.get<BasicItemsResponse<IModelStatisticsDailyItem>>(url);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findActiveUsersStatistics(): Promise<BasicItemsResponse<IActiveUsersStatisticsItem>> {
        try {
            const response = await axios.get<BasicItemsResponse<IActiveUsersStatisticsItem>>("https://stats.dextall.com/api/statistics/active-users/content");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findUsersCreationWeeklyStatistics(): Promise<BasicItemsResponse<IUserCreationStatisticsWeeklyItem>> {
        try {
            const response = await axios.get<BasicItemsResponse<IUserCreationStatisticsWeeklyItem>>("https://stats.dextall.com/api/statistics/created-users/weekly");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findUsersCreationDailyStatistics(): Promise<BasicItemsResponse<IUserCreationStatisticsDailyItem>> {
        try {
            const response = await axios.get<BasicItemsResponse<IUserCreationStatisticsDailyItem>>("https://stats.dextall.com/api/statistics/created-users/daily");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelDemosWeeklyStatistics(): Promise<BasicItemsResponse<IModelStatisticsWeeklyItem>> {
        try {
            const response = await axios.get<BasicItemsResponse<IModelStatisticsWeeklyItem>>("https://stats.dextall.com/api/statistics/model-demos/weekly");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelDemosDailyStatistics(): Promise<BasicItemsResponse<IModelStatisticsDailyItem>> {
        try {
            const response = await axios.get<BasicItemsResponse<IModelStatisticsDailyItem>>("https://stats.dextall.com/api/statistics/model-demos/daily")

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async pushModelToEngineeringService(modelId: string): Promise<BasicResponse> {
        try {
            const response = await axios.post<BasicResponse>(`/api/models/${modelId}/push-to-engineering`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async loadApplicationMetadata(): Promise<IApplicationMetadata> {
        const response = await axios.get<IApplicationMetadata>("/metadata.json");

        return response.data;
    }

    async loadUserSharedParametersProfiles(): Promise<BasicItemsResponse<RevitFacadeModelSharedParametersProfile>> {
        try {
            const response = await axios.get<BasicItemsResponse<RevitFacadeModelSharedParametersProfile>>("/api/facade-models/shared-parameter-profile");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async createUserSharedParametersProfile(command: CreateRevitFacadeModelSharedParametersProfileCommand): Promise<BasicItemResponse<string>> {
        try {
            const response = await axios.post<BasicItemResponse<string>>("/api/facade-models/shared-parameter-profile", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async updateUserSharedParametersProfile(sharedParametersProfile: RevitFacadeModelSharedParametersProfile): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>("/api/facade-models/shared-parameter-profile", sharedParametersProfile);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async createLinkedModel(request: UploadLinkedModelFileRequest): Promise<BasicItemResponse<ILinkedModel>> {
        const fileName = request.file.name;

        let extension: LinkedModelExtension | undefined = undefined;

        if (fileName.toLowerCase().endsWith(".rvt"))
            extension = "rvt";
        else if (fileName.toLowerCase().endsWith(".ifc"))
            extension = "ifc";

        if (!extension)
            return { isSuccess: false, message: "Invalid file extension!", item: null };

        const storage = await this.uploadFileToBucket(request, extension);

        if (!storage.isSuccess)
            return storage;

        const command: CreateLinkedModelCommand = { fileName, objectKey: storage.item.objectKey }

        try {
            const response = await axios.post<BasicItemResponse<ILinkedModel>>(`/api/models/${request.modelId}/create-linked-model`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findLinkedModels(modelId: string): Promise<BasicItemsResponse<ILinkedModel>> {
        try {
            const response = await axios.get<BasicItemsResponse<ILinkedModel>>(`/api/models/${modelId}/linked-model`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findLinkedModelsByIds(ids: string[]): Promise<BasicItemsResponse<ILinkedModel>> {
        try {
            const response = await axios.post<BasicItemsResponse<ILinkedModel>>("/api/linked-models/search-by-ids", { ids });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findProjectPrimaryLinkedModels(projectId: string): Promise<BasicItemsResponse<ILinkedModel>> {
        try {
            const response = await axios.get<BasicItemsResponse<ILinkedModel>>(`/api/projects/${projectId}/primary-linked-models`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async assignPrimaryLinkedModel(command: AssignLinkedModelCommand): Promise<BasicItemResponse<ILinkedModel>> {
        try {
            const response = await axios.post<BasicItemResponse<ILinkedModel>>("/api/linked-models/assign", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async removeLinkedModel(id: string): Promise<BasicResponse> {
        try {
            const response = await axios.delete<BasicResponse>(`/api/linked-models/${id}`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updateLinkedModelOrigin(id: string, origin: THREE.Vector3): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/linked-models/${id}/origin`, origin);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async uploadCommentAttachment(file: File): Promise<BasicItemResponse<IPanelCommentAttachment>> {
        try {
            type CommentStorage = {
                uploadFileUrl: string;
                downloadUrl: string;
            }

            const fileName = file.name;

            const createStorageResponse = await axios.post<BasicItemResponse<CommentStorage>>("/api/comments/create-attachment-storage", { fileName });

            if (!createStorageResponse.data.isSuccess)
                return {
                    isSuccess: false,
                    message: createStorageResponse.data.message,
                    item: null
                }

            const storage = createStorageResponse.data.item;

            await axios({
                url: storage.uploadFileUrl,
                method: "put",
                data: file,
                headers: {
                    "Content-Disposition": "attachment; filename=\"" + encodeURIComponent(fileName) + "\""
                }
            });

            return {
                isSuccess: true,
                message: null,
                item: {
                    downloadUrl: storage.downloadUrl,
                    fileName
                }
            };
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    getAccessToken(): string | null {
        return this._accessToken;
    }

    setAccessToken(value: string) {
        this._accessToken = value;
        axios.defaults.headers.common[AuthorizationHeader] = `Bearer ${value}`;
    }

    forgetAccessToken() {
        delete axios.defaults.headers.common[AuthorizationHeader];
        this._accessToken = null;
    }

    hasAccessToken() { return !!this._accessToken; }

    private getIssueDate = (project: IProject) => {
        if (project.issueDate instanceof Date)
            return project.issueDate.toISOString();

        if (typeof project.issueDate === "string" && project.issueDate !== "")
            return project.issueDate;

        return null;
    }
}

export default new Repository();