import React from "react";
import Calendar from "react-calendar";
import { Calendar as CalendarIcon } from "react-feather";
import { colors } from "../config/colors";
import { ProjectProperty } from "./projectProperty";
import { ProjectPropertyPreview } from "./projectPropertyPreview";
import "react-calendar/dist/Calendar.css";

interface IComponentProps {
    value: Date | string | null | undefined;
    setValue: (newDate: Date) => void;
    title: string;
    isEditMode: boolean;
    style?: React.CSSProperties
}

export const CalendarEditorField = (props: IComponentProps) => {
    const projectDate = props.value && new Date(props.value);

    return (<ProjectProperty 
        style={props.style}
        header={{
            title: "Issue Date",
            children: <CalendarIcon size={12} stroke={colors.subheading} strokeWidth={3} />
        }}
        isEditMode={props.isEditMode}
        edit={<Calendar onChange={(x: any) => props.setValue(new Date(Date.UTC(x.getFullYear(), x.getMonth(), x.getDate())))} value={projectDate || new Date()} />}
        preview={<ProjectPropertyPreview value={projectDate instanceof Date ? projectDate.toLocaleDateString() : ""}/>}
    />)
}