import React from "react";

const searchInputContainer: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    border: "1.5px solid #c4c4c4",
    borderRadius: "18px",
    padding: "5px",
    paddingTop: "3px",
    paddingBottom: "3px",
    background: "white",
    marginBottom: "10px",
    marginTop: "20px",
    width: "97px",
    transition: 'border-color 0.3s'
};

const searchIcon: React.CSSProperties = {
    marginLeft: "10px", 
    color: "black",
    fontSize: "15px",
    paddingRight: "1px"
};

const adminPanelSearchIcon: React.CSSProperties = {
    marginLeft: "10px", 
    color: "white",
    fontSize: "10px",
    backgroundColor: "#304E49",
    borderRadius: "20px",
    padding: "2px 3px"
};

const inputField: React.CSSProperties = {
    border: "none",
    flex: 1,
    paddingLeft: "5px",
    width: "100%",
    fontSize: "13px"
};

const adminPanel: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    background: "white",
    transition: 'border-color 0.3s',
    paddingLeft: '7px',
    border: '1.5px solid #white',
    borderRadius: '20px',
    width: '240px', 
    marginLeft: "20px",
};

export const styles = {
    searchInputContainer,
    searchIcon,
    adminPanelSearchIcon,
    inputField,
    adminPanel
};
