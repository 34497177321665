import repo from '../Repository';
import { addError, addLog } from './notificationActions';
import { showProcessingPane, reportProcessingProgress, showProcessingError } from "./processingActions";
import { getModels, getSelectedModel, getSelectedModelVersion } from "../reducers/modelsReducer";
import { getCurrentProject } from "../reducers/projectsReducer";
import { ActionDispatch, ActionGetState } from './common';
import { IEngineeringProductionModel, IModel3D, ModelTranslationStatus } from '../components/model3d';
import { AxiosError } from 'axios';
import { BasicItemResponse, BasicItemsResponse, BasicResponse } from '../components/project';
import { ApplicationType } from '../applicationType';

type SetModelsType = {
    type: "SET_SOURCE_MODELS";
    models: IModel3D[];
}

type SelectModelType = {
    type: "SET_SELECTED_MODEL";
    modelId: string;
    versionIndex?: number;
}

type SetModelForgePercentage = {
    type: "SET_MODEL_FORGE_PERCENTAGE";
    modelId: string;
    percentage: number;
    forgeStatus: string | null;
}

type SetModelCompletedType = {
    type: "SET_MODEL_COMPLETED";
    modelId: string;
}

type SetModelFailedType = {
    type: "SET_MODEL_FAILED";
    modelId: string;
    forgeStatus: string;
}

type ToggleHighLodModeType = {
    type: "TOGGLE_HIGH_LOD_MODE";
    mode: boolean;
}

type SelectPreviousModelVersionType = {
    type: "SET_PREVIOUS_MODEL_VERSION_SELECTED"
}

type SelectNextModelVersionType = {
    type: "SET_NEXT_MODEL_VERSION_SELECTED"
}

type RemoveSelectedModelType = {
    type: "REMOVE_CURRENT_MODEL"
}

type SetCurrentModelVersionOnReviewType = {
    type: "SET_MODEL_VERSION_SUBMITTED"
}

type SetCurrentModelVersionOnBidType = {
    type: "SET_MODEL_VERSION_BID"
}

type SetCurrentModelVersionOnApproveType = {
    type: "SET_MODEL_VERSION_APPROVED"
}

type RemoveSelectedModelVersionType = {
    type: "REMOVE_CURRENT_MODEL_VERSION"
}

type SelectCurrentModelVersionIndexType = {
    type: "SET_CURRENT_MODEL_SELECTED_VERSION_INDEX",
    versionIndex: number;
}

type SetVersionPinType = {
    type: "SET_CURRENT_MODEL_SELECTED_VERSION_PIN";
    value: boolean;
}

type SetSelectedModelDownloadUrlType = {
    type: "SET_SELECTED_MODEL_DOWNLOAD_URL";
    value: string
}

type SetEngineeringProductionModelsType = {
    type: "SET_ENGINEERING_PRODUCTION_MODELS";
    models: IEngineeringProductionModel[];
}

type SetSelectedEngineeringProductionModel = {
    type: "SET_SELECTED_ENGINEERING_PRODUCTION_MODEL";
    modelId: string;
}

type SetModelVersionNameType = {
    type: "SET_MODEL_VERSION_NAME";
    versionId: string;
    newName: string;
};

type SetModelsLoadingType = {
    type: "SET_MODELS_LOADING";
    isLoading: boolean;
};

type SetEngineeringModelsLoadingType = {
    type: "SET_ENGINEERING_MODELS_LOADING";
    isLoading: boolean;
};

type SetModelNameType = {
    type: "SET_MODEL_NAME";
    modelId: string;
    newName: string;
};

export type SourceRevitModelActionType = SetModelsType | SelectModelType | SetModelForgePercentage
    | SetModelCompletedType | SetModelFailedType | ToggleHighLodModeType | SelectPreviousModelVersionType
    | SelectNextModelVersionType | RemoveSelectedModelType | RemoveSelectedModelVersionType
    | SetCurrentModelVersionOnReviewType | SetCurrentModelVersionOnBidType | SelectCurrentModelVersionIndexType
    | SetVersionPinType | SetCurrentModelVersionOnApproveType | SetSelectedModelDownloadUrlType
    | SetEngineeringProductionModelsType | SetSelectedEngineeringProductionModel | SetModelVersionNameType 
    | SetModelsLoadingType | SetEngineeringModelsLoadingType | SetModelNameType;

const fetchProductionEnginneringModels = async (projectId: string, dispatch: ActionDispatch) => {
    dispatch(setEngineeringModelsLoading(true));

    const engineeringProductionModels = await repo.loadProductionEnginneringModels(projectId);

    if (!engineeringProductionModels.isSuccess) {
        console.error(`Failed to fetch engineering production models: ${engineeringProductionModels.message}`);
        dispatch(setEngineeringModelsLoading(false));
        return;
    }

    dispatch(setEngineeringProductionModels(engineeringProductionModels.items));

    dispatch(setEngineeringModelsLoading(false));

    if (engineeringProductionModels.items.length > 0) {
        dispatch(setSelectedEngineeringProductionModel(engineeringProductionModels.items[0].id));
    }}

export const fetchModels = (applicationType: ApplicationType, projectId: string, openModelId?: string, openModelVersionIndex?: number) =>
    async (dispatch: ActionDispatch, getState: ActionGetState) => {
        dispatch(setModelsLoading(true));

        dispatch(addLog('Fetch models invoked'));

        dispatch(setModels([]));
        dispatch(setEngineeringProductionModels([]));

        let response: BasicItemsResponse<IModel3D>;

        switch (applicationType) {
            case "en":
                response = await repo.loadEngineerModels(projectId);
                break;

            case "bid":
                response = await repo.loadBidModels(projectId);
                break;

            default:
                response = await repo.loadModels(projectId);
                break;
        }

        if (!response.isSuccess) {
            showProcessingError("Error", response.message);
            dispatch(setModelsLoading(false));
            return;
        }

        const currentProject = getCurrentProject(getState().projects);

        if (currentProject?.id !== projectId)
            return;

        dispatch(setModels(response.items));

        dispatch(setModelsLoading(false));

        if (openModelId && response.items.find(x => x.id === openModelId)) {
            dispatch(selectModel(openModelId, openModelVersionIndex));
            return;
        }

        if (response.items.length > 0)
            dispatch(selectModel(response.items[0].id));

        if (applicationType === "en")
            await fetchProductionEnginneringModels(projectId, dispatch);
    }

export const updateModelStatuses = (unprocessedModels: string[]) => async (dispatch: ActionDispatch, getState: ActionGetState) => {
    try {
        const models = await repo.findModelsByIds(unprocessedModels);

        const modelPercentages = new Map();
        const completedModels = new Set();
        const failedModels = new Map();

        for (let i = 0; i < models.length; ++i) {
            const model = models[i];

            modelPercentages.set(model.id, { forgePercentage: model.forgePercentage, forgeStatus: model.forgeStatus });

            if (model.status === ModelTranslationStatus.Ready)
                completedModels.add(model.id);

            if (model.status === ModelTranslationStatus.Failed)
                failedModels.set(model.id, model.forgeStatus);
        }

        const currentModels = getModels(getState().models);

        for (let i = 0; i < currentModels.length; ++i) {
            const model = currentModels[i].versions[0];

            if (modelPercentages.has(model.id)) {
                const { forgePercentage, forgeStatus } = modelPercentages.get(model.id);

                if (forgePercentage !== model.forgePercentage || forgeStatus !== model.forgeStatus)
                    dispatch(setModelForgePercentage(model.id, forgePercentage, forgeStatus));
            }

            if (completedModels.has(model.id))
                dispatch(setModelCompleted(model.id));

            if (failedModels.has(model.id))
                dispatch(setModelFailed(model.id, failedModels.get(model.id)));
        }
    } catch (error) {
        console.error("Error in SourceRevitModelsActions.updateModelStatuses", error);
        dispatch(addError('Failed to fetch models. (' + error + ')'));
    }
}

export const createNewModelVersion = (applicationType: ApplicationType, modelId: string) => async (dispatch: ActionDispatch, getState: ActionGetState) => {
    dispatch(showProcessingPane(true, "New model design option..."));
    dispatch(reportProcessingProgress("Creating a new model design option"));

    let createNewModelVersionResponse;

    try {
        createNewModelVersionResponse = applicationType === "en" ? await repo.createNewModelEngineerVersion(modelId) : await repo.createNewModelVersion(modelId);
    } catch (e: AxiosError | any) {
        if (e instanceof AxiosError && e.response?.data?.message)
            createNewModelVersionResponse = e.response.data;
        else {
            dispatch(showProcessingPane(false));
            dispatch(showProcessingError("Server error...", "Server error occured. Repeat operation later"));

            return;
        }
    }

    dispatch(showProcessingPane(false));

    if (!createNewModelVersionResponse.isSuccess) {
        dispatch(showProcessingError("Error", createNewModelVersionResponse.message));

        return;
    }

    const state = getState();

    const project = getCurrentProject(state.projects);

    const currentModel = getSelectedModel(state.models);

    dispatch(fetchModels(applicationType, project!.id, currentModel!.id, 0));
}

export const createNewModelFromBidModel = (sourceModelId: string, targetProjectId: string) => async (dispatch: ActionDispatch, _: ActionGetState) => {
    dispatch(showProcessingPane(true, "Creating a new model..."));
    dispatch(reportProcessingProgress("Creating a new model based on BID model"));

    const response = await repo.createNewBidModelVersion(sourceModelId, targetProjectId);

    dispatch(showProcessingPane(false));

    if (!response.isSuccess) {
        dispatch(showProcessingError("Error", response.message));

        return;
    }
}

export const removeCurrentModel = () => async (dispatch: ActionDispatch, getState: ActionGetState) => {
    const currentModel = getSelectedModel(getState().models);

    dispatch(showProcessingPane(true, "Deleting the model..."));

    let removeModelResponse: BasicResponse;

    try {
        removeModelResponse = await repo.removeModel(currentModel!.id);
    } catch (e: AxiosError | any) {
        if (e instanceof AxiosError && e.response?.data?.message)
            removeModelResponse = e.response.data;
        else
            removeModelResponse = { isSuccess: false, message: "Server error occured. Repeat operation later" };
    }

    dispatch(showProcessingPane(false));

    if (!removeModelResponse.isSuccess) {
        dispatch(showProcessingError("Error", removeModelResponse.message));

        return;
    }

    dispatch(removeSelectedModel());
}

export const removeCurrentModelVersion = () => async (dispatch: ActionDispatch, getState: ActionGetState) => {
    dispatch(showProcessingPane(true, "Deleting model option..."));

    const currentModelVersion = getSelectedModelVersion(getState().models);

    let removeModelVersionResponse: BasicResponse;

    try {
        removeModelVersionResponse = await repo.removeModelVersion(currentModelVersion!.id);
    } catch (e: AxiosError | any) {
        if (e instanceof AxiosError && e.response?.data?.message)
            removeModelVersionResponse = e.response.data;
        else
            removeModelVersionResponse = { isSuccess: false, message: "Server error occured. Repeat operation later" };
    }

    dispatch(showProcessingPane(false));

    if (!removeModelVersionResponse.isSuccess) {
        dispatch(showProcessingError("Error", removeModelVersionResponse.message));

        return;
    }

    dispatch(removeSelectedModelVersion());
}

export const submitCurrentModelToReview = () => async (dispatch: ActionDispatch, getState: ActionGetState) => {
    const model = getSelectedModelVersion(getState().models)!;

    dispatch(showProcessingPane(true, "Sending the model for review..."));

    const sendModelToReviewResponse = await repo.submitModelToReview(model.id);

    dispatch(showProcessingPane(false));

    if (!sendModelToReviewResponse.isSuccess) {
        dispatch(showProcessingError("Error", sendModelToReviewResponse.message));

        return;
    }

    dispatch(setCurrentModelVersionOnReview());
}

export const submitModelToBid = () => async (dispatch: ActionDispatch, getState: ActionGetState) => {
    const model = getSelectedModelVersion(getState().models)!;

    dispatch(showProcessingPane(true, "Sending the model to BID..."));

    const response = await repo.submitModelToBid(model.id);

    dispatch(showProcessingPane(false));

    if (!response.isSuccess) {
        dispatch(showProcessingError("Error", response.message));

        return;
    }

    dispatch(setCurrentModelVersionOnBid());
}

export const submitCurrentModelToApprove = (comment: string) => async (dispatch: ActionDispatch, getState: ActionGetState) => {
    const model = getSelectedModelVersion(getState().models);

    dispatch(showProcessingPane(true, "Approving the model..."));

    let sendModelToReviewResponse;

    try {
        sendModelToReviewResponse = await repo.submitModelToApprove(model!.id, comment);
    } catch (e: AxiosError | any) {
        if (e instanceof AxiosError && e.response?.data?.message)
            sendModelToReviewResponse = e.response.data;
        else {
            dispatch(showProcessingPane(false));
            dispatch(showProcessingError("Server error...", "Server error occured. Repeat operation later"));

            return;
        }
    }

    dispatch(showProcessingPane(false));

    if (!sendModelToReviewResponse.isSuccess) {
        dispatch(showProcessingError("Error", sendModelToReviewResponse.message));

        return;
    }

    dispatch(setCurrentModelVersionOnApprove());
}

export const pinSelectedModelVersion = () => async (dispatch: ActionDispatch, getState: ActionGetState) => {
    const state = getState();

    const currentModel = getSelectedModel(state.models);
    const selectedModelVersion = getSelectedModelVersion(state.models);

    if (!(currentModel && selectedModelVersion))
        return;

    const newPinState = !selectedModelVersion.isMainOption;

    dispatch(showProcessingPane(true, "Setting model main option..."));

    let pinModelVersionResponse: BasicResponse;

    try {
        pinModelVersionResponse = await repo.pinModelVersion(currentModel.id, selectedModelVersion.id, newPinState);
    } catch (e: AxiosError | any) {
        if (e instanceof AxiosError && e.response?.data?.message)
            pinModelVersionResponse = e.response.data;
        else
            pinModelVersionResponse = { isSuccess: false, message: "Server error occured. Repeat operation later" };
    }

    dispatch(showProcessingPane(false));

    if (!pinModelVersionResponse.isSuccess) {
        dispatch(showProcessingError("Error", pinModelVersionResponse.message));

        return;
    }

    dispatch(setVersionPin(newPinState));
}

export const prepareSelectedModelDownloadUrl = () => async (dispatch: ActionDispatch, getState: ActionGetState) => {
    const state = getState();

    const selectedModelVersion = getSelectedModelVersion(state.models);

    if (!selectedModelVersion)
        return;

    dispatch(showProcessingPane(true, "Downloading..."));

    let response: BasicItemResponse<string>;

    try {
        response = await repo.getModelDownloadableUrl(selectedModelVersion.id);
    } catch (e) {
        if (e instanceof AxiosError && e.response?.data?.message)
            response = e.response.data;
        else
            response = { isSuccess: false, message: "Server error occured. Repeat operation later", item: null }
    }

    dispatch(showProcessingPane(false));

    if (!response.isSuccess) {
        dispatch(showProcessingError("Error", response.message));

        return;
    }

    dispatch(setSelectedModelDownloadUrl(response.item));
}

export const pushCurrentModelToEngineeringService = () => async (dispatch: ActionDispatch, getState: ActionGetState) => {
    const model = getSelectedModelVersion(getState().models);

    if (!model)
        return;

    dispatch(showProcessingPane(true, "Pushing to the engineering..."));

    const response = await repo.pushModelToEngineeringService(model.id);

    dispatch(showProcessingPane(false));

    if (!response.isSuccess) {
        dispatch(showProcessingError("Error", response.message));
        return;
    }

    const project = getCurrentProject(getState().projects);

    await fetchProductionEnginneringModels(project!.id, dispatch);
}

export const setModels = (models: IModel3D[]): SetModelsType => {
    return {
        type: "SET_SOURCE_MODELS",
        models
    }
}

export const selectModel = (modelId: string, versionIndex?: number): SelectModelType => {
    return {
        type: "SET_SELECTED_MODEL",
        modelId,
        versionIndex
    }
}

export const setModelForgePercentage = (modelId: string, percentage: number, forgeStatus: string | null): SetModelForgePercentage => {
    return {
        type: "SET_MODEL_FORGE_PERCENTAGE",
        modelId,
        percentage,
        forgeStatus
    }
}

export const setModelCompleted = (modelId: string): SetModelCompletedType => {
    return {
        type: "SET_MODEL_COMPLETED",
        modelId
    }
}

export const setModelFailed = (modelId: string, forgeStatus: string): SetModelFailedType => {
    return {
        type: "SET_MODEL_FAILED",
        modelId,
        forgeStatus
    }
}

export const toggleHighLodMode = (mode: boolean): ToggleHighLodModeType => {
    return {
        type: "TOGGLE_HIGH_LOD_MODE",
        mode
    }
}

export const removeSelectedModel = (): RemoveSelectedModelType => {
    return {
        type: "REMOVE_CURRENT_MODEL"
    }
}

export const removeSelectedModelVersion = (): RemoveSelectedModelVersionType => {
    return {
        type: "REMOVE_CURRENT_MODEL_VERSION"
    }
}

export const setCurrentModelVersionOnReview = (): SetCurrentModelVersionOnReviewType => {
    return {
        type: "SET_MODEL_VERSION_SUBMITTED"
    }
}

export const setCurrentModelVersionOnApprove = (): SetCurrentModelVersionOnApproveType => {
    return {
        type: "SET_MODEL_VERSION_APPROVED"
    }
}

export const setCurrentModelVersionOnBid = (): SetCurrentModelVersionOnBidType => {
    return {
        type: "SET_MODEL_VERSION_BID"
    }
}

export const selectCurrentModelVersionIndex = (versionIndex: number): SelectCurrentModelVersionIndexType => {
    return {
        type: "SET_CURRENT_MODEL_SELECTED_VERSION_INDEX",
        versionIndex
    }
}

export const setVersionPin = (value: boolean): SetVersionPinType => {
    return {
        type: "SET_CURRENT_MODEL_SELECTED_VERSION_PIN",
        value
    }
}

export const setSelectedModelDownloadUrl = (value: string): SetSelectedModelDownloadUrlType => {
    return {
        type: "SET_SELECTED_MODEL_DOWNLOAD_URL",
        value
    }
}

export const setEngineeringProductionModels = (models: IEngineeringProductionModel[]): SetEngineeringProductionModelsType => {
    return {
        type: "SET_ENGINEERING_PRODUCTION_MODELS",
        models
    }
}

export const setSelectedEngineeringProductionModel = (modelId: string): SetSelectedEngineeringProductionModel => {
    return {
        type: "SET_SELECTED_ENGINEERING_PRODUCTION_MODEL",
        modelId
    }
}

export const setModelVersionName = (versionId: string, newName: string): SetModelVersionNameType => {
    return {
        type: "SET_MODEL_VERSION_NAME",
        versionId,
        newName
    };
};

export const setModelsLoading = (isLoading: boolean): SetModelsLoadingType => {
    return {
        type: "SET_MODELS_LOADING",
        isLoading
    };
};

export const setEngineeringModelsLoading = (isLoading: boolean): SetEngineeringModelsLoadingType => {
    return {
        type: "SET_ENGINEERING_MODELS_LOADING",
        isLoading
    };
};

export const setModelName = (modelId: string, newName: string): SetModelNameType => {
    return {
        type: "SET_MODEL_NAME",
        modelId,
        newName
    };
};