export class UserObjectIds {
    private readonly freeIds = new Set<number>();
    private highestId: number = 0;

    occupyId(id: number) {
        if (this.highestId < id) {
            for (let i = this.highestId + 1; i <= id; ++i)
                this.freeIds.add(i);

            this.highestId = id;
        }

        this.freeIds.delete(id);
    }

    releaseId(id: number) {
        this.freeIds.add(id);
    }

    getNewId() {
        const nextFreeIdResult = this.freeIds.values().next();

        if (!nextFreeIdResult.done) {
            const id = nextFreeIdResult.value;

            this.freeIds.delete(id);

            return id;
        }

        const id = this.highestId + 1;
        this.highestId = id;

        return id;
    }

    reset() {
        this.freeIds.clear();
        this.highestId = 0;
    }
}

export type UserUniqueIdObjectPrefix = "DXP-" | "DXC-" | "DXW-";

export const getPanelUserUniqueIdObjectPrefix = (): UserUniqueIdObjectPrefix => "DXP-";
export const getCornerUserUniqueIdObjectPrefix = (): UserUniqueIdObjectPrefix => "DXC-";
export const getWindowUserUniqueIdObjectPrefix = (): UserUniqueIdObjectPrefix => "DXW-";

const panelUserIds = new UserObjectIds();
const cornerUserIds = new UserObjectIds();
const windowUserIds = new UserObjectIds();

export default { panelUserIds, cornerUserIds, windowUserIds }