import React, { useEffect, useState } from "react"
import { Chart } from "react-google-charts";
import { IUserCreationStatisticsWeeklyItem } from "../appStatistics";

type Props = {
    usersStatistics: IUserCreationStatisticsWeeklyItem[];
    chartContainerStyle?: React.CSSProperties;
    cumulative: boolean;
}

type ChartHeader = ["Interval", "Created external users", "Created Dextall users"];
type ChartData = [serie: string, newExternalUsers: number, newInternalUsers: number];
type ChartSource = [ChartHeader, ...ChartData[]]

export const WeeklyUsersCreationStatistics = ({ usersStatistics, chartContainerStyle, cumulative }: Props) => {
    const chartHeader: ChartHeader = ["Interval", "Created external users", "Created Dextall users"];
    const [chartSource, setChartSource] = useState<ChartSource>([chartHeader]);

    useEffect(() => {
        const data: ChartSource = [chartHeader];

        let cumulativeExternalCount = 0;
        let cumulativeInternalCount = 0;

        for (const stat of usersStatistics) {
            data.push([
                `${new Date(stat.start).toLocaleDateString()} - ${new Date(stat.end).toLocaleDateString()}`,
                stat.externalCount + cumulativeExternalCount,
                stat.internalCount + cumulativeInternalCount
            ]);

            cumulativeExternalCount += cumulative ? stat.externalCount : 0;
            cumulativeInternalCount += cumulative ? stat.internalCount : 0;
        }

        setChartSource(data);
    }, [usersStatistics, cumulative])

    return <div style={{ ...chartContainerStyle }}>
        <Chart
            chartType="SteppedAreaChart"
            width="100%"
            height="400px"
            data={chartSource}
            options={{
                title: cumulative ? "Users count" : "New users",
                legend: { position: "top", maxLines: 3 },
                isStacked: true
            }} />
    </div>
}