import React, { useEffect, useState } from "react"
import { Chart } from "react-google-charts";
import { IDailyActiveUsersStatisticsItem, ISessionsDailyStatisticsItem } from "../appStatistics";

type Props = {
    dailySessions: ISessionsDailyStatisticsItem[];
    activeUsersDaily: IDailyActiveUsersStatisticsItem[];
    chartContainerStyle?: React.CSSProperties;
}

type ChartHeader = [{ type: "date" }, "Sessions count", "Active users"];
type ChartData = [serie: Date, sessionsCount: number, usersCount: number];
type ChartSource = [ChartHeader, ...ChartData[]];

export const DailySessionsStatistics = ({ dailySessions, activeUsersDaily, chartContainerStyle }: Props) => {
    const chartHeader: ChartHeader = [{ type: "date" }, "Sessions count", "Active users"];

    const [chartSource, setChartSource] = useState<ChartSource>([chartHeader]);

    useEffect(() => {
        const data: ChartSource = [chartHeader];

        for (let i = 0; i < dailySessions.length; i++) {
            const sessions = dailySessions[i];
            const activeUsers = activeUsersDaily[i];

            data.push([
                new Date(sessions.date),
                sessions.sessionsCount,
                activeUsers.usersCount
            ]);
        }

        setChartSource(data);
    }, [dailySessions, activeUsersDaily]);

    return <div style={{...chartContainerStyle}}>
        <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={chartSource}
            options={{
                title: "Sessions",
                legend: { position: "top", maxLines: 3 }
            }} />
    </div>
}