import React, { useState } from 'react';
import { IExtendedUser } from "../appStatistics";
import { Filter } from "react-feather";
import SearchBar from '../searchBar';
import { roleKeys } from '../userRolesConfig';
import styles from '../../assets/adminPanelStyles';
import { UserTable } from './usersTable';
import { CreateUserModal } from './createUserModal';
import { EditUserRolesModal } from './editUserModal';
import { ConfirmDialog } from '../confirmDialog';
import { Alert, AlertColor, Collapse, IconButton, Box } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import FilterPanel from './filterPanel';
import repo from "../../Repository";

type UsersWindowProps = {
    users: IExtendedUser[];
}

export const UsersWindow = ({ users }: UsersWindowProps) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [usersList, setUsersList] = useState<IExtendedUser[]>(users);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterPanelVisible, setFilterPanelVisible] = useState(false);
    const [roleFilters, setRoleFilters] = useState<{ [key in keyof IExtendedUser]?: boolean }>({});
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const [userToEdit, setUserToEdit] = useState<IExtendedUser | null>(null);
    const [selectedUserForDeletion, setSelectedUserForDeletion] = useState<IExtendedUser | null>(null);
    const [confirmDeleteUserDialogVisible, setConfirmDeleteUserDialogVisible] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState<AlertColor>("success");

    const filteredUsers = usersList.filter(user => {
        const matchesSearch = user.name.toLowerCase().includes(searchTerm.toLowerCase());
        const isAnyFilterActive = Object.values(roleFilters).some(Boolean);

        const matchesRoles = isAnyFilterActive
            ? roleKeys.every(role => {
                return roleFilters[role as keyof IExtendedUser] ? user[role as keyof IExtendedUser] : true;
            })
            : true;

        const includeCustomer = !isAnyFilterActive && !user.dextallEmployee;

        return matchesSearch && (matchesRoles || includeCustomer);
    });

    const handleFilterClick = () => {
        setFilterPanelVisible(prev => !prev);
    };

    const handleEditUserClick = (user: IExtendedUser) => {
        setUserToEdit(user);
        setIsEditModalOpen(true);
    };

    const handleSaveRoles = async (updatedUserRoles: IExtendedUser) => {
        try {
            const response = await repo.updateUsers([updatedUserRoles]);
            if (response.isSuccess) {
                setUsersList(usersList.map(user => {
                    if (user.id === updatedUserRoles.id) {
                        return updatedUserRoles;
                    }
                    return user;
                }));
            } else {
                console.error("Failed to update user roles:", response.message);
            }
        } catch (error) {
            console.error("An exception occurred while updating user roles:", error);
        }

        setUsersList(currentUsersList =>
            currentUsersList.map(user =>
                user.id === updatedUserRoles.id ? updatedUserRoles : user
            )
        );
    };

    const handleCloseEditModal = () => {
        setUserToEdit(null);
        setIsEditModalOpen(false);
    };

    const handleDeleteClick = async (user: IExtendedUser) => {
        setSelectedUserForDeletion(user);
        setConfirmDeleteUserDialogVisible(true);
    };

    const handleConfirmDelete = async () => {
        if (selectedUserForDeletion) {
            try {
                const deleteResponse = await repo.deleteUser(selectedUserForDeletion.id);

                if (deleteResponse.isSuccess) {
                    setUsersList(currentList =>
                        currentList.map(user =>
                            user.id === selectedUserForDeletion.id ? { ...user, locked: true } : user
                        )
                    );
                    setAlertMessage("User successfully deleted");
                    setAlertSeverity("success");
                } else {
                    console.error("Failed to delete user:", deleteResponse.message);
                    setAlertMessage("Failed to delete user: " + selectedUserForDeletion.name);
                    setAlertSeverity("error");
                }
            } catch (error) {
                console.error("An exception occurred while deleting user:", error);
                setAlertMessage("An error occurred while deleting user");
                setAlertSeverity("error");
            }

            setSelectedUserForDeletion(null);
            setConfirmDeleteUserDialogVisible(false);
            setAlertOpen(true);
        }
    };

    const ResponseAlert = () => (
        <Box sx={{ width: '100%' }}>
            <Collapse in={alertOpen}>
                <Alert
                    severity={alertSeverity}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => setAlertOpen(false)}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {alertMessage}
                </Alert>
            </Collapse>
        </Box>
    );

    const onModalClose = async () => {
        const response = await repo.findExtendedUsers();

        if (response.isSuccess)
            setUsersList(response.items);
        else
            console.error(`Failed to fetch extended users: ${response.message}`);
    }

    return (
        <div>
            <div style={styles.navbar}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SearchBar searchQuery={searchTerm} setSearchQuery={setSearchTerm} isAdminPanel={true} />
                    <Filter size={20} style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={handleFilterClick} />
                </div>
                {filterPanelVisible && (
                    <FilterPanel roleFilters={roleFilters} setRoleFilters={setRoleFilters} />
                )}
                <button style={styles.addButton} onClick={() => setModalOpen(true)}>Add User</button>
            </div>
            <UserTable
                users={filteredUsers}
                onEditClick={handleEditUserClick}
                onDeleteClick={(user) => { handleDeleteClick(user); setConfirmDeleteUserDialogVisible(true); }}
            />
            <div style={{ zIndex: 1000 }}>
                <ResponseAlert />
            </div>
            <CreateUserModal isVisible={modalOpen} onCancel={() => setModalOpen(false)} onResponse={onModalClose} />
            {isEditModalOpen && userToEdit && (
                <EditUserRolesModal
                    user={userToEdit}
                    open={isEditModalOpen}
                    onClose={handleCloseEditModal}
                    onSave={handleSaveRoles}
                />
            )}
            <ConfirmDialog
                isOpened={confirmDeleteUserDialogVisible}
                title="Confirm Deletion"
                confirmButtonTitle="Delete"
                deleteButtonClassName="red"
                onCancel={() => setConfirmDeleteUserDialogVisible(false)}
                onConfirm={handleConfirmDelete}
                windowStyles={{ width: "500px", height: "220px", top: 250 }}
                promptContainerStyles={{ height: "50px" }}
            >
                Are you sure you want to delete this user?
            </ConfirmDialog>

        </div>
    );
};

