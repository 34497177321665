import { useState } from "react";

export const useButtonState = (initialState = false) => {
    const [isHovered, setHovered] = useState(false);
    const [isSelected, setSelected] = useState(initialState);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    const handleMouseDown = () => {
        setSelected(true);
    };

    const handleMouseUp = () => {
        setSelected(false);
    };

    return {
        isHovered,
        isSelected,
        handleMouseEnter,
        handleMouseLeave,
        handleMouseDown,
        handleMouseUp,
        setSelected
    };
};
