import React, { useEffect, useRef, useState } from "react"
import { tokenStorageName } from "./components/shared";
import { IExtendedUser } from "./components/appStatistics";
import { styles } from "./assets/studioStyles";
import { AppLoadingSpinner } from "./components/appLoadingSpinner";
import { AdminPanel } from "./components/admin/adminPanel";
import { AdminPageType } from "./AdminPageType";
import repo from "./Repository";
import './App.css'

type AdminUsersAppProps = {
    adminPageType: AdminPageType;
    isApplicationAdmin: boolean;
    isStatsAdmin: boolean;
};

export const AdminUsersApp = ({ adminPageType, isApplicationAdmin, isStatsAdmin }: AdminUsersAppProps) => {
    const componentLoaded = useRef(false);
    const [users, setUsers] = useState<IExtendedUser[]>([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    const loadData = async () => {
        const usersResponse = await repo.findExtendedUsers();

        if (usersResponse.isSuccess)
            setUsers(usersResponse.items);
        else
            console.error(`Could not load users: ${usersResponse.message}`);

        setDataLoaded(true);
    }

    useEffect(() => {
        if (componentLoaded.current)
            return;

        componentLoaded.current = true;

        validate().then(x => {
            if (!x) {
                logout();
                return;
            }
            loadData();
        })
    }, []);

    if (!dataLoaded)
        return <div style={styles.container}>
            <AppLoadingSpinner />
        </div>

    return <div style={{ ...styles.container, overflowX: "hidden" }}>
        <div style={{ position: "relative", borderRadius: 0, borderTopRightRadius: 0, width: "calc(100% - 2px)", backgroundColor: "f4f4f4", overflowY: "auto", overflowX: "hidden", height: "100%", display: "flex", flexDirection: "column" }}>
            <AdminPanel users={users} adminPageType={adminPageType} isApplicationAdmin={isApplicationAdmin} isStatsAdmin={isStatsAdmin} />
        </div>
    </div>
}

const validate = async () => {
    const savedToken = localStorage[tokenStorageName];

    if (savedToken)
        repo.setAccessToken(savedToken);

    const isTokenValid = await repo.validateAccessToken();

    if (!isTokenValid) {
        repo.forgetAccessToken();
        return false;
    }

    return true;
}

const logout = () => window.location.href = window.location.origin;